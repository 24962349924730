import { Grid } from "@mui/material";

import { CustomerInformation, InvoiceDetails, InvoiceTotal } from "@APP/components";
import { Amount, Contact, InvoiceLineItem, TaxCategory } from "@APP/types";
import { Provider } from "@APP/constants";

type Props = {
  customerContact?: Contact;
  reference?: string;
  lineItems?: InvoiceLineItem[];
  totalAmountTaxExclusive?: string;
  totalAmountTaxInclusive?: string;
  taxCategories?: TaxCategory[];
  currency?: string;
  invoiceIssueDateTime?: Date;
  invoiceDueDateTime?: Date;
  remainingAmountTaxInclusive?: Amount;
  children?: JSX.Element;
};

const InvoiceSummary = ({
  lineItems,
  totalAmountTaxExclusive,
  totalAmountTaxInclusive,
  currency,
  invoiceDueDateTime,
  invoiceIssueDateTime,
  taxCategories,
  children,
  reference,
  customerContact,
  remainingAmountTaxInclusive,
}: Props) => {
  const addressInfo = {
    address:
      customerContact?.shippingAddress?.addressLines &&
      customerContact.shippingAddress?.addressLines[0],
    addressLine2:
      customerContact?.shippingAddress?.addressLines &&
      customerContact.shippingAddress?.addressLines[1],
    state: customerContact?.shippingAddress?.state,
    city: customerContact?.shippingAddress?.city,
    postcode: customerContact?.shippingAddress?.postcode,
  };
  const addressStringified =
    (addressInfo &&
      Object.values(addressInfo)
        .filter((address) => address)
        .join(", ")) ||
    "-";

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={6} xs={12}>
        <CustomerInformation
          name={customerContact?.name}
          email={customerContact?.email}
          mobile={customerContact?.mobile}
          address={Provider.isMaverick ? addressStringified : undefined}
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <InvoiceDetails
          dateTimeIssued={invoiceIssueDateTime}
          dueDateTime={invoiceDueDateTime}
          reference={reference}
        />
      </Grid>
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
      <Grid item xs={12}>
        <InvoiceTotal
          totalAmountTaxExclusive={totalAmountTaxExclusive}
          totalAmountTaxInclusive={totalAmountTaxInclusive}
          currency={currency}
          lineItems={lineItems}
          taxCategories={taxCategories}
          remainingAmountTaxInclusive={remainingAmountTaxInclusive}
        />
      </Grid>
    </Grid>
  );
};

export default InvoiceSummary;
