import React, { useEffect } from "react";
import * as Yup from "yup";
import { t } from "i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";

import { useAlert } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { RegistrationActions } from "@APP/types";
import { API } from "@APP/services";
import { AppState, setRegistrationPhone } from "@APP/redux";
import { AuthForm, AuthLayout, CommonTextField, Page } from "@APP/components";
import { errorCodeString, formatErrorMessage, getOnlyDigits } from "@APP/utils";

interface Props {
  registrationEmail: AppState["registration"]["registrationEmail"];
  registrationPhone: AppState["registration"]["registrationPhone"];
}

const OTPValidationSchema = () => {
  return Yup.object().shape({
    otp: Yup.string()
      .required("Mobile verification code is required.")
      .matches(/^$|^\d{6}$/, "Invalid mobile verification code."),
  });
};

const INVALID_ONE_TIME_PASSCODE_ERROR_CODE = 1101;

const PhoneVerificationView = ({ registrationEmail, registrationPhone }: Props) => {
  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    (() => {
      if ("OTPCredential" in window) {
        window.navigator["credentials"]
          .get({
            otp: { transport: ["sms"] },
          })
          .then((otp) => {
            otp && handleConfirm({ otp: otp.code });
          });
      }
    })();
  }, []);

  const handleSend = async ({ phone }: { phone: string }) => {
    const phoneFormatted = phone.replace(/\s/g, "");
    setRegistrationPhone(phoneFormatted);
    try {
      await API.register({
        action: RegistrationActions.REGISTER_PHONE,
        phone: phoneFormatted,
        email: registrationEmail,
      });
    } catch (error) {
      alert.open("Error", formatErrorMessage(error));
    }
  };

  const handleResend = async () => {
    otpFormik.resetForm();
    await handleSend({ phone: registrationPhone });
    alert.open(
      "Mobile Verification Code",
      "A new mobile verification code has been sent out again. Please wait a minute to receive it.",
    );
  };

  const handleConfirm = async ({ otp }: { otp: string }) => {
    try {
      await API.register({
        action: RegistrationActions.VERIFY_PHONE,
        email: registrationEmail,
        phone: registrationPhone,
        code: otp,
      });
      history.push(SCREEN_PATHS.REGISTRATION_PASSWORD);
    } catch (error) {
      const errorData = error.response?.data;
      const errorCode = errorData?.errorCode;

      if (errorData?.errorCode === INVALID_ONE_TIME_PASSCODE_ERROR_CODE) {
        const errorCodeHtmlString = errorCodeString(errorCode);
        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          t("Errors.ErrorCodes.1101") + errorCodeHtmlString,
          [{ text: "Okay" }],
        );
      }

      alert.open("Error", formatErrorMessage(error));
    }
  };

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(SCREEN_PATHS.LOGIN);
      }
    };
  }, []);

  const otpFormik = useFormik({
    initialValues: { otp: "" },
    validationSchema: OTPValidationSchema,
    onSubmit: handleConfirm,
  });

  const renderMainContent = () => (
    <AuthForm
      title="Mobile Verification code"
      subtitle={`A verification code has been sent to ${registrationPhone}.`}>
      <Box mt={5}>
        <Typography variant="body2">Please enter the code you received.</Typography>
      </Box>
      <form onSubmit={otpFormik.handleSubmit}>
        <CommonTextField
          label="Mobile Verification code"
          disabled={otpFormik.isSubmitting}
          error={Boolean(otpFormik.touched.otp && otpFormik.errors.otp)}
          helperText={otpFormik.touched.otp && otpFormik.errors.otp}
          name="otp"
          fullWidth
          margin="normal"
          id="mobile-verification-code"
          onBlur={otpFormik.handleBlur}
          onChange={otpFormik.handleChange}
          value={getOnlyDigits(otpFormik.values.otp)}
          inputProps={{ maxLength: 6, autoComplete: "one-time-code" }}
        />
        <Box mt={2}>
          <Button
            color="primary"
            disabled={otpFormik.isSubmitting}
            fullWidth
            size="large"
            id="confirm-button"
            type="submit"
            variant="contained">
            Confirm
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            color="primary"
            fullWidth
            size="large"
            variant="outlined"
            data-testid="auth-form-back-button"
            id="auth-form-back-button"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => history.push(SCREEN_PATHS.REGISTRATION_PHONE)}>
            Back
          </Button>
        </Box>
        <Box mt={3} textAlign="center">
          <Typography variant="body2">
            Didn't receive the code?{" "}
            <Button onClick={handleResend} id="resend-button" color="primary" size="small">
              Resend
            </Button>
          </Typography>
        </Box>
      </form>
    </AuthForm>
  );

  return (
    <Page title="Get Started" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

const mapStateToProps = ({ registration: { registrationEmail, registrationPhone } }: AppState) => ({
  registrationEmail,
  registrationPhone,
});

export default connect(mapStateToProps, null)(PhoneVerificationView);
