import { useTranslation } from "react-i18next";

import { Page, PersonalAndBusinessDetailsForm } from "@APP/components";
import { CompanyType } from "@APP/constants";

const OtherOrgTypesView = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("Common.CompanyType.RegisteredCompany")}>
      <PersonalAndBusinessDetailsForm
        orgType={CompanyType.RegisteredCompany}
        title={t("Common.CompanyType.RegisteredCompany")}
        dataTestIdForCardHeader="registered-company-header"
      />
    </Page>
  );
};

export default OtherOrgTypesView;
