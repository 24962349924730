import { ChangeEvent, DragEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { CommonTextField, NumberFormatCustom, Page } from "@APP/components";
import WcfFooter from "@APP/components/layout/WCFLayout";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getWorkingCapitalFinanceDetails,
  hideLoader,
  setActiveStep,
  setAssetFinanceFormData,
  setFormSubType,
  setFormType,
  setNextPath,
  setNextStepActive,
  setProductType,
  showLoader,
} from "@APP/redux";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import { API } from "@APP/services";
import { WcfForm, Document } from "@APP/types/wcf";
import { assetTypes, urgencyLevels } from "@APP/views/wcf/utils/dropdown";
import { useHistory, useParams } from "react-router-dom";
import { uploadDocuments } from "@APP/services/api";

import OptionCard from "../utils/OptionCard";
import {
  MAX_FILENAME_LENGTH,
  MAX_FILE_SIZE,
  handleKeyPress,
  removeUnderscoreAndCapitalize,
  supportedFileTypes,
} from "../utils/utils";
import { useAlert } from "@APP/hooks";

const useStyles = makeStyles(() => ({
  fileUploadBox: {
    border: "2px dashed #ccc",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    marginTop: "20px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContaier: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "flex-start",
    borderRadius: "5px",
  },
  fileListContainer: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "12px",
    marginTop: "8px",
    maxHeight: "150px",
    overflow: "auto",
  },
  fileItem: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
}));

const StyledGrid = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "2%",
});

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const durations = [
  {
    id: 0,
    duration: "12",
  },
  {
    id: 1,
    duration: "24",
  },
  {
    id: 2,
    duration: "36",
  },
  {
    id: 3,
    duration: "48",
  },
  {
    id: 5,
    duration: "60",
  },
];

function FileUploader({
  id,
  name,
  onUpload,
}: Readonly<{ id: string; name: string; onUpload: (file: File) => void }>) {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      onUpload(event.target.files[0]);
      event.target.value = "";
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      onUpload(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box
      className={classes.fileUploadBox}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      id={"wcfAssetClickToUploadDragNDrop_" + id}>
      <Typography variant="caption">
        <input
          type="file"
          name={name}
          accept=".xlsx, .xls, .xlsm, .docx, .doc, .pdf, .jpeg, .jpg, .txt, .csv, .rtf, .png, .gif, .eml, .tiff, .htm, .html, .ppt, .pptx, .odt, .ods, .msg, .zip, .rar"
          onChange={handleFileSelect}
          style={{ display: "none" }}
          ref={fileInputRef}
          id="assetFinanceFormFileUpload"
        />
        <Link
          href="#"
          id={"wcfAssetClickToUpload_" + id}
          onClick={() => fileInputRef.current?.click()}
          style={{
            cursor: "pointer",
            display: "inline",
          }}>
          Click to upload
        </Link>
      </Typography>
      <Typography variant="caption"> or Drag & drop one document at a time</Typography>
      <Typography variant="caption" sx={{ display: "block" }}>
        xlsx, xls, xlsm, docx, doc, pdf,j peg, jpg, txt, csv, rtf, png, gif, eml, tiff, htm, html
        ,ppt, pptx, odt, ods, msg, zip, rar are supported <br /> (max. 100MB)
      </Typography>
    </Box>
  );
}

function AssetFinanceForm() {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();

  const {
    wcfFormData: { assetFinanceForm },
  } = useSelector(getWorkingCapitalFinanceDetails);
  const { applicationId }: { applicationId: string } = useParams();

  const [productUnit, setProductUnit] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [docData, setDocData] = useState<Document[]>([]);
  const [assetAge, setAssetAge] = useState([
    { label: "New", selected: !!assetFinanceForm.isNew },
    { label: "Used", selected: !!!assetFinanceForm.isNew },
  ]);
  const [assetInsured, setAssetInsured] = useState([
    { label: "Yes", selected: assetFinanceForm.isInsured },
    { label: "No", selected: !assetFinanceForm.isInsured },
  ]);

  const [assetFile, setAssetFile] = useState<(File | Document)[]>([]);
  const [invoiceFile, setInvoiceFile] = useState<(File | Document)[]>([]);
  const [assetError, setAssetError] = useState<(string | null)[]>([]);
  const [invoiceError, setInvoiceError] = useState<(string | null)[]>([]);
  const [financeProductType, setFinanceProductType] = useState<string>("");

  const fileSchema = Yup.mixed()
    .test("fileType", "Unsupported file type", (value) => {
      const assetExtension = value?.assetFile?.name?.split(".")?.pop()?.toLowerCase();
      const invoiceExtension = value?.invoiceFile?.name?.split(".")?.pop()?.toLowerCase();

      if (!value) return true;
      return (
        invoiceExtension === "rar" ||
        assetExtension === "rar" ||
        assetExtension === "msg" ||
        invoiceExtension === "msg" ||
        supportedFileTypes.includes(value?.invoiceFile?.type || value?.assetFile?.type)
      );
    })
    .test("fileSize", "File size is too large", (value) => {
      if (!value) return true;
      return (value?.invoiceFile?.size || value?.assetFile?.size) <= MAX_FILE_SIZE;
    })
    .test("fileNameLength", "File name is too long", (value) => {
      if (!value) return true;
      return (
        (value?.invoiceFile?.name?.length || value?.assetFile?.name?.length) <= MAX_FILENAME_LENGTH
      );
    });

  const validate = (values: {
    deposit: number | null;
    purpose: string;
    manufacturer: string;
    yearOfManufactured: number | null;
    model: string;
    units: number | null;
    pricePerUnit: number | null;
  }) => {
    let errors = {};
    if (values.deposit && values.deposit < 1) {
      errors = { ...errors, deposit: "Units cannot be less than 1" };
    } else if (productUnit * productPrice < (values.deposit as number)) {
      errors = { ...errors, deposit: "Deposit cannot be more than amount financed" };
    }
    if (!values.purpose) {
      errors = { ...errors, purpose: "Please enter the purpose" };
    } else if (values.purpose.length > 300) {
      errors = { ...errors, purpose: "Maximum characters (300) limit reached" };
    }
    if (!values.manufacturer) {
      errors = { ...errors, manufacturer: "This is a required field" };
    } else if (values.manufacturer.length > 100) {
      errors = { ...errors, manufacturer: "Maximum words limit reached" };
    }
    if (values.yearOfManufactured && Number(values.yearOfManufactured) < 1230) {
      errors = { ...errors, yearOfManufactured: "Year cannot be less than 1230" };
    } else if (values.yearOfManufactured && Number(values.yearOfManufactured) > 2130) {
      errors = { ...errors, yearOfManufactured: "Year cannot be greater that 2130" };
    }
    if (!values.model) {
      errors = { ...errors, model: "This is a required field" };
    } else if (values.model.length > 100) {
      errors = { ...errors, model: "Maximum words limit reached" };
    }
    if (Number(values.units) < 1) {
      errors = { ...errors, units: "Units can't be less than 1" };
    } else if (Number(values.units) > 1000000) {
      errors = { ...errors, units: "Units can't be greater than 1000000" };
    }
    if (!values.pricePerUnit) {
      errors = { ...errors, pricePerUnit: "This is a required field" };
    } else if (Number(values.pricePerUnit) < 0.01) {
      errors = { ...errors, pricePerUnit: "Units can't be less than 0.01" };
    } else if (Number(values.pricePerUnit) > 1000000) {
      errors = { ...errors, pricePerUnit: "Units can't be greater than 1000000" };
    }
    return errors;
  };

  const { touched, errors, handleSubmit, handleBlur, values, setFieldValue } = useFormik<
    WcfForm["assetFinanceForm"]
  >({
    initialValues: {
      units: assetFinanceForm.units,
      pricePerUnit: assetFinanceForm.pricePerUnit,
      duration: assetFinanceForm.duration,
      isRefinance: assetFinanceForm.isRefinance || false,
      deposit: assetFinanceForm.deposit,
      purpose: assetFinanceForm.purpose,
      urgency: assetFinanceForm.urgency,
      assetType: assetFinanceForm.assetType,
      model: assetFinanceForm.model,
      manufacturer: assetFinanceForm.manufacturer,
      yearOfManufactured:
        assetFinanceForm.yearOfManufactured === 0 ? null : assetFinanceForm.yearOfManufactured,
      isNew: assetFinanceForm.isNew ? "new" : "used",
      isInsured: assetFinanceForm.isInsured,
      assetPic: assetFinanceForm.assetPic,
      invoicePic: assetFinanceForm.invoicePic,
    },
    validate,
    onSubmit: async () => {},
  });

  const handleDeleteDocument = async (name: string | null, type: number, index: number) => {
    const picArr = type === 1 ? values.assetPic : values.invoicePic;
    const errorArr = type === 1 ? assetError : invoiceError;
    const filteredArray = picArr.filter((_, idx) => idx !== index);
    const errArr = errorArr.filter((_, idx) => idx !== index);
    if (type === 1) {
      setFieldValue("assetPic", filteredArray);
      setAssetError(errArr);
      setAssetFile(filteredArray);
    }
    if (type === 2) {
      setFieldValue("invoicePic", filteredArray);
      setInvoiceError(errArr);
      setInvoiceFile(filteredArray);
    }

    if (name) {
      var id = "";
      for (let i = docData.length - 1; i >= 0; i--) {
        if (docData[i].id === name) {
          id = docData[i].id;
        }
      }
      if (id) {
        try {
          dispatch(showLoader());
          await API.deleteDocumentById(applicationId, id);
          if (type === 1) {
            setFieldValue("assetPic", filteredArray);
            setAssetFile(filteredArray);
            setAssetError((prevVal) => prevVal.filter((e) => e === null));
          } else {
            setInvoiceFile(filteredArray);
            setInvoiceError((prevVal) => prevVal.filter((e) => e === null));
          }
          await retrieveDocs();
        } catch (error) {
          // do nothing for now
        } finally {
          dispatch(hideLoader());
        }
      }
    }
  };

  const handleAssetPicUpload = async (file: File) => {
    try {
      await fileSchema.validate({ invoiceFile: file });
      const files = values.assetPic.length > 0 ? [...values.assetPic, file] : [file];
      setFieldValue("assetPic", files);
      if (file) {
        setAssetFile((prevVal) => (prevVal?.length > 0 ? [...prevVal, file] : [file]));
      } else {
        setAssetFile([]);
      }
      handleAssetFileValidation(files);
      dispatch(setNextStepActive(false));
    } catch (error) {
      alert.open("Error", error.message);
    }
  };

  const handleInvoicePicUpload = async (file: File) => {
    try {
      await fileSchema.validate({ invoiceFile: file });
      const files = values.invoicePic.length > 0 ? [...values.invoicePic, file] : [file];
      setFieldValue("invoicePic", files);
      if (file) {
        setInvoiceFile((prevVal) => (prevVal?.length > 0 ? [...prevVal, file] : [file]));
      } else {
        setInvoiceFile([]);
      }
      handleInvoiceFileValidation(files);
      dispatch(setNextStepActive(false));
    } catch (error) {
      alert.open("Error", error.message);
    }
  };

  const handleDuration = (newDuration: string | null) => {
    setFieldValue("duration", newDuration);
  };

  const handleAgeChange = (label: string) => {
    const selectedAge = assetAge.map((age) =>
      age.label === label ? { ...age, selected: true } : { ...age, selected: false },
    );
    setAssetAge(selectedAge);
    setFieldValue("isNew", label === "New");
    dispatch(
      setAssetFinanceFormData({
        ...values,
        isNew: label,
      }),
    );
  };

  const handleInsuranceChange = (label: string) => {
    const selectedInsurance = assetInsured.map((insure) =>
      insure.label === label ? { ...insure, selected: true } : { ...insure, selected: false },
    );
    setAssetInsured(selectedInsurance);
    setFieldValue("isInsured", label === "Yes");
    dispatch(
      setAssetFinanceFormData({
        ...values,
        isInsured: label === "Yes",
      }),
    );
  };

  const handleAssetFileValidation = async (files: (File | Document)[]) => {
    try {
      dispatch(showLoader());
      const formData = new FormData();
      formData.append("file", files[files.length - 1] as unknown as File);
      formData.append("documentType", "asset_photo");
      await uploadDocuments(applicationId, formData);
      await retrieveDocs();
      if (files.length > assetError.length) {
        const err = assetError;
        err.push(null);
        setAssetError(err);
      }
      setAssetError((prevVal) => prevVal.filter((e) => e === null));
    } catch (error) {
      if (error.message.includes("Request failed")) {
        try {
          await retrieveDocs();
        } catch (error) {
          // do nothing for now
        }
        alert.open("Error", "Something went wrong please try again");
      }
    } finally {
      dispatch(hideLoader());
    }
    if (assetError.some((msg) => msg === "Unsupported file type")) {
      dispatch(setNextStepActive(false));
    } else {
      dispatch(setNextStepActive(true));
      const newValues = { ...values, isNew: values.isNew ? "new" : "used" };
      dispatch(setAssetFinanceFormData(newValues));
    }
  };

  const handleInvoiceFileValidation = async (files: (File | Document)[]) => {
    try {
      dispatch(showLoader());
      const formData = new FormData();
      formData.append("file", files[files.length - 1] as unknown as File);
      formData.append("documentType", "supplier_invoice");
      await uploadDocuments(applicationId, formData);
      await retrieveDocs();
      if (files.length > invoiceError.length) {
        const err = invoiceError;
        err.push(null);
        setInvoiceError(err);
      }
      setInvoiceError((prevVal) => prevVal.filter((e) => e === null));
    } catch (error) {
      if (error.message.includes("Request failed")) {
        try {
          await retrieveDocs();
        } catch (error) {
          // do nothing for now
        }
        alert.open("Error", "Something went wrong please try again");
      }
    } finally {
      dispatch(hideLoader());
    }
    if (invoiceError.some((msg) => msg === "Unsupported file type")) {
      dispatch(setNextStepActive(false));
    } else {
      dispatch(setNextStepActive(true));
      const newValues = { ...values, isNew: values.isNew ? "new" : "used" };
      dispatch(setAssetFinanceFormData(newValues));
    }
  };

  useEffect(() => {
    (async () => {
      await retrieveApplication();
    })();
  }, []);

  const retrieveApplication = async () => {
    const res = await API.getApplicationDetailsById(applicationId);
    if (res && res?.data && res?.data?.praeturaStatus)
      history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
    setFinanceProductType(res.data.assetFinanceRequest?.productType as string);
    const isInvoice = !res.data.assetFinanceRequest;
    dispatch(setProductType(res?.data?.assetFinanceRequest?.productType as string));
    dispatch(setFormType(isInvoice ? "Invoice Finance" : "Asset Finance"));
    if (!isInvoice)
      dispatch(
        setFormSubType(
          removeUnderscoreAndCapitalize(res.data.assetFinanceRequest?.productType ?? ""),
        ),
      );
  };

  useEffect(() => {
    dispatch(setNextPath(`${SCREEN_PATHS.WCF_APPLICANT_DETAILS}/${applicationId}`));
    dispatch(setActiveStep(2));
    setAssetFile(values.assetPic);
    setInvoiceFile(values.invoicePic);
  }, []);

  useEffect(() => {
    if (
      assetError.some((msg) => msg === "Unsupported file type") ||
      invoiceError.some((msg) => msg === "Unsupported file type")
    ) {
      dispatch(setNextStepActive(false));
    }
  }, [assetError, invoiceError]);

  useEffect(() => {
    if (values.pricePerUnit && (values?.units as number) > 0) {
      setProductPrice(values.pricePerUnit as number);
      setProductUnit(values.units as number);
    }
  }, [values.pricePerUnit, values.units]);

  const retrieveDocs = async () => {
    const res = await API.getAllDocuments(applicationId);
    const docData1: Document[] = res?.data?.documents;
    setDocData(docData1);
    const assetArr: File[] = [];
    const invoiceArr: File[] = [];
    docData1.forEach((doc) => {
      if (doc.type === "asset_photo") {
        assetArr.push(doc as unknown as File);
      } else {
        invoiceArr.push(doc as unknown as File);
      }
    });
    setAssetFile(assetArr);
    setFieldValue("assetPic", assetArr);
    setInvoiceFile(invoiceArr);
    setFieldValue("invoicePic", invoiceArr);
    if (assetError.length === 0) {
      docData1.forEach((doc) => {
        if (doc.type === "asset_photo") setAssetError((prevVal) => [...prevVal, null]);
      });
    }
    if (invoiceError.length === 0) {
      docData1.forEach((doc) => {
        if (doc.type === "supplier_invoice") setInvoiceError((prevVal) => [...prevVal, null]);
      });
    }
  };

  useEffect(() => {
    (async () => {
      await retrieveDocs();
    })();
  }, []);

  return (
    <Page className={classes.container} p={0}>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Box p={3}>
          <Card elevation={1} className={classes.cardContaier}>
            <WcfStepper style={{ width: "100%" }} />
            <Grid container mt={1}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  component="p"
                  fontWeight={600}
                  color="primary"
                  paddingBottom={2}
                  id="assetFinanceFormTitle">
                  Your asset finance application
                </Typography>
                <Grid container spacing={1} pb={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Asset Finance:</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Please tell us how much you want to borrow and for what purpose.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} pb={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Tell us about the amount you would like to borrow.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      How much does the asset you want to finance cost?
                    </Typography>
                  </Grid>
                </Grid>
                <StyledGrid sx={{ pb: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <NumberFormatCustom
                        label="Number of units:"
                        name="units"
                        id="wcfAssetFormNoOfUnitsInput"
                        value={values.units}
                        error={Boolean(touched.units && errors.units)}
                        helperText={touched.units && errors.units}
                        onChange={(e) => {
                          setFieldValue("units", e.target.value);
                          dispatch(
                            setAssetFinanceFormData({
                              ...values,
                              units: Number(e.target.value),
                            }),
                          );
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        allowNegative={false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <NumberFormatCustom
                        label="Price per unit(Excl VAT): *"
                        name="pricePerUnit"
                        id="wcfAssetFormPricePerUnitInput"
                        value={values.pricePerUnit}
                        onChange={(e) => {
                          setFieldValue("pricePerUnit", e.target.value);
                          dispatch(
                            setAssetFinanceFormData({
                              ...values,
                              pricePerUnit: Number(e.target.value),
                            }),
                          );
                        }}
                        error={Boolean(touched.pricePerUnit && errors.pricePerUnit)}
                        helperText={touched.pricePerUnit && errors.pricePerUnit}
                        onBlur={handleBlur}
                        allowNegative={false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "100%" }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">For how many months?: *</Typography>
                      <ToggleButtonGroup
                        color="primary"
                        id="wcfAssetFormDurationInput"
                        value={values.duration}
                        exclusive
                        onChange={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          handleDuration(target.value);
                          dispatch(
                            setAssetFinanceFormData({
                              ...values,
                              duration: target.value,
                            }),
                          );
                        }}
                        aria-label="duration"
                        sx={{ width: "100%" }}>
                        {durations.map((option) => (
                          <ToggleButton
                            value={option.duration}
                            key={option.id}
                            sx={{
                              width: "100%",
                              color: "#808080",
                            }}>
                            {option.duration}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container marginTop={1}>
                    {financeProductType === "finance_lease" ? (
                      <Typography variant="body2" color="textSecondary">
                        If you wish to pay a higher first monthly instalment to benefit from a
                        better rate, please specify the amount here.
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        The full VAT amount will need to be paid upfront. If you wish to pay a
                        deposit (other than the VAT) to benefit from a better rate, specify the
                        amount here.
                      </Typography>
                    )}
                    <Grid marginTop="4%" item xs={12}>
                      <NumberFormatCustom
                        label={
                          financeProductType === "finance_lease"
                            ? "initial rent"
                            : "initial deposit"
                        }
                        name="deposit"
                        id="wcfAssetFormDepositInput"
                        error={Boolean(touched.deposit && errors.deposit)}
                        helperText={touched.deposit && errors.deposit}
                        value={values.deposit}
                        allowNegative={false}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setFieldValue("deposit", "");
                            dispatch(
                              setAssetFinanceFormData({
                                ...values,
                                deposit: null,
                              }),
                            );
                          } else {
                            setFieldValue("deposit", e.target.value);
                            dispatch(
                              setAssetFinanceFormData({
                                ...values,
                                deposit: Number(e.target.value),
                              }),
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        sx={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container marginTop={1}>
                    <Grid item xs={12}>
                      <CommonTextField
                        label="Funding Purpose:* "
                        id="wcfAssetFormPurposeInput"
                        value={values.purpose}
                        helperText={touched.purpose && errors.purpose}
                        error={Boolean(touched.purpose && errors.purpose)}
                        onChange={(e) => {
                          setFieldValue("purpose", e.target.value);
                          dispatch(
                            setAssetFinanceFormData({
                              ...values,
                              purpose: e.target.value,
                            }),
                          );
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        multiline
                        rows={3}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container marginTop={1}>
                    <Grid item xs={12}>
                      <TextField
                        name="urgency"
                        id="wcfAssetFormUrgencyInput"
                        select
                        error={Boolean(touched.urgency && errors.urgency)}
                        helperText={touched.urgency && errors.urgency}
                        label="When do yo need finance? *"
                        value={values.urgency}
                        onChange={(e) => {
                          setFieldValue("urgency", e.target.value);
                          dispatch(
                            setAssetFinanceFormData({
                              ...values,
                              urgency: e.target.value,
                            }),
                          );
                        }}
                        onBlur={handleBlur}
                        sx={{ width: "100%" }}>
                        {urgencyLevels.map(({ label, value }) => (
                          <MenuItem
                            key={value}
                            value={value}
                            id={"wcfAssetFormUrgencySelect_" + value}
                            style={getStyles(value, [], theme)}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </StyledGrid>
                <Typography variant="h5" m="28px 0px 16px">
                  Tell us more about the asset you want financing for
                </Typography>
                <StyledGrid>
                  <Grid container marginTop={1}>
                    <Grid item xs={12}>
                      <TextField
                        name="assetType"
                        id="wcfAssetFormAssetTypeInput"
                        select
                        label="Type of asset to be financed: *"
                        value={values.assetType}
                        error={Boolean(touched.assetType && errors.assetType)}
                        helperText={touched.assetType && errors.assetType}
                        onChange={(e) => {
                          setFieldValue("assetType", e.target.value);
                          dispatch(
                            setAssetFinanceFormData({ ...values, assetType: e.target.value }),
                          );
                        }}
                        onBlur={handleBlur}
                        sx={{ width: "100%" }}
                        defaultValue={assetTypes[0].value}>
                        {assetTypes.map(({ label, value }) => (
                          <MenuItem
                            key={value}
                            value={value}
                            id={"wcfAssetFormAssetTypeSelect_" + value}
                            style={getStyles(value, [], theme)}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container marginTop={1}>
                    <Grid item xs={12}>
                      <CommonTextField
                        label="Model: *"
                        id="wcfAssetFormModelInput"
                        value={values.model}
                        error={Boolean(touched.model && errors.model)}
                        helperText={touched.model && errors.model}
                        onChange={(e) => {
                          setFieldValue("model", e.target.value);
                          dispatch(setAssetFinanceFormData({ ...values, model: e.target.value }));
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container spacing={1} marginTop={0.5}>
                    <Grid item xs={12} md={6}>
                      <CommonTextField
                        label="Manufacturer: *"
                        id="wcfAssetFormManufacturerInput"
                        error={Boolean(touched.manufacturer && errors.manufacturer)}
                        helperText={touched.manufacturer && errors.manufacturer}
                        value={values.manufacturer}
                        onChange={(e) => {
                          setFieldValue("manufacturer", e.target.value);
                          dispatch(
                            setAssetFinanceFormData({
                              ...values,
                              manufacturer: e.target.value,
                            }),
                          );
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <NumberFormatCustom
                        label="In which year it was manufactured?"
                        id="wcfAssetFormYearOfManufacturedInput"
                        name="yearOfManufactured"
                        error={Boolean(touched.yearOfManufactured && errors.yearOfManufactured)}
                        helperText={touched.yearOfManufactured && errors.yearOfManufactured}
                        value={values.yearOfManufactured}
                        onChange={(e) => {
                          setFieldValue("yearOfManufactured", e.target.value);
                          dispatch(
                            setAssetFinanceFormData({
                              ...values,
                              yearOfManufactured: Number(e.target.value),
                            }),
                          );
                        }}
                        allowNegative={false}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        placeholder="YYYY"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">Is the asset new or used?</Typography>
                      <Grid display="flex" gap={1}>
                        {assetAge.map((value) => (
                          <OptionCard
                            key={value.label}
                            label={value.label}
                            selected={value.selected}
                            id={"wcfAssetFormAssetConditionInput" + value.label}
                            props={{ onClick: () => handleAgeChange(value.label) }}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">Will the asset be insured? *</Typography>
                      <Grid display="flex" gap={1}>
                        {assetInsured.map((value) => (
                          <OptionCard
                            id={"wcfAssetFormAssetInsuredInput" + value.label}
                            key={value.label}
                            label={value.label}
                            selected={value.selected}
                            props={{ onClick: () => handleInsuranceChange(value.label) }}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>
                        In case the asset is used, do you have a picture of the asset?
                      </Typography>
                      <FileUploader
                        name="assetPic"
                        onUpload={handleAssetPicUpload}
                        id={"wcfAssetFormAssetAttachment"}
                      />
                      {assetFile && values.assetPic.length > 0 && (
                        <Grid container className={classes.fileListContainer}>
                          {values.assetPic.map((pic, index) => (
                            <Grid
                              item
                              xs={12}
                              key={`${pic.name}-${index}`}
                              className={classes.fileItem}>
                              <Typography variant="caption">{pic.name}</Typography>
                              <IconButton
                                id={"wcfAssetFormAssetAttachmentDelete"}
                                onClick={() =>
                                  handleDeleteDocument(
                                    pic instanceof File ? pic.name : pic.id,
                                    1,
                                    index,
                                  )
                                }
                                sx={{ padding: "0px" }}>
                                <span style={{ cursor: "pointer", fontSize: "15px" }}>
                                  &#10006; {/* Unicode character for 'multiplication x' */}
                                </span>
                              </IconButton>
                              {assetError[index] !== null && (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  id={"wcfAssetFormAssetAttachmentError"}>
                                  {assetError[index]}
                                </Typography>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>Please upload a quote or invoice if you have any:</Typography>
                      <FileUploader
                        name="invoicePic"
                        onUpload={handleInvoicePicUpload}
                        id={"wcfAssetFormInvoiceAttachment"}
                      />
                      {invoiceFile && values.invoicePic.length > 0 && (
                        <Grid container className={classes.fileListContainer}>
                          {values.invoicePic.map((pic, index) => (
                            <Grid
                              item
                              xs={12}
                              key={`${pic.name}-${index}`}
                              className={classes.fileItem}>
                              <Typography variant="caption">{pic.name}</Typography>
                              <IconButton
                                id={"wcfAssetFormInvoiceAttachmentDelete"}
                                onClick={() =>
                                  handleDeleteDocument(
                                    pic instanceof File ? pic.name : pic.id,
                                    2,
                                    index,
                                  )
                                }
                                sx={{ padding: "0px" }}>
                                <span style={{ cursor: "pointer", fontSize: "15px" }}>
                                  &#10006; {/* Unicode character for 'multiplication x' */}
                                </span>
                              </IconButton>
                              {invoiceError[index] !== null && (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  id={"wcfAssetFormInvoiceAttachmentError"}>
                                  {invoiceError[index]}
                                </Typography>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </StyledGrid>
              </Grid>
              <Grid item xs={0} md={6} />
            </Grid>
          </Card>
        </Box>
        <Box flexGrow={1} />
        <WcfFooter
          displaySaveAndExitButton={true}
          displayPrevButton={true}
          displayNextButton={true}
          handleSubmit={handleSubmit}
        />
      </form>
    </Page>
  );
}

export default AssetFinanceForm;
