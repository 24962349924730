import { useEffect } from "react";
import { useSelector } from "react-redux";
import { TFunction, useTranslation } from "react-i18next";
import { endOfDay, isBefore, isValid, startOfDay } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Box, Button, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { CommonDatePicker, IconWithTooltip, NumberFormatCustom } from "@APP/components";
import { getForecastEndDate } from "@APP/redux";
import {
  capitalize,
  formatCurrency,
  getCurrencySymbol,
  getPriceInNumberFormat,
  handleAriaActiveDescendantChange,
} from "@APP/utils";
import { TxType } from "@APP/types";
import CONFIG from "@APP/config";

import { ForecastManualTransactionWithSelected } from "./CashflowForecastView";

interface Props {
  handleAddPayment: (data: ForecastManualTransactionWithSelected) => void;
}

const MAX_PAYMENT_AMOUNT = 1000000000000;

const getOtherPaymentInputValidationSchema = (forecastEndDate: Date, t: TFunction) => {
  return Yup.object().shape({
    amount: Yup.string()
      .required(t("Errors.CashflowForecasting.Validation.OtherPayments.AmountRequired"))
      .test(
        "isAmountValid",
        t("Errors.CashflowForecasting.Validation.OtherPayments.AmountRequired"),
        (value?: string) => {
          if (!value) return false;
          const priceAsNumber = getPriceInNumberFormat(value);
          if (isNaN(priceAsNumber)) return false;
          if (priceAsNumber === 0) return false;
          return true;
        },
      )
      .test(
        "notExceedAmountOfPayment",
        t("Errors.CashflowForecasting.Validation.OtherPayments.AmountMax", {
          MAX_AMOUNT: formatCurrency(MAX_PAYMENT_AMOUNT, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
        }),
        (value?: string) => {
          if (!value) return true;
          const priceAsNumber = getPriceInNumberFormat(value);
          if (isNaN(priceAsNumber)) return true;

          return priceAsNumber < MAX_PAYMENT_AMOUNT;
        },
      ),
    invoiceType: Yup.string().required(
      t("Errors.CashflowForecasting.Validation.OtherPayments.TypeRequired"),
    ),
    paymentDate: Yup.date()
      .nullable()
      .required(t("Errors.CashflowForecasting.Validation.OtherPayments.DateRequired"))
      .typeError(t("Errors.CashflowForecasting.Validation.OtherPayments.DateValid"))
      .isDateCorrectlyFormed(t("Errors.CashflowForecasting.Validation.OtherPayments.DateValid"))
      .test(
        "paymentDate",
        t("Errors.CashflowForecasting.Validation.OtherPayments.DateValid"),
        (value) => {
          if (!value) return false;
          return isValid(new Date(value));
        },
      )
      .min(
        startOfDay(new Date()),
        t("Errors.CashflowForecasting.Validation.OtherPayments.DatePast"),
      )
      .max(
        endOfDay(new Date(forecastEndDate)),
        t("Errors.CashflowForecasting.Validation.OtherPayments.DateWithinForecast"),
      ),
  });
};

const paymentTypes = [
  { label: capitalize(TxType.DEBIT), type: TxType.DEBIT },
  { label: capitalize(TxType.CREDIT), type: TxType.CREDIT },
];

const OtherPayments = ({ handleAddPayment }: Props) => {
  const forecastEndDate = useSelector(getForecastEndDate);
  const { t } = useTranslation();

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    resetForm,
    isValid,
    dirty,
  } = useFormik({
    validationSchema: getOtherPaymentInputValidationSchema(forecastEndDate, t),
    initialValues: {
      paymentDate: null,
      amount: "",
      currency: CONFIG.INPUTS.SUPPORTED_CURRENCIES[0],
      invoiceType: "" as TxType,
    },
    onSubmit: ({ amount, invoiceType, paymentDate, currency }) => {
      const newPayment = {
        date: paymentDate ?? new Date(),
        amount: { amount: getPriceInNumberFormat(amount).toString(), currency },
        type: invoiceType,
        selected: true,
      };

      handleAddPayment(newPayment);
      resetForm();
    },
  });

  useEffect(() => {
    /*
      If the forecast end date goes before payment date that user selected for custom payment
      we need to reset payment date as the payment date is invalid.
    */
    values.paymentDate &&
      isBefore(new Date(forecastEndDate), new Date(values.paymentDate)) &&
      setFieldValue("paymentDate", "", true);
  }, [forecastEndDate]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            New Payment
          </Typography>
          <Grid container>
            <Grid item xs={11}>
              <CommonDatePicker
                slotProps={{
                  textField: {
                    error: Boolean(touched.paymentDate && errors.paymentDate),
                    helperText: touched.paymentDate && errors.paymentDate,
                    name: "paymentDate",
                    margin: "dense",
                    onClick: () => {
                      !touched.paymentDate && setFieldTouched("paymentDate", true, false);
                    },
                  },
                }}
                onChange={(value) => {
                  !touched.paymentDate && setFieldTouched("paymentDate", value, false);
                  setFieldValue("paymentDate", value, true);
                }}
                value={values.paymentDate}
                maxDate={new Date(forecastEndDate)}
                label="Payment Date"
                data-testid="forecast-other-payment-date"
                customValidation
              />
            </Grid>
            <Grid item xs={1}>
              <IconWithTooltip
                color="primary"
                title="You can select a payment date only within the forecast period."
                style={{ position: "relative", top: 16, left: 8 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <NumberFormatCustom
            label="Payment Amount"
            type="text"
            fullWidth
            margin="normal"
            value={values.amount}
            name="amount"
            onBlur={handleBlur}
            onChange={handleChange}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={true}
            allowLeadingZeros={false}
            error={Boolean(touched.amount && errors.amount)}
            helperText={touched.amount && errors.amount}
            inputProps={{
              "data-testid": "other-payment-totalAmountTaxInclusive-input",
            }}
            prefix={getCurrencySymbol(values.currency)}
            variant="outlined"
            id="otherPaymentTotalAmountTaxInclusive"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={11}>
              <TextField
                fullWidth
                select
                label="Type"
                data-testid="other-payment-invoice-type"
                name="invoiceType"
                margin="normal"
                value={values.invoiceType}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.invoiceType && errors.invoiceType)}
                helperText={touched.invoiceType && errors?.invoiceType ? errors?.invoiceType : null}
                // to correct the vertical alignment on helperText for CNCF if there is no error
                sx={{ mb: touched.invoiceType && errors?.invoiceType ? 0 : 3.75 }}
                id="invoiceType"
                inputProps={{ id: "invoiceType" }}
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      "aria-activedescendant": `other-payment-type-option-${values.invoiceType}`,
                      onFocus: handleAriaActiveDescendantChange,
                    },
                  },
                }}>
                {paymentTypes.map(({ label, type }) => (
                  <MenuItem key={type} id={`other-payment-type-option-${type}`} value={type}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={1}>
              <IconWithTooltip
                color="primary"
                title="
                    A debit entry is cash leaving your account, and a credit entry is cash arriving
                    in account."
                style={{ position: "relative", top: 23, left: 8 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={Boolean(!isValid || !dirty)}
              data-testid="other-payment-add-button"
              id="otherPaymentAddButton">
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default OtherPayments;
