import APP_LOGO from "./logo.svg";
import ERP_ICON from "./erpIcon.svg";
import GOOGLE_PLAY from "./storeBadges/google-play-badge.svg";
import IOS_APP_STORE from "./storeBadges/app-store-badge.svg";
import SQUARE_LOGO from "./square_logo.svg";
import QUICK_BOOKS_LOGO from "@APP/assets/quick-books-logo.png";
import SAGE_LOGO from "@APP/assets/sage-logo.png";
import XERO_LOGO from "@APP/assets/xero-logo.png";
import SUCCESS_GIF from "@APP/assets/success.gif";
import NO_IMAGE_AVAILABLE from "@APP/assets/no-image-available.png";

interface Assets {
  IMAGES: {
    APP_LOGO: string;
    ERP_ICON: string;
    GOOGLE_PLAY: string;
    IOS_APP_STORE: string;
    SQUARE_LOGO: string;
    SUCCESS_GIF: string;
    QUICK_BOOKS_LOGO: string;
    SAGE_LOGO: string;
    XERO_LOGO: string;
    NO_IMAGE_AVAILABLE: string;
  };
}

export const IMAGES: Assets["IMAGES"] = {
  APP_LOGO,
  ERP_ICON,
  GOOGLE_PLAY,
  IOS_APP_STORE,
  SQUARE_LOGO,
  SUCCESS_GIF,
  QUICK_BOOKS_LOGO,
  SAGE_LOGO,
  XERO_LOGO,
  NO_IMAGE_AVAILABLE,
};

export default {
  IMAGES,
} as Assets;
