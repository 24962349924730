import { useCallback } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, CardActions, CardHeader, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BankAccountsTiled, DisplayedBankData, NoLinkedBankAccountsContent } from "@APP/components";
import { getBankAccounts, getPermissions } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { TabsName } from "@APP/constants";
import CONFIG from "@APP/config";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  cardActionsContainer: {
    justifyContent: "center",
  },
  cardActionButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const BankAccounts = () => {
  const bankAccounts = useSelector(getBankAccounts);
  const permissions = useSelector(getPermissions);
  const classes = useStyles();
  const { t } = useTranslation();

  const renderBankAccountsList = useCallback(() => {
    if (!bankAccounts?.length) {
      return <NoLinkedBankAccountsContent />;
    }

    const bankAccountsCommonFormat = bankAccounts?.map(
      ({ account: { identification: accountNumber, name: accountName }, bankInfo, balance }) =>
        ({
          accountNumber,
          accountName,
          balance,
          bankInfo,
          id: "bankAccountsCommonFormat",
        } as DisplayedBankData),
    );

    return (
      <BankAccountsTiled
        bankAccounts={bankAccountsCommonFormat}
        showLinkedLedgerContent
        showLinkedLedgerAlert
      />
    );
  }, [bankAccounts]);

  return (
    <Card elevation={4}>
      <CardHeader
        subheader={t("DashboardLayout.linkedBankAccountLabel")}
        subheaderTypographyProps={{ "data-testid": "card-header-linked-bank-subtitle" }}
        title="Bank Accounts"
        titleTypographyProps={{ "data-testid": "card-header-linked-bank-title", component: "h4" }}
        data-testid="bank-accounts-card-header"
      />
      <Divider />
      {renderBankAccountsList()}
      {!CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE && permissions.bank_account.create ? (
        <>
          <Divider />
          <CardActions className={classes.cardActionsContainer}>
            <Button
              className={classes.cardActionButton}
              color="primary"
              variant="contained"
              component={RouterLink}
              data-testid="link-bank-accounts-button"
              to={`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.BANK_ACCOUNTS}`}
              id="linkBankAccountsButton">
              Link additional Bank Accounts
            </Button>
          </CardActions>
        </>
      ) : null}
    </Card>
  );
};

export default BankAccounts;
