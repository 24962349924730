import React from "react";
import { Box } from "@mui/material";

export interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index }: ITabPanelProps): JSX.Element {
  return (
    <>
      {value === index && (
        <Box
          role="tabpanel"
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          pt={3}
          display="flex"
          flexDirection="column"
          flexGrow={1}>
          {children}
        </Box>
      )}
    </>
  );
}

export default TabPanel;
