import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import queryString from "query-string";

import { ConsentContent, FooterActionsButtons } from "@APP/components";
import { useAlert, useReconsentBanks, useSetupConsentInitiation } from "@APP/hooks";
import { TabsName } from "@APP/constants";
import {
  fetchUserData,
  getDashboardAppLoading,
  getReconsentBanks,
  hideLoader,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";
import { capitalize } from "@APP/utils";

const SetupConsentToAccessAccount = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const setupConsentInitiation = useSetupConsentInitiation();
  const reconsent = useReconsentBanks();
  const alert = useAlert();
  const { t } = useTranslation();

  const { state } = useLocation<{
    bankId: string;
    provider: string;
    bankName: string;
    redirectUrl: string;
  }>();

  const reconsentBanks = useSelector(getReconsentBanks);
  const loading = useSelector(getDashboardAppLoading);

  const queryParams = queryString.parse(history.location?.search ?? "") as { tab: string };
  const bankProvider: string = state?.provider;
  const isConsentExpired = queryParams.tab === TabsName.CONSENT_EXPIRED && !!reconsentBanks.length;

  const bankName = useMemo(() => {
    if (isConsentExpired) {
      return capitalize(reconsentBanks[0].custodianName);
    }

    return state?.bankName;
  }, [reconsentBanks, state]);

  useEffect(() => {
    const fetchReconsentBanks = async () => {
      try {
        dispatch(showLoader());
        const data = await reconsent.fetchReconsentBanks();

        if (!data?.length) {
          history.replace(SCREEN_PATHS.SETTINGS);
        }
      } catch (error) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message"),
          [
            {
              text: "Try Again",
              onClick: fetchReconsentBanks,
            },
            { text: "Okay", onClick: () => history.replace(CONFIG.NAVIGATION.MAIN_PAGE) },
          ],
        );
      }

      dispatch(hideLoader());
    };

    if (!reconsentBanks.length && queryParams.tab === TabsName.CONSENT_EXPIRED) {
      fetchReconsentBanks();
    }
  }, []);

  const handleContinue = async () => {
    try {
      if (isConsentExpired) {
        dispatch(showLoader());
        await reconsent.updateBankConsents();
        await dispatch(fetchUserData());

        history.replace(CONFIG.NAVIGATION.MAIN_PAGE);
      } else {
        await setupConsentInitiation();
      }
    } catch (error) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
      );
    }

    dispatch(hideLoader());
  };

  const handleRevoke = async () => {
    try {
      dispatch(showLoader());
      await reconsent.revokeBankConsents();
      await dispatch(fetchUserData());

      history.replace(CONFIG.NAVIGATION.MAIN_PAGE);
    } catch (error) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
      );
    }

    dispatch(hideLoader());
  };

  if (loading && queryParams.tab === TabsName.CONSENT_EXPIRED) {
    return null;
  }

  return (
    <>
      <ConsentContent
        bankName={bankName}
        bankProvider={bankProvider}
        isExpired={isConsentExpired}
      />
      <Divider />
      <Box pl={2} pr={2} pb={2}>
        <FooterActionsButtons
          backButtonText={isConsentExpired ? "I do not approve, take me back" : "Cancel"}
          continueButtonText="Approve"
          handleBackButton={isConsentExpired ? handleRevoke : history.goBack}
          handleContinue={handleContinue}
          backButtonDataTestId="consent-to-access-account-cancel-button"
          continueButtonDataTestId="consent-to-access-account-continue-button"
        />
      </Box>
    </>
  );
};

export default SetupConsentToAccessAccount;
