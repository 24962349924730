import { createReducer } from "deox";
import { addDays, endOfDay } from "date-fns";

import {
  setDefaultForecastState,
  setForecastChartData,
  setForecastData,
  setForecastEndDate,
  setForecastId,
  setForecastLoading,
  setManualBalanceMode,
} from "../actions";

import { ForecastResponse } from "@APP/types";

export interface ForecastState {
  forecastId: string;
  data: null | ForecastResponse;
  forecastEndDate: Date;
  loading: boolean;
  manualBalanceMode: boolean;
}

const defaultState: ForecastState = {
  forecastId: "",
  data: null,
  forecastEndDate: endOfDay(addDays(new Date(), 6)),
  loading: true,
  manualBalanceMode: false,
};

const forecastReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setForecastLoading, (state, { payload }) => ({ ...state, loading: payload })),
  handleAction(setForecastData, (state, { payload }) => ({ ...state, data: payload })),
  handleAction(setForecastId, (state, { payload }) => ({ ...state, forecastId: payload })),
  handleAction(setManualBalanceMode, (state, { payload }) => ({
    ...state,
    manualBalanceMode: payload,
  })),
  handleAction(setForecastChartData, (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      unpaidInvoices: state.data!.unpaidInvoices,
      unpaidBills: state.data!.unpaidBills,
      forecast: payload,
    },
  })),
  handleAction(setForecastEndDate, (state, { payload }) => ({
    ...state,
    forecastEndDate: payload,
  })),
  handleAction(setDefaultForecastState, () => defaultState),
]);

export { forecastReducer };
