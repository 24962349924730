import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { AlertContext } from "./AlertProvider";

const AlertModal = () => {
  const { alertOptions, closeAlert, showAlert } = React.useContext(AlertContext);
  const {
    title = "",
    message = "",
    buttons = [{ text: "Close", onClick: closeAlert }],
    content,
  } = alertOptions ?? {};

  if (!showAlert) return null;

  const handleButtonPress = (buttonIndex: number) => {
    const buttonProps = buttons?.[buttonIndex];
    if (buttonProps && buttonProps.onClick) buttonProps.onClick(buttonProps.text);
    closeAlert();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      style={{ zIndex: 15000 }}
      PaperProps={{ elevation: 0 }}
      open
      {...alertOptions?.dialogProps}>
      {content ? (
        content
      ) : (
        <>
          {!!title && (
            <DialogTitle variant="h4" data-testid="modal-window-title" id="modalWindowTitle">
              {title}
            </DialogTitle>
          )}
          <DialogContent data-testid="modal-window-message" id="modalWindowMessage">
            <DialogContentText>
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {buttons.map(({ text = "Close" }, index) => (
              <Button
                key={`${text}${index}`}
                onClick={() => handleButtonPress(index)}
                color="primary"
                data-testid={`modal-window-button-${index}`}
                id={`modalWindowButton-${index}`}>
                {text}
              </Button>
            ))}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AlertModal;
