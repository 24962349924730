import React, { useState } from "react";
import {
  ClickAwayListener,
  IconButton,
  IconButtonProps,
  SvgIconTypeMap,
  Tooltip,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import InfoIcon from "@mui/icons-material/InfoRounded";

export type IconWithTooltipProps = Omit<TooltipProps, "children"> & {
  icon?: React.ReactNode;
  infoIconSize?: SvgIconTypeMap["props"]["fontSize"];
  color?: SvgIconTypeMap["props"]["color"];
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconButtonProps?: Partial<IconButtonProps>;
};

export const PrimaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    maxWidth: 280,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius > 40 ? 20 : theme.shape.borderRadius,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);

export const SecondaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    maxWidth: 280,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius > 40 ? 20 : theme.shape.borderRadius,
  },
  arrow: {
    color: theme.palette.secondary.main,
  },
}))(Tooltip);

const IconWithTooltip = ({
  icon: Icon,
  infoIconSize = "small",
  color = "secondary",
  className,
  onButtonClick,
  iconButtonProps,
  ...tooltipProps
}: IconWithTooltipProps) => {
  const theme = useTheme();
  const isDisplaySizeLessLaptop = useMediaQuery(theme.breakpoints.down("md"));

  const [showTooltip, setShowTooltip] = useState(false);

  const onShowTooltip = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowTooltip(true);
  };

  const onHideTooltip = () => {
    setShowTooltip(false);
  };

  const handleIconButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onButtonClick?.(event);
    setShowTooltip(true);
  };

  const renderTooltip = () => {
    if (color === "secondary") {
      return (
        <SecondaryTooltip
          arrow
          placement={tooltipProps.placement || "right"}
          enterTouchDelay={isDisplaySizeLessLaptop ? 50 : undefined}
          {...tooltipProps}
          open={showTooltip}
          onOpen={onShowTooltip}
          onClose={isDisplaySizeLessLaptop ? undefined : onHideTooltip}>
          <IconButton onClick={handleIconButtonClick} edge="end" size="small" {...iconButtonProps}>
            {(Icon as JSX.Element) || <InfoIcon fontSize={infoIconSize} color={color} />}
          </IconButton>
        </SecondaryTooltip>
      );
    }

    return (
      <PrimaryTooltip
        arrow
        placement={tooltipProps.placement || "right"}
        enterTouchDelay={isDisplaySizeLessLaptop ? 50 : undefined}
        {...tooltipProps}
        open={showTooltip}
        onOpen={onShowTooltip}
        onClose={isDisplaySizeLessLaptop ? undefined : onHideTooltip}>
        <IconButton onClick={handleIconButtonClick} edge="end" size="small" {...iconButtonProps}>
          {(Icon as JSX.Element) || <InfoIcon fontSize={infoIconSize} color={color} />}
        </IconButton>
      </PrimaryTooltip>
    );
  };

  return <ClickAwayListener onClickAway={onHideTooltip}>{renderTooltip()}</ClickAwayListener>;
};

export default IconWithTooltip;
