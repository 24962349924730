import Joi from "joi";

export const assetSchema = Joi.object({
  category: Joi.string()
    .valid(
      "access_machinery_and_equipment",
      "agricultural_forestry_landscaping_machinery_and_equipment",
      "audio_visual_and_broadcasting_equipment",
      "building_systems_and_components",
      "buses_and_coaches",
      "cars_and_motorcycles",
      "commercial_equipment_and_tools",
      "commercial_furniture_and_fittings",
      "computer_software",
      "computers_peripherals_security_and_telephony_equipment",
      "construction_and_civil_engineering_machinery_and_equipment",
      "electric_motors_generators_transformers_and_accumulators",
      "engineering_machinery_and_equipment",
      "heavy_commercial_vehicles",
      "light_commercial_vehicles",
      "manufacturing_machinery_and_equipment",
      "marine_vessels_and_aircrafts",
      "materials_lifting_handling_and_storage_machinery_and_equipment",
      "medical_and_dental_equipment_and_tools",
      "mining_quarrying_and_aggregate_machinery_and_equipment",
      "other",
      "printing_and_graphics_machinery_and_equipment",
      "recycling_and_waste_management_machinery_and_equipment",
      "trailers",
    )
    .required()
    .label("Category"),
  condition: Joi.string().valid("new", "used").default("new").label("Condition"),
  description: Joi.string().max(300).label("Description"),
  manufacturer: Joi.string().max(100).required().label("Manufacturer"),
  manufactureYear: Joi.number().integer().min(1230).max(2130).allow(null).label("Manufacture year"),
  manufactureDate: Joi.string().isoDate().label("Manufacture date"),
  model: Joi.string().max(100).required().label("Model"),
  numberOfUnits: Joi.number().integer().min(1).max(1000000).default(1).label("Number of units"),
  costPerUnit: Joi.number()
    .precision(2)
    .min(0.01)
    .max(1000000000)
    .required()
    .label("Cost per unit"),
  vatPerUnit: Joi.number().precision(2).min(0).max(1000000000).required().label("VAT per unit"),
  registrationNumber: Joi.string().max(50).allow(null).label("Registration number"),
  serialNumber: Joi.string().max(100).allow(null).label("Serial number"),
  isInsured: Joi.boolean().required().label("Is insured"),
});
