import { CompanyType } from "@APP/constants";
import Joi from "joi";

export const LtdOrgDetailsSchema = Joi.object({
  companyName: Joi.string().required(),
  companyNumber: Joi.string().required(),
  orgType: Joi.string()
    .valid(CompanyType.RegisteredCompany, CompanyType.SoleTrader, CompanyType.OtherOrgTypes)
    .required(),
  director: Joi.string().required(),
});

export const OtherOrgDetailsSchema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  businessName: Joi.string().required(),
});

export const CustomerInfoSchema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
});

export const BusinessSchema = Joi.object({
  businessType: Joi.string().required(),
});
