import React, { useEffect, useMemo, useState } from "react";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import parse from "html-react-parser";
import MailLockIcon from "@mui/icons-material/MailLock";
import { useTranslation } from "react-i18next";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import EmailIcon from "@mui/icons-material/Email";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import queryString from "query-string";
import { useSelector } from "react-redux";

import {
  CommonTextField,
  FooterActionsButtons,
  ResultNotification,
  ScreenHeader,
  ScreenHeaderSubtitle,
  Table,
} from "@APP/components";
import {
  NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION,
  TabsName,
  USER_ROLES,
} from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import { errorCodeString, handleAriaActiveDescendantChange } from "@APP/utils";
import { useAccessPermission, useAlert, useHandleErrorCodes } from "@APP/hooks";
import { getPermissions, getUsername, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { getOrgUsers, resendInvitation, updateOrgUsers } from "@APP/services/api";
import { OrgUser } from "@APP/types";

const UsersList = () => {
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();
  const dispatch = useAppDispatch();
  const userName = useSelector(getUsername);
  const { fetchPermission } = useAccessPermission();
  const permissions = useSelector(getPermissions);

  const [editMode, setEditMode] = useState(false);
  const [users, setUsers] = useState<OrgUser[] | undefined>([]);
  const [entries, setEntries] = useState<number>(10);
  const [page, setPage] = useState<number>(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
  const [lastPage, setLastPage] = useState(0);
  const [errorCodeMessage, setErrorCodeString] = useState("");

  const { handleBlur, handleSubmit, values, resetForm, setFieldValue, setValues } = useFormik({
    initialValues: {
      users: users || [],
    },
    enableReinitialize: true,
    onSubmit: async ({ users: updatedUsersList }) => {
      try {
        dispatch(showLoader());
        await updateOrgUsers(editedUsers);
        await retrieveOrgUsers();
        setEditMode(false);
      } catch (e) {
        const errorData = e?.response?.data;

        const isHandled = handleErrorCodes(errorData?.errorCode);

        if (isHandled) return;

        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          "Sorry, we were unable to save the changes. Please try again later.",
          [{ text: "Okay" }],
        );
      } finally {
        dispatch(hideLoader());
      }
    },
  });

  const retrieveOrgUsers = async () => {
    try {
      setUsers([]);
      dispatch(showLoader());
      await fetchPermission([{ user: "create" }, { user: "update" }]);
      const { data, links } = await getOrgUsers({ page, entries });
      setUsers((prev) => {
        if (!prev) return data;

        const isContainingDataPage = prev.some((value) => value.email === data[0].email);

        if (isContainingDataPage) return prev;

        const newState = [...prev, ...data];

        setValues({ users: newState });
        return newState;
      });

      const { query } = queryString.parseUrl(links?.last ?? "");

      if (query.page) {
        const [lastPageEntries] = (query.page as string).split(";;;");
        const lastPageIndex = Number(lastPageEntries) / entries;
        setLastPage(lastPageIndex + 1);
      }
      dispatch(hideLoader());
    } catch (e) {
      dispatch(hideLoader());
      setUsers(undefined);
      const errorData = e?.response?.data;
      const errorCode = errorCodeString(errorData?.errorCode);
      setErrorCodeString(errorCode);

      handleErrorCodes(errorData?.errorCode);
    }
  };

  useEffect(() => {
    (async () => {
      await retrieveOrgUsers();
    })();
  }, [page, entries]);

  const editedUsers = useMemo(() => {
    if (!users) return [];

    return values.users.filter(
      (user, index) => user.role !== users[index]?.role || user.status !== users[index]?.status,
    );
  }, [values.users, users]);

  const resendEmail = async (user: OrgUser) => {
    try {
      await resendInvitation(user.email);
      alert.close();
      dispatch(showLoader());
      alert.render(getSuccessResendPopup(), { maxWidth: "md" });
    } catch (e) {
      alert.close();
      const errorData = e?.response?.data;

      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      return alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        "Sorry, we were unable to send the link to setup user account. Please try again later.",
        [{ text: "Okay" }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const getContentResendAlert = (user: OrgUser) => (
    <Box m={4}>
      <Box alignItems="center" display="flex" flexDirection="column" rowGap={4}>
        <Typography variant="h3">Resend invitation</Typography>
        <EmailIcon color="success" sx={{ fontSize: 80 }} />
        <Typography variant="subtitle2">
          User will receive an email with a link to setup their account.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={4} columnGap={2}>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => alert.close()}
          id="userListCancelButton">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => resendEmail(user)}
          id="userListResendButton">
          Resend
        </Button>
      </Box>
    </Box>
  );

  const getSuccessResendPopup = () => (
    <Box m={4}>
      <Box alignItems="center" display="flex" flexDirection="column" rowGap={4}>
        <Typography variant="h3">Success</Typography>
        <MarkEmailReadIcon color="success" sx={{ fontSize: 80 }} />
        <Typography variant="subtitle2">
          The link to setup user account was successfully sent.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => alert.close()}
          id="userListOkButton">
          Okay
        </Button>
      </Box>
    </Box>
  );

  const openResendModal = (user: OrgUser) => {
    alert.render(getContentResendAlert(user), { maxWidth: "md" });
  };

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell data-testid="user-email-table-cell">User Email</TableCell>
      <TableCell data-testid="user-role-table-cell">User Role</TableCell>
      <TableCell data-testid="user-status-table-cell">
        <Box ml="40px">User Status</Box>
      </TableCell>
    </TableRow>
  );

  const renderRows = (item: OrgUser, index: number) => {
    if (editMode && userName !== item.email) {
      const availableStatusesOfAccount = [item.status, ...(item.nextStatus || [])];

      return (
        <TableRow key={item.email}>
          <TableCell data-testid="user-email-table-cell">{item.email}</TableCell>
          <TableCell sx={{ width: "35%" }} data-testid="user-role-table-cell">
            <CommonTextField
              fullWidth
              select
              type="role"
              value={values.users[page * entries + index].role}
              label="Role"
              id="role-select"
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue(`users[${page * entries + index}].role`, e.target.value)
              }
              data-testid="role-select"
              name="role"
              margin="normal"
              variant="outlined"
              InputProps={{
                id: "role-field",
              }}
              InputLabelProps={{
                htmlFor: "role-field",
              }}
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    "aria-activedescendant": `role-option-${
                      values.users[page * entries + index].role
                    }`,
                    onFocus: handleAriaActiveDescendantChange,
                  },
                },
              }}>
              {USER_ROLES.map((role) => (
                <MenuItem key={role} id={`role-option-${role}`} value={role}>
                  {role}
                </MenuItem>
              ))}
            </CommonTextField>
          </TableCell>
          <TableCell sx={{ width: "35%" }} data-testid="user-status-table-cell">
            <CommonTextField
              fullWidth
              select
              type="status"
              value={values.users[page * entries + index].status}
              label="Status"
              id="status-select"
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue(`users[${page * entries + index}].status`, e.target.value)
              }
              data-testid="status-select"
              name="status"
              margin="normal"
              variant="outlined"
              InputProps={{
                id: "status-field",
              }}
              InputLabelProps={{
                htmlFor: "status-field",
              }}
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    "aria-activedescendant": `status-option-${
                      values.users[page * entries + index]?.status
                    }`,
                    onFocus: handleAriaActiveDescendantChange,
                  },
                },
              }}>
              {availableStatusesOfAccount.map((status) => (
                <MenuItem key={status} id={`status-option-${status}`} value={status}>
                  {status}
                </MenuItem>
              ))}
            </CommonTextField>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow key={item.email}>
        <TableCell>{item.email}</TableCell>
        <TableCell>{item.role}</TableCell>
        <TableCell sx={{ width: "30%" }}>
          {item.status === "Pending" ? (
            <Box display="flex" justifyContent="space-between" position="relative" columnGap={2}>
              <Box display="flex" alignItems="center" ml="40px">
                <Box position="absolute" left={0}>
                  <PendingActionsIcon fontSize="medium" color="error" />
                </Box>
                {item.status}
              </Box>
              {permissions?.user?.update && (
                <Box width="30%">
                  <IconButton edge="end" size="small" onClick={() => openResendModal(item)}>
                    <MailLockIcon fontSize="medium" color="primary" />
                  </IconButton>
                </Box>
              )}
            </Box>
          ) : (
            <Box ml="40px">{item.status}</Box>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const handleClickBackButton = () => {
    if (editMode) {
      resetForm();
      return setEditMode(false);
    }

    return history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.USER}`);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <ScreenHeader title="Users" />
          <ScreenHeaderSubtitle subtitle="You can view and manage users from your organisation:" />
        </Grid>
        {users && permissions?.user?.create && (
          <Grid item xs={12}>
            <Box display="flex" textAlign="center" justifyContent="flex-end" mb={2}>
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to={`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.CREATE_USER}`}
                id="userListAddUser">
                Add user
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      {users ? (
        <>
          <Box mb={3} mt={3}>
            <Card elevation={12}>
              <Table
                data={users.slice(page * entries, (page + 1) * entries)}
                renderHeader={renderHeader}
                renderRows={renderRows}
                onEntriesChange={handleOnEntriesChange}
                onPageChange={setPage}
                page={page}
                entries={entries}
                lastPage={lastPage}
              />
            </Card>
          </Box>

          <FooterActionsButtons
            backButtonText={editMode ? "Cancel" : "Back"}
            handleBackButton={handleClickBackButton}
            handleContinue={
              editMode
                ? undefined
                : (e) => {
                    e.preventDefault();
                    setEditMode(true);
                  }
            }
            disabledContinueButton={
              users.length === 1 || (editMode && !Boolean(editedUsers.length))
            }
            hiddenContinueButton={!editMode && !permissions?.user?.update}
            typeButtonContinue={editMode ? "submit" : "button"}
            continueButtonText={editMode ? "Save" : "Manage users"}
          />
        </>
      ) : (
        <>
          <ResultNotification type="error">
            Sorry, we are unable to present you the list of your organisation users. Please try
            again later.
            {parse(errorCodeMessage || "")}
          </ResultNotification>
          <FooterActionsButtons
            backButtonText="Back"
            handleBackButton={() => history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.USER}`)}
          />
        </>
      )}
    </form>
  );
};

export default UsersList;
