import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

import CONFIG from "@APP/config";
import { ActiveCheckbox, NoteBox, Page, ResultNotification } from "@APP/components";
import { Custodian, PermissionState } from "@APP/types";
import {
  fetchCardPaymentsCustodians,
  fetchCardPaymentsSettings,
  getCardPaymentsCustodians,
  getCardPaymentsSettings,
  getDashboardAppLoading,
  getPermissions,
  hideLoader,
  setCardPaymentsSettings,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";
import { useAccessPermission, useAlert, useHandleErrorCodes } from "@APP/hooks";
import { IMAGES } from "@APP/assets";
import { SCREEN_PATHS } from "@APP/navigation";
import { errorCodeString } from "@APP/utils";

export interface CardPaymentLocalConsentData {
  redirectUrl: string;
  consentId: string;
}

const CardPaymentsUK = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleErrorCodes = useHandleErrorCodes();
  const alert = useAlert();

  const isLoading = useSelector(getDashboardAppLoading);
  const { fetchAllPermissions } = useAccessPermission();
  const permissions = useSelector(getPermissions);
  const [loadingCardDetails, setLoadingCardDetails] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState<Custodian[] | null>(null);

  const fetchPaymentMethods = async () => {
    try {
      const data = await API.getCustodiansByType({ custodianType: "CardPaymentProvider" });

      setPaymentMethods(data);
    } catch (e) {}
  };

  const getCardPaymentsSettings = async () => {
    try {
      await dispatch(fetchCardPaymentsSettings());
    } catch (e) {
      const errorCode = e?.response?.data?.errorCode;

      const isHandled = handleErrorCodes(errorCode);

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message"),
        );
      }
    }
  };

  const getCardPaymentsCustodians = async () => {
    try {
      await dispatch(fetchCardPaymentsCustodians());
    } catch (e) {
      const errorCode = e?.response?.data?.errorCode;

      const isHandled = handleErrorCodes(errorCode);

      if (!isHandled) {
        alert.open(
          "Failure",
          "We are unable to retrieve your payment methods, please try again later." +
            errorCodeString(errorCode),
          [{ text: "Okay" }],
        );
      }
    }
  };

  useEffect(() => {
    (async function () {
      setLoadingCardDetails(true);
      dispatch(showLoader());

      await fetchAllPermissions();
      await fetchPaymentMethods();
      await getCardPaymentsCustodians();
      await getCardPaymentsSettings();
      setLoadingCardDetails(false);
      dispatch(hideLoader());
    })();
  }, []);

  return (
    <Page title="Payments" display="flex" alignItems="center" flexDirection="column">
      {!isLoading && !paymentMethods && !loadingCardDetails ? (
        <Box maxWidth={800} ml="auto" mr="auto">
          <ResultNotification
            type="error"
            footer={
              <Button color="secondary" variant="contained" onClick={fetchPaymentMethods}>
                Okay
              </Button>
            }>
            We are unable to retrieve your payment methods, please try again later.
          </ResultNotification>
        </Box>
      ) : (
        <>
          <Card elevation={4} sx={{ maxWidth: 960, width: "100%" }}>
            <CardContent>
              <Box alignItems="center" textAlign="center" display="flex" flexDirection="column">
                <AccountBalanceIcon color="primary" style={{ fontSize: 90 }} />
                <Box mb={1} mt={1}>
                  <Typography variant="h2" component="p">
                    Payment Methods
                  </Typography>
                </Box>
                <Typography color="textPrimary" gutterBottom>
                  Your account has already been enabled to receive payments from your customers via
                  account to account payments directly from their bank account. You can also add
                  additional payment methods to your account in order to give your customers the
                  flexibility to pay by their preferred method.
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Box mt={4} />
          {!isLoading && !loadingCardDetails ? (
            <Card elevation={4} sx={{ maxWidth: 960, width: "100%" }}>
              <CardContent>
                <Box alignItems="center" textAlign="center" display="flex" flexDirection="column">
                  <CreditCardIcon color="primary" style={{ fontSize: 80 }} />
                  <Box mb={1} mt={1}>
                    <Typography variant="h2" component="p">
                      Manage Additional Payment Methods
                    </Typography>
                  </Box>
                </Box>
                <Box mb={6}>
                  <Typography color="textPrimary" variant="h4" component="p">
                    Card Payments
                  </Typography>
                  <NoteBox mt={2}>
                    <Typography variant="body2" component="p">
                      You can give your customers the option to pay using their Credit/Debit cards
                      by linking one of the below card providers to this application.
                    </Typography>
                  </NoteBox>
                </Box>
                {paymentMethods?.map((custodian, i) => {
                  const isLastItem = i === paymentMethods.length - 1;

                  return (
                    <Box key={custodian.id} mb={isLastItem ? 0 : 3}>
                      <CardPaymentCustodianItem custodian={custodian} permissions={permissions} />
                      {!isLastItem ? (
                        <Box mt={3}>
                          <Divider />
                        </Box>
                      ) : null}
                    </Box>
                  );
                })}
              </CardContent>
            </Card>
          ) : null}
        </>
      )}
    </Page>
  );
};

const CardPaymentCustodianItem = ({
  custodian,
  permissions,
}: {
  custodian: Custodian;
  permissions?: PermissionState;
}) => {
  const theme = useTheme();
  const alert = useAlert();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const cardCustodians = useSelector(getCardPaymentsCustodians);
  const cardProviderSettings = useSelector(getCardPaymentsSettings);

  const [isLoading, setIsLoading] = useState(false);

  const isLinked = useMemo(
    () => cardCustodians.some((item) => item.id === custodian.id),
    [cardCustodians],
  );
  const isSelectedForPayments = useMemo(
    () => cardProviderSettings?.providers.some((item) => item.id === custodian.id && item.enabled),
    [cardProviderSettings],
  );

  const onLinkAccount = async () => {
    try {
      setIsLoading(true);

      const redirectUrl = `${window.location.origin}${SCREEN_PATHS.CARD_PAYMENTS_LINK_RESULT}`;

      const data = await API.getSquareConsentUrl(redirectUrl);

      const bankConsentLocalData: CardPaymentLocalConsentData = {
        consentId: data.consentId,
        redirectUrl: redirectUrl,
      };

      AppLocalStorage.setItem(
        LocalStorageKey.setupCardPaymentsConsentData,
        JSON.stringify(bankConsentLocalData),
      );

      window.location.href = data.redirectUrl;
    } catch (e) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeAcceptCardPayments = async () => {
    try {
      dispatch(showLoader());

      let cardPaymentsSettings = null;

      if (isSelectedForPayments) {
        cardPaymentsSettings = await API.disableSquareCardPayments();
      } else {
        cardPaymentsSettings = await API.enableSquareCardPayments();
      }

      dispatch(setCardPaymentsSettings(cardPaymentsSettings));
    } catch (err) {
      const errorCode = err?.response?.data?.errorCode;
      const htmlErrorCode = errorCodeString(errorCode);

      alert.open(
        "Failure",
        "We were unable to update your preference, you can select/deselect card payments still on each payment request." +
          htmlErrorCode,
        [
          {
            text: "Okay",
          },
        ],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={3} sm={3}>
        <Box display="flex" width="100%" maxWidth={isXs ? 140 : 200} mb={isXs ? 3 : 0}>
          <img src={IMAGES.SQUARE_LOGO} width="100%" alt={`${custodian.fullName} logo`} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} pl={isXs ? 0 : 3} pr={4} mb={isXs ? 2 : 0}>
        <Box display="flex" alignItems="flex-start" mb={2}>
          <Box mr={1} height={24} mt={-0.5}>
            <TaskAltRoundedIcon color="secondary" />
          </Box>
          <Typography variant="body2">
            {custodian.fullName} card fees will be applicable for each transaction.
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Box mr={1} height={24} mt={-0.5}>
            <TaskAltRoundedIcon color="secondary" />
          </Box>
          <Typography variant="body2">
            If you don’t already have a {custodian.fullName} account you can create one and link it
            at any time.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box display="flex" flexDirection="column">
          {isLinked ? (
            <>
              <Box mb={1.5}>
                <Typography
                  sx={{ paddingLeft: "2px" }}
                  variant="h3"
                  component="p"
                  color="secondary">
                  Connected
                </Typography>
              </Box>
              {permissions?.card_payment?.create ? (
                <ActiveCheckbox
                  label="Let all my customers pay by card"
                  labelProps={{ variant: "body2" }}
                  onChange={onChangeAcceptCardPayments}
                  checked={isSelectedForPayments}
                  inputProps={{ "aria-label": "Allow customers pay by card" }}
                  style={{ padding: 0, marginRight: 8 }}
                  boxProps={{
                    flexDirection: "row-reverse",
                    justifyContent: isXs ? "flex-end" : "flex-start",
                    alignItems: isXs ? "center" : "flex-start",
                  }}
                />
              ) : null}
            </>
          ) : (
            <>
              {permissions?.card_payment?.create ? (
                <>
                  <Box mb={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ alignItems: "center" }}
                      onClick={onLinkAccount}
                      disabled={isLoading}>
                      <Box display="flex" alignItems="center">
                        Link Account
                      </Box>
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    LinkComponent="a"
                    href={
                      CONFIG.URLS.PAYMENT_PROVIDER.APPLY_FOR_SQUARE ??
                      "https://squareup.com/gb/en/payments"
                    }
                    target="_blank"
                    rel="noopener noreferrer">
                    Apply for Account
                  </Button>
                </>
              ) : (
                <Typography
                  sx={{ paddingLeft: "2px" }}
                  variant="h6"
                  component="p"
                  color="secondary">
                  Please ask your administrator(s) to link your account.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CardPaymentsUK;
