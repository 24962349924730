import { createReducer } from "deox";

import {
  resetToDefaultState,
  setRegistrationEmail,
  setRegistrationInitiated,
  setRegistrationPhone,
} from "../actions";

export interface RegistrationState {
  registrationInitiated: boolean;
  registrationEmail: string;
  registrationPhone: string;
}

export const defaultState: RegistrationState = {
  registrationInitiated: false,
  registrationEmail: "",
  registrationPhone: "",
};

const registrationReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setRegistrationEmail, (state, { payload }) => ({
    ...state,
    registrationEmail: payload,
  })),
  handleAction(setRegistrationPhone, (state, { payload }) => ({
    ...state,
    registrationPhone: payload,
  })),
  handleAction(setRegistrationInitiated, (state, { payload }) => ({
    ...state,
    registrationInitiated: payload,
  })),
  handleAction(resetToDefaultState, () => defaultState),
]);

export default registrationReducer;
