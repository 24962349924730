import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  PaymentAccountList,
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import {
  getBankAccounts,
  getBankLedgers,
  getPayable,
  setPayerBankAccount,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { BankAccountExtended, CustodianFeatures } from "@APP/types";
import { useAlert } from "@APP/hooks";
import { isLinkedLedger } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  accountListWrapper: {
    margin: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },

  accountListContent: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
    "&:last-child": {
      marginBottom: theme.spacing(2),
      paddingBottom: 0,
    },
    "&:first-child": {
      marginTop: theme.spacing(2),
    },
  },
}));

const PayableSelectAccountView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const alert = useAlert();
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccountExtended | null>(null);
  const [linkedBanksAccount, setLinkedBanksAccount] = useState<BankAccountExtended[]>([]);

  const bankAccounts = useSelector(getBankAccounts);
  const ERPLedgers = useSelector(getBankLedgers);
  const { payerBankAccount, selectedPayable } = useSelector(getPayable);

  useEffect(() => {
    if (!bankAccounts?.length) return setLinkedBanksAccount([]);

    const bankAccountsWithLinkedLedger = bankAccounts.filter((bankAccount) =>
      isLinkedLedger(ERPLedgers, bankAccount.account.identification),
    );

    setLinkedBanksAccount(bankAccountsWithLinkedLedger);
    if (
      bankAccountsWithLinkedLedger.length &&
      bankAccountsWithLinkedLedger[0].bankInfo.features.includes(
        CustodianFeatures.SINGLE_IMMEDIATE_PAYMENT,
      )
    )
      setSelectedBankAccount(bankAccountsWithLinkedLedger[0]);
  }, []);

  useEffect(() => {
    if (payerBankAccount) setSelectedBankAccount(payerBankAccount);
  }, [payerBankAccount]);

  const redirectToBack = () =>
    history.push(
      `${SCREEN_PATHS.PAYABLES_LIST}/details/${selectedPayable?.entityDetails.externalId}`,
    );

  const redirectToNextStep = () => {
    if (!selectedBankAccount) return;

    dispatch(setPayerBankAccount(selectedBankAccount));

    if (
      Number(selectedBankAccount?.balance?.amount) <
      Number(selectedPayable?.totalAmountTaxInclusive.amount)
    ) {
      return alert.open(
        t("Payables.PayableSelectAccount.Alert.Title"),
        t("Payables.PayableSelectAccount.Alert.Message"),
        [
          { text: "Cancel" },
          { text: "Yes", onClick: () => history.push(SCREEN_PATHS.PAYABLE_CONFIRM) },
        ],
      );
    }

    return history.push(SCREEN_PATHS.PAYABLE_CONFIRM);
  };

  const handleSelectBankAccount = (bankAccount: BankAccountExtended) => {
    if (bankAccount.accountId === selectedBankAccount?.accountId) return;
    if (bankAccount.bankInfo.features.includes(CustodianFeatures.SINGLE_IMMEDIATE_PAYMENT))
      setSelectedBankAccount(bankAccount);
  };

  return (
    <Page title="Supplier Payment">
      <ScreenHeader title="Supplier Payment" id="payableSelectAmountTitle" />
      <ScreenHeaderSubtitle
        subtitle={t("Payables.PayableSelectAccount.ScreenHeaderSubtitle.Subtitle")}
        step={t("Payables.PayableSelectAccount.ScreenHeaderSubtitle.Step")}
      />
      <Grid container justifyContent="center">
        <Grid item lg={12} md={12} xs={12}>
          <Card className={classes.accountListWrapper}>
            <CardContent className={classes.accountListContent}>
              <PaymentAccountList
                bankAccounts={linkedBanksAccount}
                selectedAccount={selectedBankAccount}
                onAccountClick={(bankAccount) => handleSelectBankAccount(bankAccount)}
                unsupportedOutboundPaymentText="The bank account does not support outbound payments. Please pay this supplier
                    from a different bank account."
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <FooterActionsButtons
        backButtonText="Back to Invoice details"
        handleBackButton={redirectToBack}
        continueButtonText="Continue"
        handleContinue={redirectToNextStep}
        disabledContinueButton={!Boolean(selectedBankAccount)}
        continueButtonDataTestId="invoice-continue-btn"
        backButtonDataTestId="back-to-invoice-details-btn"
      />
    </Page>
  );
};

export default PayableSelectAccountView;
