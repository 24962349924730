import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { startOfDay } from "date-fns";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { FooterActionsButtons, Page, ScreenHeader, CommonDatePicker } from "@APP/components";
import {
  getInstalmentDetails,
  setInstalmentFirstPaymentAmount,
  setInstalmentFrequency,
  setInstalmentFirstPaymentDate,
  setInstalmentOccurrences,
  useAppDispatch,
  setInstalmentFinalPaymentAmount,
  getReceivable,
  getInvoice,
} from "@APP/redux";
import { formatCurrency, handleAriaActiveDescendantChange } from "@APP/utils";
import { SCREEN_PATHS } from "@APP/navigation";
import { InstalmentFrequency } from "@APP/types";

const FREQUENCY_ITEMS = [
  InstalmentFrequency.Daily,
  InstalmentFrequency.Weekly,
  InstalmentFrequency.Monthly,
];

const OCCURRENCES_ITEMS = [2, 3, 4];

const InstalmentDetailsView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const isSizeScreenMdOrUp = useMediaQuery(theme.breakpoints.up("md"));

  const instalmentDetails = useSelector(getInstalmentDetails);
  const receivable = useSelector(getReceivable);
  const invoice = useSelector(getInvoice);

  const [amountError, setAmountError] = useState(false);
  const [firstInstalmentDateError, setFirstInstalmentDateError] = useState(false);

  const onOccurrencesChange = (e: React.ChangeEvent<{ value: string }>) => {
    dispatch(setInstalmentOccurrences(Number(e.target.value)));
  };

  const onFrequencyChange = (e: React.ChangeEvent<{ value: string }>) => {
    dispatch(setInstalmentFrequency(e.target.value as InstalmentFrequency));
  };

  const onFirstPaymentDateChange = (date: Date | null, validationError?: string) => {
    setFirstInstalmentDateError(!!validationError);
    date && dispatch(setInstalmentFirstPaymentDate(date));
  };

  useEffect(() => {
    const invoiceAmount = Number(
      receivable?.totalAmountTaxInclusive?.amount ?? invoice.invoiceAmountGross,
    );
    const firstPaymentAmount = invoiceAmount / instalmentDetails.occurrences;
    const firstPaymentAmountFixed = firstPaymentAmount.toFixed(2);
    const finalPaymentAmount =
      invoiceAmount - (instalmentDetails.occurrences - 1) * Number(firstPaymentAmount.toFixed(2));
    const finalPaymentAmountFixed = finalPaymentAmount.toFixed(2);

    if (
      Number(firstPaymentAmount.toFixed(3)) < 0.01 ||
      Number(finalPaymentAmount.toFixed(3)) < 0.01
    ) {
      setAmountError(true);
      return;
    }

    setAmountError(false);
    dispatch(
      setInstalmentFinalPaymentAmount(
        finalPaymentAmountFixed === firstPaymentAmount.toFixed(2)
          ? undefined
          : finalPaymentAmountFixed,
      ),
    );
    dispatch(setInstalmentFirstPaymentAmount(firstPaymentAmountFixed));
  }, [instalmentDetails.occurrences]);

  useEffect(() => {
    !instalmentDetails.firstPaymentDate && dispatch(setInstalmentFirstPaymentDate(new Date()));
  }, []);

  return (
    <Page title="Create Payment Request">
      <ScreenHeader title="Payment Requests" id="installmentDetailsTitle" />
      <Box mt={3} mb={2}>
        <Typography variant="h6">Please enter the instalment details below.</Typography>
      </Box>
      <Card elevation={12}>
        <CardHeader title="Instalment Schedule" />
        <Divider />
        <CardContent>
          <Grid container spacing={isSizeScreenMdOrUp ? 3 : 0}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                select
                onChange={onFrequencyChange}
                value={instalmentDetails.frequency ?? ""}
                label="Frequency"
                id="frequency-select"
                data-testid="frequency-dropdown"
                name="frequency"
                margin="normal"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      "aria-activedescendant": `instalment-frequency-option-${instalmentDetails.frequency}`,
                      onFocus: handleAriaActiveDescendantChange,
                    },
                  },
                }}>
                {FREQUENCY_ITEMS.map((value) => (
                  <MenuItem key={value} id={`instalment-frequency-option-${value}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                select
                onChange={onOccurrencesChange}
                value={instalmentDetails.occurrences ?? ""}
                label="Occurrences"
                id="occurrences-select"
                data-testid="occurrences-dropdown"
                name="occurrences"
                margin="normal"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      "aria-activedescendant": `instalment-occurrences-option-${instalmentDetails.occurrences}`,
                      onFocus: handleAriaActiveDescendantChange,
                    },
                  },
                }}>
                {OCCURRENCES_ITEMS.map((value) => (
                  <MenuItem key={value} id={`instalment-occurrences-option-${value}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <CommonDatePicker
                slotProps={{
                  textField: {
                    name: "firstPaymentDate",
                    id: "installment-date-picker",
                  },
                }}
                onChange={(value, context) =>
                  onFirstPaymentDateChange(value, context.validationError as string | undefined)
                }
                value={instalmentDetails.firstPaymentDate}
                label="First Payment Date"
                data-testid="first-payment-date-input"
                minDate={startOfDay(new Date())}
                minDateMessage={t("Errors.RTPCreation.Validation.InstalmentDatePast")}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {amountError ? (
          <Box p={2}>
            <Typography color="error">
              {t("Errors.RTPCreation.Messages.InstalmentTotal")}
            </Typography>
          </Box>
        ) : (
          <Grid container>
            <Grid item md={5} xs={12}>
              <Box display="flex" flexDirection="column" p={2}>
                <Box width="100%" maxWidth={250} display="flex" justifyContent="space-between">
                  <Typography variant="h5">Payment Amount</Typography>
                  <Box ml={2}>
                    <Typography>
                      {formatCurrency(instalmentDetails.firstPaymentAmount, {
                        currency:
                          receivable?.totalAmountTaxExclusive?.currency ?? invoice.invoiceCurrency,
                      })}
                    </Typography>
                  </Box>
                </Box>
                {!!instalmentDetails.finalPaymentAmount ? (
                  <Box
                    width="100%"
                    maxWidth={250}
                    display="flex"
                    justifyContent="space-between"
                    mt={1}>
                    <Typography variant="h5">Final Payment</Typography>
                    <Box ml={2}>
                      <Typography>
                        {formatCurrency(instalmentDetails.finalPaymentAmount, {
                          currency:
                            receivable?.totalAmountTaxExclusive?.currency ??
                            invoice.invoiceCurrency,
                        })}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        )}
      </Card>
      <FooterActionsButtons
        backButtonText="Back to Payment Request Details"
        backButtonDataTestId="back-to-invoice-button"
        handleBackButton={() => history.push(SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS)}
        handleContinue={() => history.push(SCREEN_PATHS.PAYMENT_REQUESTS_SUMMARY)}
        disabledContinueButton={amountError || firstInstalmentDateError}
        continueButtonText="Continue"
        continueButtonDataTestId="continue-button"
      />
    </Page>
  );
};

export default InstalmentDetailsView;
