import { ReactNode } from "react";

import { Box, Card, CardHeader, Grid, SimplePaletteColorOptions } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import palette from "@APP/styles/theme/default/palette";

const FinanceOptions = ({
  id,
  Icon,
  title,
  subTitle,
  selected,
}: {
  id: string;
  Icon: ReactNode;
  title: string;
  subTitle: string;
  selected: boolean;
}) => {
  return (
    <Card
      id={id}
      style={{
        backgroundColor: selected ? "" : "#546e7a10",
        border: "1px solid #546e7a",
        boxShadow: selected ? "2px 2px" : "",
        borderRadius: "5px",
      }}>
      <Box display="flex" padding="16px" gap="16px">
        <span style={{ fontSize: "50px" }}>{Icon}</span>
        <CardHeader style={{ padding: "0" }} title={title} subheader={subTitle} />
        <Grid display={"flex"} justifyContent={"flex-end"}>
          {selected && (
            <CheckCircleIcon
              sx={{
                color: (palette.secondary as SimplePaletteColorOptions)?.main,
              }}
            />
          )}
        </Grid>
      </Box>
    </Card>
  );
};

export default FinanceOptions;
