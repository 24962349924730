import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Card, Grid, Typography } from "@mui/material";
import { Page } from "@APP/components";
import WcfFooter from "@APP/components/layout/WCFLayout";
import OptionCard from "@APP/views/wcf/utils/OptionCard";
import {
  getBusinessType,
  getUserOrganisation,
  setActiveStep,
  setBusinessType,
  setNextPath,
  setNextStepActive,
  setOrgType,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { convertToCapitalizeCase } from "@APP/views/wcf/utils/utils";
import { API } from "@APP/services";
import { useHistory } from "react-router-dom";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "center",
    borderRadius: "5px",
    minHeight: "80vh",
  },
}));

const BusinessType = () => {
  const defaultValue = [
    { label: "Limited Liability Company", selected: false },
    {
      label: "Limited Liability Partnership",
      selected: false,
    },
    { label: "Sole Trader", selected: false },
    { label: "Partnership", selected: false },
    { label: "Other", selected: false },
  ];
  const classes = useStyles();
  const dispatch = useDispatch();
  const org = useSelector(getUserOrganisation);
  const history = useHistory();
  const businessType = useSelector(getBusinessType);
  const [businessTypes, setBusinessTypes] = useState(defaultValue);
  const [optionSelected, setOptionSelected] = useState(false);

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      businessType: "",
    },
    onSubmit: async () => {},
  });

  const handleBusinessTypeChange = (label: string) => {
    dispatch(setOrgType(label));
    const updatedType = businessTypes.map((type) =>
      type.label === label ? { ...type, selected: true } : { ...type, selected: false },
    );
    setBusinessTypes(updatedType);
    setFieldValue("businessType", label);
    dispatch(setBusinessType(label));
    setOptionSelected(label !== "");
  };

  const fetchWcfCustomer = async () => {
    try {
      const customer = await API.getWcfCustomer();
      if (customer?.status === "Submitted") history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
      handleBusinessTypeChange(convertToCapitalizeCase(customer.companyType as string));
    } catch (error) {
    } finally {
      if (org) history.push(`${SCREEN_PATHS.WCF_MANUAL_COMPANY}`);
    }
  };

  useEffect(() => {
    dispatch(setActiveStep(0));
    fetchWcfCustomer();
    dispatch(setNextPath(`${SCREEN_PATHS.WCF_ORG_DETAILS}`));
    handleBusinessTypeChange(businessType);
  }, []);

  useEffect(() => {
    dispatch(setNextStepActive(optionSelected));
  }, [businessTypes, businessType]);

  return (
    <Page className={classes.container} p={0}>
      <Box display="flex" flexDirection="column" flexGrow={1} p={3}>
        <Card elevation={1} className={classes.cardContainer}>
          <WcfStepper style={{ width: "100%", marginBottom: "8px" }} />
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid item>
                  <Typography
                    variant="h4"
                    component="p"
                    fontWeight={600}
                    color="primary"
                    paddingBottom={2}
                    id="businessTypeTitle">
                    Your finance application
                  </Typography>
                </Grid>
                <Grid container spacing={1} pb={2}>
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      Choose your business type: *
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {businessTypes.map((item) => (
                    <Grid item key={item.label}>
                      <OptionCard
                        id={`wcfBusinessType${item.label.replace(/\s/g, "")}`}
                        label={
                          <Typography variant="h6" color="#000">
                            {item.label}
                          </Typography>
                        }
                        selected={item.selected}
                        variant="md"
                        props={{ onClick: () => handleBusinessTypeChange(item.label) }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter businessType={{ type: values.businessType }} displaySaveAndExitButton={false} />
    </Page>
  );
};

export default BusinessType;
