import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";

import { CommonTextField, Page } from "@APP/components";
import WcfFooter from "@APP/components/layout/WCFLayout";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import CONFIG from "@APP/config";
import {
  getBusinessType,
  getUserOrganisation,
  setActiveStep,
  setBusinessType,
  setNextPath,
  setNextStepActive,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";

import { WcfCustomer } from "@APP/types";
import { ManualOrgType } from "@APP/constants";
import { API } from "@APP/services";
import { CompanyDetails } from "@APP/types/wcf";

import { convertToCapitalizeCase, handleKeyPress } from "../utils/utils";
import { countries, industries } from "../utils/dropdown";

function ManualCompanyProfile() {
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(null);
  const [wcfCustomer, setWcfCustomer] = useState<WcfCustomer | null>(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const businessType = useSelector(getBusinessType);

  const org = useSelector(getUserOrganisation);

  const isOtherOrg = businessType === ManualOrgType.OTHER;
  const isLimitedCompany = businessType === ManualOrgType.LIMITED_LIABILITY_COMPANY;
  const isLimitedPartnership = businessType === ManualOrgType.LIMITED_LIABILITY_PARTNERSHIP;
  const isLimited = isLimitedCompany || isLimitedPartnership;
  const isLimitedOrOther = isLimited || isOtherOrg;

  const validate = (values: {
    industry: string;
    website: string;
    tradingName: string;
    tradingAddress: string;
    city: string;
    postcode: string;
    country: string;
    privacyPolicy: boolean;
    creditCheck: boolean;
    startTrade: string;
    vatNumber: string | null;
    registeredCompanyName?: string | null;
    companyRegistrationNumber?: string | null;
  }) => {
    let errors = {};
    if (!values.industry) {
      errors = {
        ...errors,
        industry: "Please select your industry.",
      };
    }
    if (values?.website?.length > 100) {
      errors = {
        ...errors,
        website: "Maximum 100 characters.",
      };
    }
    const websiteRegex = /^https?:\/\/(?:www\.)?[^\s$.?#]+\.[^\s$.?#]+[^\s]*$/;
    if (values.website && !websiteRegex.test(values.website)) {
      errors = {
        ...errors,
        website:
          'Invalid URL format. URL must start with "https://" or "http://" and be an absolute URI.',
      };
    }
    if (!values.tradingName || values.tradingName === null) {
      errors = {
        ...errors,
        tradingName: isOtherOrg
          ? "Please enter your company name"
          : "Please enter your trading name",
      };
    } else if (values?.tradingName?.length > 100) {
      errors = {
        ...errors,
        tradingName: "Maximum 100 characters.",
      };
    }
    if (!values.tradingAddress || values.tradingAddress === null) {
      errors = {
        ...errors,
        tradingAddress: isOtherOrg
          ? "Registered address is a required field"
          : "Trading address is a required field.",
      };
    } else if (values?.tradingAddress?.length > 100) {
      errors = {
        ...errors,
        tradingAddress: "Maximum 100 characters.",
      };
    }
    if (!values.city || values.city === null) {
      errors = {
        ...errors,
        city: "City is a required field.",
      };
    } else if (values?.city?.length > 100) {
      errors = {
        ...errors,
        city: "Maximum 100 characters.",
      };
    }
    const postcodeRegex =
      /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Za-z][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;
    if (!values.postcode || values.postcode === null) {
      errors = {
        ...errors,
        postcode: "Postcode is a required field.",
      };
    } else if (!postcodeRegex.test(values.postcode)) {
      errors = {
        ...errors,
        postcode: "Please enter correct postcode",
      };
    }
    if (!values.privacyPolicy) {
      errors = {
        ...errors,
        privacyPolicy: "Please accept the privacy policy.",
      };
    }
    if (!values.creditCheck) {
      errors = {
        ...errors,
        creditCheck: "Please give consent to credit check.",
      };
    }
    if (!values.startTrade || values.startTrade.toString() === "Invalid Date") {
      errors = {
        ...errors,
        startTrade: "Please select your trading start date.",
      };
    }
    if (new Date(values.startTrade) > new Date()) {
      errors = {
        ...errors,
        startTrade: "Please enter a valid date",
      };
    }
    if (!values.country) {
      errors = {
        ...errors,
        country: "Country is a required field.",
      };
    }
    const vatNumRegex = /^(?:GB)?\d{9}$|^(?:GB)?\d{12}$/;
    if (values.vatNumber && !vatNumRegex.test(values.vatNumber as string)) {
      errors = {
        ...errors,
        vatNumber: "Field must be either 9 or 12 characters long",
      };
    }
    if (values?.registeredCompanyName && values?.registeredCompanyName?.length >= 100) {
      errors = {
        ...errors,
        registeredCompanyName: "Registered company name cannot be more than 100 characters. ",
      };
    }
    if (isOtherOrg && !values.registeredCompanyName) {
      errors = {
        ...errors,
        registeredCompanyName: "Please enter registered Company name",
      };
    }
    const companyRegNumRegex = /^([A-Z]{2}|[0-9]{2}|(R)([0-9]{1}))([0-9]{6})$/;
    if (isOtherOrg && !values.companyRegistrationNumber) {
      errors = {
        ...errors,
        companyRegistrationNumber: "Please enter registration number",
      };
    }
    if (
      values.companyRegistrationNumber &&
      !companyRegNumRegex.test(values.companyRegistrationNumber) &&
      isOtherOrg
    ) {
      errors = {
        ...errors,
        companyRegistrationNumber: "Please enter a valid Company registration number",
      };
    }
    return errors;
  };

  const fetchWcfCustomer = async () => {
    try {
      const customer = await API.getWcfCustomer();
      if (customer?.status === "Submitted") history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
      setWcfCustomer(customer);
      dispatch(setBusinessType(convertToCapitalizeCase(customer.companyType as string)));
    } catch (error) {
      setWcfCustomer(null);
    }
  };

  const handleAddHttp = () => {
    if (!values.website) {
      return setFieldValue("website", "https://");
    }
    if (!values.website && !wcfCustomer?.website) {
      return setFieldValue("website", "https://");
    }

    if (
      values.website.includes("https://") ||
      wcfCustomer?.website?.includes("https://") ||
      values.website.includes("http://") ||
      wcfCustomer?.website?.includes("http://")
    ) {
      return;
    }
    if (
      (values.website && !values?.website?.includes("https://")) ||
      (wcfCustomer?.website && !wcfCustomer?.website?.includes("https://"))
    ) {
      return setFieldValue("website", `https://${values?.website || wcfCustomer?.website}`);
    }
  };

  const getCompanyDetails = async () => {
    try {
      const response = await API.getCompanyByNumber(org?.taxInfo?.utrNumber as string);
      setCompanyDetails(response);
    } catch (error) {
      setCompanyDetails(null);
    }
  };

  const setInitialValues = () => {
    setFieldValue("tradingName", wcfCustomer ? wcfCustomer.tradingName : null);
    setFieldValue("industry", wcfCustomer ? wcfCustomer.industry : industries[0].value);
    setFieldValue("website", wcfCustomer ? wcfCustomer.website : null);
    setFieldValue("vatNumber", wcfCustomer ? wcfCustomer.vatInformation.vatNumber : null);
    setFieldValue("creditCheck", wcfCustomer ? wcfCustomer.hasConsentedToCreditCheck : null);
    setFieldValue("privacyPolicy", wcfCustomer ? wcfCustomer.hasConsentedToPrivacyPolicy : null);
    setFieldValue(
      "registeredCompanyName",
      wcfCustomer
        ? wcfCustomer?.registeredCompanyName
        : companyDetails?.company_name
        ? companyDetails?.company_name
        : null,
    );
    setFieldValue(
      "tradingAddress",
      (isLimitedOrOther
        ? wcfCustomer !== null
          ? wcfCustomer?.registeredAddress?.street
          : companyDetails?.registered_office_address?.address_line_1 ?? ""
        : wcfCustomer?.tradingAddress?.street) ?? "",
    );
    setFieldValue(
      "companyRegistrationNumber",
      wcfCustomer
        ? wcfCustomer?.companyRegistrationNumber ?? null
        : companyDetails
        ? companyDetails?.company_number
        : "",
    );
    setFieldValue(
      "city",
      (isLimitedOrOther
        ? wcfCustomer
          ? wcfCustomer?.registeredAddress?.cityOrTown
          : companyDetails?.registered_office_address.locality
        : wcfCustomer?.tradingAddress?.cityOrTown) ?? "",
    );
    setFieldValue(
      "postcode",
      (isLimitedOrOther
        ? wcfCustomer
          ? wcfCustomer?.registeredAddress?.postcode
          : companyDetails?.registered_office_address.postal_code
        : wcfCustomer?.tradingAddress?.postcode) ?? "",
    );
    setFieldValue("sicCode", wcfCustomer ? wcfCustomer?.sicCode : companyDetails?.sic_codes[0]);
  };

  useEffect(() => {
    dispatch(setActiveStep(0));
    if (!org) history.push(`${SCREEN_PATHS.WCF_ACCOUNTING}`);
    if (org?.taxInfo.utrNumber) getCompanyDetails();
    fetchWcfCustomer();
    dispatch(setNextStepActive(true));
    dispatch(setNextPath(SCREEN_PATHS.WORKING_CAPITAL_FINANCE));
  }, []);

  useEffect(() => {
    setFieldValue("startTrade", new Date(wcfCustomer?.tradingFromDate as string));
  }, [wcfCustomer?.tradingFromDate]);

  useEffect(() => {
    setInitialValues();
  }, [wcfCustomer, companyDetails]);

  const { errors, touched, handleBlur, values, setFieldTouched, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        registeredCompanyName: wcfCustomer?.registeredCompanyName ?? null,
        industry: wcfCustomer?.industry ?? industries[0].value,
        website: wcfCustomer?.website
          ? wcfCustomer.website.includes("https://")
            ? wcfCustomer.website
            : `https://${wcfCustomer?.website}`
          : "",
        startTrade: "",
        tradingName: wcfCustomer?.tradingName ?? "",
        tradingAddress:
          (isLimitedOrOther
            ? wcfCustomer?.registeredAddress?.street
            : wcfCustomer?.tradingAddress?.street) ?? "",
        companyRegistrationNumber: wcfCustomer?.companyRegistrationNumber ?? "",
        city:
          (isLimitedOrOther
            ? wcfCustomer?.registeredAddress?.cityOrTown
            : wcfCustomer?.tradingAddress?.cityOrTown) ?? "",
        postcode:
          (isLimitedOrOther
            ? wcfCustomer?.registeredAddress?.postcode
            : wcfCustomer?.tradingAddress?.postcode) ?? "",
        country:
          (isLimitedOrOther
            ? wcfCustomer?.registeredAddress?.country
            : wcfCustomer?.tradingAddress?.country) ?? countries[0].value,
        privacyPolicy: wcfCustomer?.hasConsentedToPrivacyPolicy ?? false,
        creditCheck: wcfCustomer?.hasConsentedToCreditCheck ?? false,
        vatNumber: wcfCustomer?.vatInformation?.vatNumber ?? null,
        sicCode: "",
      },
      validate,
      onSubmit: () => {},
    });

  return (
    <Page display="flex" flexDirection="column" p={0}>
      <form style={{ width: "100%" }}>
        <Box p={3}>
          <Card
            elevation={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "32px",
              alignItems: "center",
              borderRadius: "5px",
              minHeight: isLimited ? "80vh" : "",
            }}>
            <WcfStepper style={{ width: "100%" }} />
            <Grid item container mt={1} xs={12}>
              <Grid item xs={8}>
                <Typography
                  variant="h4"
                  component="p"
                  fontWeight={600}
                  color="primary"
                  paddingBottom={2}
                  id="manualCompanyProfileTitle">
                  Complete company profile
                </Typography>
                <Grid container spacing={1} pb={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      To proceed with your application we need to gather some information on your
                      company, please enter the below details.
                    </Typography>
                  </Grid>
                </Grid>
                {!isLimited && (
                  <>
                    <Grid container spacing={1} mt={1}>
                      <Grid display="flex" item xs={12}>
                        <Grid item xs={4}>
                          <Typography variant="h6" pb={1}>
                            {isOtherOrg
                              ? "What is your Company Name?"
                              : "What is your Trading Name?"}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <CommonTextField
                            label={isOtherOrg ? "Company Name:" : "Trading Name:"}
                            name="tradingName"
                            id="wcfManualCompanyFormCompanyNameOrTradingName"
                            error={Boolean(touched.tradingName && errors.tradingName)}
                            helperText={touched?.tradingName && errors?.tradingName}
                            value={values.tradingName}
                            onChange={(e) => {
                              setFieldValue("tradingName", e.target.value);
                            }}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyPress}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{ width: "60%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} mt={1}>
                      <Grid display="flex" item xs={12}>
                        <Grid item xs={4}>
                          <Typography variant="h6" pb={1}>
                            When did you start trading?
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <DatePicker
                            sx={{ width: "60%" }}
                            slotProps={{
                              textField: {
                                error: Boolean(touched.startTrade && errors.startTrade),
                                helperText:
                                  touched.startTrade && (errors.startTrade as string | undefined),
                                name: "startTrade",
                                id: "wcfManualCompanyFormTradingDateInput",
                                onClick: () =>
                                  !touched.startTrade && setFieldTouched("startTrade", true, false),
                              },
                            }}
                            onChange={(value) => {
                              !touched.startTrade && setFieldTouched("startTrade", true, false);
                              setFieldValue(
                                "startTrade",
                                isNaN(Number(value)) ? null : value,
                                true,
                              );
                            }}
                            value={values.startTrade}
                            label="Trading Date:"
                            format="dd-MM-yyyy"
                            aria-label="company date picker"
                            disableFuture
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                {isOtherOrg && (
                  <Grid container spacing={1} mt={1}>
                    <Grid display="flex" item xs={12}>
                      <Grid item xs={4}>
                        <Typography variant="h6" pb={1}>
                          What is your registration number?
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <CommonTextField
                          label="Registration Number"
                          name="companyRegistrationNumber"
                          id="wcfManualCompanyFormCompanyRegistrationNumber"
                          error={Boolean(
                            touched.companyRegistrationNumber && errors.companyRegistrationNumber,
                          )}
                          helperText={
                            touched?.companyRegistrationNumber && errors?.companyRegistrationNumber
                          }
                          value={values.companyRegistrationNumber}
                          onChange={(e) => {
                            setFieldValue("companyRegistrationNumber", e.target.value);
                          }}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyPress}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "60%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid marginTop="1%" container spacing={1}>
                  <Grid display="flex" item xs={12}>
                    <Grid item xs={4}>
                      <Typography variant="h6" pb={1}>
                        What industry do you work in?
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="wcfManualCompanyFormIndustryInput"
                        select
                        name="industry"
                        label="Industry:"
                        error={Boolean(touched.industry && errors.industry)}
                        value={values.industry}
                        helperText={touched.industry && errors.industry}
                        onChange={(e) => {
                          setFieldValue("industry", e.target.value);
                        }}
                        onBlur={handleBlur}
                        sx={{ width: "60%" }}>
                        {industries.map((value) => (
                          <MenuItem key={value.value} value={value.value}>
                            {value.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1} mt={1}>
                  <Grid display="flex" item xs={12}>
                    <Grid item xs={4}>
                      <Typography variant="h6" pb={1}>
                        What is your company website?
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <CommonTextField
                        id="wcfManualCompanyFormWebsiteInput"
                        label="Website:"
                        name="website"
                        error={Boolean(touched.website && errors.website)}
                        helperText={touched?.website && errors?.website}
                        value={values.website}
                        onClick={handleAddHttp}
                        onFocus={handleAddHttp}
                        onChange={(e) => {
                          setFieldValue("website", e.target.value);
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "60%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mt={1}>
                  <Grid display="flex" item xs={12}>
                    <Grid item xs={4}>
                      <Typography variant="h6" my={1}>
                        What is your VAT number?
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid item xs={12}>
                        <CommonTextField
                          label="VAT number:"
                          name="vatNumber"
                          id="wcfManualCompanyFormVatNumberInput"
                          value={values.vatNumber}
                          error={Boolean(touched.vatNumber && errors.vatNumber)}
                          helperText={touched.vatNumber && errors.vatNumber}
                          onChange={(e) => {
                            setFieldValue("vatNumber", e.target.value);
                          }}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyPress}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "60%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} py={2}>
                  <Grid display="flex" item xs={12} pb={1}>
                    <Grid item xs={4}>
                      <Typography variant="h6">
                        {isOtherOrg || isLimited ? `Registered Address:` : `Trading Address:`}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <CommonTextField
                        label={isOtherOrg || isLimited ? `Registered Address:` : `Trading Address:`}
                        name="tradingAddress"
                        id="wcfManualCompanyFormCommonRegOrTradeAddressInput"
                        value={values.tradingAddress}
                        error={Boolean(touched.tradingAddress && errors.tradingAddress)}
                        helperText={touched.tradingAddress && errors.tradingAddress}
                        onChange={(e) => {
                          setFieldValue("tradingAddress", e.target.value ?? null);
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "60%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid display="flex" item xs={12} md={12}>
                    <Grid item xs={4} />
                    <Grid item xs={8}>
                      <CommonTextField
                        label="City:"
                        name="city"
                        id="wcfManualCompanyFormCommonRegOrTradeCityInput"
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        onChange={(e) => {
                          setFieldValue("city", e.target.value);
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "60%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid display="flex" marginTop="1%" item xs={12} md={12}>
                    <Grid item xs={4} />
                    <Grid item xs={8}>
                      <CommonTextField
                        label="Postcode:"
                        name="postcode"
                        id="wcfManualCompanyFormCommonRegOrTradePostcodeInput"
                        error={Boolean(touched.postcode && errors.postcode)}
                        helperText={touched.postcode && errors.postcode}
                        value={values.postcode}
                        onChange={(e) => {
                          setFieldValue("postcode", e.target.value);
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "60%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid display="flex" item xs={12} mt={1}>
                    <Grid item xs={4} />
                    <Grid item xs={8}>
                      <TextField
                        id="wcfManualCompanyFormCommonRegOrTradeCountryInput"
                        select
                        label="Country:"
                        name="country"
                        value={values.country}
                        onChange={(e) => {
                          setFieldValue("country", e.target.value);
                        }}
                        defaultValue={countries[0].label}
                        sx={{ width: "60%" }}>
                        {countries.map(({ label, value }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container display="flex" alignItems="center">
                  <Grid item xs={12}>
                    <FormControl error={Boolean(touched.privacyPolicy && errors.privacyPolicy)}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="privacyPolicy"
                            id="wcfManualCompanyFormPrivacyPolicyCheckboxInput"
                            checked={values.privacyPolicy}
                            onChange={(e) => {
                              setFieldValue("privacyPolicy", e.target.checked);
                            }}
                          />
                        }
                        label={
                          <Typography>
                            By ticking this box I agree to{" "}
                            <Link
                              href={CONFIG.URLS.PRAETURA_PRIVACY_POLICY}
                              rel="noopener noreferrer"
                              id="wcfManualCompanyFormPrivacyPolicyCheckboxLink"
                              target="_blank">
                              Praetura's Privacy Policy
                            </Link>
                          </Typography>
                        }
                      />
                      {Boolean(touched.privacyPolicy && errors.privacyPolicy) && (
                        <Typography
                          variant="caption"
                          color="error"
                          id="manualCompanyProfilePrivacyError">
                          {touched.privacyPolicy && errors.privacyPolicy}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container display="flex" alignItems="center">
                  <Grid item xs={12}>
                    <FormControl error={Boolean(touched.creditCheck && errors.creditCheck)}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="creditCheck"
                            checked={values.creditCheck}
                            id="wcfManualCompanyFormCreditCheckCheckboxInput"
                            onChange={(e) => {
                              setFieldValue("creditCheck", e.target.checked);
                            }}
                          />
                        }
                        label={
                          <Typography>
                            By ticking this box I consent to Praetura Finance performing a Credit
                            check
                          </Typography>
                        }
                      />
                      {Boolean(touched.creditCheck && errors.creditCheck) && (
                        <Typography
                          variant="caption"
                          color="error"
                          id="manualCompanyProfileCreditError">
                          {touched.creditCheck && errors.creditCheck}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </form>
      <Box flexGrow={1} />
      <WcfFooter
        displaySaveAndExitButton={true}
        displayPrevButton={false}
        displayNextButton={true}
        handleSubmit={handleSubmit}
        values={values}
      />
    </Page>
  );
}

export default ManualCompanyProfile;
