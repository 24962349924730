import { ThemeOptions } from "@mui/material";

const shadows: Exclude<ThemeOptions["shadows"], undefined> = [
  "none",
  "rgba(136, 165, 191, 0.48) 0px 0px 4px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 1px 6px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 2px 6px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 3px 12px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 4px 18px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 5px 20px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 6px 22px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 7px 24px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 8px 26px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 9px 28px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 10px 30px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 11px 32px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 12px 34px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 13px 36px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 14px 38px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 15px 40px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 16px 42px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 17px 44px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 18px 46px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 19px 48px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 19px 50px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 20px 52px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 21px 54px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
  "rgba(136, 165, 191, 0.48) 0px 22px 56px, rgba(255, 255, 255, 0.8) 0px 8px 32px",
];

export default shadows;
