import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { endOfDay, isAfter } from "date-fns";

import { FooterActionsButtons, Page, ResultNotification, ScreenHeader } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getAutomatedCollections,
  getPermissions,
  hideLoader,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { formatDisplayedDate } from "@APP/utils";
import { useAccessPermission } from "@APP/hooks";

const DeliverySuccessView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { date } = queryString.parse(history.location.search);
  const { receivablesSuccess } = useSelector(getAutomatedCollections);
  const permissions = useSelector(getPermissions);
  const { fetchPermission } = useAccessPermission();

  const isDeliveryScheduled = isAfter(endOfDay(new Date(date as string)), endOfDay(new Date()));

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      await fetchPermission([{ rtp: "create" }]);
      dispatch(hideLoader());
    })();
  }, []);

  const getContentMessage = () => {
    if (receivablesSuccess.length === 1) {
      return isDeliveryScheduled
        ? `Your payment request was created. It will be sent to the customer on ${formatDisplayedDate(
            date as string,
          )}.`
        : "Your payment request was successfully sent.";
    }

    return isDeliveryScheduled
      ? `Your payment requests were created. They will be sent to the customers on ${date}.`
      : "Your payment requests were successfully sent.";
  };

  const handleNavigateToDashboard = () => history.push(SCREEN_PATHS.DASHBOARD);
  const handleNavigateToCreateRTP = () => history.push(SCREEN_PATHS.RECEIVABLES_LIST);

  return (
    <Page title="Create Payment Request">
      <ScreenHeader title="Payment Request" id="receivableDeliverySuccessTitle" />

      <ResultNotification type="success">{getContentMessage()}</ResultNotification>

      <FooterActionsButtons
        backButtonText="Back to Dashboard"
        handleBackButton={handleNavigateToDashboard}
        disabledContinueButton={!permissions?.rtp?.create}
        continueButtonText="Create New Payment Request"
        handleContinue={handleNavigateToCreateRTP}
      />
    </Page>
  );
};

export default DeliverySuccessView;
