import { endOfDay, format, isAfter } from "date-fns";

import { createPaymentRequest } from "@APP/services/api";
import { BankAccountExtended, Receivable } from "@APP/types";
import { AuthState } from "@APP/redux/reducers/auth";
import { RtpDetailsState } from "@APP/redux/reducers/rtpDetails";
import { API_DATE_FORMAT, ErpId, Provider } from "@APP/constants";

export enum Channel {
  EMAIL = "Email",
}

export enum RTPMessageType {
  RTP_MESSAGE = "RtpMessage",
  DUE_RTP_MESSAGE = "DueRtpMessage",
  OVERDUE_RTP_MESSAGE = "OverdueRtpMessage",
}

export const createRTP = async (
  rtpDetails: RtpDetailsState,
  user: AuthState["user"],
  receivable: Receivable,
  paymentAccount?: BankAccountExtended,
) => {
  const deliveryDetails = rtpDetails.deliveryDetails;
  const instalmentDetails = rtpDetails.instalmentDetails;

  if (
    (!deliveryDetails.account && !Provider.isMaverick) ||
    !deliveryDetails.deliveryDate ||
    (!deliveryDetails.deliveryEmails[receivable.entityDetails.externalId] &&
      !deliveryDetails.deliveryEmails.default) ||
    (!paymentAccount && !Provider.isMaverick)
  )
    throw new Error("No payment account data found");

  const isDeliveryScheduled = isAfter(endOfDay(deliveryDetails.deliveryDate), endOfDay(new Date()));

  const amountReceivable =
    user?.erp !== ErpId.INTERNAL && receivable.remainingAmountTaxInclusive.amount !== "0.00"
      ? receivable.remainingAmountTaxInclusive
      : receivable.totalAmountTaxInclusive;

  const data = {
    details: receivable.entityDetails,
    supplierBankDetails: !Provider.isMaverick
      ? {
          accountNumber: paymentAccount?.account.identification || "",
          scheme: paymentAccount?.account.schemeName || "",
          bankId: paymentAccount?.bankId,
        }
      : undefined,
    supplier: {
      name: user?.org?.name || "",
      companyInfo: user?.org?.companyInfo,
      businessContact: user?.org?.businessContact,
    },
    customer: {
      ...receivable.customerContact,
      email:
        deliveryDetails.deliveryEmails[receivable.entityDetails.externalId] ||
        deliveryDetails.deliveryEmails.default,
    },
    channel: Channel.EMAIL,
    status: "Created",
    scheduledDates: isDeliveryScheduled
      ? [format(deliveryDetails.deliveryDate, API_DATE_FORMAT)]
      : undefined,
    reminders: [
      {
        enabled: rtpDetails.reminder,
        channel: Channel.EMAIL,
        tpe: RTPMessageType.DUE_RTP_MESSAGE,
      },
    ],
    amount: amountReceivable,
    standingOrder: rtpDetails.isRecurring
      ? {
          reference: receivable.reference,
          frequency: instalmentDetails.frequency,
          numberOfPayments: instalmentDetails.occurrences.toString(),
          firstPaymentDate: new Date(instalmentDetails.firstPaymentDate).toISOString(),
          firstPaymentAmount: {
            amount: instalmentDetails.firstPaymentAmount!,
            currency: receivable.totalAmountTaxInclusive.currency,
          },
          finalPaymentAmount: instalmentDetails.finalPaymentAmount
            ? {
                amount: instalmentDetails.finalPaymentAmount!,
                currency: receivable.totalAmountTaxInclusive.currency,
              }
            : undefined,
        }
      : undefined,
    rtpCreationUserZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    cardPaymentProvider: rtpDetails.acceptCardPayments ? "square" : undefined,
  };

  return await createPaymentRequest(data, isDeliveryScheduled ? "Create" : "CreateAndSend");
};
