import { useHistory, useLocation } from "react-router-dom";
import { Box, Card } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ConsentContent, FooterActionsButtons, Page } from "@APP/components";
import { useSetupConsentInitiation } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  paragraph: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
}));

const SetupBankConsentView = () => {
  const classes = useStyles();
  const history = useHistory();
  const setupConsentInitiation = useSetupConsentInitiation();
  const { state } = useLocation<{
    bankId: string;
    provider: string;
    bankName: string;
    redirectUrl: string;
  }>();
  const bankName: string = state?.bankName;
  const bankProvider: string = state?.provider;

  return (
    <Page className={classes.root} title="Bank Consent">
      <Box mb={3}>
        <Card elevation={12}>
          <ConsentContent bankName={bankName} bankProvider={bankProvider} />
        </Card>
        <FooterActionsButtons
          backButtonText="Cancel"
          handleBackButton={history.goBack}
          continueButtonText="Approve"
          handleContinue={setupConsentInitiation}
          backButtonDataTestId="access-account-information-cancel-button"
          continueButtonDataTestId="access-account-information-continue-button"
        />
      </Box>
    </Page>
  );
};

export default SetupBankConsentView;
