import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { SortType } from "@APP/types";

/**
 * Returns correct sort icon based on current state of sortBy and sortType
 */

interface Props {
  active: boolean;
  type: SortType;
}

const SortIcon = ({ active, type }: Props) => {
  if (active) {
    switch (type) {
      case SortType.asc:
        return <ExpandLessIcon className="tableActionIcon" color="error" />;
      case SortType.desc:
        return <ExpandMoreIcon className="tableActionIcon" color="error" />;
    }
  }

  return <UnfoldMoreIcon className="tableActionIcon" color="error" />;
};

export default SortIcon;
