import Joi from "joi";

const urgencyValues = ["asap", "in_the_following_weeks", "in_the_following_months", "other"];

export const invoiceFinanceRequestSchema = Joi.object({
  annualTurnover: Joi.number().precision(2).min(1).required().label("Annual turnover"),
  currency: Joi.string().valid("GBP", "USD", "EUR").default("GBP").label("Currency"),
});

export const invoiceFinance = Joi.object({
  urgency: Joi.string()
    .valid(...urgencyValues)
    .default("asap")
    .label("Urgency"),
  detailedFundingPurpose: Joi.string()
    .optional()
    .allow(null)
    .max(10000)
    .label("Detailed funding purpose"),
  invoiceFinanceRequest: invoiceFinanceRequestSchema.required().label("Invoice finance request"),
}).unknown(true);
