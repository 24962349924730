import { Box } from "@mui/material";

import { Page } from "@APP/components";
import { ClearingLedgerView, SetLedgerForPaymentsBooking } from "@APP/views";
import { Provider } from "@APP/constants";

const SetupLedger = () => {
  return (
    <Page title="Corresponding bank accounts">
      <SetLedgerForPaymentsBooking />
      {Provider.isMaverick && (
        <Box mt={4}>
          <ClearingLedgerView />
        </Box>
      )}
    </Page>
  );
};

export default SetupLedger;
