import { SyntheticEvent, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import queryString from "query-string";

import { Box, Button, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIphoneOutlined from "@mui/icons-material/PhoneIphoneOutlined";
import InfoIcon from "@mui/icons-material/InfoRounded";

import CONFIG from "@APP/config";
import { AppState, logout } from "@APP/redux";
import { AuthForm, AuthLayout, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { AppLocalStorage, LocalStorageKey } from "@APP/services";

type InformationContent = {
  primaryText: string;
  secondaryText?: string | JSX.Element;
  icon?: JSX.Element;
  alignItems?: "center" | "flex-start";
};

export interface Props {
  auth: AppState["auth"];
  logout: ReturnType<typeof mapDispatchToProps>["logout"];
}

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    marginTop: 0,
    minWidth: 40,
  },
  listItemText: {
    margin: 0,
  },
  registrationWarning: {
    color: theme.palette.secondary.main,
  },
}));

const RegistrationInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const informationContent: InformationContent[] = [
    {
      primaryText: "An Email Address",
      secondaryText: (
        <>
          We will use your email address as part of our verification process. It will also be shown
          to your customers in any outgoing communications.
          <br />
          <b>Please ensure you choose the email address you wish to display to your customers.</b>
        </>
      ),
      icon: <EmailOutlinedIcon color="primary" />,
      alignItems: "flex-start",
    },
    {
      primaryText: t("Registration.InfoPhoneNumber"),
      secondaryText: (
        <>
          We will use your mobile telephone number as part of our verification process. It will also
          be shown to your customers in any outgoing communications.
          <br />
          <b>
            Please ensure you use the mobile phone number you wish to display to your customers.
          </b>
        </>
      ),
      icon: <PhoneIphoneOutlined color="primary" />,
      alignItems: "flex-start",
    },
  ];

  return (
    <>
      <Box mb={0.5}>
        <Typography variant="h4">For account registration you will need:</Typography>
      </Box>
      <List dense>
        {informationContent.map(({ primaryText, secondaryText, icon, alignItems }) => (
          <ListItem key={primaryText} alignItems={alignItems} disableGutters>
            {icon && <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>}
            <Box mb={1}>
              <Box mb={1}>
                <Typography variant="subtitle2">{primaryText}</Typography>
              </Box>
              <Typography variant="body2">{secondaryText}</Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export const RegistrationInformationView = ({ auth }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const { ref } = queryString.parse(location.search);

  useEffect(() => {
    (async () => {
      if (ref) {
        const refValue = Array.isArray(ref) ? ref.join("") : ref;
        AppLocalStorage.setItem(LocalStorageKey.authReference, refValue);
      }
    })();
  }, [ref]);

  useEffect(() => {
    if (auth.authenticated) logout();
  }, []);

  const handleOnContinue = (event: SyntheticEvent) => {
    event.stopPropagation();
    history.push(SCREEN_PATHS.REGISTRATION_TERMS_AND_CONDITIONS);
  };

  const submitButton = () => (
    <Button
      fullWidth
      size="large"
      color="primary"
      variant="contained"
      data-testid="continue-button"
      id="continue-button"
      onClick={handleOnContinue}>
      Continue
    </Button>
  );

  const fiveMinuteNotice = () => (
    <Typography align="center" variant="h6" component="p">
      The process of registration takes around 5 minutes.
    </Typography>
  );

  /* Replace solution name when this information becomes available from Product */
  const renderMainContent = () =>
    CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION ? (
      <AuthForm title="Funding your potential" backwardPath={SCREEN_PATHS.LOGIN}>
        <Box mb={3}>
          <Box marginY={1}>
            <Typography my={2}>Welcome to Praetura Lending!</Typography>
            <Typography mb={2}>Let us know how we can support your business.</Typography>
            <Typography mb={2}>
              To get started with your application, you need to create a profile using your email
              address and phone number. You can pause your application at any point in time and
              resume by simply logging on again.
            </Typography>
            <Typography>To create your Praetura Lending profile, you need: </Typography>
          </Box>
          <List dense>
            <ListItem>
              <Box>
                <ListItemIcon className={classes.listItemIcon}>
                  <EmailOutlinedIcon />
                </ListItemIcon>
              </Box>
              <Box>
                <Typography>An Email Address</Typography>
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                <ListItemIcon className={classes.listItemIcon}>
                  <PhoneIphoneOutlined />
                </ListItemIcon>
              </Box>
              <Box>
                <Typography>A UK Phone Number</Typography>
              </Box>
            </ListItem>
          </List>
          {fiveMinuteNotice()}
        </Box>
        {submitButton()}
      </AuthForm>
    ) : (
      <>
        {isMobile && CONFIG.PIS_PROVIDER === "Maverick" && (
          <Box pb={2} display="flex">
            <InfoIcon fontSize="medium" color="secondary" />
            <Typography className={classes.registrationWarning} variant="h4" align="center">
              Registration is easiest on your desktop. Go to{" "}
              <b>{CONFIG.API.ROOT_URL}/registration</b> on your desktop to register.
            </Typography>
          </Box>
        )}
        <AuthForm backwardPath={SCREEN_PATHS.REGISTRATION_START}>
          <Box mb={3}>
            <Box mb={2}>
              <RegistrationInformation />
            </Box>
            {fiveMinuteNotice()}
          </Box>
          {submitButton()}
        </AuthForm>
      </>
    );

  return (
    <Page title="Get Started" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

const mapStateToProps = ({ auth }: AppState) => ({ auth });

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationInformationView);
