import { useEffect } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";

import { Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { setNextPath } from "@APP/redux/actions/workingCapitalFinance";
import WcfFooter from "@APP/components/layout/WCFLayout";
import { IMAGES } from "@APP/assets";
import CONFIG from "@APP/config";
import { useHistory, useParams } from "react-router-dom";
import { API } from "@APP/services";
import {
  getBankAccounts,
  getDashboardVisible,
  getReviewForm,
  hideLoader,
  setReviewPage,
  setSettingsHidden,
  showLoader,
} from "@APP/redux";
import LinkBankAccounts from "@APP/views/dashboard/LinkBankAccounts/LinkBankAccounts";
import { TabsName } from "@APP/constants";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    maxHeight: "85vh",
    height: "80vh",
    flexDirection: "column",
    padding: "32px",
    alignItems: "center",
    width: "100%",
    position: "relative",
    borderRadius: "5px",
  },
  nextSteps: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
  },
  LinkBankAccount: {
    width: "100%",
  },
}));

function SuccessPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const isReviewForm = useSelector(getReviewForm);
  const isDashboardVisible = useSelector(getDashboardVisible);
  const bankAccounts = useSelector(getBankAccounts);

  const { applicationId }: { applicationId: string } = useParams();

  const retrieveApplication = async () => {
    try {
      dispatch(showLoader());
      const res = await API.getApplicationDetailsById(applicationId);
      if (res && res?.data && res?.data?.praeturaStatus && !isReviewForm)
        history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
    } catch (error) {
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    retrieveApplication();
    dispatch(setNextPath(SCREEN_PATHS.WCF_FINANCE_LISTING));
    dispatch(setSettingsHidden(false));
    return () => {
      dispatch(setReviewPage(false));
    };
  }, []);

  const redirectToBankAccounts = () => {
    history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.BANK_ACCOUNTS}`);
  };

  return (
    <Page className={classes.container} p={0}>
      <Box p={3}>
        <Card
          elevation={1}
          className={classes.cardContainer}
          style={{
            overflow:
              isDashboardVisible && ((bankAccounts && bankAccounts?.length < 0) || !bankAccounts)
                ? "scroll"
                : "hidden",
          }}>
          <img alt={CONFIG.PRODUCT_NAME} src={IMAGES.SUCCESS_GIF} id="successGIF" />
          <Typography variant="h3" mt={2} mb={2} id="successTitle">
            Your Finance Application has been
          </Typography>
          <Typography variant="h3" id={"wcfSuccessfullySubmitted"}>
            Successfully Submitted 🎉
          </Typography>

          <CardContent className={classes.nextSteps}>
            <Typography variant="h6">Next steps to keep in mind</Typography>
            <Typography variant="body2" color="textSecondary">
              We are now processing your application. You will be contacted within 2 business days.
            </Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              You can always check the status of your application in this portal. Go to Submitted
            </Typography>
            <Typography variant="body2" color="textSecondary">
              applications to view the details of your application and its status.
            </Typography>
          </CardContent>
          {isDashboardVisible && ((bankAccounts && bankAccounts?.length < 0) || !bankAccounts) && (
            <CardContent className={classes.LinkBankAccount}>
              <LinkBankAccounts redirect={redirectToBankAccounts} />
            </CardContent>
          )}
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter
        displaySaveAndExitButton={false}
        displayPrevButton={false}
        displayNextButton={false}
        goToApplication={true}
      />
    </Page>
  );
}

export default SuccessPage;
