import * as Yup from "yup";

const ACCOUNT_HOLDER_NAME_REGEX = /^([a-zA-Z0-9 \-&,.[\]()]+)$/;

export const errorMessages = {
  sortCode: {
    required: "Please enter your Sort Code.",
    invalidLength: "Your sort code should consist of 6 numbers.",
  },
  accountHolderName: {
    required: "Please enter Business Name.",
    maxLength: "Your Business Name should be less than 150 characters long.",
    invalidRegex:
      "Your Business Name should consist of letters, numbers, spaces and & , - ( ) [ ]. characters only.",
  },
  accountNumber: {
    required: "Please enter your Bank Account Number.",
    invalidRegex: "Your Account Number should consist of 8 numbers.",
  },
  sortCodeConfirm: {
    required: "Please confirm your Sort Code.",
    invalidMatch: "Sort Code must match.",
  },
  accountHolderNameConfirm: {
    required: "Please confirm your Business Name.",
    invalidMatch: "Account Holder's Name must match.",
  },
  accountNumberConfirm: {
    required: "Please confirm your Bank Account Number.",
    invalidMatch: "Account Number must match.",
  },
};

const validationSchema = Yup.object().shape({
  sortCode: Yup.string()
    .required(errorMessages.sortCode.required)
    .test(
      "sort code",
      errorMessages.sortCode.invalidLength,
      (value: string = "") => value.replaceAll("-", "").length === 6,
    ),
  accountHolderName: Yup.string()
    .required(errorMessages.accountHolderName.required)
    .max(150, errorMessages.accountHolderName.maxLength)
    .matches(ACCOUNT_HOLDER_NAME_REGEX, errorMessages.accountHolderName.invalidRegex),
  accountNumber: Yup.string()
    .required(errorMessages.accountNumber.required)
    .matches(/^\d{8}$/, errorMessages.accountNumber.invalidRegex),
  sortCodeConfirm: Yup.string()
    .required(errorMessages.sortCodeConfirm.required)
    .oneOf([Yup.ref("sortCode"), null], errorMessages.sortCodeConfirm.invalidMatch),
  accountHolderNameConfirm: Yup.string()
    .required(errorMessages.accountHolderNameConfirm.required)
    .oneOf(
      [Yup.ref("accountHolderName"), null],
      errorMessages.accountHolderNameConfirm.invalidMatch,
    ),
  accountNumberConfirm: Yup.string()
    .required(errorMessages.accountNumberConfirm.required)
    .oneOf([Yup.ref("accountNumber"), null], errorMessages.accountNumberConfirm.invalidMatch),
});

export default validationSchema;
