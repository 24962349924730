import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import CONFIG from "@APP/config";
import { i18n } from "@APP/services";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

i18n.init();

if (CONFIG.SERVICES.SENTRY && !["test", "development"].includes(process.env.NODE_ENV)) {
  Sentry.init({ dsn: CONFIG.SERVICES.SENTRY.DSN });
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
