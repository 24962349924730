import { ThemeOptions } from "@mui/material";

import shadows from "./shadows";
import typography from "./typography";
import palette from "./palette";
import shape from "./shape";
import components from "./components";

const themeOptions: ThemeOptions = {
  palette,
  shadows,
  typography,
  shape,
  components,
};

export default themeOptions;
