import { createReducer } from "deox";
import {
  setActiveStep,
  setApplicantFormData,
  setApplicationID,
  setAssetFinanceFormData,
  setCompanyProfileFormData,
  setFormSubType,
  setFormType,
  setInvoiceFinanceFormData,
  setNextClicked,
  setNextPath,
  setNextStepActive,
  setProductType,
  setCustomer,
  setIsRefinance,
  setOrgType,
} from "../actions/workingCapitalFinance";
import { WcfForm } from "@APP/types/wcf";
import { assetTypes, countries, industries, urgencyLevels } from "@APP/views/wcf/utils/dropdown";
import { WcfCustomer } from "@APP/types";

export interface WCFDetailsDtate {
  nextStepActive: boolean;
  nextPath: string;
  activeStep: number;
  applicationId: string;
  wcfFormData: WcfForm;
  formType: string;
  subType: string;
  clicked: boolean;
  title: string;
  productType: string;
  isRefinance: boolean;
  customer: WcfCustomer;
  orgType: string;
}

export const companyDefaultData = {
  industry: industries[0].value,
  website: "",
  startTrade: "",
  tradingName: "",
  tradingAddress: "",
  city: "",
  registeredCompanyName: "",
  companyRegistrationNumber: "",
  vatNumber: "",
  postcode: "",
  country: countries[0].value,
  privacyPolicy: false,
  creditCheck: false,
};

export const assetDefaultData = {
  units: null,
  pricePerUnit: null,
  duration: "60",
  deposit: null,
  purpose: "",
  urgency: urgencyLevels[0].value,
  assetType: assetTypes[0].value,
  model: "",
  manufacturer: "",
  yearOfManufactured: null,
  isRefinance: null,
  isNew: "new",
  isInsured: false,
  assetPic: [],
  invoicePic: [],
};

export const applicantDefaultData = [];

export const invoiceDefaultData: WcfForm["invoiceFinanceForm"] = {
  purpose: null,
  urgency: urgencyLevels[0].value,
  annualTurnover: null,
};

export const wcfDefaultData = {
  companyProfileForm: companyDefaultData,
  assetFinanceForm: assetDefaultData,
  applicantDetailsForm: applicantDefaultData,
  invoiceFinanceForm: invoiceDefaultData,
};

export const customerDefaultData: WcfCustomer = {
  status: "",
  registeredCompanyName: null,
  tradingName: "",
  companyType: "",
  companyRegistrationNumber: "",
  tradingFromDate: "",
  industry: "",
  sicCode: "",
  website: null,
  registeredAddress: {
    street: "",
    cityOrTown: "",
    countyOrState: null,
    country: "",
    postcode: "",
  },
  tradingAddress: {
    street: "",
    cityOrTown: "",
    countyOrState: null,
    country: "",
    postcode: "",
  },
  vatInformation: {
    vatNumber: "",
    isRegisteredForVat: false,
  },
  hasConsentedToCreditCheck: false,
  hasConsentedToPrivacyPolicy: false,
};

const defaultState: WCFDetailsDtate = {
  nextStepActive: false,
  nextPath: "",
  activeStep: 0,
  wcfFormData: wcfDefaultData,
  formType: "",
  subType: "",
  applicationId: "",
  clicked: false,
  title: "",
  productType: "",
  isRefinance: false,
  customer: customerDefaultData,
  orgType: "",
};

const workingcapitalFinanceReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setNextStepActive, (state, { payload }) => ({
    ...state,
    nextStepActive: payload,
  })),

  handleAction(setNextPath, (state, { payload }) => ({
    ...state,
    nextPath: payload,
  })),

  handleAction(setActiveStep, (state, { payload }) => ({
    ...state,
    activeStep: payload,
  })),

  handleAction(setApplicationID, (state, { payload }) => ({
    ...state,
    applicationId: payload,
  })),

  handleAction(setProductType, (state, { payload }) => ({
    ...state,
    productType: payload,
  })),

  handleAction(setOrgType, (state, { payload }) => ({
    ...state,
    orgType: payload,
  })),

  handleAction(setIsRefinance, (state, { payload }) => ({
    ...state,
    isRefinance: payload,
  })),

  handleAction(setNextClicked, (state, { payload }) => ({
    ...state,
    clicked: payload.clicked,
    title: payload.title,
  })),

  handleAction(setApplicantFormData, (state, { payload }) => ({
    ...state,
    wcfFormData: {
      ...state.wcfFormData,
      applicantDetailsForm: payload,
    },
  })),

  handleAction(setCompanyProfileFormData, (state, { payload }) => ({
    ...state,
    wcfFormData: {
      ...state.wcfFormData,
      companyProfileForm: payload,
    },
  })),

  handleAction(setAssetFinanceFormData, (state, { payload }) => ({
    ...state,
    wcfFormData: {
      ...state.wcfFormData,
      assetFinanceForm: payload,
    },
  })),

  handleAction(setInvoiceFinanceFormData, (state, { payload }) => ({
    ...state,
    wcfFormData: {
      ...state.wcfFormData,
      invoiceFinanceForm: payload,
    },
  })),

  handleAction(setFormType, (state, { payload }) => ({
    ...state,
    formType: payload,
  })),

  handleAction(setFormSubType, (state, { payload }) => ({
    ...state,
    subType: payload,
  })),

  handleAction(setCustomer, (state, { payload }) => ({
    ...state,
    customer: payload,
  })),
]);

export default workingcapitalFinanceReducer;
