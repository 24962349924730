import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { getUser } from "@APP/redux";
import { ORG_ADMIN_ROLE, TabsName } from "@APP/constants";

import CreateUser from "./CreateUser";
import UserDetails from "./UserDetails";
import UsersList from "./UsersList";
import SuccessCreationUser from "./SuccessCreationUser";

const Users = () => {
  const history = useHistory();
  const queryParams = queryString.parse(history.location?.search ?? "") as { tab: string };
  const user = useSelector(getUser);

  if (user?.roles.includes(ORG_ADMIN_ROLE)) {
    return (
      <>
        {queryParams.tab === TabsName.USER && <UserDetails />}
        {queryParams.tab === TabsName.USERS_LIST && <UsersList />}
        {queryParams.tab === TabsName.CREATE_USER && <CreateUser />}
        {queryParams.tab === TabsName.CREATE_USER_SUCCESS && <SuccessCreationUser />}
      </>
    );
  }

  return <>{queryParams.tab === TabsName.USER && <UserDetails />}</>;
};

export default Users;
