import parse from "html-react-parser";
import { Box, Button, Grid, Typography } from "@mui/material";

import { InvoiceType } from "@APP/types";

interface Props {
  dataType: "receivables" | "payables" | "rtps";
  onTryAgain?: () => void;
  tryAgainErrorMessage?: string;
  showTryAgainButton?: boolean;
  errorCodeMessage?: string;
}

const ListWithError = ({
  dataType,
  onTryAgain,
  tryAgainErrorMessage,
  showTryAgainButton = true,
  errorCodeMessage,
}: Props) => {
  const getErrorMessage = () => {
    switch (dataType) {
      case InvoiceType.Receivables:
        return "We are unable to retrieve your Invoice details from your accounting package. Please check the link via the settings menu.";
      case "rtps":
        return "We are unable to get your Payment Requests. Please try again later.";
      case InvoiceType.Payables:
        return "We are unable to retrieve your Supplier Invoice details from your accounting package. Please check the link via the settings menu.";
    }
  };

  return (
    <>
      <Box
        minHeight={420}
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center">
        <Typography data-testid="error-message" variant="body1" id="listWithErrorMessage">
          {tryAgainErrorMessage || getErrorMessage()}
          <br />
          Please try again later.
          {parse(errorCodeMessage || "")}
        </Typography>
      </Box>
      {showTryAgainButton && (
        <Grid container justifyContent="center">
          <Grid item md={4}>
            <Box textAlign="center" mt={3}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                data-testid={`try-again-button-${dataType}`}
                id={`tryAgainButton${dataType}`}
                onClick={onTryAgain}>
                Try again
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ListWithError;
