import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Box, Button, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SubscriptionOperations, TabsName } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import { getSubscription } from "@APP/redux";
import CONFIG from "@APP/config";
import { SubscriptionFeatureTypes } from "@APP/types";
import { Center } from "@APP/views/common";

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
  },
}));

const Success = () => {
  const classes = useStyles();
  const history = useHistory();

  const { operation } = queryString.parse(history.location.search ?? "") as { operation: string };
  const subscription = useSelector(getSubscription);

  const isPlanOfCoopUser =
    subscription?.isPlanFree &&
    CONFIG.FEATURES.SUBSCRIPTIONS.TYPE === SubscriptionFeatureTypes.Flexible;

  const renderMessageContent = () => {
    if (operation === SubscriptionOperations.SUBSCRIBE) {
      return isPlanOfCoopUser ? (
        <Typography>Your account has been successfully activated.</Typography>
      ) : (
        <Typography>Your account is live, you can now use all features in the app.</Typography>
      );
    }

    return <Typography variant="h5">Your account has been successfully deactivated.</Typography>;
  };

  return (
    <Card elevation={4} className={classes.root}>
      <Box textAlign="center" mb={3} mt={3}>
        <Typography variant="h3" color="primary">
          Success!
        </Typography>
      </Box>
      <CardContent>{renderMessageContent()}</CardContent>
      <Divider />
      <CardActions>
        <Center>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNT}`)}
            id="successFinishButton"
            className="minWidth">
            Finish
          </Button>
        </Center>
      </CardActions>
    </Card>
  );
};

export default Success;
