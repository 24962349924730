import { Amount } from ".";

type ForecastBankAccount = {
  bankId: string;
  accountIdentification: string;
  accountName: string;
  balance: Amount;
  logo?: string;
};

export type ForecastInvoice = {
  id: string;
  dueDate: Date;
  customerName: string;
  amount: Amount;
};

type ForecastPayableReceivable = {
  totalAmount: Amount;
  items: ForecastInvoice[];
};

export type ForecastChartData = {
  date: string;
  balance: number;
};

export type ForecastResponse = {
  balances?: {
    totalBalance: Amount;
    items: ForecastBankAccount[];
  } | null;
  unpaidInvoices: ForecastPayableReceivable;
  unpaidBills: ForecastPayableReceivable;
  forecast?: ForecastChartData[];
};

export enum TxType {
  DEBIT = "debit",
  CREDIT = "credit",
}

export enum ForecastFilterOp {
  INCLUDE = "include",
  EXCLUDE = "exclude",
}

export type ForecastManualTransaction = {
  date: Date;
  amount: Amount;
  type: TxType;
};

type ForecastInvoiceFilter = {
  ids: string[];
  op: ForecastFilterOp;
};

export type CustomForecastRequest = {
  manualBalance?: Amount;
  manualTxs?: Array<Omit<ForecastManualTransaction, "date"> & { date: string }>;
  payablesFilter?: ForecastInvoiceFilter;
  receivablesFilter?: ForecastInvoiceFilter;
};

export enum CashflowForecastingPaymentType {
  Payables = "payables",
  Receivables = "receivables",
  ManualTx = "manualTx",
}
