import { useCallback, useEffect, useState, KeyboardEvent } from "react";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateFieldProps, DateValidationError } from "@mui/x-date-pickers";
import { format, isValid } from "date-fns";
import { parsDate } from "@APP/utils";

import { CommonTextFieldProps } from "../CommonTextField/CommonTextField";
import { CommonDatePickerValidationScheme } from "./CommonDatePickerValidationSchema";

export type CommonDatePickerProps<TDate> = DatePickerProps<TDate> & {
  customValidation?: boolean;
  minDate?: Date;
  maxDate?: Date;
  maxDateMessage?: string;
  minDateMessage?: string;
  invalidDateMessage?: string;
  onFieldBlur?: (value: Date | null, error?: string) => void;
  ariaOpenDialogLabelPrefix?: string;
  slotProps: {
    textField: CommonTextFieldProps;
    field?: DateFieldProps<TDate>;
  };
};

const CommonDatePicker = ({
  onChange,
  value,
  label,
  maxDate,
  minDate,
  maxDateMessage,
  minDateMessage,
  invalidDateMessage,
  customValidation = false,
  onFieldBlur,
  slotProps: {
    textField: {
      name,
      id,
      fullWidth = true,
      variant = "outlined",
      error,
      margin = "normal",
      helperText = " ",
      onBlur,
      FormHelperTextProps,
      // using the spread operator here causes the modal popup to not correctly display when viewing from iOS safari.
    },
    field,
    ...slotProps
  },
  ariaOpenDialogLabelPrefix = `Choose ${label}`,

  ...restProps
}: CommonDatePickerProps<any>) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const validateDateAndSetValidationError = useCallback(
    (date: Date | null) => {
      if (!customValidation)
        try {
          CommonDatePickerValidationScheme({
            minDate,
            maxDate,
            maxDateMessage,
            minDateMessage,
            invalidDateMessage,
          }).validateSync({
            date,
          });
          setErrorMessage(undefined);
        } catch (error) {
          setErrorMessage(error.message);
          return error.message;
        }
    },
    [minDate, maxDate, customValidation],
  );

  // if the value is changed by an external trigger, need to validate the value or the error state doesn't update correctly
  useEffect(() => {
    !customValidation && validateDateAndSetValidationError(value);
  }, [value]);

  const handleEnterKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") return;

    onFieldBlur?.(parsDate((event.target as HTMLInputElement).value), errorMessage);
  };

  return (
    <DatePicker
      className="keyboardDatePicker"
      value={value ? new Date(value) : null}
      label={label}
      onChange={(date, context) => {
        if (!customValidation) {
          const newValidationError = validateDateAndSetValidationError(date);
          onChange?.(date, { validationError: newValidationError as DateValidationError });
        } else onChange?.(date, context);
      }}
      slots={{
        day: (props: PickersDayProps<any>) => (
          <PickersDay
            {...props}
            aria-disabled={props.disabled}
            tabIndex={props.disabled ? -1 : 0}
          />
        ),
      }}
      slotProps={{
        textField: {
          name: name,
          variant: variant,
          fullWidth: fullWidth,
          margin: margin,
          id: id,
          error: customValidation ? error : Boolean(errorMessage),
          helperText: customValidation ? helperText : errorMessage || " ",
          FormHelperTextProps: {
            id: `${name}-error`,
            "aria-live": "assertive",
            className:
              (customValidation
                ? helperText === " " && " visuallyHidden"
                : errorMessage === undefined && " visuallyHidden") || undefined,
            ...FormHelperTextProps,
          },
          InputLabelProps: {
            shrink: true,
          },
          onBlur: (event) => {
            onBlur?.(event);
            onFieldBlur?.(parsDate(event.target.value), errorMessage);
          },
          onKeyDown: handleEnterKeyDown,
        },
        openPickerButton: {
          "aria-label":
            errorMessage !== "invalidDate" && value && isValid(value)
              ? `${ariaOpenDialogLabelPrefix}, selected date is ${format(value, "PPP")}`
              : `${ariaOpenDialogLabelPrefix}`,
        },
        ...slotProps,
      }}
      sx={{ marginBottom: 0 }}
      minDate={minDate}
      maxDate={maxDate}
      disablePast
      {...restProps}
    />
  );
};

export default CommonDatePicker;
