import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Card, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Page } from "@APP/components";
import WcfFooter from "@APP/components/layout/WCFLayout";
import { setNextPath } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { WcfCustomer } from "@APP/types";
import { API } from "@APP/services";
import { convertToCapitalizeCase } from "@APP/views/wcf/utils/utils";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "center",
    borderRadius: "5px",
    minHeight: "80vh",
  },
  bold: {
    color: "#000",
  },
}));

const CompanyDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState<WcfCustomer | null>(null);

  const fetchWcfCustomer = async () => {
    try {
      const customer = await API.getWcfCustomer();
      setCustomer(customer);
    } catch (error) {
      setCustomer(null);
    }
  };

  useEffect(() => {
    dispatch(setNextPath(`${SCREEN_PATHS.WCF_MANUAL_COMPANY}`));
    fetchWcfCustomer();
  }, []);

  return (
    <Page className={classes.container} p={0}>
      <Box display="flex" flexDirection="column" flexGrow={1} p={3}>
        <Card elevation={1} className={classes.cardContainer}>
          <form style={{ width: "100%" }}>
            <Grid container>
              <Grid item xs={12} md={7}>
                <Typography
                  variant="h4"
                  component="p"
                  fontWeight={600}
                  color="primary"
                  paddingBottom={2}
                  id="companyDetailsTitle">
                  Check your company profile
                </Typography>
              </Grid>
              <Grid container spacing={1} pb={2}>
                <Grid item xs={12} md={7}>
                  <Typography variant="body2" color="textSecondary">
                    Please view the company profile we retrieved from companies house. Click{" "}
                    <b className={classes.bold}>“Next Step”</b> to proceed.
                  </Typography>
                </Grid>
                <Grid container spacing={1} mt={1} p="8px 0px 0px 8px">
                  <Grid item display="flex" alignItems="center" xs={12} md={7}>
                    <Grid item xs={12} md={3}>
                      <Typography variant="h6">Company Name:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="body2" color="textSecondary">
                        {customer?.registeredCompanyName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} p="8px 0px 0px 8px">
                  <Grid item display="flex" alignItems="center" xs={12} md={7}>
                    <Grid item xs={12} md={3}>
                      <Typography variant="h6">Registered Address:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="body2" color="textSecondary">
                        {`${customer?.registeredAddress?.street} ${customer?.registeredAddress?.cityOrTown} ${customer?.registeredAddress?.country} ${customer?.registeredAddress?.postcode}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} p="8px 0px 0px 8px">
                  <Grid item display="flex" alignItems="center" xs={12} md={7}>
                    <Grid item xs={12} md={3}>
                      <Typography variant="h6">Company Type:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="body2" color="textSecondary">
                        {convertToCapitalizeCase(customer?.companyType ?? "")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} p="8px 0px 0px 8px">
                  <Grid item display="flex" alignItems="center" xs={12} md={7}>
                    <Grid item xs={12} md={3}>
                      <Typography variant="h6">Company Number:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="body2" color="textSecondary">
                        {customer?.companyRegistrationNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} p="8px 0px 0px 8px">
                  <Grid item display="flex" alignItems="center" xs={12} md={7}>
                    <Grid item xs={12} md={3}>
                      <Typography variant="h6">Trading Start Date:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="body2" color="textSecondary">
                        {customer?.tradingFromDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} p="8px 0px 0px 8px">
                  <Grid item display="flex" alignItems="center" xs={12} md={7}>
                    <Grid item xs={12} md={3}>
                      <Typography variant="h6">SIC Code:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography variant="body2" color="textSecondary">
                        {customer?.sicCode}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter displaySaveAndExitButton={false} />
    </Page>
  );
};

export default CompanyDetails;
