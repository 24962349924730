import { AppState } from "@APP/redux";
import CONFIG from "@APP/config";

import { useCheckCardPaymentAppAvailable, useCheckLedgersLinked } from "./checks";

export type FeatureGateways = ReturnType<typeof useFeatureGateway>[keyof ReturnType<
  typeof useFeatureGateway
>];

type CreatePaymentOptions = {
  checkClearingLedger?: boolean;
};

/**
 * Entry point for all features that are needed in checks
 */
const useFeatureGateway = () => {
  const checkCardPaymentAppAvailable = useCheckCardPaymentAppAvailable();
  const { checkLedgersLinked, checkClearingLedgerLinked } = useCheckLedgersLinked();

  const createPayment = async (options?: CreatePaymentOptions, state?: AppState) => {
    const checkCardPaymentAppAvailableResult = await checkCardPaymentAppAvailable(state);

    if (!checkCardPaymentAppAvailableResult) return false;

    return await checkLedgersLinked(
      options?.checkClearingLedger && CONFIG.FEATURES.SETUP.CLEARING_LEDGER,
    );
  };

  const createInvoice = async (state?: AppState) => {
    return await checkLedgersLinked(CONFIG.FEATURES.SETUP.CLEARING_LEDGER, state);
  };

  const checkClearingLedger = async (state?: AppState) => {
    return await checkClearingLedgerLinked(state);
  };

  return { createPayment, createInvoice, checkClearingLedger };
};

export default useFeatureGateway;
