import { useEffect, useRef, useState } from "react";
import { TFunction, Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import throttle from "lodash/throttle";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

import CONFIG from "@APP/config";

import TermsAndConditionsLinks from "./TermsAndConditionsLinks";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    maxHeight: 300,
    overflowY: "auto",
    marginBottom: theme.spacing(2),
    "& p": {
      marginBottom: theme.spacing(1),
    },
    "& ul": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      "& li": {
        marginBottom: theme.spacing(0.5),
      },
    },
    "& a": {
      color: theme.palette.primary.main,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  iconBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const termsAndConditionsValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    termsAndConditions: Yup.bool().oneOf([true], t("Errors.Registration.Validation.TermsAccept")),
  });

interface Props {
  onSubmit: () => void | Promise<void>;
}

const TermsAndConditions = ({ onSubmit }: Props) => {
  const [showChevron, setShowChevron] = useState(true);
  const { t } = useTranslation();
  const contentRef = useRef();
  const classes = useStyles();

  const { handleChange, handleSubmit, values, isValid, dirty } = useFormik({
    initialValues: { termsAndConditions: false },
    validationSchema: termsAndConditionsValidationSchema(t),
    onSubmit: onSubmit,
  });

  const watchScroll = (e: React.UIEvent) => {
    const t = e.target as HTMLElement;
    const isTextEnd = t.scrollHeight - t.scrollTop <= t.clientHeight;
    setShowChevron(!isTextEnd);
  };

  useEffect(() => {
    if (!contentRef.current) return;
    const element = contentRef.current as HTMLElement;

    const isTextWithScroll = element.scrollHeight - element.scrollTop > element.clientHeight;
    setShowChevron(isTextWithScroll);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="caption">Read and accept the Terms & Conditions.</Typography>
      <Box
        className={classes.textContainer}
        mt={2}
        mb={1}
        ref={contentRef}
        onScroll={throttle(watchScroll, 300)}>
        <Typography variant="body2">
          <Trans i18nKey="Registration.TermsOfUse" values={{ providerName: CONFIG.AIS_PROVIDER }} />
        </Typography>
        <TermsAndConditionsLinks />
      </Box>
      <Box className={classes.iconBox}>
        <ExpandMore fontSize="large" color="primary" opacity={showChevron ? 1 : 0} />
      </Box>
      <Divider />
      <Box mt={1}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.termsAndConditions}
              onChange={handleChange}
              name="termsAndConditions"
              id="termsAndConditions"
              color="primary"
              data-testid="confirm-conditions-checkbox"
            />
          }
          label={
            <Typography variant="body2" color="primary">
              {t("Registration.CheckboxAgreement", {
                providerName: CONFIG.AIS_PROVIDER,
                productName: t("ProductName"),
              })}
            </Typography>
          }
        />
      </Box>
      <Box mt={2}>
        <Button
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          type="submit"
          data-testid="confirm-button"
          id="confirm-button"
          disabled={!(isValid && dirty) && !values.termsAndConditions}>
          I'm Ready
        </Button>
      </Box>
    </form>
  );
};

export default TermsAndConditions;
