import { useEffect, useState } from "react";
import { t } from "i18next";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BottomNavigation, Button, Grid, Paper } from "@mui/material";
import { toast } from "react-toastify";

import { useAlert } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getBusinessType,
  getUserOrganisation,
  getWorkingCapitalFinanceDetails,
  hideLoader,
  setApplicantFormData,
  setAssetFinanceFormData,
  setCompanyProfileFormData,
  setCustomer,
  setFormSubType,
  setFormType,
  setInvoiceFinanceFormData,
  setNextPath,
  setSettingsHidden,
  showLoader,
} from "@APP/redux";
import {
  WCF_API_ERROR_CODE,
  WCF_CUSTOMER_ERROR_CODE,
  createApplication,
  createWcfCustomer,
  getWcfCustomer,
  submitToPraetura,
  submitWcfCustomer,
  updateApplication,
} from "@APP/services/api";
import { invoiceFinanceDefaultValue } from "@APP/views/wcf/utils/defaultValue";
import {
  applicantDefaultData,
  assetDefaultData,
  companyDefaultData,
  invoiceDefaultData,
} from "@APP/redux/reducers/workingCapitalFinance";
import { assetFinancePayload } from "@APP/views/wcf/utils/assetFinancePayload";
import {
  amendValues,
  dateFormatter,
  getVATPerUnit,
  isFutureDate,
  percentage,
} from "@APP/views/wcf/utils/utils";
import { ERPOrgType, WcfCustomer } from "@APP/types";
import {
  assetSchema,
  companySchema,
  companySchemaOther,
  errorCodeString,
  formatErrorMessage,
  invoiceFinance,
} from "@APP/utils";
import {
  BusinessSchema,
  LtdOrgDetailsSchema,
  OtherOrgDetailsSchema,
} from "@APP/utils/schema/businessType";
import { WcfForm } from "@APP/types/wcf";
import { CompanyType, ManualOrgType } from "@APP/constants";

const useStyles = makeStyles(() => ({
  footercontainer: {
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  footerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  button: {
    borderRadius: "5px",
  },
}));

interface DataType {
  registeredCompanyName?: string;
  companyRegistrationNumber?: string;
  orgType?: CompanyType;
  director?: string;
  firstName?: string;
  lastName?: string;
  businessName?: string;
}

interface BusinessType {
  type: string;
}

interface Props {
  data?: DataType;
  businessType?: BusinessType;
  displaySaveAndExitButton?: boolean;
  displayPrevButton?: boolean;
  displayNextButton?: boolean;
  goToApplication?: boolean;
  showApplicationSubmitText?: boolean;
  showCustomerSubmitText?: boolean;
  title?: string;
  customerData?: WcfCustomer;
  disablePrevious?: boolean;
  handleSubmit?: () => void;
  values?: WcfForm["companyProfileForm"];
  allowRedirect?: boolean;
}

function WcfFooter({
  data,
  businessType,
  displaySaveAndExitButton = true,
  displayPrevButton = true,
  displayNextButton = true,
  goToApplication = false,
  showApplicationSubmitText = false,
  showCustomerSubmitText = false,
  title,
  customerData,
  disablePrevious = false,
  handleSubmit,
  values,
  allowRedirect = true,
}: Props) {
  const {
    formType,
    nextStepActive,
    nextPath,
    productType,
    isRefinance,
    wcfFormData: { invoiceFinanceForm, applicantDetailsForm, assetFinanceForm },
    customer,
  } = useSelector(getWorkingCapitalFinanceDetails);
  const bType = useSelector(getBusinessType);
  const history = useHistory();
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const dispatch = useDispatch();
  const { applicationId }: { applicationId: string } = useParams();
  const [wcfCustomer, setWcfCustomer] = useState<WcfCustomer | null>(null);
  const [vat, setVat] = useState<number | null>(null);

  const org = useSelector(getUserOrganisation);
  const business = useSelector(getBusinessType);

  const fetchWcfCustomer = async () => {
    try {
      const customer = await getWcfCustomer();
      setWcfCustomer(customer);
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;
      if (errorCode === 5004) return;
      if (!WCF_CUSTOMER_ERROR_CODE.includes(errorCode)) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      }
    }
  };

  const getApplications = () => {
    try {
      const vatUpFront = percentage(
        20,
        (assetFinanceForm.units as number) * (assetFinanceForm.pricePerUnit as number),
      );
      if (productType === "hire_purchase") setVat(vatUpFront);
      else setVat(null);
    } catch (error) {
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    }
  };

  const handleNext = async (isSaveAndExit = false, nextStep = false) => {
    try {
      let res = null;
      let path = "";
      dispatch(showLoader());
      switch (true) {
        case location.pathname.includes("business-type"):
          const validateBusinessType = BusinessSchema.validate({
            businessType: businessType?.type,
          });
          if (!isSaveAndExit && validateBusinessType.error?.details) {
            handleSubmit && handleSubmit();
            toast(`Please complete all required fields before moving to the next screen.`);
            return;
          }
          break;
        case location.pathname.includes("org-details"):
          const isLimited =
            bType === ManualOrgType.LIMITED_LIABILITY_PARTNERSHIP ||
            bType === ManualOrgType.LIMITED_LIABILITY_COMPANY;
          handleSubmit && handleSubmit();
          const validateCompany = isLimited
            ? LtdOrgDetailsSchema.validate({
                companyName: data?.registeredCompanyName,
                companyNumber: data?.companyRegistrationNumber,
                orgType: data?.orgType,
                director: data?.director,
              })
            : OtherOrgDetailsSchema.validate({
                firstName: data?.firstName as string,
                lastName: data?.lastName as string,
                businessName: data?.businessName as string,
              });
          if (!isSaveAndExit && validateCompany?.error?.details) {
            toast(`Please complete all required fields before moving to the next screen.`);
            return;
          }
          break;
        case location.pathname.includes("manual-company"):
          const manualPayload = {
            companyType:
              business === ManualOrgType.OTHER
                ? "OtherOrg"
                : business.includes("Limited")
                ? "LimitedCompany"
                : business === ManualOrgType.PARTNERSHIP
                ? "LimitedLiabilityPartnership"
                : "SoleTrader",
            registeredCompanyName:
              business === ManualOrgType.SOLE_TRADER || business === ManualOrgType.PARTNERSHIP
                ? null
                : values?.registeredCompanyName || org?.companyInfo?.name || org?.name || null,
            registeredAddress:
              business === "Other" || business.includes("Limited")
                ? {
                    street: amendValues(values?.tradingAddress),
                    postcode: amendValues(values?.postcode),
                    cityOrTown: amendValues(values?.city),
                    countyOrState: null,
                    country: amendValues(values?.country),
                  }
                : null,
            companyRegistrationNumber:
              business === ManualOrgType.SOLE_TRADER
                ? null
                : org?.taxInfo.utrNumber ?? amendValues(values?.companyRegistrationNumber),
            tradingName: business.includes("Limited") ? null : amendValues(values?.tradingName),
            tradingAddress:
              business === ManualOrgType.OTHER || business.includes("Limited")
                ? null
                : {
                    street: amendValues(values?.tradingAddress),
                    postcode: amendValues(values?.postcode),
                    cityOrTown: amendValues(values?.city),
                    countyOrState: null,
                    country: amendValues(values?.country),
                  },
            tradingFromDate: amendValues(
              dateFormatter(values?.startTrade as string),
              "NaN-NaN-NaN",
            ),
            industry: amendValues(values?.industry),
            sicCode: values?.sicCode,
            website: amendValues(values?.website),
            vatInformation: {
              vatNumber: amendValues(values?.vatNumber),
              isRegisteredForVat: !!org?.taxInfo.vatRegistrationNumber,
            },
            hasConsentedToCreditCheck: values?.creditCheck,
            hasConsentedToPrivacyPolicy: values?.privacyPolicy,
          };

          const companyValidationManual =
            business === "Other" ||
            business === "Limited Liability Partnership" ||
            business === "Limited Liability Company"
              ? companySchemaOther.validate(manualPayload)
              : companySchema.validate(manualPayload);

          if (
            !isSaveAndExit &&
            companyValidationManual.error &&
            companyValidationManual.error.details
          ) {
            handleSubmit && handleSubmit();
            toast(`Please complete all required fields before moving to the next screen.`);
            return;
          } else {
            await createWcfCustomer(manualPayload as WcfCustomer);
            await dispatch(setSettingsHidden(false));
            alert.open(
              "Success",
              "Your company profile has been updated! You can now proceed to the next step.",
            );
          }
          break;
        case location.pathname.includes("company-profile-form"):
          const isSoleTrader = org?.orgType === ERPOrgType.SOLE_TRADER;
          const registeredAddress = {
            street: customer.tradingAddress?.street || org?.companyInfo?.address?.lines[0],
            postcode: customer.tradingAddress?.postcode || org?.companyInfo?.address?.postcode,
            cityOrTown: customer.tradingAddress?.cityOrTown || org?.companyInfo?.address?.city,
            countyOrState: org?.companyInfo?.address?.country,
            country: customer?.registeredAddress?.country,
          };
          const tradingAddress = {
            street: customer.tradingAddress?.street,
            postcode: customer.tradingAddress?.postcode,
            cityOrTown: customer.tradingAddress?.cityOrTown,
            countyOrState: null,
            country: customer.tradingAddress?.country,
          };
          const payload = {
            companyType: org?.orgType,
            registeredCompanyName: isSoleTrader
              ? null
              : customerData?.registeredCompanyName ||
                customer.registeredCompanyName ||
                org?.companyInfo?.name ||
                org?.name ||
                null,
            registeredAddress: isSoleTrader ? null : registeredAddress,
            companyRegistrationNumber: customer.companyRegistrationNumber ?? org?.taxInfo.utrNumber,
            tradingName: customerData?.tradingName || customer.tradingName,
            tradingAddress: isSoleTrader ? tradingAddress : null,
            tradingFromDate: amendValues(dateFormatter(customer.tradingFromDate), "NaN-NaN-NaN"),
            industry: customerData?.industry || customer.industry,
            sicCode: org?.SIC[0],
            website: customer.website,
            vatInformation: {
              vatNumber:
                customerData?.vatInformation?.vatNumber ||
                customer.vatInformation.vatNumber ||
                org?.taxInfo.vatRegistrationNumber,
              isRegisteredForVat: !!org?.taxInfo.vatRegistrationNumber,
            },
            hasConsentedToCreditCheck: customer.hasConsentedToCreditCheck,
            hasConsentedToPrivacyPolicy: customer.hasConsentedToPrivacyPolicy,
          };

          dispatch(setCustomer(payload));
          const companyValidation =
            (await org?.orgType) === ERPOrgType.OTHER_ORG
              ? companySchemaOther.validate(payload)
              : companySchema.validate(payload);
          if (
            !isSaveAndExit &&
            ((companyValidation.error && companyValidation.error.details) ||
              isFutureDate(customer.tradingFromDate))
          ) {
            handleSubmit && handleSubmit();
            toast(`Please complete all required fields before moving to the next screen.`);
            return;
          } else {
            await createWcfCustomer(payload);
            await dispatch(setSettingsHidden(false));
            alert.open(
              "Success",
              "Your company profile has been updated! You can now proceed to the next step.",
            );
          }
          break;
        case location.pathname.includes("asset-finance-form"):
          if (!assetFinanceForm) break;
          handleSubmit && handleSubmit();
          const assetData = {
            category: assetFinanceForm.assetType,
            description: assetFinanceForm.purpose === "" ? null : assetFinanceForm.purpose,
            costPerUnit: assetFinanceForm.pricePerUnit,
            model: assetFinanceForm.model === "" ? null : assetFinanceForm.model,
            manufacturer:
              assetFinanceForm.manufacturer === "" ? null : assetFinanceForm.manufacturer,
            manufactureYear:
              assetFinanceForm.yearOfManufactured === 0
                ? null
                : assetFinanceForm.yearOfManufactured,
            isInsured: assetFinanceForm.isInsured,
            condition: assetFinanceForm.isNew ? "new" : "used",
            numberOfUnits: assetFinanceForm.units as number,
            vatPerUnit: getVATPerUnit(assetFinanceForm.pricePerUnit),
          };
          const assetValidation = await assetSchema.validate(assetData);
          const isGreaterThanAmountFinanced =
            assetData.costPerUnit &&
            assetData.costPerUnit * assetData.numberOfUnits < (assetFinanceForm.deposit as number);
          const isDepositZero =
            (assetFinanceForm?.deposit as number) === 0 && assetFinanceForm?.deposit !== null;
          if (
            (!isSaveAndExit && assetValidation.error && assetValidation.error.details) ||
            isGreaterThanAmountFinanced ||
            isDepositZero
          ) {
            toast(`Please complete all required fields before moving to the next screen.`);
            return;
          } else {
            res = await updateApplication(
              {
                ...assetFinancePayload,
                assetFinanceRequest: {
                  currency: "GBP",
                  balloonAmount: 0,
                  vatPaidUpFrontAmount: vat,
                  productType: productType,
                  isRefinance: isRefinance,
                  depositOrInitialRentalAmount:
                    assetFinanceForm.deposit === null ? null : assetFinanceForm.deposit,
                  assets: [{ ...assetData }],
                  duration: {
                    unit: "months",
                    amount: Number(assetFinanceForm.duration),
                  },
                },
                urgency: assetFinanceForm.urgency,
                applicants:
                  applicantDetailsForm && applicantDetailsForm.length > 0
                    ? applicantDetailsForm.map((applicant) => {
                        return {
                          firstName: amendValues(applicant.firstName),
                          lastName: amendValues(applicant.lastName),
                          middleName: amendValues(applicant.middleName),
                          dateOfBirth: amendValues(
                            dateFormatter(applicant.dateOfBirth as string),
                            "NaN-NaN-NaN",
                          ),
                          hasConsentedToPrivacyPolicy: applicant.privacyPolicy,
                          hasConsentedToCreditCheck: applicant.creditCheck,
                          isMainApplicant: applicant.mainApplicant === "Yes",
                          address: {
                            street: amendValues(applicant.address),
                            cityOrTown: amendValues(applicant.city),
                            countryOrState: amendValues(applicant.country),
                            country: amendValues(applicant.country),
                            postcode: amendValues(applicant.postcode),
                          },
                          companyRole: amendValues(applicant.role),
                          contactDetails: {
                            firstName: amendValues(applicant.firstName),
                            lastName: amendValues(applicant.lastName),
                            middleName: amendValues(applicant.middleName),
                            mobilePhoneNumber: amendValues(applicant.phoneNumber),
                            email: amendValues(applicant.email),
                          },
                          guarantees: {
                            can_provide_personal_guarantee: false,
                          },
                        };
                      })
                    : null,
                formStage: {
                  page: nextStep ? 4 : 3,
                  status: "Draft",
                },
              },
              applicationId,
            );
          }
          break;
        case location.pathname.includes("working-capital-finance"):
          res = await createApplication(
            title === "Invoice financing" ? invoiceFinanceDefaultValue : assetFinancePayload,
          );
          break;
        case location.pathname.includes("invoice"):
          handleSubmit && handleSubmit();
          const invoiceData = {
            invoiceFinanceRequest: {
              annualTurnover: invoiceFinanceForm.annualTurnover,
              currency: "GBP",
            },
            detailedFundingPurpose:
              invoiceFinanceForm.purpose === "" ? null : invoiceFinanceForm.purpose,
            urgency: invoiceFinanceForm.urgency,
            applicants:
              applicantDetailsForm && applicantDetailsForm.length > 0
                ? applicantDetailsForm.map((applicant) => {
                    return {
                      firstName: amendValues(applicant.firstName),
                      lastName: amendValues(applicant.lastName),
                      middleName: amendValues(applicant.middleName),
                      dateOfBirth: amendValues(
                        dateFormatter(applicant.dateOfBirth as string),
                        "NaN-NaN-NaN",
                      ),
                      hasConsentedToPrivacyPolicy: applicant.privacyPolicy,
                      hasConsentedToCreditCheck: applicant.creditCheck,
                      isMainApplicant: applicant.mainApplicant === "Yes",
                      address: {
                        street: amendValues(applicant.address),
                        cityOrTown: amendValues(applicant.city),
                        countryOrState: amendValues(applicant.country),
                        country: amendValues(applicant.country),
                        postcode: amendValues(applicant.postcode),
                      },
                      companyRole: amendValues(applicant.role),
                      contactDetails: {
                        firstName: amendValues(applicant.firstName),
                        lastName: amendValues(applicant.lastName),
                        middleName: amendValues(applicant.middleName),
                        mobilePhoneNumber: amendValues(applicant.phoneNumber),
                        email: amendValues(applicant.email),
                      },
                      guarantees: {
                        can_provide_personal_guarantee: false,
                      },
                    };
                  })
                : null,
          };
          const invoiceValidation = await invoiceFinance.validate(invoiceData);
          if (!isSaveAndExit && invoiceValidation.error && invoiceValidation.error.details) {
            toast(`Please complete all required fields before moving to the next screen.`);
            return;
          } else {
            res = await updateApplication(
              {
                ...invoiceFinanceDefaultValue,
                ...invoiceData,
                formStage: {
                  page: nextStep ? 4 : 3,
                  status: "Draft",
                },
              },
              applicationId,
            );
            break;
          }
        case location.pathname.includes("applicant-details"):
          if (
            (!isSaveAndExit && applicantDetailsForm && applicantDetailsForm.length === 0) ||
            (!isSaveAndExit && !applicantDetailsForm)
          ) {
            toast(`Please add an applicant before moving to the next screen.`);
            return;
          } else {
            if (formType === "Asset Finance") {
              res = await updateApplication(
                {
                  ...assetFinancePayload,
                  assetFinanceRequest: {
                    currency: "GBP",
                    balloonAmount: 0,
                    vatPaidUpFrontAmount: vat,
                    productType: productType,
                    isRefinance: isRefinance,
                    depositOrInitialRentalAmount: assetFinanceForm.deposit,
                    assets: [
                      {
                        category: assetFinanceForm.assetType,
                        description: assetFinanceForm.purpose,
                        costPerUnit: assetFinanceForm.pricePerUnit,
                        model: assetFinanceForm.model,
                        manufacturer: assetFinanceForm.manufacturer,
                        manufactureYear:
                          assetFinanceForm.yearOfManufactured === 0
                            ? null
                            : assetFinanceForm.yearOfManufactured,
                        isInsured: assetFinanceForm.isInsured,
                        condition: assetFinanceForm.isNew,
                        numberOfUnits: assetFinanceForm.units as number,
                        vatPerUnit: getVATPerUnit(assetFinanceForm.pricePerUnit),
                      },
                    ],
                    duration: {
                      unit: "months",
                      amount: Number(assetFinanceForm.duration),
                    },
                  },
                  urgency: assetFinanceForm.urgency,
                  applicants:
                    applicantDetailsForm && applicantDetailsForm.length > 0
                      ? applicantDetailsForm.map((applicant) => {
                          return {
                            firstName: amendValues(applicant.firstName),
                            lastName: amendValues(applicant.lastName),
                            middleName: amendValues(applicant.middleName),
                            dateOfBirth: amendValues(
                              dateFormatter(applicant.dateOfBirth as string),
                              "NaN-NaN-NaN",
                            ),
                            hasConsentedToPrivacyPolicy: applicant.privacyPolicy,
                            hasConsentedToCreditCheck: applicant.creditCheck,
                            isMainApplicant: applicant.mainApplicant === "Yes",
                            address: {
                              street: amendValues(applicant.address),
                              cityOrTown: amendValues(applicant.city),
                              countryOrState: amendValues(applicant.country),
                              country: amendValues(applicant.country),
                              postcode: amendValues(applicant.postcode),
                            },
                            companyRole: amendValues(applicant.role),
                            contactDetails: {
                              firstName: amendValues(applicant.firstName),
                              lastName: amendValues(applicant.lastName),
                              middleName: amendValues(applicant.middleName),
                              mobilePhoneNumber: amendValues(applicant.phoneNumber),
                              email: amendValues(applicant.email),
                            },
                            guarantees: {
                              can_provide_personal_guarantee: false,
                            },
                          };
                        })
                      : null,
                  formStage: {
                    page: nextStep ? 5 : 4,
                    status: "Draft",
                  },
                },
                applicationId,
              );
            } else {
              res = await updateApplication(
                {
                  ...invoiceFinanceDefaultValue,
                  invoiceFinanceRequest: {
                    annualTurnover: invoiceFinanceForm.annualTurnover,
                    currency: "GBP",
                  },
                  detailedFundingPurpose:
                    invoiceFinanceForm.purpose === "" ? null : invoiceFinanceForm.purpose,
                  urgency: invoiceFinanceForm.urgency,
                  applicants:
                    applicantDetailsForm && applicantDetailsForm.length > 0
                      ? applicantDetailsForm.map((applicant) => {
                          return {
                            firstName: amendValues(applicant.firstName),
                            lastName: amendValues(applicant.lastName),
                            middleName: amendValues(applicant.middleName),
                            dateOfBirth: amendValues(
                              dateFormatter(applicant.dateOfBirth as string),
                              "NaN-NaN-NaN",
                            ),
                            hasConsentedToPrivacyPolicy: applicant.privacyPolicy,
                            hasConsentedToCreditCheck: applicant.creditCheck,
                            isMainApplicant: applicant.mainApplicant === "Yes",
                            address: {
                              street: amendValues(applicant.address),
                              cityOrTown: amendValues(applicant.city),
                              countryOrState: amendValues(applicant.country),
                              country: amendValues(applicant.country),
                              postcode: amendValues(applicant.postcode),
                            },
                            companyRole: amendValues(applicant.role),
                            contactDetails: {
                              firstName: amendValues(applicant.firstName),
                              lastName: amendValues(applicant.lastName),
                              middleName: amendValues(applicant.middleName),
                              mobilePhoneNumber: amendValues(applicant.phoneNumber),
                              email: amendValues(applicant.email),
                            },
                            guarantees: {
                              can_provide_personal_guarantee: false,
                            },
                          };
                        })
                      : null,
                  formStage: {
                    page: nextStep ? 5 : 4,
                    status: "Draft",
                  },
                },
                applicationId,
              );
            }
          }
          const isMainApplicant = applicantDetailsForm?.filter(
            (mainApp) => mainApp.mainApplicant === "Yes",
          );
          if (!isSaveAndExit && !isMainApplicant?.length) {
            toast(`Please add at least one main applicant.`);
            return;
          }
          break;
        case location.pathname.includes("review-form"):
          if (!applicantDetailsForm) break;
          if (formType === "Asset Finance") {
            res = await updateApplication(
              {
                ...assetFinancePayload,
                assetFinanceRequest: {
                  currency: "GBP",
                  balloonAmount: 0,
                  vatPaidUpFrontAmount: vat,
                  productType: productType,
                  isRefinance: isRefinance,
                  depositOrInitialRentalAmount: assetFinanceForm.deposit,
                  assets: [
                    {
                      category: assetFinanceForm.assetType,
                      description: assetFinanceForm.purpose,
                      costPerUnit: assetFinanceForm.pricePerUnit,
                      model: assetFinanceForm.model,
                      manufacturer: assetFinanceForm.manufacturer,
                      manufactureYear:
                        assetFinanceForm.yearOfManufactured === 0
                          ? null
                          : assetFinanceForm.yearOfManufactured,
                      isInsured: assetFinanceForm.isInsured,
                      condition: assetFinanceForm.isNew,
                      numberOfUnits: assetFinanceForm.units as number,
                      vatPerUnit: getVATPerUnit(assetFinanceForm.pricePerUnit),
                    },
                  ],
                  duration: {
                    unit: "months",
                    amount: Number(assetFinanceForm.duration),
                  },
                },
                urgency: assetFinanceForm.urgency,
                applicants:
                  applicantDetailsForm.length > 0
                    ? applicantDetailsForm.map((applicant) => {
                        return {
                          firstName: amendValues(applicant.firstName),
                          lastName: amendValues(applicant.lastName),
                          middleName: amendValues(applicant.middleName),
                          dateOfBirth: amendValues(
                            dateFormatter(applicant.dateOfBirth as string),
                            "NaN-NaN-NaN",
                          ),
                          hasConsentedToPrivacyPolicy: applicant.privacyPolicy,
                          hasConsentedToCreditCheck: applicant.creditCheck,
                          isMainApplicant: applicant.mainApplicant === "Yes",
                          address: {
                            street: amendValues(applicant.address),
                            cityOrTown: amendValues(applicant.city),
                            countryOrState: amendValues(applicant.country),
                            country: amendValues(applicant.country),
                            postcode: amendValues(applicant.postcode),
                          },
                          companyRole: amendValues(applicant.role),
                          contactDetails: {
                            firstName: amendValues(applicant.firstName),
                            lastName: amendValues(applicant.lastName),
                            middleName: amendValues(applicant.middleName),
                            mobilePhoneNumber: amendValues(applicant.phoneNumber),
                            email: amendValues(applicant.email),
                          },
                          guarantees: {
                            can_provide_personal_guarantee: false,
                          },
                        };
                      })
                    : null,
                formStage: {
                  page: 5,
                  status: "Draft",
                },
              },
              applicationId,
            );
          } else {
            res = await updateApplication(
              {
                ...invoiceFinanceDefaultValue,
                invoiceFinanceRequest: {
                  annualTurnover: invoiceFinanceForm.annualTurnover,
                  currency: "GBP",
                },
                detailedFundingPurpose:
                  invoiceFinanceForm.purpose === "" ? null : invoiceFinanceForm.purpose,
                urgency: invoiceFinanceForm.urgency,
                applicants:
                  applicantDetailsForm.length > 0
                    ? applicantDetailsForm.map((applicant) => {
                        return {
                          firstName: amendValues(applicant.firstName),
                          lastName: amendValues(applicant.lastName),
                          middleName: amendValues(applicant.middleName),
                          dateOfBirth: amendValues(
                            dateFormatter(applicant.dateOfBirth as string),
                            "NaN-NaN-NaN",
                          ),
                          hasConsentedToPrivacyPolicy: applicant.privacyPolicy,
                          hasConsentedToCreditCheck: applicant.creditCheck,
                          isMainApplicant: applicant.mainApplicant === "Yes",
                          address: {
                            street: amendValues(applicant.address),
                            cityOrTown: amendValues(applicant.city),
                            countryOrState: amendValues(applicant.country),
                            country: amendValues(applicant.country),
                            postcode: amendValues(applicant.postcode),
                          },
                          companyRole: amendValues(applicant.role),
                          contactDetails: {
                            firstName: amendValues(applicant.firstName),
                            lastName: amendValues(applicant.lastName),
                            middleName: amendValues(applicant.middleName),
                            mobilePhoneNumber: amendValues(applicant.phoneNumber),
                            email: amendValues(applicant.email),
                          },
                          guarantees: {
                            can_provide_personal_guarantee: false,
                          },
                        };
                      })
                    : null,
                formStage: {
                  page: 5,
                  status: isSaveAndExit ? "Draft" : "Completed",
                },
              },
              applicationId,
            );
          }
          if (!isSaveAndExit) {
            // Submit Customer before creating the application && only if customer is in draft status
            if (wcfCustomer?.status === "Draft") {
              await submitWcfCustomer();
              dispatch(setSettingsHidden(true));
            }
            await submitToPraetura(applicationId);
          }
          dispatch(setCompanyProfileFormData(companyDefaultData));
          dispatch(setAssetFinanceFormData(assetDefaultData));
          dispatch(setInvoiceFinanceFormData(invoiceDefaultData));
          dispatch(setApplicantFormData(applicantDefaultData));
          dispatch(setFormType(""));
          dispatch(setFormSubType(""));
          break;
        default:
          break;
      }
      if (title === "Invoice financing") {
        path = `${SCREEN_PATHS.WCF_INVOICE_APPLICATION}/${res}`;
      } else if (title === "Asset finance") {
        path = `${SCREEN_PATHS.WCF_ASSET_FINANCE}/${res}`;
      }
      if (allowRedirect) {
        const appList = `${SCREEN_PATHS.WCF_FINANCE_LISTING}`;
        const appPath = isSaveAndExit ? appList : nextPath;
        dispatch(setNextPath(isSaveAndExit ? appList : path));
        history.push(title ? path : appPath);
      }
    } catch (error) {
      const errorData = error.response?.data;
      if (WCF_CUSTOMER_ERROR_CODE.includes(errorData.errorCode)) {
        alert.open(
          "Error",
          `We were unable to submit your application, please try again later.` +
            errorCodeString(errorData?.errorCode),
        );
      } else if (WCF_API_ERROR_CODE.includes(errorData.errorCode)) {
        // To show exact field name on failure
        const errorMessage = error.response?.data?.errorMessage;
        const match = errorMessage.match(/response:\s*({[^}]+})/);
        const fieldNameMatch = errorMessage.match(/at '([^']+)'/);
        if (fieldNameMatch) {
          const fieldName = fieldNameMatch[1];
          alert.open(
            "Error",
            `We were unable to submit your application, please update "${fieldName}" and resubmit your application.` +
              errorCodeString(errorData?.errorCode),
          );
        }
        if (match) {
          const errorDetail = error?.response?.error?.details?.find(
            (detail: { code?: string }) => detail?.code === "property_invalid_empty",
          );
          if (errorDetail) {
            const fieldNameMatch = errorDetail.message.match(/'([^']+)'/);
            if (fieldNameMatch && fieldNameMatch.length > 1) {
              const fieldName = fieldNameMatch[1]
                .replace(/_/g, " ")
                .replace(/\b\w/g, (c: string) => c.toUpperCase());
              alert.open(
                "Error",
                `We were unable to submit your application, please update "${fieldName}" and resubmit your application.` +
                  errorCodeString(errorData?.errorCode),
              );
            }
          } else {
            alert.open(
              "Error",
              "We were unable to submit your application, please try again later." +
                errorCodeString(errorData?.errorCode),
            );
          }
        } else if (errorData?.errorCode === 24120) {
          alert.open(
            "Error",
            `We were unable to submit your application, ${errorMessage}` +
              errorCodeString(errorData?.errorCode),
          );
        }
      } else {
        alert.open("Error", "We were unable to submit your application, please try again later");
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  const handlePrevious = () => {
    let route = "";
    switch (true) {
      case location.pathname.includes("accounting"):
        route = SCREEN_PATHS.WCF_LANDING;
        break;
      case location.pathname.includes("company-profile-form"):
        route = SCREEN_PATHS.COMPANY_PROFILE;
        break;
      case location.pathname.includes("company-profile"):
      case location.pathname.includes("business-type"):
        route = SCREEN_PATHS.WCF_ACCOUNTING;
        break;
      case location.pathname.includes("org-details"):
        route = SCREEN_PATHS.WCF_BUSINESS_TYPE;
        break;
      case location.pathname.includes("company-details"):
        route = SCREEN_PATHS.WCF_ORG_DETAILS;
        break;
      case location.pathname.includes("working-capital-finance"):
        route = SCREEN_PATHS.WCF_COMPANY_PROFILE_FORM;
        break;
      case location.pathname.includes("asset-finance-form"):
        route = `${SCREEN_PATHS.WCF_ASSET_FINANCE}/${applicationId}`;
        break;
      case location.pathname.includes("asset-finance"):
      case location.pathname.includes("invoice"):
        route = SCREEN_PATHS.WORKING_CAPITAL_FINANCE;
        break;
      case location.pathname.includes("applicant-details"):
        if (formType === "Invoice Finance") {
          route = `${SCREEN_PATHS.WCF_INVOICE_APPLICATION}/${applicationId}`;
        } else {
          route = `${SCREEN_PATHS.WCF_ASSET_FINANCE_FORM_DATA}/${applicationId}`;
        }
        break;
      case location.pathname.includes("review-form"):
        route = `${SCREEN_PATHS.WCF_APPLICANT_DETAILS}/${applicationId}`;
        break;
      case location.pathname.includes("manual-company"):
        route = SCREEN_PATHS.WCF_ORG_DETAILS;
        break;
      default:
        break;
    }
    history.push(route);
  };

  useEffect(() => {
    fetchWcfCustomer();
  }, []);

  useEffect(() => {
    getApplications();
  }, [assetFinanceForm]);

  return (
    <Paper
      className={classes.footercontainer}
      sx={goToApplication ? { display: "flex", justifyContent: "flex-end" } : {}}
      elevation={3}>
      <BottomNavigation sx={{ padding: "16px" }}>
        <Grid container className={classes.footerGrid} justifyContent="flex-end">
          <Grid item>
            {displayPrevButton && (
              <Button
                variant="outlined"
                id={"wcfFooterPreviousButton"}
                onClick={() => handlePrevious()}
                disabled={disablePrevious}
                className={classes.button}>
                Previous
              </Button>
            )}
          </Grid>
          <Grid item>
            {displaySaveAndExitButton && (
              <Button
                className={classes.button}
                variant="outlined"
                id={"wcfFooterSaveAndExitButton"}
                style={{ marginRight: "4px" }}
                onClick={() => handleNext(true)}>
                Save & Exit
              </Button>
            )}
            {displayNextButton && (
              <Button
                variant="contained"
                className={classes.button}
                id={"wcfFooterNextAndSubmitButton"}
                disabled={!nextStepActive}
                onClick={() => handleNext(false, true)}>
                {showCustomerSubmitText
                  ? "Submit Company Profile"
                  : showApplicationSubmitText
                  ? "Submit Application"
                  : "Next Step"}
              </Button>
            )}
            {goToApplication && (
              <Button
                variant="contained"
                id={"wcfFooterGotoApplicationButton"}
                className={classes.button}
                onClick={() => history.push(SCREEN_PATHS.WCF_FINANCE_LISTING)}>
                Go To Application
              </Button>
            )}
          </Grid>
        </Grid>
      </BottomNavigation>
    </Paper>
  );
}

export default WcfFooter;
