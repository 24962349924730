import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  rootBox: {
    display: "flex",
    flexDirection: "column",
  },
  gridBankAccounts: {
    display: "flex",
    flexDirection: "column",
  },
  mainCard: {
    marginBottom: theme.spacing(4),
  },
  accountListWrapper: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  accountListContent: {
    overflowY: "auto",
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    "&::-webkit-scrollbar-track": {
      background: theme.palette.background.default,
    },
    "&::-webkit-scrollbar": {
      width: theme.spacing(0.6),
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:last-child": {
      marginBottom: theme.spacing(2),
      paddingBottom: 0,
    },
    "&:first-child": {
      marginTop: theme.spacing(2),
    },
  },
  messageCard: {
    margin: theme.spacing(1, 0),
  },
  bankAvatar: {
    height: 25,
    width: 25,
    marginLeft: theme.spacing(1),
  },
  bankAccountsCardHeader: {
    paddingBottom: 0,
    "& .MuiCardHeader-action": {
      marginRight: 0,
    },
  },
}));
