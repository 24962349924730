import { ReactNode } from "react";
import { Card, CardContent, CardHeader, Divider, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { IconWithTooltip } from "@APP/components";

interface Props {
  title: string;
  subTitle: string;
  titleInfoWidgetText?: string;
  children: ReactNode;
  noPadding?: boolean;
}
const useStyles = makeStyles(() => ({
  cardContainerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: 1,
  },
  cardContentNoPadding: {
    padding: 0,
  },
}));

const ForecastSection = ({ title, subTitle, titleInfoWidgetText, children, noPadding }: Props) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.cardContainerContent}
      elevation={4}
      data-testid={`table-section-container-${title.replace(/ /g, "-").toLowerCase()}`}>
      <CardHeader
        title={
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1}>
              <Typography variant="h5" id="forecastSectionTitle">
                {title}
              </Typography>
            </Box>
            {titleInfoWidgetText && <IconWithTooltip color="primary" title={titleInfoWidgetText} />}
          </Box>
        }
        subheader={subTitle}
      />
      <Divider />
      <CardContent
        className={clsx(classes.cardContent, { [classes.cardContentNoPadding]: noPadding })}>
        {children}
      </CardContent>
    </Card>
  );
};

export default ForecastSection;
