import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CardActions,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  getBankLedgers,
  getClearingLedger,
  getErpId,
  getPermissions,
  getUser,
  hideLoader,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import CONFIG from "@APP/config";

import { API } from "@APP/services";
import { ErpBanksAccounts } from "@APP/types";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { CommonTextField, IconWithTooltip } from "@APP/components";
import { getERPBankAccounts, SQUARE_MERCHENT_ERRORS } from "@APP/services/api";
import { capitalize, formatErrorMessage, handleAriaActiveDescendantChange } from "@APP/utils";

import ClearingLedgerContainer from "./ClearingLedgerContainer";
import {
  BankAccountExtendedWithLedger,
  getCheckForMatchingLedgerAndBankAccount,
  NO_LINKED_BANK_ACCOUNT_VALUE,
} from "../SetLedgerForPaymentsBooking";

const useStyles = makeStyles((theme) => ({
  clearingLedgerContainerItem: {
    padding: theme.spacing(2, 2),
    margin: theme.spacing(2, 0),
    width: "100%",
    "& .MuiSelect-select:focus": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const ClearingLedgerView = () => {
  const classes = useStyles();
  const clearingLedger = useSelector(getClearingLedger);
  const erpId = useSelector(getErpId);
  const theme = useTheme();
  const isDisplaySizeLessLaptop = useMediaQuery(theme.breakpoints.down("md"));
  const ERPLedgers = useSelector(getBankLedgers);
  const permissions = useSelector(getPermissions);
  const user = useSelector(getUser);
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const alert = useAlert();
  const { t } = useTranslation();
  const history = useHistory();

  const [banksAccounts, setBanksAccounts] = useState<BankAccountExtendedWithLedger[]>([]);
  const [erpBanksAccounts, setErpBanksAccounts] = useState<ErpBanksAccounts[]>([]);
  const [clearingLedgerAccount, setClearingLedgerAccount] = useState("");
  const [disableSave, setDisableSave] = useState(true);

  const bankLedgerPermissonAccessed = permissions.bank_ledger.create;

  const getListERPBankAccounts = async () => {
    try {
      dispatch(showLoader());
      const { data } = await getERPBankAccounts(user?.erp!);
      setErpBanksAccounts(data);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        t("Errors.AccountingPackageLinking.Alerts.RetrieveLedgerAccounts.Message"),
        [
          { text: "Cancel", onClick: () => history.goBack() },
          { text: "Try again", onClick: async () => await getListERPBankAccounts() },
        ],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const getSelectedSquareAccountDetails = async () => {
    try {
      dispatch(showLoader());
      const data = await API.getSelectedSquareAccount();
      setClearingLedgerAccount(data.bankLedgerId ?? "");
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;
      if (SQUARE_MERCHENT_ERRORS.includes(errorCode)) return;
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleChangeLedger = (
    event: React.ChangeEvent<{ value: unknown | typeof NO_LINKED_BANK_ACCOUNT_VALUE }>,
  ) => {
    const value = event.target.value as string;
    setClearingLedgerAccount(value);
    setDisableSave(false);
  };

  useEffect(() => {
    setBanksAccounts(
      user?.bankAccounts?.map((bankAccount) => {
        const currentLedger = ERPLedgers.find(
          (ledger) => ledger.bankDetails?.accountNumber === bankAccount.account.identification,
        );

        if (ERPLedgers.length === 0 && erpBanksAccounts.length === 1) {
          return {
            ...bankAccount,
            ledgerAccount: erpBanksAccounts[0],
          };
        }

        return {
          ...bankAccount,
          ledgerAccount: currentLedger
            ? erpBanksAccounts.find((erpBankAccount) =>
                getCheckForMatchingLedgerAndBankAccount(user.erp!, {
                  erpBankAccount,
                  ledgerAccount: currentLedger,
                }),
              )
            : erpBanksAccounts.find(
                (erpBankAccount) =>
                  erpBankAccount.accountNumber === bankAccount.account.identification.slice(0, 14),
              ),
        };
      }) || [],
    );
  }, [erpBanksAccounts, ERPLedgers]);

  useEffect(() => {
    (async () => {
      await getListERPBankAccounts();
      if (CONFIG.FEATURES.RTP.CARD_PAYMENTS) await getSelectedSquareAccountDetails();
    })();
  }, []);

  const saveUpdates = async () => {
    setDisableSave(true);
    try {
      dispatch(showLoader());
      await API.accountSelectionForSquarePayment(clearingLedgerAccount, erpId ?? "");
    } catch (error) {
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  if (!clearingLedger) return null;

  return (
    <ClearingLedgerContainer>
      <Box component="table" role="table" width="100%" mx={2} pr={4}>
        <Grid container component="tr">
          <Grid item xs={6} md={7} component="th" align="left">
            <Typography
              variant={isDisplaySizeLessLaptop ? "h5" : "h4"}
              component="p"
              data-testid="linked-clearing-ledger-label">
              Your clearing ledger
            </Typography>
          </Grid>
          <Grid item xs={6} md={5} component="th" align="left">
            <Typography variant="h5" component="p" id="bank-accounts-label">
              Please select the account in which you will receive payments for Square
            </Typography>
          </Grid>
        </Grid>
        <Paper
          className={classes.clearingLedgerContainerItem}
          component={(props) => <Grid container alignItems="center" component="tr" {...props} />}>
          <Grid item xs={6} md={7} component="td">
            <Grid container>
              <Grid item xs={12} md={7}>
                <ListItemText
                  primary={
                    <span>
                      <b>Name:</b> {clearingLedger?.name}
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <ListItemText
                  primary={
                    <span>
                      <b>Account number in {capitalize(erpId)}:</b>{" "}
                      {clearingLedger.bankDetails.accountNumber}
                    </span>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={5} component="td" display="flex">
            <CommonTextField
              disabled={!bankLedgerPermissonAccessed}
              fullWidth
              select
              label="Please select the account in which you will receive payments for Square"
              id="account-select"
              hiddenLabel
              name={`input-selector-${banksAccounts[0]?.accountId}`}
              inputProps={{ id: `selector-${banksAccounts[0]?.accountId}-input` }}
              InputLabelProps={{ htmlFor: `selector-${banksAccounts[0]?.accountId}-input` }}
              value={clearingLedgerAccount}
              onChange={handleChangeLedger}
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    "aria-activedescendant":
                      banksAccounts[0]?.ledgerAccount?.accountId === NO_LINKED_BANK_ACCOUNT_VALUE
                        ? `${NO_LINKED_BANK_ACCOUNT_VALUE}-${banksAccounts[0]?.accountId}-option`
                        : `ledger-bank-account-${banksAccounts[0]?.accountId}-option-${banksAccounts[0]?.ledgerAccount?.accountId}`,
                    onFocus: handleAriaActiveDescendantChange,
                  },
                },
              }}>
              {erpBanksAccounts?.map(({ accountName, accountId, accountNumber }) => (
                <MenuItem
                  key={accountName || accountNumber}
                  value={accountId}
                  id={`ledger-bank-account-option-${accountId}`}>
                  {accountName || accountNumber}
                </MenuItem>
              ))}
            </CommonTextField>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              ml={1.5}
              data-testid="tooltip-match-acc-not-found">
              <IconWithTooltip
                title={`It is important that you select the bank account in your nominated accounting package for card payments. This ensures payments are updated correctly when settlement occurs. If you are in any doubt contact us at ${CONFIG.SUPPORT_EMAIL}`}
                infoIconSize="medium"
                placement="bottom"
              />
            </Box>
          </Grid>
        </Paper>
      </Box>
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          data-testid="footer-save-button"
          disabled={disableSave}
          onClick={saveUpdates}
          id="clearingLedgerSaveButton"
          fullWidth>
          Save
        </Button>
      </CardActions>
    </ClearingLedgerContainer>
  );
};

export default ClearingLedgerView;
