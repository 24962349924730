import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
  },
  sortHeader: {
    cursor: "pointer",
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  linkTitle: {
    ...theme.typography.body1,
  },
  checkbox: {
    padding: 0,
  },
  checkingCell: {
    textAlign: "center",
  },
  linkOffIcon: {
    fontSize: 100,
  },
  viewInvoiceLink: {
    textDecoration: "underline",
  },
}));
