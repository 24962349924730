import { ErpId } from "@APP/constants";
import { Amount, Address } from "@APP/types";

export interface ImmediatePaymentDetails {
  amount: Amount;
  creditAccount: Account;
  debtorAccount?: Account;
  creditorDetails?: CreditorDetails;
  instructionIdentification?: string;
  endToEndIdentification?: string;
  remittanceInformation?: RemittanceInformation;
  executionDate?: string;
  additionalData?: AdditionalData;
  paymentType?: PaymentTypes;
}

export enum PaymentTypes {
  IMMEDIATE = "Immediate",
}

export enum SchemesNames {
  IBAN = "IBAN",
  SORT_CODE_ACCOUNT_NUMBER = "SortCodeAccountNumber",
}

export interface Account {
  schemeName: SchemesNames;
  identification: string;
  name?: string;
  secondaryIdentification?: string;
}

export interface CreditorDetails {
  paymentContextCode: string;
  merchantCategoryCode: string;
  merchantCustomerIdentification: string;
  deliveryAddress: Address;
}

/*
Information supplied to enable the matching of an entry with the items that the transfer is intended to settle,
such as commercial invoices in an accounts' receivable system.
 */
export interface RemittanceInformation {
  unstructured?: string;
  reference?: string | null;
}

export interface AdditionalData {
  BankId?: string;
  requestId?: string;
  erpId?: string;
  invoiceId?: string;
  initialInvoiceType?: string;
  customerId?: string;
  userId?: string;
  payerAccountId?: string;
  memberId?: string;
  invoiceReference?: string;
}

export interface PaymentInitiation {
  redirectUrl: string;
  consentId: string;
  status?: string;
  creationDateTime: string;
  additionalData?: AdditionalData;
}

export enum MaverickPaymentAccountType {
  Checking = "Checking",
  Savings = "Savings",
}

export enum MaverickPaymentAccountSecCode {
  WEB = "WEB",
  TEL = "TEL",
  PPD = "PPD",
  CCD = "CCD",
}

export const SEC_CODES_LABELS = {
  [MaverickPaymentAccountSecCode.WEB]: "WEB - Web Authorization",
  [MaverickPaymentAccountSecCode.TEL]: "TEL - Telephone Authorization",
  [MaverickPaymentAccountSecCode.PPD]: "PPD - Written Authorization (Individual)",
  [MaverickPaymentAccountSecCode.CCD]: "CCD - Written Authorization (Corporation)",
};

export interface CreateMaverickSupplierPaymentPayload {
  amount: Amount;
  erpId: ErpId;
  invoiceId: string;
  routingNumber: string;
  accountName: string;
  accountNumber: string;
  accountType: MaverickPaymentAccountType;
  customerEmail: string;
  secCode: MaverickPaymentAccountSecCode;
}

export interface GetMaverickPayableIdsByStatusPayload {
  erpId: ErpId;
  invoiceIds?: string[];
  status: string;
}
