import request from "@APP/services/api/request";
import CONFIG from "@APP/config";
import { InvoiceFinanceReq, AssetFinanceReq } from "@APP/types/wcf";
import { WcfCustomer } from "@APP/types";

export const createApplication = async (application: InvoiceFinanceReq | AssetFinanceReq) => {
  const response = await request(
    {
      method: "POST",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/applications`,
      data: application,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );
  return response.data;
};

export const updateApplication = async (
  updatedApplication: InvoiceFinanceReq | AssetFinanceReq,
  applicationId: string,
) => {
  const response = await request(
    {
      method: "PUT",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/applications/${applicationId}`,
      data: updatedApplication,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );
  return response.data;
};

export const wcfListing = async () => {
  const response = await request<any>(
    {
      method: "GET",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/applications`,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );

  return response;
};

export const submitToPraetura = async (applicationId: string) => {
  const response = await request(
    {
      method: "POST",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/applications/${applicationId}/submit`,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );

  return response;
};

export const getApplicationDetailsById = async (applicationId: string) => {
  const response = await request<InvoiceFinanceReq | AssetFinanceReq>(
    {
      method: "GET",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/applications/${applicationId}`,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );

  return response;
};

// Customer

export const getWcfCustomer = async () => {
  const response = await request<WcfCustomer>(
    {
      method: "GET",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/customer`,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );
  return response.data;
};

export const createWcfCustomer = async (customerPayload: WcfCustomer) => {
  const response = await request(
    {
      method: "PUT",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/customer`,
      data: customerPayload,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );

  return response;
};

export const submitWcfCustomer = async () =>
  await request(
    {
      method: "POST",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/customer/submit`,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );

export const uploadDocuments = async (applicationId: string, file: FormData) => {
  await request(
    {
      method: "PUT",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/applications/${applicationId}/documents`,
      data: file,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );
};

export const getAllDocuments = async (applicationId: string) => {
  const response = await request(
    {
      method: "GET",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/applications/${applicationId}/documents`,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );
  return response;
};

export const deleteDocumentById = async (applicationId: string, documentId: string) => {
  await request(
    {
      method: "DELETE",
      url: `${CONFIG.API.WCF_URL}/${CONFIG.API.VERSION}/wcf/applications/${applicationId}/documents/${documentId}`,
    },
    { contain: CONFIG?.API?.CONTAIN || false },
  );
};
