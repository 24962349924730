import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import * as Yup from "yup";
import { Box, Card, CardContent, CardHeader, Divider, Grid, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import {
  formatCurrency,
  getOnlyDigits,
  getPriceInNumberFormat,
  handleAriaActiveDescendantChange,
} from "@APP/utils";
import { BankAccountExtended, CustodianFeatures } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { useAlert } from "@APP/hooks";
import {
  PaymentAccountList,
  CommonTextField,
  FooterActionsButtons,
  NumberFormatCustom,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import {
  getBankAccounts,
  getMakePayment,
  setAmount,
  setBankAccountNumber,
  setBankAccountSortCode,
  setBeneficiaryName,
  setCurrency,
  setReference,
  setSelectedPayerAccount,
  useAppDispatch,
} from "@APP/redux";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 2, 2),

    "& .MuiSelect-select:focus": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  gridContainer: {
    marginBottom: theme.spacing(2),
  },
  tabsContainer: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
  },
  accountListContent: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  // card: {
  //   minHeight: theme.spacing(35.4),
  // },
}));

const MAX_LENGTH_OF_AMOUNT = 10;

const MAKE_PAYMENT_REQUIRED_FIELD: Array<keyof IMakePaymentInitialValues> = [
  "beneficiaryName",
  "bankAccountSortCode",
  "bankAccountNumber",
  "currency",
];

interface IMakePaymentInitialValues {
  beneficiaryName: string;
  bankAccountSortCode: string;
  bankAccountNumber: string;
  BICCode: string;
  IBAN: string;
  currency: string;
  amount: string;
  reference: string;
  selectedPayerAccount: BankAccountExtended | null;
  // sortCodeAccountNumberOrIBAN: number;
}

const makePaymentValidationSchema = (t: TFunction) => {
  // if (tabIndex === 0) {
  return Yup.object().shape({
    beneficiaryName: Yup.string().required(
      t("Errors.OutboundPayments.Validation.MakePaymentNameRequired"),
    ),
    bankAccountSortCode: Yup.string()
      .min(8, t("Errors.OutboundPayments.Validation.SortCodeLength"))
      .required(t("Errors.OutboundPayments.Validation.SortCodeRequired")),
    bankAccountNumber: Yup.string()
      .matches(/^\d{8}$/, t("Errors.OutboundPayments.Validation.AccountNumberLength"))
      .required(t("Errors.OutboundPayments.Validation.AccountNumberRequired")),
    currency: Yup.string().required(
      t("Errors.OutboundPayments.Validation.MakePaymentCurrencyRequired"),
    ),
    amount: Yup.string()
      .required(t("Errors.OutboundPayments.Validation.MakePaymentAmountRequired"))
      .test(
        "Zero amount",
        t("Errors.OutboundPayments.Validation.MakePaymentAmountRequired"),
        (value: string = "") => {
          const valueNumber = Number(value);

          return isNaN(valueNumber) || valueNumber !== 0;
        },
      )
      .test(
        "Exceed transaction amount",
        t("Errors.OutboundPayments.Validation.MakePaymentAmountMax", {
          MAX: formatCurrency(CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT),
        }),
        (value: string = "") => {
          const valueNumber = Number(value.replace(",", ""));

          return CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT >= valueNumber;
        },
      ),
  });
  // }
  //
  // return Yup.object().shape({
  //   beneficiaryName: Yup.string().required("Please enter a beneficiary name."),
  //   BICCode: Yup.string().required("Please enter a BIC code."),
  //   IBAN: Yup.string().required("Please enter an IBAN."),
  //   currency: Yup.string().required("Please select payment currency."),
  //   amount: Yup.string().required("Please enter Amount."),
  // });
};
const MakePaymentDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const { t } = useTranslation();

  const {
    beneficiaryName,
    BICCode,
    bankAccountNumber,
    // sortCodeAccountNumberOrIBAN,
    bankAccountSortCode,
    currency,
    IBAN,
    reference,
    amount,
    selectedPayerAccount,
  } = useSelector(getMakePayment);
  const bankAccounts = useSelector(getBankAccounts);

  // const [tabIndex, setTabIndex] = useState(
  //   sortCodeAccountNumberOrIBAN === SchemesNames.SORT_CODE_ACCOUNT_NUMBER ? 0 : 1,
  // );

  // const SORT_CODE_ACCOUNT_NUMBER_TAB_INDEX = 0;

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    handleBlur,
    isSubmitting,
    touched,
    isValid,
    setValues,
    setFieldValue,
    // validateForm,
  } = useFormik<IMakePaymentInitialValues>({
    initialValues: {
      beneficiaryName: beneficiaryName || "",
      bankAccountSortCode: bankAccountSortCode || "",
      bankAccountNumber: bankAccountNumber || "",
      BICCode: BICCode || "",
      IBAN: IBAN || "",
      currency: currency || "",
      amount: amount || "",
      reference: reference || "",
      selectedPayerAccount: selectedPayerAccount || null,
      // sortCodeAccountNumberOrIBAN: SORT_CODE_ACCOUNT_NUMBER_TAB_INDEX,
    },
    validationSchema: makePaymentValidationSchema(t),
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   (async () => await validateForm())();
  // }, [tabIndex]);

  // Waiting for correct IBAN work
  // const handleChangeTab = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
  //   setTabIndex(newTabIndex);
  //
  //   if (newTabIndex !== 0) return dispatch(setSortCodeAccountNumberOrIBAN(SchemesNames.IBAN));
  //   else return dispatch(setSortCodeAccountNumberOrIBAN(SchemesNames.SORT_CODE_ACCOUNT_NUMBER));
  // };

  useEffect(() => {
    if (!selectedPayerAccount && bankAccounts && bankAccounts.length === 1) {
      dispatch(setSelectedPayerAccount(bankAccounts[0]));
      setValues({ ...values, selectedPayerAccount: bankAccounts[0] });
    }
  }, []);

  const redirectToConfirmPage = () => {
    if (Number(selectedPayerAccount?.balance?.amount) < Number(amount)) {
      return alert.open(
        t("Errors.OutboundPayments.Alerts.PaymentAboveBalance.Title"),
        t("Errors.OutboundPayments.Alerts.PaymentAboveBalance.Message"),
        [
          { text: "Cancel" },
          { text: "Yes", onClick: () => history.push(SCREEN_PATHS.MAKE_PAYMENT_CONFIRM) },
        ],
      );
    }

    return history.push(SCREEN_PATHS.MAKE_PAYMENT_CONFIRM);
  };

  const handleSelectBankAccount = (bankAccount: BankAccountExtended) => {
    if (bankAccount.accountId === values.selectedPayerAccount?.accountId) return;

    if (bankAccount.bankInfo.features.includes(CustodianFeatures.SINGLE_IMMEDIATE_PAYMENT)) {
      dispatch(setSelectedPayerAccount(bankAccount));
      setValues({ ...values, selectedPayerAccount: bankAccount });
    }
  };

  const handleSetBeneficiaryName = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setBeneficiaryName(values.beneficiaryName));
  };

  const handleSetBankAccountSortCode = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setBankAccountSortCode(values.bankAccountSortCode));
  };

  // const handleSetIBAN = (e: React.FocusEvent) => {
  //   handleBlur(e);
  //   dispatch(setIBAN(values.IBAN));
  // };

  const handleSetAmount = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setAmount(getPriceInNumberFormat(values.amount).toString()));
  };

  const handleSetReference = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setReference(values.reference));
  };

  const handleSetCurrency = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setCurrency(values.currency));
  };

  // const handleSetBICCode = (e: React.FocusEvent) => {
  //   handleBlur(e);
  //   dispatch(setBICCode(values.BICCode));
  // };

  const handleBankAccountNumber = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setBankAccountNumber(values.bankAccountNumber));
  };

  const handleChangeAmount = (e: React.ChangeEvent<{ value: string }>) => {
    const { value } = e.target;
    const beforeDot = value.includes(".")
      ? getPriceInNumberFormat(value.slice(0, value.indexOf("."))).toString()
      : getPriceInNumberFormat(value).toString();

    setFieldValue("amount", beforeDot.length <= MAX_LENGTH_OF_AMOUNT ? value : values.amount);
  };

  const handleChangeReference = (e: React.ChangeEvent<{ value: string }>) => {
    const { value } = e.target;

    setFieldValue("reference", /^[A-Za-z\d]*$/.test(value) ? value : values.reference);
  };

  const getIsNonFilledRequiredFields = (): boolean => {
    return MAKE_PAYMENT_REQUIRED_FIELD.some((fieldName) => !values[fieldName]);
  };

  return (
    <Page title="Make Payment">
      <ScreenHeader title="Make Payment" id="makePaymentTitle" />
      <ScreenHeaderSubtitle
        subtitle="From time to time you may want to make outbound payments that are not associated with an invoice from a supplier. You can make those payments here."
        step="Enter Payment details"
      />
      <form onSubmit={handleSubmit}>
        <Grid className={classes.gridContainer} container spacing={6}>
          <Grid item lg={6} md={6} xs={12}>
            <Card>
              <CardHeader title="Beneficiary" />
              <Divider />
              <Box className={classes.inputContainer}>
                <CommonTextField
                  label="Beneficiary Name"
                  margin="normal"
                  name="beneficiaryName"
                  value={values.beneficiaryName}
                  onChange={handleChange}
                  error={Boolean(touched.beneficiaryName && errors.beneficiaryName)}
                  helperText={touched.beneficiaryName && errors.beneficiaryName}
                  onBlur={handleSetBeneficiaryName}
                  id="makePaymentBeneficiaryName"
                />
                {/*<Tabs*/}
                {/*  className={classes.tabsContainer}*/}
                {/*  value={tabIndex}*/}
                {/*  indicatorColor="primary"*/}
                {/*  textColor="primary"*/}
                {/*  onChange={handleChangeTab}*/}
                {/*  name="bankAccountOrIBAN">*/}
                {/*  <Tab label="Account number" {...setIdTab(0)} />*/}
                {/*  <Tab label="IBAN format" {...setIdTab(1)} />*/}
                {/*</Tabs>*/}
                {/*{SORT_CODE_ACCOUNT_NUMBER_TAB_INDEX === 0 ? (*/}
                <AccountNumberContent
                  errors={errors}
                  touched={touched}
                  handleBlurBankAccountNumber={handleBankAccountNumber}
                  handleBlurBankAccountSortCode={handleSetBankAccountSortCode}
                  handleChange={handleChange}
                  isSubmitting={isSubmitting}
                  values={values}
                />
                {/*) : (*/}
                {/*  <IBANContent*/}
                {/*    values={values}*/}
                {/*    handleChange={handleChange}*/}
                {/*    handleBlurIBAN={handleSetIBAN}*/}
                {/*    handleBlurBICCode={handleSetBICCode}*/}
                {/*    touched={touched}*/}
                {/*    errors={errors}*/}
                {/*  />*/}
                {/*)}*/}
              </Box>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Card>
              {/*<Card className={classes.card}>*/}
              <CardHeader title="Payment Details" />
              <Divider />
              <Box className={classes.inputContainer}>
                <NumberFormatCustom
                  placeholder="Amount"
                  label="Amount"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={values.amount}
                  name="amount"
                  onChange={handleChangeAmount}
                  onBlur={handleSetAmount}
                  decimalScale={2}
                  allowNegative={false}
                  thousandSeparator={true}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                  variant="outlined"
                  id="makePaymentAmount"
                />
                <CommonTextField
                  fullWidth
                  select
                  label="Currency"
                  id="currency-select"
                  data-testid="external-ledger-select"
                  name="currency"
                  margin="normal"
                  value={values.currency}
                  onChange={handleChange}
                  onBlur={handleSetCurrency}
                  error={Boolean(touched.currency && errors.currency)}
                  helperText={touched.currency && errors.currency}
                  variant="outlined"
                  inputProps={{
                    id: "currency-field",
                  }}
                  InputLabelProps={{
                    htmlFor: "currency-field",
                  }}
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        "aria-activedescendant": `currency-option-${values.currency}`,
                        onFocus: handleAriaActiveDescendantChange,
                      },
                    },
                  }}>
                  {CONFIG.INPUTS.SUPPORTED_CURRENCIES.map((currency) => (
                    <MenuItem key={currency} id={`currency-option-${currency}`} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </CommonTextField>
                <CommonTextField
                  label="My Reference"
                  margin="normal"
                  name="reference"
                  value={values.reference}
                  onChange={handleChangeReference}
                  error={Boolean(touched.reference && errors.reference)}
                  helperText={touched.reference && errors.reference}
                  onBlur={handleSetReference}
                  inputProps={{ maxLength: 18 }}
                  id="makePaymentReference"
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </form>
      <Card>
        <CardHeader title="Select the bank account you wish to make payment from." />
        <Divider />
        <CardContent className={classes.accountListContent}>
          {!!bankAccounts?.length && (
            <PaymentAccountList
              bankAccounts={bankAccounts}
              selectedAccount={selectedPayerAccount}
              onAccountClick={(bankAccount) => handleSelectBankAccount(bankAccount)}
              unsupportedOutboundPaymentText={t("Errors.OutboundPayments.Messages.NoOutbound")}
            />
          )}
        </CardContent>
      </Card>
      <FooterActionsButtons
        handleContinue={redirectToConfirmPage}
        continueButtonText="Continue"
        continueButtonDataTestId="continue-button"
        disabledContinueButton={
          !isValid || !values.selectedPayerAccount || getIsNonFilledRequiredFields()
        }
        typeButtonContinue="submit"
      />
    </Page>
  );
};

type AccountNumberProps = {
  values: IMakePaymentInitialValues;
  errors: FormikErrors<IMakePaymentInitialValues>;
  touched: FormikTouched<IMakePaymentInitialValues>;
  isSubmitting: boolean;
  handleChange: (e: React.ChangeEvent) => void;
  handleBlurBankAccountSortCode: (e: React.FocusEvent) => void;
  handleBlurBankAccountNumber: (e: React.FocusEvent) => void;
};

const AccountNumberContent = ({
  values,
  isSubmitting,
  handleChange,
  handleBlurBankAccountSortCode,
  handleBlurBankAccountNumber,
  touched,
  errors,
}: AccountNumberProps) => (
  <>
    <InputMask
      name="bankAccountSortCode"
      value={getOnlyDigits(values.bankAccountSortCode)}
      mask={CONFIG.INPUTS.BANK_SORT_CODE_MASK ?? ""}
      maskPlaceholder=""
      disabled={isSubmitting}
      onChange={handleChange}
      onBlur={handleBlurBankAccountSortCode}
      data-testid="sort-code-input">
      <CommonTextField
        label="Bank Account Sort Code"
        error={Boolean(touched.bankAccountSortCode && errors.bankAccountSortCode)}
        helperText={touched.bankAccountSortCode && errors.bankAccountSortCode}
        margin="normal"
        name="bankAccountSortCode"
        data-testid="sort-code-text-field"
        id="makePaymentSortCode"
      />
    </InputMask>
    <NumberFormatCustom
      placeholder="Bank Account Number"
      label="Bank Account Number"
      type="text"
      fullWidth
      margin="normal"
      value={values.bankAccountNumber}
      name="bankAccountNumber"
      onChange={handleChange}
      onBlur={handleBlurBankAccountNumber}
      error={Boolean(touched.bankAccountNumber && errors.bankAccountNumber)}
      helperText={touched.bankAccountNumber && errors.bankAccountNumber}
      inputProps={{
        maxLength: 8,
      }}
      decimalScale={0}
      variant="outlined"
      id="makePaymentAccountNumber"
    />
  </>
);

// type IBANContentProps = {
//   values: IMakePaymentInitialValues;
//   errors: FormikErrors<IMakePaymentInitialValues>;
//   touched: FormikTouched<IMakePaymentInitialValues>;
//   handleChange: (e: React.ChangeEvent) => void;
//   handleBlurBICCode: (e: React.FocusEvent) => void;
//   handleBlurIBAN: (e: React.FocusEvent) => void;
// };

// const IBANContent = ({
//   values,
//   handleChange,
//   touched,
//   errors,
//   handleBlurBICCode,
//   handleBlurIBAN,
// }: IBANContentProps) => (
//   <>
//     <CommonTextField
//       label="BIC Code"
//       margin="dense"
//       name="BICCode"
//       value={values.BICCode}
//       onChange={handleChange}
//       error={Boolean(touched.BICCode && errors.BICCode)}
//       helperText={touched.BICCode && errors.BICCode}
//       onBlur={handleBlurBICCode}
//     />
//     <CommonTextField
//       label="IBAN"
//       margin="dense"
//       name="IBAN"
//       value={values.IBAN}
//       onChange={handleChange}
//       error={Boolean(touched.IBAN && errors.IBAN)}
//       helperText={touched.IBAN && errors.IBAN}
//       onBlur={handleBlurIBAN}
//     />
//   </>
// );

export default MakePaymentDetails;
