import { createActionCreator } from "deox";

import { ForecastState } from "@APP/redux/reducers/forecast";
import { ForecastChartData } from "@APP/types";

export const setForecastLoading = createActionCreator(
  "@FORECAST/SET_FORECAST_LOADING",
  (resolve) => (loading: boolean) => resolve(loading),
);

export const setForecastData = createActionCreator(
  "@FORECAST/SET_FORECAST_DATA",
  (resolve) => (data: ForecastState["data"]) => resolve(data),
);

export const setForecastId = createActionCreator(
  "@FORECAST/SET_FORECAST_ID",
  (resolve) => (forecastId: string) => resolve(forecastId),
);

export const setManualBalanceMode = createActionCreator(
  "@FORECAST/SET_MANUAL_BALANCE_MODE",
  (resolve) => (manualBalanceMode: boolean) => resolve(manualBalanceMode),
);

export const setForecastChartData = createActionCreator(
  "@FORECAST/SET_FORECAST_CHART_DATA",
  (resolve) => (forecastChartData: ForecastChartData[]) => resolve(forecastChartData),
);

export const setForecastEndDate = createActionCreator(
  "@FORECAST/SET_FORECAST_END_DATE",
  (resolve) => (forecastEndDate: Date) => resolve(forecastEndDate),
);

export const setDefaultForecastState = createActionCreator(
  "@FORECAST/SET_DEFAULT_FORECAST_STATE",
  (resolve) => () => resolve(),
);
