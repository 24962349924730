import { createStore, applyMiddleware, compose, Store, Action } from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createFilter } from "redux-persist-transform-filter";
import { useDispatch } from "react-redux";

import * as forecastActions from "@APP/redux/actions/forecast";
import * as dashboardAppActions from "@APP/redux/actions/dashboardApp";

import rootReducer from "../reducers";
import { createStateSyncMiddleware } from "redux-state-sync";

const authFilter = createFilter("auth", ["authenticated"]);

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "automatedCollections",
    "payable",
    "invoice",
    "makePayment",
    "auth",
    "rtpDetails",
    "deliveryDetails",
    "soleTrader",
    "registeredCompany",
    "workingCapitalFinance",
  ],
  transforms: [authFilter],
  stateReconciler: autoMergeLevel2,
};

const stateSyncConfig = {
  // the specific action type strings i.e. "@DASHBOARD_APP/SHOW_LOADER"  will not be triggered in other tabs
  blacklist: [
    dashboardAppActions.showLoader.type,
    dashboardAppActions.hideLoader.type,
    forecastActions.setForecastData.type,
    forecastActions.setForecastLoading.type,
    forecastActions.setForecastId.type,
    forecastActions.setForecastChartData.type,
    forecastActions.setForecastEndDate.type,
    "persist/PERSIST",
  ],
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const configureStore = (): Store => {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const middlewares = [createStateSyncMiddleware(stateSyncConfig)];

  return createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk), applyMiddleware(...middlewares)),
  );
};

export type AppState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () =>
  useDispatch<AppDispatch & ThunkDispatch<AppState, void, Action>>();

export const store = configureStore();
export const persistor = persistStore(store);
