import React, { useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";
import { isMobile } from "react-device-detect";

import { AuthForm, AuthLayout, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import CONFIG from "@APP/config";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 94,
  },
}));

const RegistrationSuccessView = () => {
  const classes = useStyles();
  const history = useHistory();
  const IsMobileInvoiceIt = CONFIG.PRODUCT_NAME === "Invoice It" && isMobile;

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(SCREEN_PATHS.LOGIN);
      }
    };
  }, []);

  const renderMainContent = () => (
    <AuthForm title="Congratulations!">
      <Box textAlign="center" data-testid="success-message-container">
        <Box mb={4} mt={6}>
          <CheckIcon color="secondary" className={classes.icon} />
        </Box>
        <Box mb={4}>
          <Typography id="registration-success-title">You’ve created your profile.</Typography>
          <Typography id="registration-success-subtitle">
            {CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION
              ? "Log in to complete your application."
              : "The verification email has been sent to you. Please close this page and verify your email."}
          </Typography>
        </Box>
        {!IsMobileInvoiceIt && (
          <Button
            fullWidth
            component={RouterLink}
            replace
            to={SCREEN_PATHS.LOGIN}
            color="secondary"
            variant="contained"
            id="login-button"
            size="large">
            Log in
          </Button>
        )}
      </Box>
    </AuthForm>
  );

  return (
    <Page title="Congratulations!" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

export default RegistrationSuccessView;
