import * as Yup from "yup";
import { getCountryCallingCode, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { isValid, getDefaultOptions, parse } from "date-fns";
import { TFunction } from "react-i18next";

import { CountryCode } from "libphonenumber-js/types";
import { AnyObject } from "yup/lib/types";

import { EMAIL_REGEX } from "@APP/constants";
import { DateDefaultOptions } from "@APP/types";

/**
 * Returns a boolean if the date is formatted to the short date format for the Locale set in CONFIG.DATE_LOCALE
 * i.e. for the UK it would be dd/MM/yyyy and for the US it would be MM/dd/yyyy.
 * Returns false false if a date is partially formatted i.e. 01/2023
 * @param value - Date or string
 * @param formatString -format string, e.g. "dd/MM/yyyy"
 */

export const isDateValid = (value: Date | string | null, formatString: string = "P") => {
  if (!value) return false;
  if (value instanceof Date) return isValid(value);
  return Boolean(parsDate(value, formatString).toString() !== "Invalid Date");
};

export const parsDate = (value: string, formatString: string = "P") => {
  const dateLocaleOptions: DateDefaultOptions = getDefaultOptions();

  return parse(value.toString(), formatString, new Date(), dateLocaleOptions);
};

function isDateCorrectlyFormed(
  this: Yup.BaseSchema<string | Date | undefined, AnyObject, string | Date | undefined>,
  message: string,
) {
  return this.test("isDateCorrectlyFormed", message, function (value) {
    const { path, createError } = this;
    if (!value || !isDateValid(value)) return createError({ path, message });
    if ((typeof value === "string" || "object") && isDateValid(value)) {
      return true;
    }

    return createError({ path, message });
  });
}

const YupMethods = [
  {
    name: "isDateCorrectlyFormed",
    method: isDateCorrectlyFormed,
    schema: [Yup.string, Yup.date],
  },
];

YupMethods.forEach(({ name, method, schema }) => {
  schema.forEach((schema) => {
    Yup.addMethod(schema, name, method);
  });
});

export const emailValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("Errors.Common.Validation.InvalidEmail"))
      .matches(EMAIL_REGEX, t("Errors.Common.Validation.InvalidEmail"))
      .max(255)
      .required(t("Errors.Common.Validation.EmailRequired")),
  });

export const getPhoneNumberValidationSchema = (
  countryCode: CountryCode,
  t: TFunction,
  isRequired: boolean = true,
) =>
  Yup.object().shape({
    phone: Yup.string()
      .transform((value) => value.replace(/\s/g, ""))
      .test("empty phone", t("Errors.Registration.Validation.PhoneRequired"), (value = "") =>
        isRequired ? !!value.replace(/\+/, "") : true,
      )
      .test("phone", t("Errors.Registration.Validation.PhoneValid"), (value = "") => {
        const phoneWithoutCountryCode = value.replace(`+${getCountryCallingCode(countryCode)}`, "");
        if (!phoneWithoutCountryCode.length && !isRequired) {
          return true;
        }

        // we have to try to parse the phone number to cover US numbers and UK numbers that start with a leading 0
        try {
          const parsedPhoneNumber = parsePhoneNumber(value, countryCode).number;

          return isValidPhoneNumber(parsedPhoneNumber);
        } catch (error) {
          return false;
        }
      }),
  });

const UPPERCASE_REGEX = /[A-Z]/;
const LOWERCASE_REGEX = /[a-z]/;
const NUMBER_REGEX = /[0-9]/;
const SPECIAL_CHAR_REGEX = /[!@#$%^&*?,.:;(_+=)-]/;
const ASCII_REGEX_WITH_SPECIAL_CHAR = /^[\x20-\x7F]*$/;

export const passwordValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t("Errors.Registration.Validation.PasswordRequired"))
      .min(8, t("Errors.Registration.Validation.PwLength"))
      .matches(ASCII_REGEX_WITH_SPECIAL_CHAR, t("Errors.Registration.Validation.PwEnglish"))
      .matches(UPPERCASE_REGEX, t("Errors.Registration.Validation.PwUpper"))
      .matches(LOWERCASE_REGEX, t("Errors.Registration.Validation.PwLower"))
      .matches(NUMBER_REGEX, t("Errors.Registration.Validation.PwNumber"))
      .matches(SPECIAL_CHAR_REGEX, t("Errors.Registration.Validation.PwSpecial")),
    confirmPassword: Yup.string()
      .required(t("Errors.Registration.Validation.PwConfirm"))
      .oneOf([Yup.ref("password"), null], t("Errors.Registration.Validation.PwMatch")),
  });

const MIN_BUSINESS_NAME_LENGTH_WHITESPACE_EXCLUSIVE = 2;
//RegEx excludes non-Latin alphanumeric characters and angled braces (< & >), but should allow all other symbols.
const businessNameRegex = /^[\x20-\x3B\x3D\x3F-\x7E£€]*$/;

export const businessNameValidationSchema = () =>
  Yup.string()
    .required("Please enter your Business Name.")
    .min(MIN_BUSINESS_NAME_LENGTH_WHITESPACE_EXCLUSIVE, "Please enter from 2 to 128 characters.")
    .max(128, "Please enter from 2 to 128 characters.")
    .matches(
      businessNameRegex,
      "This field may not contain non English characters or ‘< >’ symbols.",
    )
    .test(
      "Valid business name without only whitespace",
      "Please enter your Business Name.",
      (name) =>
        (name && name.trim().length >= MIN_BUSINESS_NAME_LENGTH_WHITESPACE_EXCLUSIVE) || false,
    );

export const CustomerValidationSchema = (countyCode: CountryCode, t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(t("Errors.CustomerCreation.Validation.NameRequired"))
      .max(128, t("Errors.CustomerCreation.Validation.NameLength"))
      .matches(/^\S.*$/, t("Errors.CustomerCreation.Validation.NoWhitespace")),
    email: Yup.string()
      .email(t("Errors.Common.Validation.InvalidEmail"))
      .matches(EMAIL_REGEX, t("Errors.Common.Validation.InvalidEmail"))
      .max(255),
    mobile: getPhoneNumberValidationSchema(countyCode, t, false).fields.phone,
    address: Yup.string()
      .min(2, "Please enter from 2 to 100 symbols.")
      .max(100, "Please enter from 2 to 100 symbols.")
      .matches(/^\S.*$/, t("Errors.CustomerCreation.Validation.NoWhitespace")),
    addressLine2: Yup.string()
      .min(2, "Please enter from 2 to 100 symbols.")
      .max(100, "Please enter from 2 to 100 symbols.")
      .matches(/^\S.*$/, t("Errors.CustomerCreation.Validation.NoWhitespace")),
    city: Yup.string()
      .min(2, "Please enter from 2 to 30 symbols.")
      .max(30, "Please enter from 2 to 30 symbols.")
      .matches(/^\S.*$/, t("Errors.CustomerCreation.Validation.NoWhitespace")),
    postcode: Yup.string()
      .min(2, "Please enter from 2 to 30 symbols.")
      .max(30, "Please enter from 2 to 30 symbols.")
      .matches(/^\S.*$/, t("Errors.CustomerCreation.Validation.NoWhitespace")),
    vatNumber: Yup.string().matches(
      /^[a-zA-Z0-9]{9,12}$/,
      "VAT number must be 9-12 characters, and contain only letters or numbers.",
    ),
  });
};
