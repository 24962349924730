import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as Yup from "yup";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";

import { CommonTextField, NoteBox } from "@APP/components";
import { ErpId, OrgType, Provider } from "@APP/constants";
import { useAlert, useHandleErrorCodes, useOrganisationDetails } from "@APP/hooks";
import {
  getPermissions,
  getUser,
  hideLoader,
  setOrgNotes,
  setUserOrganisationData,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import {
  fetchNotes,
  sendNotes,
  updateNotes,
  updateOrganisationDetails,
  uploadOrganisationLogo,
} from "@APP/services/api";
import { IMAGES } from "@APP/assets";
import { UserOrganisation } from "@APP/types";
import { Center } from "@APP/views/common";
import ContactDetails from "../ContactDetails";
import { handleKeyPress } from "@APP/views/wcf/utils/utils";
import { formatErrorMessage, isNativeInvoicingEnabled } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "auto",
    height: 120,
    width: 120,
    color: theme.palette.common.white,
    fontSize: 24,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      height: 120,
      width: 120,
      fontSize: 14,
    },
  },
  avatarContainer: {
    borderWidth: 1,
    borderColor: theme.palette.action.disabled,
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
  },
  avatarLabel: {
    position: "absolute",
    left: 5,
    top: -9,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(0.8),
    paddingRight: theme.spacing(0.8),
  },
  avatarDownloadIconContainer: {
    position: "absolute",
    right: 8,
    top: -12,
    backgroundColor: theme.palette.background.paper,
  },
  detailsDisplay: {
    display: "flex",
    width: "60%",
    justifyContent: "space-between",
    fontWeight: "500",
    wordBreak: "break-word",
  },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: 4,
};

const organisationDetailsValidationSchema = (isLimitedCompany: boolean) => {
  const schemaForSoleTrader = {
    companyName: Yup.string()
      .required("Please enter your Company Name.")
      .min(2, "Please enter from 2 to 60 symbols.")
      .max(60, "Please enter from 2 to 60 symbols.")
      .matches(
        /^[A-Za-z\d-: ]+$/,
        "This field may contain only letters, numbers, hyphen, colon and spaces.",
      ),
    companyAddress: Yup.string()
      .notRequired()
      .min(2, "Please enter from 2 to 100 symbols.")
      .max(100, "Please enter from 2 to 100 symbols.")
      .matches(
        /^[A-Za-z\d!@#$%^&*)(+=._\-/\\, ]+$/,
        "This field may contain only letters, numbers and special characters.",
      ),
    vatNumber: Yup.string()
      .notRequired()
      .min(9, "VAT number must be 9-12 characters, and contain only letters or numbers.")
      .max(11, "VAT number must be 9-12 characters, and contain only letters or numbers.")
      .matches(
        /^[A-Za-z\d]+$/,
        "VAT number must be 9-12 characters, and contain only letters or numbers.",
      ),
    companyAddressLine2: Yup.string()
      .notRequired()
      .min(2, "Please enter from 2 to 100 symbols.")
      .max(100, "Please enter from 2 to 100 symbols.")
      .matches(
        /^[A-Za-z\d!@#$%^&*)(+=._\-/\\, ]+$/,
        "This field may contain only letters, numbers and special characters.",
      ),
    companyAddressPostCode: Yup.string().notRequired(),
    companyNotes: Yup.string().notRequired(),
    companyAddressCity: Yup.string()
      .notRequired()
      .min(2, "Please enter from 2 to 30 symbols.")
      .max(30, "Please enter from 2 to 30 symbols.")
      .matches(/^[A-Za-z- ]+$/, "This field may contain only letters, hyphen and spaces."),
    companyAddressCountry: Yup.string().notRequired(),
  };

  if (isLimitedCompany) {
    return Yup.object().shape({
      ...schemaForSoleTrader,
      companyNumber: Yup.string()
        .required("Please enter a valid company number.")
        .length(8, "Please enter a valid company number.")
        .matches(/^[A-Za-z\d]+$/, "Please enter a valid company number."),
    });
  }

  return Yup.object().shape(schemaForSoleTrader);
};

const getOrgValues = (org?: UserOrganisation | null) => ({
  companyName: org?.companyInfo.name ?? "",
  companyNumber: org?.taxInfo.utrNumber ?? "",
  vatNumber: org?.taxInfo.vatRegistrationNumber ?? "",
  companyAddress: org?.companyInfo.address?.lines[0] ?? "",
  companyAddressLine2: org?.companyInfo.address?.lines[1] ?? "",
  companyAddressPostCode: org?.companyInfo.address?.postcode ?? "",
  companyAddressCity: org?.companyInfo.address?.city ?? "",
  companyAddressCountry: org?.companyInfo.address?.country ?? "",
  companyAddressState: org?.companyInfo.address?.state ?? "",
});

const OrganisationDetails = () => {
  const classes = useStyles();
  const alert = useAlert();
  const organisationDetailsHook = useOrganisationDetails();
  const { t } = useTranslation();
  const user = useSelector(getUser);

  const theme = useTheme();
  const handleErrorCodes = useHandleErrorCodes();
  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isDisplaySizeLessMd = useMediaQuery(theme.breakpoints.down("xl"));

  const dispatch = useAppDispatch();
  const permissions = useSelector(getPermissions);

  const [isEditMode, setIsEditMode] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [notes, setNotes] = useState("");
  const [defaultNotes, setDefaultNotes] = useState("");
  const [logoFile, setLogoFile] = useState<Blob>();
  const [organisationLogoSrc, setOrganisationLogoSrc] = useState<string>(
    user?.org?.logo ? user.org.logo : "",
  );

  const logoFileInputRef = useRef<HTMLInputElement | null>(null);
  const isDisabledField = user?.erp !== ErpId.INTERNAL;
  const isAdmin = user?.roles.includes("org-admin");
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const isLimitedCompany =
    user?.org?.orgType === OrgType.LIMITED_COMPANY ||
    user?.org?.orgType === OrgType.LIMITED_LIABILITY_PARTNERSHIP;

  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    validateForm,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: getOrgValues(user?.org),
    validationSchema: organisationDetailsValidationSchema(isLimitedCompany),
    onSubmit: async ({
      companyName,
      companyNumber,
      companyAddress,
      vatNumber,
      companyAddressLine2,
      companyAddressPostCode,
      companyAddressCity,
      companyAddressCountry,
      companyAddressState,
    }) => {
      let orgInfo;
      dispatch(showLoader());

      if (!user?.org) return;

      orgInfo = {
        ...user.org,
        companyInfo: {
          ...user.org.companyInfo,
          name: companyName,
          address: {
            ...user?.org?.companyInfo.address,
            lines: [companyAddress, companyAddressLine2],
            postcode: companyAddressPostCode,
            city: companyAddressCity,
            country: companyAddressCountry,
            state: companyAddressState,
          },
        },
        taxInfo: {
          ...user?.org?.taxInfo,
          utrNumber: companyNumber,
          vatRegistrationNumber: vatNumber,
        },
        name: companyName,
      };

      if (logoFile) {
        try {
          const formData = new FormData();
          formData.append("logo", logoFile);

          const logoLink = await uploadOrganisationLogo(formData);

          setLogoFile(undefined);
          setOrganisationLogoSrc(URL.createObjectURL(logoFile));
          orgInfo.logo = logoLink;
        } catch (e) {
          const errorCode = e.response?.data?.errorCode;

          const isHandled = handleErrorCodes(errorCode);

          dispatch(hideLoader());

          if (isHandled) {
            return;
          }

          return alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            "Your logo could not be uploaded. The file type must be png, jpg or jpeg and must be no bigger than 5MB.",
          );
        }
      }

      dispatch(setUserOrganisationData(orgInfo));
      resetForm({ values });
      setIsEditMode(false);
      dispatch(hideLoader());
    },
  });

  const handleOrgDetails = async () => {
    let orgInfo;
    dispatch(showLoader());
    try {
      if (!user?.org) return;

      orgInfo = {
        ...user.org,
        companyInfo: {
          ...user.org.companyInfo,
          name: values.companyName,
          address: {
            ...user?.org?.companyInfo.address,
            lines: [values.companyAddress, values.companyAddressLine2],
            postcode: values.companyAddressPostCode,
            city: values.companyAddressCity,
            country: values.companyAddressCountry,
            state: values.companyAddressState,
          },
        },
        taxInfo: {
          ...user?.org?.taxInfo,
          utrNumber: values.companyNumber,
          vatRegistrationNumber: values.vatNumber,
        },
        name: values.companyName,
      };

      await updateOrganisationDetails(user!.org!.id, orgInfo);
      alert.open("Success", "Organisation details updated");
    } catch (e) {
      const errorCode = e.response?.data?.errorCode;

      const isHandled = handleErrorCodes(errorCode);

      dispatch(hideLoader());

      if (isHandled) {
        return;
      }

      return alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Settings.Organisation.Alerts.OrganisationUpdating.Message"),
        [{ text: "Okay" }],
      );
    }

    dispatch(setUserOrganisationData(orgInfo));
    resetForm({ values });
    setIsEditMode(false);
    dispatch(hideLoader());
  };

  useEffect(() => {
    setValues(getOrgValues(user?.org));
  }, [user?.org]);

  const confirmationLogoContent = useCallback((logoFile: Blob) => {
    setLogoFile(logoFile);
    return (
      <>
        <DialogContent>
          <Box my={2}>
            <Typography variant="h4" align="center">
              Are you sure you want to use this image?
            </Typography>
          </Box>
          <Avatar
            className={classes.avatar}
            src={URL.createObjectURL(logoFile)}
            alt="Company Logo"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              alert.close();
              logoFileInputRef.current?.click();
            }}
            variant="outlined"
            color="primary"
            id="organizationDetailsPickNewButton">
            Pick a new one
          </Button>
          <Button
            onClick={() => {
              alert.close();
              setConfirm(true);
            }}
            variant="contained"
            color="primary"
            id="organizationDetailsConfirmButton">
            Confirm
          </Button>
        </DialogActions>
      </>
    );
  }, []);

  const onFileChange = (event: any) => {
    alert.render(confirmationLogoContent(event.target?.files?.[0]));
  };

  const notesFromUser = useMemo(() => user?.notes || "", [user]);

  const getProductsOrServices = async () => {
    try {
      const data = await fetchNotes();
      setNotes(data?.notes);
      setDefaultNotes(data?.notes);
      dispatch(setOrgNotes(data?.notes));
    } catch (e) {
      if (e.response?.data.errorCode === 6802) {
        setDefaultNotes("");
        setNotes("");
        dispatch(setOrgNotes(""));
      } else {
        alert.open("Error", e.message);
      }
    }
  };

  const submitinvoiceNotes = async () => {
    try {
      if (notesFromUser) {
        const data = await updateNotes({ notes: notes.length >= 2 ? notes : null });
        setNotes(data?.notes);
        alert.open("Success", "Invoice notes updated");
        getProductsOrServices();
      } else {
        const data = await sendNotes({ notes: notes.length >= 2 ? notes : null });
        setNotes(data?.notes);
        alert.open("Success", "Invoice notes created");
        getProductsOrServices();
      }
      setIsEditMode(false);
    } catch (e) {
      alert.open("Error", formatErrorMessage(e));
    }
  };

  useEffect(() => {
    setNotes(notesFromUser);
    setDefaultNotes(notesFromUser);
  }, [notesFromUser]);

  const handleEditClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsEditMode(true);
    let initialErrors = await validateForm();
    let errorFields = Object.keys(initialErrors);
    errorFields.forEach((field) => setFieldTouched(field, true, false));
  };

  const handleClickCancelButton = () => {
    resetForm();
    setLogoFile(undefined);
    setIsEditMode(false);
  };

  const renderCardAction = () => {
    // if (!permissions.organisation.update) {
    //   return null;
    // }

    if (user?.erp === ErpId.INTERNAL && isNativeInvoicingEnabled()) {
      return (
        <CardActions>
          {isEditMode ? (
            <Center
              gap={1}
              justifyContent={isPhone ? "center" : "space-between"}
              padding="8px 16px 8px 16px"
              display={isPhone ? "grid" : "flex"}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                id="organisation-details-cancel-button"
                onClick={handleClickCancelButton}
                className="minWidth">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                id="organisation-details-save-button"
                onClick={() => {
                  handleOrgDetails();
                }}
                disabled={(!isValid || !dirty) && !logoFile}
                className="minWidth">
                Save
              </Button>
            </Center>
          ) : (
            <Center>
              <Button
                type="button"
                variant="contained"
                color="primary"
                id="organisation-details-edit-button"
                onClick={handleEditClick}
                className="minWidth">
                Edit
              </Button>
            </Center>
          )}
        </CardActions>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        open={isEditMode}
        onClose={handleClickCancelButton}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        data-testid="organisation-details-modal">
        <Box sx={style}>
          <Grid
            maxHeight="80vh"
            style={{
              overflowY: "auto",
              padding: isPhone ? "0px 4px 0px 4px" : "0px 16px 0px 16px",
            }}>
            {user?.erp === ErpId.INTERNAL && isNativeInvoicingEnabled() && (
              <>
                <Box mb={2} mt={2}>
                  <Box display="flex" flexDirection="column" mb={1}>
                    <Typography variant="h6" data-testid="organisation-invoice-notes">
                      Invoice Notes
                    </Typography>
                    <Typography variant="body1" data-testid="organisation-invoice-notes-subheader">
                      We wil display invoice notes on all the invoices you send to your customers.
                      Max 250 characters.
                    </Typography>
                  </Box>
                  <CommonTextField
                    inputProps={{
                      readOnly: isDisabledField,
                      disabled: isDisabledField,
                      id: "organisation-invoice-notes-input",
                      "data-testid": "organisation-invoice-notes-input",
                      maxLength: 250,
                    }}
                    fullWidth
                    name="organisationInvoiceNotes"
                    onKeyDown={handleKeyPress}
                    multiline
                    rows={3}
                    onChange={(e) => setNotes(e.target.value)}
                    onBlur={handleBlur}
                    value={notes}
                    error={(notes.length >= 1 && notes.length < 2) || notes.length > 250}
                    helperText={
                      (notes.length >= 1 && notes.length < 2) || notes.length > 250
                        ? "Please enter from 2 to 250 symbols."
                        : null
                    }
                    id="organizationDetailsCompanyNumber"
                  />
                  {user?.erp === ErpId.INTERNAL && isNativeInvoicingEnabled() && (
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: isPhone ? "center" : "flex-end" }}>
                      <Button
                        style={{
                          marginTop: "3%",
                        }}
                        color="primary"
                        variant="contained"
                        data-testid="submit-notes-button"
                        onClick={submitinvoiceNotes}
                        id="submitNotes"
                        disabled={notes === defaultNotes || notes.length === 1}
                        className="minWidth">
                        Save notes
                        <VisuallyHiddenInput type="file" />
                      </Button>
                    </Grid>
                  )}
                </Box>
                <Divider sx={{ margin: "16px 0 16px 0" }} />
              </>
            )}
            <Typography variant="h6" data-testid="organisation-details-title">
              {t("Settings.OrganisationDetails.OrgDetailsFieldTitle")}
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr"}
              columnGap={3}
              rowGap={2}
              marginTop={2}>
              <CommonTextField
                inputProps={{
                  readOnly: isDisabledField,
                  disabled: isDisabledField,
                  id: "company-name-input",
                  "data-testid": "company-name-input",
                }}
                fullWidth
                label="Company name"
                placeholder="Company name"
                name="companyName"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                onBlur={handleBlur}
                value={values.companyName}
                error={Boolean(touched.companyName && errors.companyName)}
                helperText={touched.companyName && errors.companyName}
                id="organizationDetailsCompanyName"
              />
              {isLimitedCompany && (
                <CommonTextField
                  inputProps={{
                    readOnly: isDisabledField,
                    disabled: isDisabledField,
                    id: "company-number-input",
                    "data-testid": "company-number-input",
                  }}
                  fullWidth
                  label="Company Number"
                  onKeyDown={handleKeyPress}
                  placeholder="Company Number"
                  name="companyNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyNumber}
                  error={Boolean(touched.companyNumber && errors.companyNumber)}
                  helperText={touched.companyNumber && errors.companyNumber}
                  id="organizationDetailsCompanyNumber"
                />
              )}
              {user!.erp === ErpId.INTERNAL && (
                <CommonTextField
                  inputProps={{
                    readOnly: isDisabledField,
                    disabled: isDisabledField,
                    id: "vat-number-input",
                    "data-testid": "vat-number-input",
                  }}
                  fullWidth
                  onKeyDown={handleKeyPress}
                  label="VAT number"
                  placeholder="VAT number"
                  name="vatNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vatNumber}
                  error={Boolean(touched.vatNumber && errors.vatNumber)}
                  helperText={touched.vatNumber && errors.vatNumber}
                  id="organizationDetailsVatNumber"
                />
              )}
            </Box>
            <Divider sx={{ margin: "16px 0 16px 0" }} />
            <Box mb={2} mt={2}>
              <Typography variant="h6" data-testid="company-address-title">
                Company address
              </Typography>
              <Box
                display="grid"
                gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr"}
                columnGap={3}
                rowGap={2}
                marginTop={2}>
                <CommonTextField
                  inputProps={{
                    readOnly: isDisabledField,
                    disabled: isDisabledField,
                    id: "company-address-input",
                    "data-testid": "company-address-input",
                  }}
                  fullWidth
                  name="companyAddress"
                  onKeyDown={handleKeyPress}
                  label="Address"
                  placeholder="Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyAddress}
                  error={Boolean(touched.companyAddress && errors.companyAddress)}
                  helperText={touched.companyAddress && errors.companyAddress}
                  id="organizationDetailsCompanyAddress"
                />
                <CommonTextField
                  inputProps={{
                    readOnly: isDisabledField,
                    disabled: isDisabledField,
                    id: "company-address-line2-input",
                    "data-testid": "company-address-line2-input",
                  }}
                  fullWidth
                  label="Address Line 2"
                  onKeyDown={handleKeyPress}
                  placeholder="Address Line 2"
                  name="companyAddressLine2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyAddressLine2}
                  error={Boolean(touched.companyAddressLine2 && errors.companyAddressLine2)}
                  helperText={touched.companyAddressLine2 && errors.companyAddressLine2}
                  id="organizationDetailsCompanyAddressLine2"
                />
                <CommonTextField
                  inputProps={{
                    readOnly: isDisabledField,
                    disabled: isDisabledField,
                    id: "company-address-city-input",
                    "data-testid": "company-address-city-input",
                  }}
                  fullWidth
                  label="City"
                  placeholder="City"
                  name="companyAddressCity"
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyAddressCity}
                  error={Boolean(touched.companyAddressCity && errors.companyAddressCity)}
                  helperText={touched.companyAddressCity && errors.companyAddressCity}
                  id="organizationDetailsCompanyAddressCity"
                />
                {Provider.isMoneyhub ? (
                  <CommonTextField
                    inputProps={{
                      readOnly: isDisabledField,
                      disabled: isDisabledField,
                      id: "company-address-country-input",
                      "data-testid": "company-address-country-input",
                    }}
                    fullWidth
                    label="Country"
                    placeholder="Country"
                    name="companyAddressCountry"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    onBlur={handleBlur}
                    value={values.companyAddressCountry}
                    error={Boolean(touched.companyAddressCountry && errors.companyAddressCountry)}
                    helperText={touched.companyAddressCountry && errors.companyAddressCountry}
                    id="organizationDetailsCompanyAddressCountry"
                  />
                ) : (
                  <CommonTextField
                    inputProps={{
                      readOnly: isDisabledField,
                      disabled: isDisabledField,
                      id: "company-address-state-input",
                      "data-testid": "company-address-state-input",
                    }}
                    fullWidth
                    label="State"
                    placeholder="State"
                    name="companyAddressState"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    onBlur={handleBlur}
                    value={values.companyAddressState}
                    error={Boolean(touched.companyAddressState && errors.companyAddressState)}
                    helperText={touched.companyAddressState && errors.companyAddressState}
                    id="organizationDetailsCompanyAddressState"
                  />
                )}
                <CommonTextField
                  inputProps={{
                    readOnly: isDisabledField,
                    disabled: isDisabledField,
                    id: "company-address-post-code-input",
                    "data-testid": "company-address-post-code-input",
                  }}
                  fullWidth
                  label={t("Settings.OrganisationDetails.PostcodeFieldTitle")}
                  placeholder={t("Settings.OrganisationDetails.PostcodeFieldTitle")}
                  name="companyAddressPostCode"
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  onBlur={handleBlur}
                  value={values.companyAddressPostCode}
                  error={Boolean(touched.companyAddressPostCode && errors.companyAddressPostCode)}
                  helperText={touched.companyAddressPostCode && errors.companyAddressPostCode}
                  id="organizationDetailsCompanyAddressPostCode"
                />
              </Box>
            </Box>
            {renderCardAction()}
          </Grid>
        </Box>
      </Modal>
      <Card elevation={4}>
        <CardHeader
          title="Organisation"
          subheader="View your organisation details."
          data-testid="organisation-card-header"
          id="organisation-card-header"
        />
        <Divider />
        <CardContent>
          <Box mb={2}>
            {user?.erp !== ErpId.INTERNAL && permissions.organisation.update ? (
              <Box mb={2}>
                <NoteBox>
                  {isLimitedCompany
                    ? t("Settings.RegisteredCompanyNote")
                    : t("Settings.NonRegisteredCompanyNote")}
                </NoteBox>
              </Box>
            ) : null}
            <Grid
              display="flex"
              alignItems="normal"
              container
              direction={isSmSizeScreen ? "column" : "row"}
              spacing={3}>
              <Grid item xs={12} md={6} mt={0.5}>
                <Card elevation={4}>
                  <CardContent>
                    <>
                      <Box display="flex" justifyContent="center">
                        <Box
                          className={
                            user?.erp === ErpId.INTERNAL ? classes.avatarContainer : undefined
                          }
                          p={4}
                          position="relative">
                          {!isDisabledField && (
                            <>
                              <input
                                ref={logoFileInputRef}
                                onChange={onFileChange}
                                type="file"
                                style={{ display: "none" }}
                                id="organizationDetailsFileInput"
                              />
                              <Typography
                                className={classes.avatarLabel}
                                color="textSecondary"
                                variant="caption">
                                Company Logo
                              </Typography>
                            </>
                          )}
                          <Avatar
                            className={classes.avatar}
                            src={
                              logoFile
                                ? URL.createObjectURL(logoFile)
                                : organisationLogoSrc !== ""
                                ? organisationLogoSrc
                                : IMAGES.NO_IMAGE_AVAILABLE
                            }
                            alt="Company Logo">
                            {user?.org?.companyInfo.name.toUpperCase() || ""}
                          </Avatar>
                        </Box>
                      </Box>
                      {user?.erp === ErpId.INTERNAL && isNativeInvoicingEnabled() && (
                        <Grid
                          item
                          mt={2}
                          mb={2}
                          style={{ display: isAdmin ? "flex" : "none", justifyContent: "center" }}>
                          {logoFile && confirm ? (
                            <CardContent>
                              <Center
                                gap={1}
                                flexDirection={isDisplaySizeLessMd ? "column-reverse" : "row"}
                                justifyContent="space-between"
                                padding="8px 16px 8px 16px">
                                <Button
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  id="organisation-details-cancel-button"
                                  onClick={handleClickCancelButton}
                                  className="minWidth">
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  id="organisation-details-save-button"
                                  onClick={() => setIsEditMode(false)}
                                  disabled={(!isValid || !dirty) && !logoFile}
                                  className="minWidth">
                                  Save
                                </Button>
                              </Center>
                            </CardContent>
                          ) : (
                            user?.erp === ErpId.INTERNAL &&
                            isNativeInvoicingEnabled() && (
                              <Button
                                color="primary"
                                onClick={() => logoFileInputRef.current?.click()}
                                variant="contained"
                                data-testid="upload-company-logo-button"
                                id="uploadCompanyLogo"
                                className="minWidth">
                                Upload company logo
                                <VisuallyHiddenInput type="file" />
                              </Button>
                            )
                          )}
                        </Grid>
                      )}
                    </>
                    <ContactDetails />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                {user?.erp === ErpId.INTERNAL && isNativeInvoicingEnabled() && (
                  <Card elevation={4} style={{ marginBottom: "16px" }}>
                    <Box mt={2} mb={2}>
                      <CardHeader
                        title="Invoice Notes"
                        subheader="We will display invoice notes on all the invoices you send to your customers. Max 250 characters."
                        data-testid="invoice-notes-card-header"
                        id="invoiceNotesCardHeader"
                      />
                      <CardContent
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "revert-layer",
                          overflow: "hidden",
                          width: "100%",
                          wordBreak: "break-word",
                        }}>
                        <Typography>{defaultNotes}</Typography>
                      </CardContent>
                    </Box>
                  </Card>
                )}
                <Card elevation={4}>
                  <CardContent>
                    <Box display="flex" flexDirection="column" gap={3}>
                      <Typography variant="h6" data-testid="organisation-details-title">
                        {t("Settings.OrganisationDetails.OrgDetailsFieldTitle")}
                      </Typography>
                      <Box>
                        <Typography className={classes.detailsDisplay}>
                          Company name:{" "}
                          <Typography
                            sx={{
                              width: "60%",
                              textAlign: "left",
                            }}>
                            {values.companyName}
                          </Typography>
                        </Typography>
                        {isLimitedCompany && (
                          <Typography className={classes.detailsDisplay}>
                            Company number:
                            <Typography
                              sx={{
                                width: "60%",
                                textAlign: "left",
                              }}>
                              {values.companyNumber}
                            </Typography>
                          </Typography>
                        )}
                        <Typography className={classes.detailsDisplay}>
                          VAT number:{" "}
                          <Typography
                            sx={{
                              width: "60%",
                              textAlign: "left",
                            }}>
                            {values.vatNumber}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ marginBottom: "16px", marginTop: "16px" }} />
                    <Box mb={2} mt={2}>
                      <Typography variant="h6" data-testid="company-address-title">
                        Company address
                      </Typography>
                      <Typography className={classes.detailsDisplay} mt={2}>
                        Address:{" "}
                        <Typography
                          sx={{
                            width: "60%",
                            textAlign: "left",
                          }}>
                          {values.companyAddress && values.companyAddress}
                          {values.companyAddressLine2 && `, ${values.companyAddressLine2}`}
                        </Typography>
                      </Typography>
                      <Typography className={classes.detailsDisplay}>
                        City:{" "}
                        <Typography
                          sx={{
                            width: "60%",
                            textAlign: "left",
                          }}>
                          {values.companyAddressCity}
                        </Typography>
                      </Typography>
                      <Typography className={classes.detailsDisplay}>
                        Country:
                        <Typography
                          sx={{
                            width: "60%",
                            textAlign: "left",
                          }}>
                          {values.companyAddressCountry}
                        </Typography>
                      </Typography>
                      <Typography className={classes.detailsDisplay}>
                        PostCode:{" "}
                        <Typography
                          sx={{
                            width: "60%",
                            textAlign: "left",
                          }}>
                          {values.companyAddressPostCode}
                        </Typography>
                      </Typography>
                    </Box>
                    {isAdmin &&
                      (user?.erp === ErpId.INTERNAL && isNativeInvoicingEnabled() ? (
                        <CardActions sx={{ justifyContent: "center" }}>
                          <Center>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              id="organisation-details-edit-button"
                              onClick={handleEditClick}
                              className="minWidth">
                              Edit
                            </Button>
                          </Center>
                        </CardActions>
                      ) : (
                        <CardActions>
                          <Center>
                            <Button
                              color="primary"
                              variant="contained"
                              id="get-organisation-details-button"
                              data-testid="get-organisation-details-button"
                              onClick={async (e: SyntheticEvent) => {
                                e.preventDefault();
                                dispatch(showLoader());
                                await organisationDetailsHook.updateOrganisationDetails();
                                dispatch(hideLoader());
                              }}
                              className="minWidth">
                              {t("Settings.OrganisationDetails.ContinueButton")}
                            </Button>
                          </Center>
                        </CardActions>
                      ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

export default OrganisationDetails;
