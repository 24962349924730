import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { fetchUserSubscription, getSubscription, hideLoader, showLoader } from "@APP/redux";
import { API } from "@APP/services";
import { UNPAID_RTPS_EXIST_FOR_SUBSCRIPTION_ERROR_CODE } from "@APP/services/api";

const useSubscription = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const subscription = useSelector(getSubscription);
  const { t } = useTranslation();

  const subscribeToDefaultPlan = async () => {
    try {
      dispatch(showLoader());

      const plans = await API.getSubscriptionsBillingPlans();
      // Array of plans is sorted by priority, first "available" plan is the default one
      const defaultPlan = plans.find((plan) => plan.available);

      if (defaultPlan) {
        await API.subscribeToPlan(defaultPlan.id);
        await dispatch(fetchUserSubscription());
      }

      history.push(SCREEN_PATHS.REGISTRATION_COMPLETE);
    } catch (e) {
      const errorCode = e.response?.data?.errorCode;

      const isHandled = handleErrorCodes(errorCode, {
        errorCodes: [UNPAID_RTPS_EXIST_FOR_SUBSCRIPTION_ERROR_CODE],
      });

      if (!isHandled) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          t("Errors.Subscriptions.Alerts.ActivateAccount.Message"),
          [{ text: "Okay", onClick: () => history.push(SCREEN_PATHS.SETTINGS) }],
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  const resubscribeToDefaultSubscription = async () => {
    try {
      if (!subscription?.planId) return;

      const plan = await API.getSubscriptionsBillingPlans();
      // Take the first plan available for the user
      const availablePlan = plan.find((plan) => plan.available);

      if (availablePlan && availablePlan.id !== subscription?.planId) {
        try {
          await API.unsubscribeFromPlan(subscription?.id!);
        } catch (error) {
          return { errorOnUnsubscribe: true };
        }

        try {
          await API.subscribeToPlan(availablePlan.id);
        } catch (error) {
          return { errorOnSubscribe: true };
        }

        return { resubscribedSuccessfully: true };
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { subscribeToDefaultPlan, resubscribeToDefaultSubscription };
};

export default useSubscription;
