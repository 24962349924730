import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      /**
       * Global style configuration
       * ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
       */
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
        fontSize: "1rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.75rem",
        },
      },
      body: {
        backgroundColor: theme.palette.background.default,
        height: "100%",
        width: "100%",
        color: theme.palette.text.primary,
      },
      a: {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".minWidth": {
        minWidth: "232px",
      },
      /**
       * Global CSS-classes and utility styles
       * ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
       */
      ".visuallyHidden": {
        position: "absolute",
        left: "-10000px",
        top: "auto",
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: "hidden",
        clip: "rect(0,0,0,0)",
        whiteSpace: "nowrap",
        border: 0,
      },
      ".semanticButton": {
        padding: 0,
        background: "none",
        border: "none",
        cursor: "pointer",
      },
      /**
       * Global style fixes and adjustments
       * ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
       */
      // Fixes misalignment of placement of the end adorned element for KeyboardDatePicker component
      ".keyboardDatePicker": {
        "& .MuiOutlinedInput-adornedEnd": {
          paddingRight: 0,
        },
      },
      // Fixes vertical alignment of the Select input icon when the font size is set smaller than the default.
      ".MuiSelect-select ~ .MuiSelect-icon": {
        top: "auto",
      },
      // remove blue shadow applied to autocomplete field input
      ".MuiInputBase-input": {
        padding: "12px 14px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 1000px white inset",
        },
      },
      ".MuiAutocomplete-input": {
        padding: "3px 4px 3px 6px!important",
      },
      // styles for restricting width of the Menu popover to match with input width
      ".MuiMenu-paper": {
        width: "min-content",
        "& .MuiMenuItem-root": {
          whiteSpace: "pre-wrap",
        },
      },
    },
  }),
);

/**
 * An empty component that applies global styles.
 */
const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
