import clsx from "clsx";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";

import { TOP_BAR_HEIGHT, OPEN_SIDEBAR_WIDTH, CLOSE_SIDEBAR_WIDTH } from "@APP/styles";

type Props = {
  isFullSideBar: boolean;
  onButtonClick?: () => void;
};

const useStyles = makeStyles((theme) => ({
  burgerButtonContainer: {
    position: "fixed",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      left: 10,
      height: TOP_BAR_HEIGHT,
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.up("sm")]: {
      top: TOP_BAR_HEIGHT - 24,
    },
  },
  burgerOpened: {
    [theme.breakpoints.up("sm")]: {
      left: OPEN_SIDEBAR_WIDTH - 22,
      transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  burgerClosed: {
    [theme.breakpoints.up("sm")]: {
      left: CLOSE_SIDEBAR_WIDTH - 22,
      transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  burgerButton: {
    padding: "10px",
    borderRadius: "50%",
    minWidth: 0,
  },
}));

const MenuButton = ({ onButtonClick, isFullSideBar }: Props) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.burgerButtonContainer, {
        [classes.burgerOpened]: isFullSideBar,
        [classes.burgerClosed]: !isFullSideBar,
      })}>
      <Button
        className={classes.burgerButton}
        variant="contained"
        color="secondary"
        onClick={onButtonClick}
        id="menu-toggle"
        aria-label="Toggle the menu"
        data-testid="side-bar-burger-btn"
        style={{ boxShadow: "none" }}>
        <MenuIcon />
      </Button>
    </Box>
  );
};

export default MenuButton;
