import { SetStateAction } from "react";
import { API } from "@APP/services";
import { SCREEN_PATHS } from "@APP/navigation";
import { History } from "history";
import { ErpId } from "@APP/constants";

export const formatOption = (str: string) => {
  str = str.replace(/_/g, " ");
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
};

export const dateFormatter = (date: string | Date) => {
  const DOB = new Date(date ?? "") ?? "";
  const year = DOB.getFullYear();
  const month = String(DOB.getMonth() + 1).padStart(2, "0");
  const day = String(DOB.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const dateFormatterUK = (date: string | Date) => {
  let dt = new Date(date ?? "") ?? "";
  if (typeof date === "string") {
    const parts = date.split("-");
    if (parts.length !== 3) {
      return "Invalid Date";
    }
    dt = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
  }
  const day = dt.getDate();
  const month = dt.getMonth() + 1;
  const year = dt.getFullYear();

  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;
  return `${formattedDay}-${formattedMonth}-${year}`;
};

export const isFutureDate = (date: string | Date) => {
  return new Date(date as string) > new Date();
};

export function removeUnderscoreAndCapitalize(str: string) {
  const words = str.split("_");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(" ");
}

export const handleMinValues = (
  value: string,
  name: string,
  setFieldValue: (name: string, values: string) => void,
  minValue = 0,
) => {
  !isNaN(Number(value)) && parseFloat(value) > minValue && setFieldValue(name, value);
};

export const fetchWcfCustomer = async (
  setCustNotFound: (value: SetStateAction<boolean>) => void,
  setIsAPLinked: (value: SetStateAction<boolean>) => void,
  setRedirectToApplication: (value: SetStateAction<boolean>) => void,
) => {
  try {
    const customer = await API.getWcfCustomer();
    if (customer && customer.status === "Submitted") {
      setRedirectToApplication(true);
    }
  } catch (error) {
    const errorCode = error.response?.data?.errorCode;
    if (errorCode === 5004 || errorCode === 24110) setCustNotFound(true);
    setIsAPLinked(errorCode === 24110);
  }
};

export const handleRedirect = async (
  custNotFound: boolean,
  isAPLinked: boolean,
  redirectToApplication: boolean,
  history: History<unknown>,
) => {
  const { currentErp } = await API.getCurrentERP();

  let externalERPLinked =
    currentErp === ErpId.SAGE || currentErp === ErpId.XERO || currentErp === ErpId.QUICKBOOKS;
  if (custNotFound) {
    if (isAPLinked && externalERPLinked) {
      history.push(SCREEN_PATHS.COMPANY_PROFILE);
    } else if (currentErp === ErpId.INTERNAL) {
      history.push(SCREEN_PATHS.WCF_MANUAL_COMPANY);
    } else {
      history.push(SCREEN_PATHS.WCF_ACCOUNTING);
    }
  } else {
    if (redirectToApplication) {
      history.push(SCREEN_PATHS.WORKING_CAPITAL_FINANCE);
    } else {
      const customer = await API.getWcfCustomer();
      if (isAPLinked || externalERPLinked) {
        history.push(SCREEN_PATHS.WCF_COMPANY_PROFILE_FORM, { customer: customer });
      } else {
        history.push(SCREEN_PATHS.WCF_MANUAL_COMPANY, { customer: customer });
      }
    }
  }
};

export const amendValues = (value: any, compareValue = "") => {
  return value === compareValue ? null : value;
};

export const supportedFileTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroenabled.12",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/pdf",
  "image/jpeg",
  "image/jpeg",
  "text/plain",
  "text/csv",
  "application/rtf",
  "image/png",
  "image/gif",
  "message/rfc822",
  "image/tiff",
  "text/html",
  "text/html",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/octet-stream",
  "application/zip",
  "application/x-rar-compressed",
];

export const MAX_FILE_SIZE = 100 * 1024 * 1024;
export const MAX_FILENAME_LENGTH = 255;

export const getVATPerUnit = (price: number | null) => {
  if (!price) return null;
  return Number((price * 0.2).toFixed(2));
};

export function percentage(partialValue: number, totalValue: number) {
  return (partialValue / 100) * totalValue;
}
export const convertToSnakeCase = (str: string) => {
  let snakeCaseStr = str.replace(/\s+/g, "_");
  snakeCaseStr = snakeCaseStr.toLowerCase();
  return snakeCaseStr;
};

export const convertToCapitalizeCase = (str: string) => {
  let words = str.split("_");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  let capitalizeCaseStr = words.join(" ");
  return capitalizeCaseStr;
};

export const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  if (event.key === " " && (event.target as HTMLInputElement).value.trim() === "") {
    event.preventDefault();
  }
};
