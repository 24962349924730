import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { getUser, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { ErpId } from "@APP/constants";
import { InvoiceType, Receivable } from "@APP/types";
import { formatErrorMessage } from "@APP/utils";
import { useAlert } from "@APP/hooks";
import { API } from "@APP/services";
import { FooterActionsButtons, InvoiceSummary, Page, ResultNotification } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";

const InvoiceVoidedSuccess = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { requestId }: { requestId: string } = useParams();

  const user = useSelector(getUser);

  const [selectedReceivable, setSelectedReceivable] = useState<Receivable>();

  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoader());
        setSelectedReceivable(
          (await API.getInvoice(
            user?.erp as ErpId,
            InvoiceType.Receivables,
            requestId,
          )) as Receivable,
        );
      } catch (error) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      } finally {
        dispatch(hideLoader());
      }
    })();
  }, []);

  if (!selectedReceivable) return null;

  return (
    <Page title="Нour Invoice was successfully voided" id="invoiceVoidedSuccessTitle">
      <ResultNotification type="success">
        Your Invoice was successfully voided, please check the details below:
      </ResultNotification>
      <Box mt={2}>
        <InvoiceSummary
          customerContact={selectedReceivable.customerContact}
          reference={selectedReceivable.reference}
          invoiceIssueDateTime={selectedReceivable.dateTimeIssued}
          invoiceDueDateTime={selectedReceivable.dueDateTime}
          lineItems={selectedReceivable.lineItems}
          totalAmountTaxExclusive={selectedReceivable.totalAmountTaxExclusive?.amount}
          totalAmountTaxInclusive={selectedReceivable.totalAmountTaxInclusive?.amount}
          currency={selectedReceivable.totalAmountTaxExclusive?.currency}
          taxCategories={selectedReceivable.taxCategories}
        />
      </Box>
      <FooterActionsButtons
        backButtonText="Go to Customer Invoices"
        handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_LIST)}
      />
    </Page>
  );
};

export default InvoiceVoidedSuccess;
