import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Card, CardHeader, Grid } from "@mui/material";

import { Page } from "@APP/components";
import WcfFooter from "@APP/components/layout/WCFLayout";
import { SCREEN_PATHS } from "@APP/navigation";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContaier: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "32px",
    borderRadius: "5px",
    alignItems: "center",
    height: "80vh",
  },
  financeContainer: {
    backgroundColor: "#FCFCFC",
    textAlign: "center",
    padding: "32px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
  },
}));

function Landing() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Page className={classes.container} title="Working capital finance" p={0}>
      <Box p={3}>
        <Card className={classes.cardContaier} id={"wcfLandingApplyforFinancingCard"}>
          <Grid className={classes.financeContainer}>
            <CardHeader
              id={"wcfLandingApplyforFinancingTitle"}
              title="You haven't applied for any financing yet."
              subheader="Start your application here:"
            />
            <Button
              sx={{ borderRadius: "5px" }}
              variant="outlined"
              id={"wcfLandingApplyforFinancingButton"}
              onClick={() => history.push(SCREEN_PATHS.WCF_ACCOUNTING)}>
              Apply for financing
            </Button>
          </Grid>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter
        displaySaveAndExitButton={false}
        displayPrevButton={false}
        displayNextButton={false}
      />
    </Page>
  );
}

export default Landing;
