import { NavLink as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Card, CardActions, CardHeader, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SCREEN_PATHS } from "@APP/navigation";
import { NoLinkedBankAccountsContent } from "@APP/components";
import { useSelector } from "react-redux";
import { getPermissions } from "@APP/redux";
import { Center } from "@APP/views/common";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const NoLinkedBankAccountsView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const permissions = useSelector(getPermissions);

  return (
    <Box className={classes.root}>
      <Card elevation={4}>
        <CardHeader
          subheader={t(
            permissions.bank_account.create
              ? "CommonViews.NoLinkedBankAccountsView.CardSubheader"
              : "CommonViews.NoLinkedBankAccountsView.CardSubheaderNoAuth",
          )}
          subheaderTypographyProps={{ "data-testid": "card-header-no-linked-subtitle" }}
          title={t("CommonViews.NoLinkedBankAccountsView.CardTitle")}
          titleTypographyProps={{
            "data-testid": "card-header-no-linked-title",
            id: "card-header-no-linked-title",
            component: "h4",
          }}
          data-testid="no-linked-bank-accounts-please-link-card-header"
          id="no-linked-bank-accounts-please-link-card-header"
        />
        <Divider />
        <NoLinkedBankAccountsContent />
        <Divider />
        <CardActions>
          <Center>
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              data-testid="link-bank-accounts-button"
              to={`${SCREEN_PATHS.SETUP_BANK_ACCOUNTS}`}
              id="linkAccountPackageButton"
              className="minWidth">
              {t("CommonViews.NoLinkedBankAccountsView.ButtonLabel")}
            </Button>
          </Center>
        </CardActions>
      </Card>
    </Box>
  );
};

export default NoLinkedBankAccountsView;
