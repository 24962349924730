import { useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getWorkingCapitalFinanceDetails, hideLoader, showLoader } from "@APP/redux";
import { updateApplication } from "@APP/services/api";
import { assetFinancePayload } from "@APP/views/wcf/utils/assetFinancePayload";
import { amendValues, dateFormatter, getVATPerUnit, percentage } from "@APP/views/wcf/utils/utils";
import { invoiceFinanceDefaultValue } from "@APP/views/wcf/utils/defaultValue";
import { formatErrorMessage } from "@APP/utils";
import { useAlert } from "@APP/hooks";
import { WcfForm } from "@APP/types/wcf";

const useHandleApplicantDetails = (): ((
  applicantDetailsForm: WcfForm["applicantDetailsForm"],
) => Promise<void>) => {
  const {
    formType,
    productType,
    isRefinance,
    wcfFormData: { invoiceFinanceForm, assetFinanceForm },
  } = useSelector(getWorkingCapitalFinanceDetails);
  const { applicationId }: { applicationId: string } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();

  const [vat, setVat] = useState<number | null>(null);

  const getApplications = () => {
    try {
      const vatUpFront = percentage(
        20,
        (assetFinanceForm.units as number) * (assetFinanceForm.pricePerUnit as number),
      );
      if (productType === "hire_purchase") setVat(vatUpFront);
      else setVat(null);
    } catch (error) {
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    }
  };

  useEffect(() => {
    getApplications();
  }, [assetFinanceForm]);

  const handleApplicantDetails = useCallback(
    async (applicantDetailsForm: WcfForm["applicantDetailsForm"]) => {
      try {
        dispatch(showLoader());
        if (formType === "Asset Finance") {
          await updateApplication(
            {
              ...assetFinancePayload,
              assetFinanceRequest: {
                currency: "GBP",
                balloonAmount: 0,
                vatPaidUpFrontAmount: vat,
                productType: productType,
                isRefinance: isRefinance,
                depositOrInitialRentalAmount: assetFinanceForm.deposit,
                assets: [
                  {
                    category: assetFinanceForm.assetType,
                    description: assetFinanceForm.purpose,
                    costPerUnit: assetFinanceForm.pricePerUnit,
                    model: assetFinanceForm.model,
                    manufacturer: assetFinanceForm.manufacturer,
                    manufactureYear:
                      assetFinanceForm.yearOfManufactured === 0
                        ? null
                        : assetFinanceForm.yearOfManufactured,
                    isInsured: assetFinanceForm.isInsured,
                    condition: assetFinanceForm.isNew,
                    numberOfUnits: assetFinanceForm.units as number,
                    vatPerUnit: getVATPerUnit(assetFinanceForm.pricePerUnit),
                  },
                ],
                duration: {
                  unit: "months",
                  amount: Number(assetFinanceForm.duration),
                },
              },
              urgency: assetFinanceForm.urgency,
              applicants:
                applicantDetailsForm && applicantDetailsForm.length > 0
                  ? applicantDetailsForm.map((applicant) => {
                      return {
                        firstName: amendValues(applicant.firstName),
                        lastName: amendValues(applicant.lastName),
                        middleName: amendValues(applicant.middleName),
                        dateOfBirth: amendValues(
                          dateFormatter(applicant.dateOfBirth as string),
                          "NaN-NaN-NaN",
                        ),
                        hasConsentedToPrivacyPolicy: applicant.privacyPolicy,
                        hasConsentedToCreditCheck: applicant.creditCheck,
                        isMainApplicant: applicant.mainApplicant === "Yes",
                        address: {
                          street: amendValues(applicant.address),
                          cityOrTown: amendValues(applicant.city),
                          countryOrState: amendValues(applicant.country),
                          country: amendValues(applicant.country),
                          postcode: amendValues(applicant.postcode),
                        },
                        companyRole: amendValues(applicant.role),
                        contactDetails: {
                          firstName: amendValues(applicant.firstName),
                          lastName: amendValues(applicant.lastName),
                          middleName: amendValues(applicant.middleName),
                          mobilePhoneNumber: amendValues(applicant.phoneNumber),
                          email: amendValues(applicant.email),
                        },
                        guarantees: {
                          can_provide_personal_guarantee: false,
                        },
                      };
                    })
                  : null,
              formStage: {
                page: 4,
                status: "Draft",
              },
            },
            applicationId,
          );
        } else {
          await updateApplication(
            {
              ...invoiceFinanceDefaultValue,
              invoiceFinanceRequest: {
                annualTurnover: invoiceFinanceForm.annualTurnover,
                currency: "GBP",
              },
              detailedFundingPurpose:
                invoiceFinanceForm.purpose === "" ? null : invoiceFinanceForm.purpose,
              urgency: invoiceFinanceForm.urgency,
              applicants:
                applicantDetailsForm && applicantDetailsForm.length > 0
                  ? applicantDetailsForm.map((applicant) => {
                      return {
                        firstName: amendValues(applicant.firstName),
                        lastName: amendValues(applicant.lastName),
                        middleName: amendValues(applicant.middleName),
                        dateOfBirth: amendValues(
                          dateFormatter(applicant.dateOfBirth as string),
                          "NaN-NaN-NaN",
                        ),
                        hasConsentedToPrivacyPolicy: applicant.privacyPolicy,
                        hasConsentedToCreditCheck: applicant.creditCheck,
                        isMainApplicant: applicant.mainApplicant === "Yes",
                        address: {
                          street: amendValues(applicant.address),
                          cityOrTown: amendValues(applicant.city),
                          countryOrState: amendValues(applicant.country),
                          country: amendValues(applicant.country),
                          postcode: amendValues(applicant.postcode),
                        },
                        companyRole: amendValues(applicant.role),
                        contactDetails: {
                          firstName: amendValues(applicant.firstName),
                          lastName: amendValues(applicant.lastName),
                          middleName: amendValues(applicant.middleName),
                          mobilePhoneNumber: amendValues(applicant.phoneNumber),
                          email: amendValues(applicant.email),
                        },
                        guarantees: {
                          can_provide_personal_guarantee: false,
                        },
                      };
                    })
                  : null,
              formStage: {
                page: 4,
                status: "Draft",
              },
            },
            applicationId,
          );
        }
      } catch (error) {
        alert.open("Error", "We were unable to process your action, please try again later.");
      } finally {
        dispatch(hideLoader());
      }
    },
    [],
  );

  return handleApplicantDetails;
};

export default useHandleApplicantDetails;
