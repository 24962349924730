import clsx from "clsx";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";

import {
  formatCurrency,
  getRTPFailureReasonMessage,
  getErrorMessageByErrorCode,
  STATUS_SETTLED,
  formatErrorMessage,
  formatDisplayedDate,
  convertReceivablesToDeliveryData,
} from "@APP/utils";
import {
  CustomerReceivablesDetailsTable,
  FooterActionsButtons,
  Page,
  ScreenHeader,
} from "@APP/components";
import {
  getAutomatedCollections,
  getBankAccounts,
  getRtpDetails,
  getUser,
  hideLoader,
  markFailureReceivable,
  markSuccessReceivable,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { createRTP } from "@APP/services/RTP";
import { ErrorCode, Receivable } from "@APP/types";
import { BANK_CONSENT_EXPIRED_ERROR_CODE } from "@APP/services/api";
import {
  useAlert,
  useHandleErrorCodes,
  useOrganisationDetails,
  useCreateInvoice,
} from "@APP/hooks";
import CONFIG from "@APP/config";
import { API_DATE_FORMAT, ErpId } from "@APP/constants";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "none",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  smallCell: {
    width: 230,
  },
}));

const BANK_ACCOUNT_USING_ERROR_CODES: ErrorCode[] = [1232, 1233];

const ReceivablesConfirmDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { updateOrganisationDetails } = useOrganisationDetails();
  const { approveReceivable } = useCreateInvoice();

  const user = useSelector(getUser);
  const bankAccounts = useSelector(getBankAccounts);
  const { receivables, receivableFailure } = useSelector(getAutomatedCollections);
  const rtpDetails = useSelector(getRtpDetails);

  const paymentAccount = bankAccounts?.find(
    (bank) => bank.account.identification === rtpDetails.deliveryDetails?.account,
  );

  const execFailureResult = () => {
    dispatch(hideLoader());
    history.push({
      pathname: SCREEN_PATHS.PAYMENT_REQUESTS_FAILURE,
    });
  };

  const execSuccessResult = () => {
    dispatch(hideLoader());
    history.push({
      pathname: SCREEN_PATHS.PAYMENT_REQUESTS_SUCCESS,
      search: `?date=${format(
        new Date(rtpDetails.deliveryDetails?.deliveryDate),
        API_DATE_FORMAT,
      )}`,
    });
  };

  const handleContinue = async () => {
    dispatch(showLoader());

    const isOrgUpdated = await updateOrganisationDetails();

    if (isOrgUpdated) {
      return dispatch(hideLoader());
    }

    try {
      await checkCreateRTPs();
    } catch (e) {
      dispatch(hideLoader());
      alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), formatErrorMessage(e), [
        { text: "Okay" },
      ]);
    }
  };

  const checkCreateRTPs = async () => {
    const createdRTPs = await Promise.allSettled(
      (receivables as Receivable[]).map(async (receivable) => {
        if (receivable.status === "Draft" && user?.erp === ErpId.INTERNAL) {
          await approveReceivable(receivable);
        }

        return await createRTP(rtpDetails, user, receivable, paymentAccount);
      }),
    );

    for (const [index, rtp] of createdRTPs.entries()) {
      if (rtp.status === STATUS_SETTLED.FULFILLED) {
        dispatch(markSuccessReceivable(receivables[index]));
      } else if (rtp.status === STATUS_SETTLED.REJECTED) {
        const invalidFields = rtp.reason?.response?.data?.invalidFields;
        const errorCode = rtp.reason?.response?.data?.errorCode;

        const isHandled = handleErrorCodes(errorCode, {
          errorCodes: [BANK_CONSENT_EXPIRED_ERROR_CODE],
        });

        if (isHandled) return;

        if (BANK_ACCOUNT_USING_ERROR_CODES.includes(errorCode)) {
          const errorMessage = getErrorMessageByErrorCode(errorCode, "using");
          alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), errorMessage, [
            { text: "Okay" },
          ]);

          return;
        }

        if (invalidFields) {
          const failureReason = getRTPFailureReasonMessage(invalidFields);
          dispatch(markFailureReceivable({ ...receivables[index], failureReason }));
        } else {
          dispatch(markFailureReceivable(receivables[index]));
        }
      }
    }

    if (!receivableFailure.length) {
      execSuccessResult();
    } else {
      execFailureResult();
    }
  };

  const handleBackNavigation = () => {
    if (rtpDetails.isRecurring) {
      return history.push(SCREEN_PATHS.RECEIVABLE_INSTALMENT_DETAILS);
    }

    history.push(SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS);
  };

  const handleClickOnCustomerName = (receivableId?: string) => () => {
    if (!receivableId) return history.push(SCREEN_PATHS.RECEIVABLE_DETAILS);

    history.push(`${SCREEN_PATHS.RECEIVABLES_LIST}/${receivableId}`);
  };

  return (
    <Page title="Create Payment Request">
      <ScreenHeader title="Confirm Payment Requests" id="receivableConfirmTitle" />
      <Box mt={3} mb={2}>
        <Typography variant="h6">
          Please check that the details below are correct prior to sending your requests.
        </Typography>
      </Box>
      <CustomerReceivablesDetailsTable
        data={convertReceivablesToDeliveryData({
          receivables,
          deliveryEmails: rtpDetails.deliveryDetails.deliveryEmails,
          isExternalErp: user?.erp !== ErpId.INTERNAL,
        })}
        handleClickOnCustomerName={handleClickOnCustomerName}
        emailTitle="Customer Email"
      />
      <Box mt={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Card elevation={12}>
              <CardContent>
                <MuiTable>
                  <TableBody>
                    {paymentAccount?.account?.identification ? (
                      <TableRow>
                        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                          Receive funds into this account
                        </TableCell>
                        <TableCell className={clsx(classes.tableCell)}>
                          {paymentAccount?.account?.identification}
                          {paymentAccount?.nickname || paymentAccount?.account.name
                            ? `/ ${paymentAccount?.nickname ?? paymentAccount?.account.name}`
                            : ""}
                        </TableCell>
                      </TableRow>
                    ) : null}

                    <TableRow>
                      <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                        Send Payment Request on
                      </TableCell>
                      <TableCell className={clsx(classes.tableCell)}>
                        {formatDisplayedDate(rtpDetails.deliveryDetails?.deliveryDate)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                        Due Date Reminder
                      </TableCell>
                      <TableCell className={clsx(classes.tableCell)}>
                        {rtpDetails.reminder ? "On" : "Off"}
                      </TableCell>
                    </TableRow>
                    {receivables.length === 1 && CONFIG.FEATURES.RTP.RECURRING_PAYMENTS ? (
                      <TableRow>
                        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                          Accept Payment In Instalment
                        </TableCell>
                        <TableCell className={clsx(classes.tableCell)}>
                          {rtpDetails.isRecurring ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </MuiTable>
              </CardContent>
              {rtpDetails.isRecurring && (
                <>
                  <Divider />
                  <Box p={2} pt={1}>
                    <MuiTable>
                      <TableBody>
                        <TableRow>
                          <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                            <Typography variant="h6" component="h6">
                              Instalment Details:
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                            Payment Amount
                          </TableCell>
                          <TableCell className={clsx(classes.tableCell)}>
                            {formatCurrency(rtpDetails.instalmentDetails.firstPaymentAmount, {
                              currency: receivables[0]?.totalAmountTaxInclusive?.currency,
                            })}
                          </TableCell>
                        </TableRow>
                        {rtpDetails.instalmentDetails.finalPaymentAmount && (
                          <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                              Final Payment
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell)}>
                              {formatCurrency(rtpDetails.instalmentDetails.finalPaymentAmount, {
                                currency: receivables[0]?.totalAmountTaxInclusive?.currency,
                              })}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                            Frequency
                          </TableCell>
                          <TableCell className={clsx(classes.tableCell)}>
                            {rtpDetails.instalmentDetails.frequency}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                            Occurrences
                          </TableCell>
                          <TableCell className={clsx(classes.tableCell)}>
                            {rtpDetails.instalmentDetails.occurrences}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                            First Payment Date
                          </TableCell>
                          <TableCell className={clsx(classes.tableCell)}>
                            {formatDisplayedDate(rtpDetails.instalmentDetails.firstPaymentDate)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </MuiTable>
                  </Box>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
      <FooterActionsButtons
        backButtonText={
          rtpDetails.isRecurring
            ? "Back to Instalment Details"
            : t("RTP.ReceivablesConfirmDetails.BackButtonNotRecurring")
        }
        handleBackButton={handleBackNavigation}
        continueButtonText="Send"
        handleContinue={handleContinue}
      />
    </Page>
  );
};

export default ReceivablesConfirmDetails;
