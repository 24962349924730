import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addDays, endOfDay, format, startOfDay } from "date-fns";
import clsx from "clsx";
import { Box, Grid, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { getForecastEndDate } from "@APP/redux";
import { CommonDatePicker, CommonTextField } from "@APP/components";
import { API_DATE_FORMAT } from "@APP/constants";
import { handleAriaActiveDescendantChange } from "@APP/utils";

export enum ForecastPeriods {
  SevenDays = "SevenDays",
  Month = "Month",
  Custom = "Custom",
}

const forecastPeriodOptions = [
  { label: "Next 7 days", key: ForecastPeriods.SevenDays },
  { label: "Next 30 days", key: ForecastPeriods.Month },
  { label: "Custom", key: ForecastPeriods.Custom },
];

type Props = {
  handleForecastPeriodChange: (value: Date) => void;
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(2),
  },
  fromField: {
    marginTop: theme.spacing(1),
    pointerEvents: "none",
  },
}));

const nextSevenDays = format(addDays(new Date(), 6), API_DATE_FORMAT);
const nextThirtyDays = format(addDays(new Date(), 29), API_DATE_FORMAT);

const ForecastPeriod = ({ handleForecastPeriodChange }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDisplaySizeLessLaptop = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const forecastEndDate = useSelector(getForecastEndDate);

  const [forecastPeriodType, setForecastPeriodType] = useState<ForecastPeriods>(
    ForecastPeriods.SevenDays,
  );

  useEffect(() => {
    switch (format(forecastEndDate, API_DATE_FORMAT)) {
      case nextSevenDays:
        return setForecastPeriodType(ForecastPeriods.SevenDays);
      case nextThirtyDays:
        return setForecastPeriodType(ForecastPeriods.Month);
      default:
        setForecastPeriodType(ForecastPeriods.Custom);
    }
  }, [forecastEndDate]);

  const onForecastPeriodChange = (period: ForecastPeriods) => {
    switch (period) {
      case ForecastPeriods.SevenDays:
        return handleForecastPeriodChange(new Date(nextSevenDays));
      case ForecastPeriods.Month:
        return handleForecastPeriodChange(new Date(nextThirtyDays));
      default:
        setForecastPeriodType(ForecastPeriods.Custom);
    }
  };

  const handleDateAccept = (date: Date | null, validationError?: string) => {
    !validationError && date && handleForecastPeriodChange(date);
  };

  return (
    <Grid container item justifyContent={isDisplaySizeLessLaptop ? "center" : "flex-end"}>
      <Grid
        container
        item
        md={7}
        xs={12}
        justifyContent={isDisplaySizeLessLaptop ? "space-between" : "flex-end"}>
        <Grid
          container
          justifyContent={isDisplaySizeLessLaptop ? "space-between" : "flex-end"}
          alignItems="center"
          direction="row"
          spacing={1}>
          <Grid item>
            <Box textAlign={isDisplaySizeLessLaptop ? "left" : "right"}>
              <Typography variant="h5">Forecast Period:</Typography>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <CommonTextField
              className={classes.inputField}
              fullWidth
              select
              data-testid="forecast-period-select"
              name="forecastPeriod"
              size="small"
              value={forecastPeriodType}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onForecastPeriodChange(e.target.value as ForecastPeriods)
              }
              variant="outlined"
              label="Forecast Period"
              hiddenLabel
              id="forecast-period-select"
              inputProps={{ id: "forecast-period-select" }}
              defaultValue={nextSevenDays}
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    "aria-activedescendant": `forecast-period-option-${forecastPeriodType}`,
                    onFocus: handleAriaActiveDescendantChange,
                  },
                },
              }}>
              {forecastPeriodOptions.map(({ label, key }) => (
                <MenuItem key={key} value={key} id={`forecast-period-option-${key}`}>
                  {label}
                </MenuItem>
              ))}
            </CommonTextField>
          </Grid>
        </Grid>
        <Grid container item direction="row" mt={isDisplaySizeLessLaptop ? 0 : 1}>
          <Grid container item justify-content="space-between" spacing={1} direction="row">
            <Grid item md={4} xs={false} />
            <Grid item md={4} xs={12}>
              <CommonDatePicker
                disabled
                className={classes.fromField}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                label="From"
                value={new Date()}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CommonDatePicker
                className={clsx(classes.inputField, "keyboardDatePicker")}
                value={new Date(forecastEndDate)}
                onAccept={handleDateAccept}
                onFieldBlur={(value, error) => handleDateAccept(value, error)}
                slotProps={{
                  textField: {
                    name: "forecastEndDate",
                    margin: "dense",
                    id: "forecast-period-date-picker",
                  },
                  popper: { placement: "bottom-end" },
                }}
                maxDate={addDays(endOfDay(new Date()), 89)}
                minDate={startOfDay(new Date())}
                label="To"
                data-testid="forecast-period-end-date"
                aria-label="forecast period date picker"
                maxDateMessage={t(
                  "Errors.CashflowForecasting.Validation.ForecastRange.ToDatePeriod",
                )}
                minDateMessage={t(
                  "Errors.CashflowForecasting.Validation.ForecastRange.ToDateAfter",
                )}
                ariaOpenDialogLabelPrefix="Choose forecast To Date"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForecastPeriod;
