import palette from "@APP/styles/theme/default/palette";
import { Card, CardProps, SimplePaletteColorOptions } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  optionCard: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "16px",
    alignItems: "center",
    height: "44px",
    width: "22%",
    borderRadius: "5%",
    cursor: "pointer",
  },
  bigCard: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "16px",
    alignItems: "center",
    height: "100px",
    width: "300px",
    border: "1px solid #D9D9D9",
    cursor: "pointer",
  },
}));

function OptionCard({
  id,
  label,
  selected = false,
  variant = "sm",
  props,
}: Readonly<{
  id?: string;
  label: string | React.ReactNode;
  selected?: boolean;
  variant?: "sm" | "md";
  props?: CardProps;
}>) {
  const classes = useStyles();
  const isSmall = variant === "sm";
  const isSelectedSm = selected
    ? `2px solid ${(palette.primary as SimplePaletteColorOptions)?.main}`
    : "2px solid #ccc";
  const isSelectedMd = selected ? "#D9D9D9" : "#fff";
  return (
    <Card
      id={id}
      elevation={0}
      className={isSmall ? classes.optionCard : classes.bigCard}
      sx={{
        border: isSmall ? isSelectedSm : "1px solid #D9D9D9",
        background: isSmall ? "#fff" : isSelectedMd,
      }}
      {...props}>
      {label}
    </Card>
  );
}

export default OptionCard;
