import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LinkOffRoundedIcon from "@mui/icons-material/LinkOffRounded";

import { BankAccountExtended } from "@APP/types";
import {
  NO_LINKED_BANK_ACCOUNTS_EXIST_ERROR_CODE,
  UNLINK_MX_BANK_ACCOUNT_ERROR_CODE,
} from "@APP/services/api/errorCodes";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { fetchUserData, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import CONFIG from "@APP/config";
import { API } from "@APP/services";

import { useStyles } from "../styles";
import YourBankAccounts, { BankAccountsAction } from "../YourBankAccounts";
import MxWidgetIframe, { MX_OAUTH_ERROR_REASONS } from "./MxWidgetIframe";

type Props = {
  onCompleteRegistration: () => void;
};

const LinkMxBankAccounts = ({ onCompleteRegistration }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();

  const [isShowMxWidget, setIsShowMxWidget] = useState(false);

  const subscriptionMessage = useRef("");

  const handleErrorByDefault = () => {
    alert.open(
      t("Errors.Common.Alerts.AlertTitles.Failure"),
      t("Errors.Common.Messages.UserErrorPage"),
      [{ text: "Okay", onClick: () => history.push(SCREEN_PATHS.DASHBOARD) }],
    );
  };

  const onLinkSuccess = async (bankName: string) => {
    dispatch(showLoader());

    try {
      setIsShowMxWidget(false);

      await dispatch(fetchUserData());

      dispatch(hideLoader());

      return alert.open(
        "Success",
        `Your ${bankName} bank account(s) have been successfully linked.${subscriptionMessage.current}`,
        [{ text: "Okay" }],
      );
    } catch (error) {
      dispatch(hideLoader());

      const errorCode = error?.response?.data?.errorCode;

      const isHandled = handleErrorCodes(errorCode);

      if (!isHandled) handleErrorByDefault();
    }
  };

  const onOAuthError = (errorReason: MX_OAUTH_ERROR_REASONS) => {
    let errorTitle = t("Errors.Common.Alerts.AlertTitles.Error");
    let errorDescription = t("Errors.Common.Alerts.Generic.Message");

    if (
      errorReason === MX_OAUTH_ERROR_REASONS.DENIED ||
      errorReason === MX_OAUTH_ERROR_REASONS.CANCELLED
    ) {
      errorTitle = t("Errors.BankAccountLinking.Alerts.AuthFail.Title");
      errorDescription = t("Errors.BankAccountLinking.Alerts.AuthFail.Message");
    }

    alert.open(errorTitle as string, errorDescription);
    setIsShowMxWidget(false);
  };

  const onLinkBankAccountsError = () => {
    alert.open(
      t("Errors.Common.Alerts.AlertTitles.Failure"),
      t("Errors.ErrorCodes.1230", { SUPPORT_EMAIL: CONFIG.SUPPORT_EMAIL }),
    );
    setIsShowMxWidget(false);
  };

  const onDeleteBankAccountsConsent = async (accounts: BankAccountExtended[]) => {
    try {
      dispatch(showLoader());

      await API.deleteMxBankAccountsConsent(accounts[0].accountId);

      await dispatch(fetchUserData());

      dispatch(hideLoader());

      alert.open("", "Your bank consent was successfully revoked.", [{ text: "Okay" }]);
    } catch (error) {
      dispatch(hideLoader());

      const errorCode = error?.response?.data?.errorCode;

      const isHandled = handleErrorCodes(errorCode, {
        errorCodes: [UNLINK_MX_BANK_ACCOUNT_ERROR_CODE],
      });

      if (errorCode === NO_LINKED_BANK_ACCOUNTS_EXIST_ERROR_CODE || isHandled) {
        return;
      }

      handleErrorByDefault();
    }
  };

  const bankAccountsActions: BankAccountsAction[] = useMemo(() => {
    return [
      {
        title: "Unlink Bank Accounts",
        icon: <LinkOffRoundedIcon color="primary" />,
        action: (accounts) =>
          alert.open("", "Are you sure you want to unlink these bank accounts?", [
            { text: "Confirm", onClick: () => onDeleteBankAccountsConsent(accounts) },
            { text: "Cancel" },
          ]),
      },
    ] as BankAccountsAction[];
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.gridBankAccounts}>
        <YourBankAccounts actions={bankAccountsActions} />
      </Grid>
      <Grid item xs={12}>
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setIsShowMxWidget((prevState) => !prevState)}
            id="linkMxBankAccountsButton">
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              aria-expanded={isShowMxWidget}
              aria-controls="mx-widget-iframe">
              Select institution to link accounts
              {isShowMxWidget ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Box>
          </Button>
          {isShowMxWidget && (
            <Box
              mt={2}
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
              id="mx-widget-iframe">
              <Box minWidth={320} maxWidth={700} width="100%">
                <MxWidgetIframe
                  onLinkSuccess={onLinkSuccess}
                  onOAuthError={onOAuthError}
                  onError={onLinkBankAccountsError}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LinkMxBankAccounts;
