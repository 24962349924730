import CONFIG from "@APP/config";
import {
  BankAccount,
  BankConsentData,
  BankConsentInitData,
  CardPaymentApp,
  CoPRequest,
  ReconsentData,
} from "@APP/types";
import request from "../request";

/*
 ------- Moneyhub integration -------
*/

export const getMoneyhubBankAccounts = async (bankId: string) => {
  const response = await request<{ data: BankAccount[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bankId}/accounts`,
  });
  return response.data;
};

export const getBankConsents = (bankId: string) =>
  request<BankConsentData[]>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bankId}/consent`,
  });

export const initiateBankConsent = (
  bankId: string,
  redirectUrl: string,
  permissions: string[] = [],
) => {
  return request<BankConsentInitData>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bankId}/consent`,
    data: {
      permissions,
      custodianId: bankId,
    },
    params: {
      redirectUrl: redirectUrl,
    },
  });
};

export const authorizeBankConsent = (
  bankId: string,
  consentId: string,
  consentCode: string,
  sub: string,
  redirectUrl: string,
  nonce?: string,
) => {
  return request<BankConsentData>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bankId}/consent/${consentId}/authorize`,
    params: {
      code: consentCode,
      sub,
      redirectUrl,
      nonce,
    },
    timeout: 60000,
  });
};

export const getAllMoneyhubBanksAccounts = async () => {
  const response = await request<{ data: BankAccount[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/accounts`,
  });
  return response.data;
};

export const getBankReconsents = async () => {
  const response = await request<{ data: ReconsentData[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/reconsents`,
  });
  return response.data;
};

export const updateBankConsents = async (connectionIds: string[]) => {
  await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/reconsents`,
    data: {
      connectionIds,
    },
  });
};

export const revokeBankConsents = async (consentIds: string[]) => {
  await request<{ data: ReconsentData[] }>({
    method: "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/reconsents`,
    data: {
      consentIds,
      deleteData: true,
    },
  });
};

/*
  ------- MX integration -------
*/

export const getMxWidgetUrl = async (colorScheme: "dark" | "light" = "light") => {
  const response = await request<{ url: string }>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/connect`,
    params: {
      colorScheme,
    },
  });

  return response.data;
};

export const getMxBankAccounts = async () => {
  const response = await request<{ data: BankAccount[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/accounts`,
  });

  return response.data;
};

export const deleteMxBankAccountsConsent = async (accountId: string) => {
  await request({
    method: "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/accounts/${accountId}/delete`,
  });
};

/*
  ------- Maverick integration -------
*/

export const getMaverickApp = async () => {
  const response = await request<CardPaymentApp>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/maverick/onboarding`,
  });

  return response.data;
};

export const getMaverickWidgetUrl = async () => {
  const response = await request<{ url: string }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/maverick/onboarding/url`,
  });

  return response.data;
};

/*
  ------- Confirmation of Payee integration -------
*/

export const registerMetroBankAccount = async (payload: CoPRequest) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/metro/accounts`,
    data: payload,
  });

  return response.data;
};

export const getMetroPBankAccounts = async () => {
  const response = await request<{ data: BankAccount[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/metro/accounts`,
  });

  return response.data;
};

export const deleteMetroBankAccount = async () => {
  const response = await request({
    method: "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/metro/accounts`,
  });
  return response;
};
