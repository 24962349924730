const INVALID_FIELDS_NAMING = {
  "customer.mobile": "customer mobile phone number",
  "customer.email": "customer email address",
  "customer.name": "customer name",
  "supplier.mobile": "mobile phone number",
  "supplier.email": "email address",
  "supplier.name": "name",
  "supplier.companyInfo.mobile": "mobile phone number",
  "supplier.companyInfo.email": "email address",
  "supplier.companyInfo.name": "name",
  "supplier.primaryContact.mobile": "mobile phone number",
  "supplier.primaryContact.email": "email address",
  "supplier.primaryContact.name": "name",
  "supplier.otherContacts.mobile": "mobile phone number",
  "supplier.otherContacts.email": "email address",
  "supplier.otherContacts.name": "name",
  "lineItem.amount": "line item amount",
  "amount.amount": "Your payments do not match the invoice amount.",
  "address.addressLines": "customer address line",
  "address.postCode": "customer postcode",
  "address.state": "customer state",
  "address.city": "customer city",
};

export const getFieldsValidationErrorParts = (
  invalidFields: Array<{ fieldName: keyof typeof INVALID_FIELDS_NAMING }>,
) => invalidFields.map((errorField) => INVALID_FIELDS_NAMING[errorField.fieldName]).join(", ");

export const getRTPFailureReasonMessage = (
  invalidFields: Array<{ fieldName: keyof typeof INVALID_FIELDS_NAMING }>,
) => {
  const invalidInvoiceAmountIncluded = invalidFields.find(
    (errorField) => errorField.fieldName === "amount.amount",
  );

  const invalidFieldsWithoutInvoiceAmount = invalidFields.filter(
    (invalidField) => invalidField.fieldName !== "amount.amount",
  );

  let errorMessage = !!invalidFieldsWithoutInvoiceAmount.length
    ? `Invalid ${getFieldsValidationErrorParts(invalidFieldsWithoutInvoiceAmount)}.`
    : "";

  if (!!invalidInvoiceAmountIncluded) {
    errorMessage += `${!!errorMessage ? "\n" : ""}${INVALID_FIELDS_NAMING["amount.amount"]}`;
  }

  return errorMessage;
};
