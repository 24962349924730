import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Page, ScreenHeader, ScreenHeaderSubtitle } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getDashboardAppLoading,
  getPayable,
  getUser,
  hideLoader,
  setSelectedPayableData,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { InvoiceType } from "@APP/types";
import { API } from "@APP/services";
import { ErpId } from "@APP/constants";
import { useAlert } from "@APP/hooks";
import PayableDetailsForm from "./PayableDetailsForm";

const PayableDetails = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const { t } = useTranslation();
  const { payableId }: { payableId: string } = useParams();

  const user = useSelector(getUser);
  const isLoading = useSelector(getDashboardAppLoading);
  const { selectedPayable: payable } = useSelector(getPayable);

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      try {
        const payable = await API.getInvoice(user?.erp as ErpId, InvoiceType.Payables, payableId);
        dispatch(setSelectedPayableData(payable));
      } catch (error) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          t("Errors.OutboundPayments.Alerts.GetInvoice.Message"),
          [
            {
              text: "Okay",
              onClick: () => history.push(SCREEN_PATHS.PAYABLES_LIST),
            },
          ],
        );
        console.log(error);
      }
      dispatch(hideLoader());
    })();
  }, []);

  if (!payable && isLoading) {
    return null;
  }

  return (
    <Page title="Supplier Invoice">
      <ScreenHeader title="Supplier Invoice" id="payableDetailsTitle" />
      <ScreenHeaderSubtitle subtitle="View Invoice" />

      <PayableDetailsForm payable={payable} />
    </Page>
  );
};

export default PayableDetails;
