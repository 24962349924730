import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Helmet } from "react-helmet";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setDefaultOptions } from "date-fns";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RootComponent from "./RootComponent";

import { store, persistor } from "@APP/redux";
import { history } from "@APP/navigation";
import { AlertProvider } from "@APP/hooks/useAlert";
import { GlobalStyles, themeOptions } from "@APP/styles";
import { LOCALES } from "@APP/constants";
import Config from "@APP/config";

import IdleTimeout from "./hooks/useIdleTimer";

setDefaultOptions({
  locale: LOCALES[Config.DATE_LOCALE],
});

export default function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={LOCALES[Config.DATE_LOCALE]}>
        <PersistGate loading={null} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createTheme(themeOptions)}>
              {Config.SERVICES.CLARITY?.ID && (
                <Helmet>
                  <script type="text/javascript">
                    {`
                    (function(c,l,a,r,i,t,y){
                      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "${Config.SERVICES.CLARITY.ID}");
                  `}
                  </script>
                </Helmet>
              )}
              <GlobalStyles />
              <Router history={history}>
                <AlertProvider>
                  <IdleTimeout idleTime={30} />
                  <RootComponent />
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    theme="light"
                    pauseOnHover
                    toastStyle={{
                      backgroundColor: "#158ECE",
                      color: "#f5f6f8",
                    }}
                  />
                </AlertProvider>
              </Router>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </LocalizationProvider>
    </Provider>
  );
}
