import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import enGB from "@APP/i18n/enGB.json";
import enUS from "@APP/i18n/enUS.json";
import CONFIG from "@APP/config";
import partner from "@APP/i18n/partner.json";

export const translations: { [key: string]: typeof enGB } = {
  enGB: enGB,
  enUS: enUS,
};

export const resources = {
  [CONFIG.LANGUAGE]: { translation: translations[CONFIG.LANGUAGE], partner },
} as const;

/*
if a key is present in both the language and partner json files, the key in the partner file will be used.
this is useful for overriding translations for a specific partner.
you can write the key as:
"CashFlowForecast": {
    "ScreenSubheader": "Some Sample Text"
}

alternatively you can write the key as:
"CashFlowForecast.ScreenSubheader": "Some Sample Text"
*/

export const init = async () => {
  return i18next.use(initReactI18next).init({
    ns: ["translation", "partner"],
    defaultNS: "partner",
    fallbackNS: "translation",
    lng: CONFIG.LANGUAGE,
    debug: process.env.NODE_ENV === "development",
    resources,
  });
};

export default { init };
