import Joi from "joi";

export const applicantSchema = Joi.object({
  firstName: Joi.string().max(50).required(),
  middleName: Joi.string().allow(null).max(50),
  lastName: Joi.string().max(50).required(),
  dateOfBirth: Joi.string().isoDate().required(),
  hasConsentedToPrivacyPolicy: Joi.boolean().valid(true).required(),
  hasConsentedToCreditCheck: Joi.boolean().valid(true).required(),
  isMainApplicant: Joi.boolean().required(),
  address: Joi.object({
    street: Joi.string().max(100).required(),
    cityOrTown: Joi.string().max(100).required(),
    countyOrState: Joi.string().allow(null).max(100),
    postcode: Joi.string()
      .regex(
        /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Za-z][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
      )
      .required(),
    country: Joi.string().valid("GB", null).required(),
  }).unknown(),
  companyRole: Joi.string()
    .valid("director", "shareholder", "business_owner", "company_secretary", "partner", "other")
    .required(),
  contactDetails: Joi.object({
    mobilePhoneNumber: Joi.string()
      .pattern(/^(\+44|0)\d{10}$/)
      .required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .pattern(/^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/)
      .max(100)
      .required(),
  }),
  guarantees: Joi.object({
    can_provide_personal_guarantee: Joi.boolean().required(),
  }).required(),
});
