import React from "react";

function AssetFinancingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 66 70">
      <path
        fill="currentColor"
        d="M54.175 37.624L38.5 54.249h-6.325V47.54L47.85 30.915l6.325 6.709zm9.35-2.334c0 .875-.825 1.75-1.65 2.625L55 45.207l-2.475-2.625 7.15-7.583-1.65-1.75L56.1 35.29l-6.325-6.708 6.05-6.125c.55-.583 1.65-.583 2.475 0l3.85 4.083c.55.584.55 1.75 0 2.625-.55.584-1.1 1.167-1.1 1.75 0 .584.55 1.167 1.1 1.75.825.875 1.65 1.75 1.375 2.625zM8.25 58.332V11.665H27.5V26.25h13.75v4.375l5.5-5.834v-1.458l-16.5-17.5h-22c-3.025 0-5.5 2.625-5.5 5.833v46.667c0 3.208 2.475 5.833 5.5 5.833h33c3.025 0 5.5-2.625 5.5-5.833H8.25zm22-8.458c-.55 0-1.1.291-1.375.291L27.5 43.75h-4.125L17.6 48.707l1.65-7.875h-4.125l-2.75 14.583H16.5l7.975-7.583 1.65 6.708h2.75l1.375-.291v-4.375z"></path>
    </svg>
  );
}

export default AssetFinancingIcon;
