import { useEffect, useMemo, useState } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import { AppState } from "@APP/redux";
import { useFeatureGateway } from "@APP/hooks";

import { RouteConfig } from "./routes";
import { SCREEN_PATHS } from "./paths";
import { RouteProtector } from "./protectors";

interface Props extends RouteConfig {
  appState: AppState;
}

/**
 * Extended Route component.
 * Handles route protection for a give `protector` property.
 * Performs the appropriate redirect based on the "route protection" result, if necessary.
 */
const AppRoute = ({
  appState,
  path,
  redirect,
  routes,
  component: Component,
  children,
  protector,
  ...rest
}: Props) => {
  const gateway = useFeatureGateway();
  const [protectorRenderResultState, setProtectorRenderResultState] = useState<
    JSX.Element | null | undefined
  >();

  /**
   * Handles route protection.
   * Specifies the appropriate redirect based on the invocation of "route protector" function.
   * @param protectorFn - RouteProtector function.
   * @returns Redirect component or null.
   */
  const handleRouteProtector = async (protectorFn: RouteProtector) => {
    if (typeof protectorFn === "function") {
      const protectionResult = await protectorFn({
        state: appState,
        location: (rest as any).location as RouteComponentProps["location"],
        match: (rest as any).computedMatch as RouteComponentProps["match"],
        gateway,
      });

      if (typeof protectionResult === "string") {
        return <Redirect push={false} to={protectionResult} />;
      } else if (protectionResult === false) {
        return <Redirect push={false} to={SCREEN_PATHS.LOGOUT} />;
      }
    }
    return null;
  };

  const protectorRenderResult = useMemo(async () => {
    if (protector) {
      if (Array.isArray(protector)) {
        for (const fn of protector) {
          const result = await handleRouteProtector(fn);

          if (result) return result;
        }
      } else {
        return handleRouteProtector(protector);
      }
    }

    return null;
  }, []);

  // In case the protector function returned a Promise, wait until it settles and save the result.
  useEffect(() => {
    protectorRenderResult?.then((result) => setProtectorRenderResultState(result));
  }, [protectorRenderResult]);

  /**
   * Prevent rendering the page until protector function is executed and the result is saved (in case of async protector).
   */
  if (protectorRenderResultState === undefined) return null;

  return (
    protectorRenderResultState || (
      <Route
        path={path}
        children={children}
        render={(props) =>
          redirect ? (
            <Redirect push={false} to={redirect} />
          ) : (
            Component && <Component {...props} routes={routes} />
          )
        }
      />
    )
  );
};

const mapStateToProps = (appState: AppState) => ({ appState });

export default connect(mapStateToProps)(AppRoute);
