import React, { ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";

const Center = ({ children, ...props }: { children: ReactNode } & BoxProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" {...props}>
      {children}
    </Box>
  );
};

export default Center;
