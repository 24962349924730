import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppState, fetchCardPaymentAppDetails, useAppDispatch } from "@APP/redux";
import { useAlert } from "@APP/hooks/index";
import { SCREEN_PATHS } from "@APP/navigation";
import { TabsName } from "@APP/constants";
import { Provider } from "@APP/constants";
import { CardPaymentApplicationStatus } from "@APP/types";
import CONFIG from "@APP/config";

const INCOMPLETE_CARD_PAYMENT_APP_STATUSES = [
  CardPaymentApplicationStatus.New,
  CardPaymentApplicationStatus.Pending,
];
const CHECK_CARD_PAYMENT_APP_STATUSES = [
  CardPaymentApplicationStatus.Underwriting,
  CardPaymentApplicationStatus.Declined,
  CardPaymentApplicationStatus.Cancelled,
];

const useCheckCardPaymentAppAvailable = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigateOnSetupCardPaymentApp = useCallback(() => {
    history.push(SCREEN_PATHS.SETUP_PAYMENT_APPLICATION);
  }, []);

  const navigateToCheckCardPaymentStatus = useCallback(() => {
    history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.CARD_PAYMENTS}`);
  }, []);

  const checkCardPaymentAppAvailable = useCallback(
    async (state?: AppState) => {
      if (Provider.isMoneyhub || CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE) return true;

      try {
        const cardPaymentApp = state
          ? state.auth.user?.cardPaymentApp
          : await dispatch(fetchCardPaymentAppDetails());

        if (
          !cardPaymentApp ||
          INCOMPLETE_CARD_PAYMENT_APP_STATUSES.includes(cardPaymentApp?.status)
        ) {
          alert.open(
            t("Errors.Admin.Alerts.MaverickEnable.Title"),
            t("Errors.Admin.Alerts.MaverickEnable.Message"),
            [
              {
                text: "Complete payment application",
                onClick: navigateOnSetupCardPaymentApp,
              },
            ],
            { persistent: true },
          );

          return false;
        } else if (CHECK_CARD_PAYMENT_APP_STATUSES.includes(cardPaymentApp?.status)) {
          alert.open(
            "Unable to make or accept payments",
            "In order to request or make a payment you need to be approved via our partner Maverick, please check your current payment status.",
            [
              {
                text: "Check payment status",
                onClick: navigateToCheckCardPaymentStatus,
              },
            ],
            { persistent: true },
          );

          return false;
        }

        return true;
      } catch (error) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          t("Errors.Admin.Alerts.PaymentApplicationStatus.Message"),
        );
      }
    },
    [navigateOnSetupCardPaymentApp],
  );

  return checkCardPaymentAppAvailable;
};

export default useCheckCardPaymentAppAvailable;
