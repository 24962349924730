import { createReducer } from "deox";

import { CompanyType } from "@APP/constants";
import { GetCompanyByKeywordType, GetDirectorsByRegistrationNumberType } from "@APP/types";

import {
  setRegisteredCompanySearchingValue,
  setSelectedRegisteredCompany,
  setSelectedRegisteredDirector,
  setSelectedCompanyType,
  setOtherOrgTypesFirstName,
  setOtherOrgTypesLastName,
  setOtherOrgTypesBusinessName,
  setBusinessType,
  setReviewPage,
} from "../actions/registeredCompany";

interface OtherOrgTypes {
  firstName: string;
  lastName: string;
  businessName: string;
}

interface SelectedRegisteredCompany {
  selectedCompany: GetCompanyByKeywordType | null;
  selectedDirector: GetDirectorsByRegistrationNumberType | null;
  searchingValue: string;
}

interface RegisteredCompanyState {
  otherOrgTypes: OtherOrgTypes;
  selectedRegisteredCompany: SelectedRegisteredCompany;
  selectedCompanyType: CompanyType | null;
  businessType: string;
  isReviewForm: boolean;
}

const registeredCompanyDefaultState: RegisteredCompanyState = {
  otherOrgTypes: {
    firstName: "",
    lastName: "",
    businessName: "",
  },
  selectedRegisteredCompany: {
    selectedCompany: null,
    selectedDirector: null,
    searchingValue: "",
  },
  selectedCompanyType: null,
  businessType: "",
  isReviewForm: false,
};

const registeredCompanyReducer = createReducer(registeredCompanyDefaultState, (handleAction) => [
  handleAction(setOtherOrgTypesFirstName, (state, { payload }) => ({
    ...state,
    otherOrgTypes: {
      ...state.otherOrgTypes,
      firstName: payload,
    },
  })),
  handleAction(setOtherOrgTypesLastName, (state, { payload }) => ({
    ...state,
    otherOrgTypes: {
      ...state.otherOrgTypes,
      lastName: payload,
    },
  })),
  handleAction(setOtherOrgTypesBusinessName, (state, { payload }) => ({
    ...state,
    otherOrgTypes: {
      ...state.otherOrgTypes,
      businessName: payload,
    },
  })),
  handleAction(setSelectedRegisteredCompany, (state, { payload }) => ({
    ...state,
    selectedRegisteredCompany: {
      ...state.selectedRegisteredCompany,
      selectedCompany: payload,
    },
  })),
  handleAction(setSelectedRegisteredDirector, (state, { payload }) => ({
    ...state,
    selectedRegisteredCompany: {
      ...state.selectedRegisteredCompany,
      selectedDirector: payload,
    },
  })),
  handleAction(setSelectedCompanyType, (state, { payload }) => ({
    ...state,
    selectedCompanyType: payload,
  })),
  handleAction(setRegisteredCompanySearchingValue, (state, { payload }) => ({
    ...state,
    selectedRegisteredCompany: {
      ...state.selectedRegisteredCompany,
      searchingValue: payload,
    },
  })),
  handleAction(setBusinessType, (state, { payload }) => ({
    ...state,
    businessType: payload,
  })),
  handleAction(setReviewPage, (state, { payload }) => ({
    ...state,
    isReviewForm: payload,
  })),
]);

export default registeredCompanyReducer;
