import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

import { SCREEN_PATHS } from "@APP/navigation";
import { fetchUserData, getBankAccounts, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { API } from "@APP/services";
import {
  COP_ACCOUNT_ALREADY_LINKED_ERROR_CODE,
  COP_CLOSE_MATCH_ERROR_CODE,
  COP_INVALID_ACCOUNT_TYPE_ERROR_CODE,
  COP_NO_MATCH_ERROR_CODE,
} from "@APP/services/api";
import { getOnlyDigits } from "@APP/utils";
import { CoPRequest } from "@APP/types";

import YourBankAccounts from "../YourBankAccounts";
import LinkCoPBankAccountForm from "./LinkCoPBankAccountForm";

type Props = {
  onCompleteRegistration: () => void;
};

export const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    paddingBottom: theme.spacing(3),
  },
  dialogTitle: {
    ...theme.typography.h4,
  },
}));

export type CoPFormValues = CoPRequest & {
  sortCodeConfirm: string;
  accountHolderNameConfirm: string;
  accountNumberConfirm: string;
};

const LinkCoPBankAccount = ({ onCompleteRegistration }: Props) => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const bankAccounts = useSelector(getBankAccounts);

  const onFormSubmit = async ({ accountNumber, sortCode, accountHolderName }: CoPFormValues) => {
    dispatch(showLoader());
    try {
      await API.registerMetroBankAccount({
        accountNumber,
        sortCode: getOnlyDigits(sortCode),
        accountHolderName,
      });
      // need to refetch user data to ensure the bank account is linked and the user is redirected to the correct screen
      await dispatch(fetchUserData());
      alert.open("Success", "Your Metro bank account has been successfully validated.", [
        { text: "Okay", onClick: onCompleteRegistration },
      ]);
    } catch (error) {
      const errorData = error?.response?.data;
      const errorCode = errorData?.errorCode;

      const isHandled = handleErrorCodes(errorCode, {
        errorMessage: errorData?.errorMessage,
      });

      if (errorCode === COP_CLOSE_MATCH_ERROR_CODE) {
        history.replace(SCREEN_PATHS.COP_APPLICATION_PENDING);
      }
      if ([COP_NO_MATCH_ERROR_CODE, COP_INVALID_ACCOUNT_TYPE_ERROR_CODE].includes(errorCode)) {
        history.replace(SCREEN_PATHS.COP_INVALID_ACCOUNT_TYPE);
      }

      // if the user has already linked a bank account:
      // refetch the user data to ensure the bank account is linked and the user is redirected to the correct SCREEN_PATHS
      if (errorCode === COP_ACCOUNT_ALREADY_LINKED_ERROR_CODE) await dispatch(fetchUserData());

      if (isHandled) return;

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const renderMainContent = () =>
    bankAccounts ? <YourBankAccounts /> : <LinkCoPBankAccountForm onFormSubmit={onFormSubmit} />;

  return <>{renderMainContent()}</>;
};

export default LinkCoPBankAccount;
