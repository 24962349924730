import { useMemo } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { RTP_STATUS_COLOR } from "@APP/constants";
import { RTP, RTPStatus } from "@APP/types";
import { filterRtpsByStatus } from "@APP/utils";

import ListWithError from "../InvoiceTableInformation/ListWithError";

const useStyles = makeStyles(() => ({
  paymentRequestChartCard: {
    minHeight: 500,
    height: "100%",
  },
}));

const CustomLegend = ({ payload }: TooltipProps<ValueType, NameType>) => (
  <ul>
    {payload?.map((entry: any, index: number) => (
      <li key={`item-${index}`} style={{ color: entry.color }}>
        <Typography data-testid={`rtp-chart-legend-item-${index}`}>
          {`${entry.value} - ${entry.payload.value} (${(entry.payload.percent * 100).toFixed(0)}%)`}
        </Typography>
      </li>
    ))}
  </ul>
);

const CustomTooltip = ({ payload, active }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Box border="solid 1px silver" bgcolor="white" p={1}>
        <Typography>{`${payload[0].name} - ${payload[0].value}`}</Typography>
      </Box>
    );
  }

  return null;
};

type Props = {
  rtps: Array<RTP> | null;
  onTryAgain: () => void;
  errorCodeMessage?: string;
};

const PaymentRequestsChart = ({ rtps, errorCodeMessage, onTryAgain }: Props) => {
  const classes = useStyles();

  const pieData = useMemo(() => {
    return [
      {
        name: "Paid",
        value: rtps ? filterRtpsByStatus(rtps, RTPStatus.PaymentReceived).length : 0,
        color: RTP_STATUS_COLOR.PaymentReceived,
      },
      {
        name: "Request Made",
        value: rtps ? filterRtpsByStatus(rtps, RTPStatus.RequestMade).length : 0,
        color: RTP_STATUS_COLOR.RequestMade,
      },
      {
        name: "Scheduled",
        value: rtps ? filterRtpsByStatus(rtps, RTPStatus.Scheduled).length : 0,
        color: RTP_STATUS_COLOR.Scheduled,
      },
      {
        name: "Declined",
        value: rtps ? filterRtpsByStatus(rtps, RTPStatus.Declined).length : 0,
        color: RTP_STATUS_COLOR.Declined,
      },
    ];
  }, [rtps]);

  return (
    <Card className={classes.paymentRequestChartCard} elevation={4}>
      <CardHeader
        title="Your payment requests"
        titleTypographyProps={{ "data-testid": "rtp-chart-head-label", component: "h4" }}
        id="rtp-chart-title"
      />
      <CardContent>
        {rtps ? (
          <ResponsiveContainer width="100%" height={450} aria-label="payment request pie chart">
            {rtps.length ? (
              <PieChart>
                <Pie
                  data={pieData}
                  color="#000000"
                  dataKey="value"
                  nameKey="name"
                  legendType="wye"
                  cy={160}
                  innerRadius={70}
                  outerRadius={100}
                  startAngle={90}
                  endAngle={450}
                  fill="#8884d8">
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                      aria-label={`${entry.name} pie chart segment`}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  layout="vertical"
                  align="center"
                  verticalAlign="bottom"
                  content={<CustomLegend />}
                />
              </PieChart>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                height="100%">
                <Typography variant="body1" data-testid="chart-text-no-rtps">
                  There are no Payment Requests yet.
                </Typography>
              </Box>
            )}
          </ResponsiveContainer>
        ) : (
          <ListWithError
            errorCodeMessage={errorCodeMessage}
            dataType="rtps"
            onTryAgain={onTryAgain}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default PaymentRequestsChart;
