import React from "react";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import { Box, Card, IconButton, Link, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CommonTextField, Table } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";
import { ICustomerReceivablesDetails } from "@APP/types";

import { IReceivablesDeliveryAndContactDetailsForm } from "../ReceivablesDeliveryAndContactDetails";

const useStyles = makeStyles((theme) => ({
  deleteCell: {
    textAlign: "center",
    maxWidth: 30,
  },
  deleteIcon: {
    cursor: "Pointer",
  },
  linkTitle: {
    ...theme.typography.body1,
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  data: IReceivablesDeliveryAndContactDetailsForm["data"];
  errors: FormikErrors<IReceivablesDeliveryAndContactDetailsForm>;
  touched: FormikTouched<IReceivablesDeliveryAndContactDetailsForm>;
  handleChange: (e: React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
  onValueChange: (field: string, value: any, shouldValidate?: boolean) => void;
  saveEmail: (externalId: string, email: string) => void;
  removeReceivable: (externalId: string) => () => void;
};

const ReceivablesContactInfo = ({
  data,
  errors,
  touched,
  handleChange,
  onValueChange,
  removeReceivable,
  saveEmail,
  handleBlur,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const areUniqueReferencesEmpty = data.every((item) => !item.reference);

  const getEmailFieldError = (i: number) => {
    return errors?.data && (errors.data[i] as ICustomerReceivablesDetails)?.email;
  };

  const getEmailFieldTouched = (i: number) => {
    return touched?.data && touched.data[i] && touched.data[i].email;
  };

  const handleBlurEmail = (i: number) => (e: React.FocusEvent) => {
    handleBlur(e);
    const email = Boolean(getEmailFieldError(i)) ? "" : data[i].email!;
    saveEmail(data[i].id, email.toLowerCase());
  };

  const handleRowClick = (receivableId: string | null) => () => {
    if (!receivableId) return history.push(SCREEN_PATHS.RECEIVABLE_DETAILS);

    history.push(`${SCREEN_PATHS.RECEIVABLES_LIST}/${receivableId}`);
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell component="td" />
      <TableCell data-testid="customer-name-field-label">Customer Name</TableCell>
      <TableCell data-testid="customer-email-field-label">Customer Email</TableCell>
      <TableCell data-testid="amount-field-label">Amount</TableCell>
      {!areUniqueReferencesEmpty && (
        <TableCell data-testid="unique-reference-field-label">Unique Reference</TableCell>
      )}
      <TableCell data-testid="invoice-due-date-field-label">Invoice Due Date</TableCell>
    </TableRow>
  );

  const renderRows = (item: ICustomerReceivablesDetails, index: number) => (
    <TableRow key={item.reference} hover>
      <TableCell className={classes.deleteCell}>
        <IconButton
          size="small"
          onClick={removeReceivable(item.id)}
          aria-label="delete receivable"
          id="receivableContactDeleteButton">
          <CloseIcon
            className={classes.deleteIcon}
            color="error"
            id="receivableContactDeleteIcon"
          />
        </IconButton>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <ReceiptIcon className={classes.tableIcon} color="action" />
          <Link
            component="button"
            type="button"
            className={classes.linkTitle}
            onClick={handleRowClick(item.id)}
            id="receivableContactName">
            {item.name}
          </Link>
        </Box>
      </TableCell>
      <TableCell>
        <CommonTextField
          type="email"
          fullWidth
          label="Email Address"
          hiddenLabel
          placeholder="Email Address"
          error={Boolean(getEmailFieldError(index) && getEmailFieldTouched(index))}
          helperText={getEmailFieldTouched(index) && getEmailFieldError(index)}
          name={`data[${index}.email]`}
          onBlur={handleBlurEmail(index)}
          onChange={handleChange}
          onValueChange={onValueChange}
          value={data[index].email || ""}
          inputProps={{ "data-testid": "email-address-input" }}
          id="receivableContactEmail"
        />
      </TableCell>
      <TableCell>{formatCurrency(item.amount, { currency: item.currency })}</TableCell>
      {!areUniqueReferencesEmpty && <TableCell>{item.reference}</TableCell>}
      <TableCell>{formatDisplayedDate(item.dueDateTime)}</TableCell>
    </TableRow>
  );

  return (
    <Card elevation={12}>
      <Table
        data={data}
        renderHeader={renderHeader}
        renderRows={renderRows}
        showPagination={false}
      />
    </Card>
  );
};

export default ReceivablesContactInfo;
