import { SvgIcon, SvgIconProps } from "@mui/material";

const MakePaymentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 16">
      <path d="M13 9C12.1667 9 11.4583 8.70833 10.875 8.125C10.2917 7.54167 10 6.83334 10 6C10 5.16667 10.2917 4.45833 10.875 3.875C11.4583 3.29167 12.1667 3 13 3C13.8333 3 14.5417 3.29167 15.125 3.875C15.7083 4.45833 16 5.16667 16 6C16 6.83334 15.7083 7.54167 15.125 8.125C14.5417 8.70833 13.8333 9 13 9ZM6 12C5.45 12 4.979 11.804 4.587 11.412C4.195 11.02 3.99934 10.5493 4 10V2C4 1.45 4.196 0.979002 4.588 0.587002C4.98 0.195002 5.45067 -0.000664969 6 1.69779e-06H20C20.55 1.69779e-06 21.021 0.196001 21.413 0.588001C21.805 0.980001 22.0007 1.45067 22 2V10C22 10.55 21.804 11.021 21.412 11.413C21.02 11.805 20.5493 12.0007 20 12H6ZM8 10H18C18 9.45 18.196 8.979 18.588 8.587C18.98 8.195 19.4507 7.99934 20 8V4C19.45 4 18.979 3.804 18.587 3.412C18.195 3.02 17.9993 2.54934 18 2H8C8 2.55 7.804 3.021 7.412 3.413C7.02 3.805 6.54934 4.00067 6 4V8C6.55 8 7.021 8.196 7.413 8.588C7.805 8.98 8.00067 9.45067 8 10ZM19 16H2C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V3H2V14H19V16Z" />
    </SvgIcon>
  );
};

export default MakePaymentIcon;
