import * as React from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import clsx from "clsx";

/**
 * Common text field Component, open to extend generic properties and not duplicate them
 */

export type CommonTextFieldProps = TextFieldProps & {
  /**
   * Used as help function for updating value on blur
   * in most cases `setFieldValue` from formik
   */
  onValueChange?: (field: string, value: any, shouldValidate?: boolean) => void;
};

const useStyles = makeStyles({
  hiddenLabel: {
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
  },
});

const CommonTextField = React.forwardRef((props: CommonTextFieldProps, ref) => {
  const classes = useStyles();

  const {
    value = "",
    onValueChange,
    onBlur,
    hiddenLabel,
    InputLabelProps,
    InputProps,
    FormHelperTextProps,
    // Setting `helperText = " "` will keep the helper element in the DOM, making it available for screen readers.
    helperText = " ",
    ...restProps
  } = props;

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    onBlur && onBlur(e);
    if (props.type === "email" && onValueChange && props.name) {
      onValueChange(props.name, e.target.value.toLowerCase());
    }
  };

  return (
    <>
      <TextField
        value={value}
        type={props.type || "text"}
        variant={props.variant || "outlined"}
        id={`${props.name}-input`}
        data-testid={
          props.error && helperText instanceof String
            ? helperText.replace(/ /g, "-").toLowerCase()
            : null
        }
        {...restProps}
        helperText={helperText}
        onBlur={onBlurHandler}
        style={{ marginBottom: 0 }}
        className={clsx([props.className, { [classes.hiddenLabel]: hiddenLabel }])}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: hiddenLabel ? true : InputLabelProps?.shrink,
          className: hiddenLabel ? "visuallyHidden" : InputLabelProps?.className,
        }}
        InputProps={{
          ...InputProps,
          ref: ref,
          "aria-describedby": `${props.name}-error`,
        }}
        FormHelperTextProps={{
          "aria-live": "assertive",
          ...FormHelperTextProps,
          id: `${props.name}-error`,
          className: helperText === " " ? "visuallyHidden" : undefined,
        }}
      />
    </>
  );
});

export default CommonTextField;
