import React from "react";

function InvoiceFinanceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 80 78">
      <path
        fill="#000"
        d="M45 31.688c0-.647-.263-1.267-.732-1.724a2.533 2.533 0 00-1.768-.714h-20c-.663 0-1.299.257-1.768.714A2.407 2.407 0 0020 31.688c0 .646.263 1.266.732 1.723a2.533 2.533 0 001.768.714h20c.663 0 1.299-.257 1.768-.714A2.407 2.407 0 0045 31.687zm-3.333 9.75c0-.647-.264-1.267-.733-1.724A2.533 2.533 0 0039.167 39H22.5c-.663 0-1.299.257-1.768.714A2.407 2.407 0 0020 41.438c0 .646.263 1.266.732 1.723a2.533 2.533 0 001.768.714h16.667c.663 0 1.299-.257 1.767-.714a2.407 2.407 0 00.733-1.724zm.833 7.312c.663 0 1.299.257 1.768.714.469.457.732 1.077.732 1.724 0 .646-.263 1.266-.732 1.723a2.533 2.533 0 01-1.768.714h-20a2.533 2.533 0 01-1.768-.714A2.407 2.407 0 0120 51.187c0-.646.263-1.266.732-1.723a2.533 2.533 0 011.768-.714h20z"></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M20 70.686h43.333a9.287 9.287 0 006.482-2.618 8.826 8.826 0 002.685-6.32V43.875c0-.647-.263-1.267-.732-1.724A2.533 2.533 0 0070 41.436H59.167V16.063c0-4.624-5.364-7.315-9.224-4.628l-.583.407a7.666 7.666 0 01-4.372 1.355 7.665 7.665 0 01-4.368-1.368 12.788 12.788 0 00-7.287-2.27c-2.611 0-5.158.793-7.286 2.27a7.665 7.665 0 01-4.368 1.368 7.666 7.666 0 01-4.372-1.355l-.584-.407c-3.86-2.687-9.223 0-9.223 4.628V58.5c0 3.232 1.317 6.332 3.661 8.617 2.344 2.286 5.524 3.57 8.839 3.57zm8.953-54.892a7.7 7.7 0 014.38-1.362 7.7 7.7 0 014.38 1.362 12.753 12.753 0 007.274 2.278 12.755 12.755 0 007.28-2.262l.583-.406a.847.847 0 011.194.237c.08.127.122.273.123.422V61.75a8.71 8.71 0 001 4.062H20c-1.99 0-3.897-.77-5.303-2.142A7.221 7.221 0 0112.5 58.5V16.063c0-.149.043-.295.123-.422a.826.826 0 01.33-.298.852.852 0 01.864.06l.583.407a12.754 12.754 0 007.28 2.262c2.608-.003 5.151-.8 7.273-2.278zm30.214 45.955V46.31H67.5V61.75c0 1.077-.439 2.11-1.22 2.872a4.221 4.221 0 01-2.947 1.19 4.221 4.221 0 01-2.946-1.19 4.011 4.011 0 01-1.22-2.872z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default InvoiceFinanceIcon;
