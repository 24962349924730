import {
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Amount, InvoiceLineItem, TaxCategory } from "@APP/types";
import { formatCurrency } from "@APP/utils";

type Props = {
  totalAmountTaxInclusive?: string;
  totalAmountTaxExclusive?: string;
  taxCategories?: TaxCategory[];
  currency?: string;
  lineItems?: InvoiceLineItem[];
  isDisplayingHeader?: boolean;
  remainingAmountTaxInclusive?: Amount;
};

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
  },
  lineItem: {
    "& > td": {
      border: "none",
    },
  },
  tableCells: {
    "& > td": {
      border: "none",
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
  },
  dividerLeft: {
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    paddingBottom: theme.spacing(0.5),
  },
  dividerRight: {
    paddingRight: theme.spacing(2),
    paddingLeft: 0,
    paddingBottom: theme.spacing(0.5),
  },
  lineItems: {
    "& > tr:first-child > td": {
      paddingTop: theme.spacing(3),
    },
  },
  totalItems: {
    "& > tr:first-child > td": {
      paddingTop: theme.spacing(3),
    },
  },
  tableCellsRight: {
    "& tr > *:nth-child(2n)": {
      textAlign: "right",
    },
  },
}));

const InvoiceTotal = ({
  totalAmountTaxInclusive,
  totalAmountTaxExclusive,
  taxCategories,
  lineItems,
  currency,
  remainingAmountTaxInclusive,
  isDisplayingHeader = true,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const VAT =
    totalAmountTaxInclusive && totalAmountTaxExclusive && !taxCategories?.length
      ? (Number(totalAmountTaxInclusive) - Number(totalAmountTaxExclusive)).toString()
      : undefined;

  return (
    <Card elevation={12}>
      <CardContent className={classes.card}>
        <Table>
          <caption className="visuallyHidden">
            Invoice table, {isDisplayingHeader && "description, "}
            {lineItems && "line items,"} amount
          </caption>
          {isDisplayingHeader && (
            <TableHead className={classes.tableCellsRight}>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" component="h4">
                    Description
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" component="h4">
                    Net Amount
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          {lineItems && (
            <TableBody className={clsx(classes.lineItems, classes.tableCellsRight)}>
              {lineItems.map(({ id, description, amountTaxExclusive: { amount, currency } }) => (
                <TableRow key={id} className={classes.tableCells}>
                  <TableCell>{description}</TableCell>
                  <TableCell>{formatCurrency(amount, { currency })}</TableCell>
                </TableRow>
              ))}
              <TableRow className={classes.lineItem} aria-hidden>
                <TableCell className={classes.dividerLeft} aria-hidden>
                  <Divider component="div" aria-hidden />
                </TableCell>
                <TableCell className={classes.dividerRight} aria-hidden>
                  <Divider component="div" aria-hidden />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableBody className={clsx(classes.totalItems, classes.tableCellsRight)}>
            {totalAmountTaxExclusive && (
              <TableRow className={classes.tableCells}>
                <TableCell>{t("Invoice.TotalAmountTaxExclusiveLabel")}</TableCell>
                <TableCell>
                  {formatCurrency(totalAmountTaxExclusive, {
                    currency: currency,
                  })}
                </TableCell>
              </TableRow>
            )}
            {VAT && (
              <TableRow className={classes.tableCells}>
                <TableCell>{t("Invoice.VatDescriptionLabel")}</TableCell>
                <TableCell>{formatCurrency(VAT, { currency: currency })}</TableCell>
              </TableRow>
            )}
            {taxCategories &&
              taxCategories.map((taxCategory) => (
                <TableRow className={classes.tableCells}>
                  <TableCell>{taxCategory.name}</TableCell>
                  <TableCell>
                    {formatCurrency(taxCategory.amount.amount, {
                      currency: taxCategory.amount.currency,
                    })}
                  </TableCell>
                </TableRow>
              ))}
            {totalAmountTaxInclusive && (
              <TableRow className={classes.tableCells}>
                <TableCell>
                  <b>Gross Total</b>
                </TableCell>
                <TableCell>
                  <b>{formatCurrency(totalAmountTaxInclusive, { currency: currency })}</b>
                </TableCell>
              </TableRow>
            )}
            {remainingAmountTaxInclusive && (
              <TableRow className={classes.tableCells}>
                <TableCell>
                  <b>Remaining Amount</b>
                </TableCell>
                <TableCell>
                  <b>
                    {formatCurrency(remainingAmountTaxInclusive.amount, {
                      currency: remainingAmountTaxInclusive.currency,
                    })}
                  </b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default InvoiceTotal;
