import { ErrorCode } from "@APP/types";

export const ERROR_CODES_LIST = [
  113, 114, 1000, 1007, 1008, 1009, 1016, 1017, 1020, 1021, 1030, 1031, 1032, 1033, 1037, 1041,
  1042, 1043, 1101, 1230, 1231, 1232, 1233, 1234, 1330, 1332, 1333, 1334, 1335, 1904, 1906, 2347,
  2348, 2349, 3000, 3005, 3006, 4005, 4321, 5001, 5003, 5005, 6006, 6009, 6007, 6017, 6019, 6030,
  6101, 6102, 6103, 6106, 6108, 6109, 6110, 6111, 6301, 6302, 6912, 6920, 7035, 7036, 8002, 9001,
  20000, 20100, 21001, 21002, 21003, 21004, 21005, 21006, 21007, 21008, 21009, 21010, 63011, 24110,
  24120, 24140, 50003, 50004,
] as const;

export const ERROR_CODE_OBJECTS: ErrorCode[] = [3000, 3006, 5001, 6108, 6109];

export const NOT_UNIQUE_CUSTOMER_NAME_ERROR_CODES: ErrorCode[] = [6110, 6111];

export const RTP_VALIDATION_ERROR_CODES: ErrorCode[] = [8002, 6302];

export const NO_CARD_PAYMENT_APPLICATION_EXIST_ERROR_CODE: ErrorCode = 20000;

export const REQUEST_LIMIT_REACHED_ERROR_CODE: ErrorCode = 6109;

export const NO_LINKED_BANK_ACCOUNTS_EXIST_ERROR_CODE: ErrorCode = 4321;

export const UNLINK_MX_BANK_ACCOUNT_ERROR_CODE: ErrorCode = 4005;

export const BANK_CONSENT_EXPIRED_ERROR_CODE: ErrorCode = 3006;

export const NO_TOKEN_FOUND_FOR_AIS_ERROR_CODE: ErrorCode = 5003;

export const TOKEN_EXPIRED_ERROR_CODE: ErrorCode = 5005;

export const NO_USER_SUBSCRIPTION_EXIST_ERROR_CODE: ErrorCode = 1031;

export const UNPAID_RTPS_EXIST_FOR_SUBSCRIPTION_ERROR_CODE: ErrorCode = 1037;

export const NO_CLEARING_LEDGER_CREATED_ERROR_CODE: ErrorCode = 6912;

export const CLEARING_LEDGER_ALREADY_EXIST_ERROR_CODE: ErrorCode = 6920;

export const INVALID_MAVERICK_SEC_ERROR_CODE: ErrorCode = 20100;

export const NO_PENDING_EMAIL_VERIFICATION_ERROR_CODE: ErrorCode = 2349;

// COP Account Error Codes
export const COP_ACCOUNT_REGISTRATION_FAILED_ERROR_CODE: ErrorCode = 21001;
export const COP_ACCOUNT_ALREADY_LINKED_ERROR_CODE: ErrorCode = 21002;
export const COP_ACCOUNT_NO_LINKED_ACCOUNT_ERROR_CODE: ErrorCode = 21004;
export const COP_CLOSE_MATCH_ERROR_CODE: ErrorCode = 21005;
export const COP_NO_MATCH_ERROR_CODE: ErrorCode = 21006;
export const COP_INVALID_ACCOUNT_TYPE_ERROR_CODE: ErrorCode = 21007;
export const COP_INVALID_SORT_CODE_ERROR_CODE: ErrorCode = 21008;
export const COP_TEMPORARILY_BLOCKED_ERROR_CODE: ErrorCode = 21009;
export const COP_DISABLED_ACCOUNT_ERROR_CODE: ErrorCode = 21010;

// AP Error Coded
export const SAGE_SUBSCRIPTION_NOT_FOUND: ErrorCode = 6019;

export const USER_EMAIL_ALREADY_EXIST: ErrorCode = 1904;

export const INVALID_ORG_USER_EMAIL: ErrorCode = 1906;

export const COP_HANDLED_ERROR_CODES: ErrorCode[] = [
  COP_ACCOUNT_REGISTRATION_FAILED_ERROR_CODE,
  COP_ACCOUNT_ALREADY_LINKED_ERROR_CODE,
  COP_ACCOUNT_NO_LINKED_ACCOUNT_ERROR_CODE,
  COP_CLOSE_MATCH_ERROR_CODE,
  COP_NO_MATCH_ERROR_CODE,
  COP_INVALID_ACCOUNT_TYPE_ERROR_CODE,
  COP_INVALID_SORT_CODE_ERROR_CODE,
  COP_TEMPORARILY_BLOCKED_ERROR_CODE,
  COP_DISABLED_ACCOUNT_ERROR_CODE,
];

export const INVALID_RECAPTCHA_ERROR_CODE: ErrorCode = 114;

export const USER_BLOCKED_ERROR_CODES: ErrorCode[] = [1041, 1042];

export const OGR_BLOCKED_ERROR_CODE: ErrorCode = 1043;

// From ERP Set Up

export const ORG_VALIDATION_ERROR_CODES: ErrorCode[] = [
  1008, 1007, 1009, 1021, 6006, 6017, 6102, 6103, 6106,
];
export const ORG_COMPANY_VALIDATION_ERROR_CODES: ErrorCode[] = [6009, 6030, 1000];
export const CONSENT_REVOCATION_ERROR_CODES: ErrorCode[] = [3005];

// WCF Customer Error Codes
export const WCF_CUSTOMER_ERROR_CODE: ErrorCode[] = [24110, 24140];

export const WCF_API_ERROR_CODE: ErrorCode[] = [24120, 24140];

export const SQUARE_MERCHENT_ERRORS: ErrorCode[] = [50003, 50004];
