import React from "react";
import { Box, Card, Typography, CardHeader, CardContent, Divider, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/InfoRounded";
import ErrorIcon from "@mui/icons-material/Error";
import clsx from "clsx";
import { TFunction, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  placeholderIcon: {
    marginBottom: theme.spacing(1),
  },
  textTitle: { fontWeight: "bold", marginTop: 10 },
  textContent: { fontWeight: "bold", marginTop: 30, whiteSpace: "pre-wrap" },
  header: { textAlign: "center" },
  icon: {
    fontSize: 120,
  },
  iconSuccess: {
    fill: theme.palette.success.main,
  },
  iconError: {
    fill: theme.palette.error.main,
  },
  iconInfo: {
    fill: theme.palette.info.main,
  },
  iconWarning: {
    fill: theme.palette.warning.main,
  },
}));

type TypeResult = "success" | "info" | "error" | "warning";

type Props = {
  type?: TypeResult;
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  children?: React.ReactNode | string;
  footer?: React.ReactNode | string;
};

const getTitleByType = (t: TFunction) => {
  return {
    success: t("Errors.Common.Alerts.AlertTitles.Success"),
    info: t("Errors.Common.Alerts.AlertTitles.Info"),
    error: t("Errors.Common.Alerts.AlertTitles.Failure"),
    warning: t("Errors.Common.Alerts.AlertTitles.Warning"),
  };
};

const IconByType = ({ type }: { type: TypeResult }) => {
  const classes = useStyles();

  if (type === "success") {
    return <CheckIcon className={clsx(classes.icon, classes.iconSuccess)} />;
  } else if (type === "info") {
    return <InfoIcon className={clsx(classes.icon, classes.iconInfo)} />;
  } else if (type === "warning") {
    return <WarningIcon className={clsx(classes.icon, classes.iconWarning)} />;
  }

  return <ErrorIcon className={clsx(classes.icon, classes.iconError)} />;
};

const ResultNotification = ({ icon, children, title, type, footer }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const titleByType = getTitleByType(t);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Card elevation={12}>
          <CardHeader
            className={classes.header}
            title={
              <Typography variant="h3" className={classes.textTitle}>
                {type && !title ? titleByType[type] : title}
              </Typography>
            }
            data-testid="result-notification-title"
            id="resultNotificationTitle"
          />
          <Divider />
          <CardContent>
            <Box
              mt={3}
              mb={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              data-testid="result-notification-children"
              id="resultNotificationChildren">
              {type && !icon ? <IconByType type={type} /> : icon}
              <Typography
                className={classes.textContent}
                align="center"
                data-testid="result-notification-text"
                id="resultNotificationText">
                {children}
              </Typography>
            </Box>
            <Box mt={3} mb={3} textAlign="center" id="resultNotificationFooter">
              {footer}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResultNotification;
