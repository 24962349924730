function WcfIcon({ height = 24, width = 24 }: { height?: number; width?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${height}px`}
      fill="none"
      viewBox="0 0 116 71">
      <path
        fill="currentColor"
        d="M101.5 25.357h7.25V71h-7.25V25.357zM29 43.107l7.25-5.071V71H29V43.107zM14.5 53.25l7.25-5.071V71H14.5V53.25zm29-20.286l7.25-5.071V71H43.5V32.964zM58 27.893l7.25 5.071V70.96H58V27.893zM76.125 40.57l3.625-2.535V71H72.5V38.036l3.625 2.535zM87 32.964l7.25-5.071V71H87V32.964zM0 63.393l7.25-5.072V71H0v-7.607zM116 0v20.286h-7.25V8.676L76.125 31.46l-21.75-15.215L0 54.32v-7.211L54.375 9.113l21.75 15.214 27.471-19.256H87V0h29z"></path>
    </svg>
  );
}

export default WcfIcon;
