import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { ErpId, Provider, TabsName } from "@APP/constants";
import useSubscription from "@APP/hooks/useSubscription";
import { NoteBox } from "@APP/components";
import { SetupConsentToAccessAccount } from "@APP/views/setup";
import { ErpBanksAccounts, SubscriptionFeatureTypes } from "@APP/types";
import { useAccessPermission, useBankLedgers } from "@APP/hooks";
import { getBankAccounts, getErpId, getSubscription } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";

import LinkMoneyhubBankAccounts from "./LinkMoneyhubBankAccounts";
import LinkMxBankAccounts from "./LinkMxBankAccounts";
import LinkCoPBankAccount from "./LinkCoPBankAccount";
import SetupBankAccountsCardActions from "./SetupBankAccountsCardActions";

const useStyles = makeStyles(() => ({
  cardRoot: {
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  filledCardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

const BankAccounts = () => {
  const classes = useStyles();
  const subscribe = useSubscription();
  const history = useHistory();
  const { t } = useTranslation();
  const { linkSingleDefaultLedger, fetchERPBankAccounts } = useBankLedgers();
  const { fetchAllPermissionsGlobally } = useAccessPermission();

  const erp = useSelector(getErpId);
  const bankAccounts = useSelector(getBankAccounts);
  const subscription = useSelector(getSubscription);

  const [erpBanksAccounts, setErpBanksAccounts] = useState<ErpBanksAccounts[] | null>(null);

  const queryParams = queryString.parse(history.location?.search ?? "") as { tab: string };
  const isSettingsScreen = window.location.pathname.includes(SCREEN_PATHS.SETTINGS);

  const fillErpBankAccounts = async () => {
    try {
      const bankAccounts = await fetchERPBankAccounts(fillErpBankAccounts, () => history.goBack());
      setErpBanksAccounts(bankAccounts);
    } catch (e) {
      setErpBanksAccounts([]);

      return;
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAllPermissionsGlobally();

      if (isSettingsScreen || erp === ErpId.INTERNAL) {
        setErpBanksAccounts([]);

        return;
      }

      await fillErpBankAccounts();
    })();
  }, []);

  const handleCompleteRegistration = async () => {
    if (CONFIG.FEATURES.SUBSCRIPTIONS.TYPE !== SubscriptionFeatureTypes.None && !subscription) {
      return subscribe.subscribeToDefaultPlan();
    }

    if (erp === ErpId.INTERNAL) {
      history.push(SCREEN_PATHS.REGISTRATION_COMPLETE);
    } else if (erpBanksAccounts?.length === 1 && bankAccounts?.length === 1) {
      await linkSingleDefaultLedger(erpBanksAccounts, bankAccounts);
      history.push(SCREEN_PATHS.REGISTRATION_COMPLETE);
    } else {
      history.push(SCREEN_PATHS.SET_LEDGER_FOR_PAYMENTS_BOOKING);
    }
  };

  const bankAccountContent = () => {
    if (CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE) {
      return (
        erpBanksAccounts !== null && (
          <LinkCoPBankAccount onCompleteRegistration={handleCompleteRegistration} />
        )
      );
    }

    return Provider.isMX
      ? erpBanksAccounts !== null && (
          <LinkMxBankAccounts onCompleteRegistration={handleCompleteRegistration} />
        )
      : erpBanksAccounts !== null && (
          <LinkMoneyhubBankAccounts onCompleteRegistration={handleCompleteRegistration} />
        );
  };

  return (
    <Card className={classes.cardRoot} elevation={4}>
      <CardHeader
        title={t("Setup.SetupBankAccounts.CardTitle")}
        subheader={
          queryParams.tab === TabsName.BANK_ACCOUNTS && t("Setup.SetupBankAccounts.CardSubheader")
        }
        data-testid="bank-accounts-card-header"
        id="setupBankAccountCardTitle"
      />
      <Divider />
      <CardContent
        className={
          queryParams.tab === TabsName.CONSENT || queryParams.tab === TabsName.CONSENT_EXPIRED
            ? classes.cardContent
            : classes.filledCardContent
        }>
        {(queryParams.tab === TabsName.CONSENT || queryParams.tab === TabsName.CONSENT_EXPIRED) && (
          <SetupConsentToAccessAccount />
        )}
        {(queryParams.tab === TabsName.BANK_ACCOUNTS || !queryParams.tab) && (
          <>
            {CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE && !!bankAccounts ? null : (
              <NoteBox mb={2}>{t("Setup.SetupBankAccounts.NoteBox")}</NoteBox>
            )}
            {bankAccountContent()}
          </>
        )}
      </CardContent>
      <SetupBankAccountsCardActions onCompleteRegistration={handleCompleteRegistration} />
    </Card>
  );
};

export default BankAccounts;
