import { Box, Checkbox, BoxProps, CheckboxProps, Typography, TypographyProps } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

interface ActiveCheckboxProps extends CheckboxProps {
  label?: string | React.ReactNode;
  labelProps?: TypographyProps;
  boxProps?: BoxProps;
  id?: string;
}

const ActiveCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.action.active,
    "&$checked": {
      color: theme.palette.action.active,
    },
  },
  checked: {},
}))((props: ActiveCheckboxProps) => {
  const { boxProps, labelProps, ...restProps } = props;

  return (
    <Box display="flex" alignItems="center" {...boxProps}>
      <Typography id="activeCheckboxLabel">{props.label}</Typography>
      <Checkbox
        {...restProps}
        data-testid={
          props.label
            ? (props.label as string).replace(/ /g, "-").toLowerCase() + "-checkbox"
            : null
        }
        id={props.id}
      />
    </Box>
  );
});

export default ActiveCheckbox;
