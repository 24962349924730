/**
 * Keys for data stored in the Local Storage.
 */
export enum LocalStorageKey {
  authToken = "auth.token",
  authTokenToPayInvoices = "auth.tokenToPayInvoices",
  setupErpConsentData = "setup.erpConsentData",
  setupBankConsentData = "setup.bankConsentData",
  setupCardPaymentsConsentData = "setup.cardPaymentsConsentData",
  persistStore = "persist:root",
  sessionIdleTime = "auth.sessionIdleTime",
  sessionAuthLogout = "auth.sessionAutoLogout",
  registrationCompleted = "user.registrationCompleted",
  authReference = "auth.ref",
}

export const getItem = (key: LocalStorageKey) => localStorage.getItem(key);
export const setItem = (key: LocalStorageKey, value: string) => localStorage.setItem(key, value);
export const removeItem = (key: LocalStorageKey) => localStorage.removeItem(key);

/**
 * A typesafe wrapper for the methods provided by browser's Local Storage API.
 * Only the keys listed in "LocalStorageKey" can be used as arguments. to access the data in Local Storage.
 */
const AppLocalStorage = {
  getItem,
  setItem,
  removeItem,
};

export default AppLocalStorage;
