import { Box, Typography } from "@mui/material";
import { format, isBefore } from "date-fns";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { InvoiceType, Payable, Receivable } from "@APP/types";
import { API_DATE_FORMAT } from "@APP/constants";

const useStyles = makeStyles(() => ({
  overdueAndDueCounting: {
    fontSize: "3rem",
  },
}));

type Props = {
  data: Array<Receivable | Payable> | null;
  dataType: InvoiceType;
};

const OverdueContent = ({ dataType, data }: Props) => {
  const classes = useStyles();

  const todayDate = new Date(format(new Date(), API_DATE_FORMAT));

  let signatureOverdue;

  const getOverdueCounting = () => {
    switch (dataType) {
      case InvoiceType.Receivables:
        return (data as Receivable[]).reduce((acc, receivable) => {
          return isBefore(new Date(receivable?.dueDateTime), todayDate) ? acc + 1 : acc;
        }, 0);
      case InvoiceType.Payables:
        return (data as Payable[]).reduce((acc, payable) => {
          return isBefore(new Date(payable.dueDateTime), todayDate) ? acc + 1 : acc;
        }, 0);
      default:
        return 0;
    }
  };

  const overdueCounting = getOverdueCounting();

  switch (dataType) {
    case InvoiceType.Receivables:
      signatureOverdue =
        overdueCounting > 1 || overdueCounting === 0 ? "Overdue Invoices" : "Overdue Invoice";
      break;
    case InvoiceType.Payables:
      signatureOverdue =
        overdueCounting > 1 || overdueCounting === 0
          ? "Overdue Supplier Invoices"
          : "Overdue Supplier Invoice";
  }

  return (
    <Box
      data-testid={`overdue-content-${dataType}`}
      display="flex"
      justifyContent="center"
      alignItems="center">
      <Typography
        className={clsx(classes.overdueAndDueCounting, "styleOverdue")}
        component="span"
        variant="h1"
        color="error">
        {overdueCounting}
      </Typography>
      <Box display="flex" ml={2} alignItems="center">
        <Typography variant="caption" color="textSecondary">
          {signatureOverdue}
        </Typography>
      </Box>
    </Box>
  );
};

export default OverdueContent;
