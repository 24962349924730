import axios from "axios";
import * as Sentry from "@sentry/react";

// Maximum retry count and time window in milliseconds (e.g., 1 retries per minute)
const MAX_RETRY_COUNT = 1;
const RETRY_TIME_WINDOW = 60 * 1000; // 1 minute

// Store retry counts and timestamps for rate limiting
const logRetryTracker: Record<string, { count: number; lastAttempt: number }> = {};

const logService = {
  async logErrorToBackend(endpoint: string, logData: any) {
    try {
      await axios.post(endpoint, logData);
    } catch (error) {
      Sentry.captureException(error);
      // Do nothing for now
    }
  },

  logError(error: any, orgId: string, sendLogsToBE: boolean) {
    // Capture the error with Sentry
    Sentry.captureException(error);

    if (!window?.env?.REACT_APP_LOGZ_ENDPOINT || !sendLogsToBE) {
      return; // If logging is not enabled, simply return
    }

    // Create a unique key based on the organization and error
    const uniqueKey = `${orgId}-${error.response?.status || "unknown"}`;

    // Get the current time
    const currentTime = Date.now();

    // Check if we are within the retry limit
    if (
      logRetryTracker[uniqueKey] &&
      logRetryTracker[uniqueKey].count >= MAX_RETRY_COUNT &&
      currentTime - logRetryTracker[uniqueKey].lastAttempt < RETRY_TIME_WINDOW
    ) {
      return;
    }

    // Initialize or update retry tracker
    if (!logRetryTracker[uniqueKey]) {
      logRetryTracker[uniqueKey] = { count: 0, lastAttempt: 0 };
    }

    logRetryTracker[uniqueKey].count += 1;
    logRetryTracker[uniqueKey].lastAttempt = currentTime;

    // Attempt to log the error to the backend, retrying up to 3 times
    let retryCount = 0;
    const tryLogging = () => {
      retryCount++;
      logService
        .logErrorToBackend(window.env.REACT_APP_LOGZ_ENDPOINT as string, {
          errorRes: error.response?.data ?? error,
          orgId: orgId,
        })
        .catch((err) => {
          if (retryCount < MAX_RETRY_COUNT) {
            console.log(`Retrying log attempt ${retryCount}`);
            setTimeout(tryLogging, 1000); // Retry after 1 second
          } else {
            // Do nothing for now
          }
        });
    };

    tryLogging();
  },
};

export default logService;
