import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CommonTextField, NoteBox, PhoneField } from "@APP/components";
import { getPermissions, getUser, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import CONFIG from "@APP/config";
import { checkUserRoles } from "@APP/utils";
import { ORG_ADMIN_ROLE } from "@APP/constants";
import { useAccessPermission } from "@APP/hooks";

import UserManagement from "./UserManagement";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "auto",
    height: 150,
    width: 150,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.action.active,
    fontSize: 48,

    [theme.breakpoints.down("md")]: {
      height: 75,
      width: 75,
    },
  },
  userInfo: {
    alignSelf: "center",
  },
}));

const UserDetails = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const { fetchPermission } = useAccessPermission();
  const permissions = useSelector(getPermissions);

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      await fetchPermission([{ user: "view" }]);
      dispatch(hideLoader());
    })();
  }, []);

  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Card elevation={4}>
        <CardHeader
          title="User"
          subheader="View user details."
          id="user-card-header"
          data-testid="user-card-header"
        />
        <Divider />
        <CardContent>
          <Grid container direction={isSmSizeScreen ? "column" : "row"} spacing={3}>
            <Grid item xs={12} md={4}>
              <Avatar className={classes.avatar}>{user?.username[0].toUpperCase() || ""}</Avatar>
            </Grid>
            <Grid item xs={12} md={8} className={classes.userInfo}>
              <Grid container direction="column" spacing={3}>
                <Grid item md={10} xs={12}>
                  <CommonTextField
                    inputProps={{
                      readOnly: true,
                      disabled: true,
                      "data-testid": "email-input",
                      id: "email-input",
                    }}
                    fullWidth
                    label="Email"
                    name="email"
                    value={user?.username || ""}
                    autoComplete="email"
                    id="userDetailsEmail"
                  />
                </Grid>
                <Grid item md={10} xs={12}>
                  <PhoneField
                    inputProps={{
                      id: "phone-number-input",
                      "data-testid": "phone-number-input",
                      disabled: true,
                    }}
                    fullWidth
                    label="Mobile Telephone Number"
                    name="phone"
                    value={user?.phone || ""}
                    countryCode={CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE}
                    readOnly
                    id="userDetailsPhone"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center">
            <Box mt={6} position="relative">
              <NoteBox>
                <Typography variant="h6" component="p" align="center">
                  If you want to delete your account, please send your request to{" "}
                  <Link
                    id="supportEmailLink"
                    underline="always"
                    href={`mailto:${CONFIG.SUPPORT_EMAIL}`}>
                    {CONFIG.SUPPORT_EMAIL}
                  </Link>
                  .
                </Typography>
              </NoteBox>
            </Box>
          </Box>
        </CardContent>
      </Card>
      {checkUserRoles([ORG_ADMIN_ROLE], user?.roles) && permissions?.user?.view && (
        <Box mt={4}>
          <UserManagement />
        </Box>
      )}
    </>
  );
};

export default UserDetails;
