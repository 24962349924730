import request from "@APP/services/api/request";
import CONFIG from "@APP/config";

export type LoginAnalyticsData = {
  loginMethod: "Credentials" | "PIN" | "Biometrics";
  appInfo: "Web" | "Mobile";
  deviceInfo?: string;
  operatingSystemInfo?: string;
};

export const sendLoginAnalytics = async (data: LoginAnalyticsData) =>
  await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/analytics/login`,
    data,
  });
