import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";

import { SCREEN_PATHS } from "@APP/navigation";
import { TabsName } from "@APP/constants";
import { Center } from "@APP/views/common";

const UserManagement = () => (
  <Card elevation={4}>
    <CardHeader
      subheader="Manage your organisation users"
      subheaderTypographyProps={{ "data-testid": "card-header-user-management" }}
      title="User Management"
      titleTypographyProps={{
        id: "card-header-user-management-title",
        "data-testid": "card-header-user-management-title",
        component: "h4",
      }}
      data-testid="manage-organisation-users-card-header"
      id="userManagementTitle"
    />
    <Divider />
    <CardContent>
      <Box alignItems="center" display="flex" flexDirection="column">
        <PeopleIcon color="primary" sx={{ fontSize: "7rem" }} />
        <Box my={3}>
          <Typography color="textPrimary" align="center" variant="h2">
            User Management
          </Typography>
        </Box>
        <Typography
          align="center"
          color="textSecondary"
          variant="body1"
          data-testid="please-select-an-accounting-package-text">
          You can view, add or block organisation users, assign them new roles.
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Center>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          id="open-user-management-panel-button"
          data-testid="open-user-management-panel-button"
          to={`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.USERS_LIST}`}
          className="minWidth">
          Open user management panel
        </Button>
      </Center>
    </CardActions>
  </Card>
);

export default UserManagement;
