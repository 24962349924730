export const invoiceFinanceDefaultValue = {
  assetFinanceRequest: null,
  invoiceFinanceRequest: {
    annualTurnover: null,
    currency: null,
  },
  commercialLoanRequest: null,
  urgency: null,
  detailedFundingPurpose: null,
  partyToContact: null,
  introducer: {
    id: null,
    contactDetails: null,
  },
  applicants: [
    {
      firstName: null,
      lastName: null,
      middleName: null,
      dateOfBirth: null,
      hasConsentedToPrivacyPolicy: null,
      hasConsentedToCreditCheck: null,
      isMainApplicant: null,
      address: {
        street: null,
        cityOrTown: null,
        countryOrState: null,
        country: null,
        postcode: null,
      },
      companyRole: null,
      contactDetails: {
        firstName: null,
        lastName: null,
        middleName: null,
        mobilePhoneNumber: null,
        otherPhoneNumber: null,
        email: null,
      },
      guarantees: {
        can_provide_personal_guarantee: false,
      },
    },
  ],
  formStage: {
    page: 3,
    status: "Draft",
  },
};
