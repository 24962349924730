import { ReactNode, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";

import { BankAccountsTiled, DisplayedBankData, MessageCard } from "@APP/components";
import { getBankAccounts, getDashboardApp } from "@APP/redux";
import { BankAccountExtended, Custodian } from "@APP/types";

import { useStyles } from "./styles";

export interface BankAccountsAction {
  title: string;
  icon?: ReactNode;
  action: (accounts: BankAccountExtended[]) => void;
}

type BankAccountsGroupedByBankType = {
  [key: string]: { bankInfo?: Custodian; accounts: BankAccountExtended[] };
};

const groupBankAccountsBasedOnBanks = (bankAccounts: BankAccountExtended[]) => {
  const groupedBankAccountsObject: BankAccountsGroupedByBankType = {};

  bankAccounts.forEach((account) => {
    const bankId = account.bankId.toLowerCase();

    if (!!groupedBankAccountsObject[bankId]) {
      groupedBankAccountsObject[bankId].accounts.push(account);
    } else {
      groupedBankAccountsObject[bankId] = {
        bankInfo: account.bankInfo,
        accounts: [account],
      };
    }
  });

  return groupedBankAccountsObject;
};

interface Props {
  /**
   * Actions (items) of the menu at the right top corner of grouped bank accounts by bank
   */
  actions?: BankAccountsAction[];
}

const YourBankAccounts = ({ actions }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const bankAccounts = useSelector(getBankAccounts);

  const { isLoading } = useSelector(getDashboardApp);

  const groupedBankAccountsByBank = useMemo(() => {
    return bankAccounts ? groupBankAccountsBasedOnBanks(bankAccounts) : {};
  }, [bankAccounts]);

  const getOtherBanksAccounts = () => {
    if (isLoading) return null;

    if (!bankAccounts?.length) {
      return (
        <Box className={classes.messageCard}>
          <MessageCard type="info">
            <Typography
              data-testid="no-linked-bank-accounts-title"
              id="no-linked-bank-accounts-title">
              {t("Setup.SetupBankAccounts.NoLinkedBankAccounts")}
            </Typography>
          </MessageCard>
        </Box>
      );
    }

    return (
      <Box maxHeight={400} overflow="auto" p={2} pt={2}>
        {Object.keys(groupedBankAccountsByBank).map((key) => {
          const bankAccountsGroup = groupedBankAccountsByBank[key];

          if (!bankAccountsGroup.bankInfo) return null;

          return (
            <Box key={bankAccountsGroup.bankInfo.id} mb={2}>
              <BankAccountsGroupByBank
                bankInfo={bankAccountsGroup.bankInfo!}
                accounts={bankAccountsGroup.accounts}
                actions={actions}
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <Box mb={1}>
        <Typography variant="h5" data-testid="linked-bank-bank-accounts-title">
          {t("Setup.SetupBankAccounts.LinkedBankAccountsTitle", {
            productName: t("ProductName"),
          })}
        </Typography>
      </Box>
      {getOtherBanksAccounts()}
    </>
  );
};

const BankAccountsGroupByBank = ({
  bankInfo,
  accounts,
  actions,
}: {
  bankInfo: Custodian;
  accounts: BankAccountExtended[];
  actions?: BankAccountsAction[];
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const bankAccountsDisplayed = useMemo(
    () =>
      accounts?.map(({ account: { identification: accountNumber, name: accountName } }) => {
        return {
          accountNumber,
          accountName,
        } as DisplayedBankData;
      }),
    [accounts],
  );

  const handleOpenMenuWithActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuWithActions = () => {
    setAnchorEl(null);
  };

  return (
    <Card elevation={4}>
      <CardHeader
        className={classes.bankAccountsCardHeader}
        title={bankInfo.fullName}
        titleTypographyProps={{ variant: "h5", component: "span" }}
        avatar={
          <Avatar
            src={bankInfo.logo}
            alt={bankInfo.fullName}
            className={classes.bankAvatar}
            color="secondary"
          />
        }
        action={
          actions ? (
            <>
              <IconButton
                color="primary"
                size="small"
                aria-label="bank accounts actions"
                aria-controls="bank-accounts-group-actions-menu"
                aria-haspopup="true"
                onClick={handleOpenMenuWithActions}
                onMouseEnter={handleOpenMenuWithActions}
                id="yourBankAccountMoreHorizButton">
                <MoreHorizRoundedIcon />
              </IconButton>
              <Menu
                id="bank-accounts-group-actions-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                MenuListProps={{ onMouseLeave: handleCloseMenuWithActions, role: "" }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handleCloseMenuWithActions}>
                {actions.map(({ title, icon, action }) => (
                  <MenuItem
                    key={title}
                    onClick={() => {
                      action(accounts);
                      handleCloseMenuWithActions();
                    }}
                    role=""
                    style={{ whiteSpace: "nowrap" }}>
                    {icon}
                    <button className="semanticButton">
                      <Box ml={icon ? 1 : 0}>
                        <Typography>{title}</Typography>
                      </Box>
                    </button>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : undefined
        }
      />
      <BankAccountsTiled bankAccounts={bankAccountsDisplayed} showLinkedLedgerContent />
    </Card>
  );
};

export default YourBankAccounts;
