import Joi from "joi";

export const companySchemaOther = Joi.object({
  registeredCompanyName: Joi.string().allow(null).max(100).required(),
  tradingName: Joi.string().max(100).allow(null),
  companyType: Joi.string().allow(null).optional(),
  companyRegistrationNumber: Joi.string().allow(null).required(),
  tradingFromDate: Joi.string().allow(null).isoDate(),
  industry: Joi.string()
    .valid(
      "agriculture_forestry_and_fishing",
      "architecture_and_engineering",
      "business_support_services",
      "car_dealers_garages_and_mechanics",
      "cleaning_landscaping_and_gardening",
      "construction",
      "education_and_training",
      "finance_and_insurance",
      "health_and_care",
      "hotels_restaurants_and_pubs",
      "it_and_communications",
      "leisure_and_other_services",
      "manufacturing_food_processing_and_printing",
      "professional_services",
      "property_and_real_estate",
      "publishing_film_and_media_production",
      "retail",
      "recruitment_agencies",
      "transport_and_logistics",
      "wholesale",
      "other",
    )
    .required(),
  sicCode: Joi.string().allow(null).optional(),
  website: Joi.string()
    .allow(null)
    .pattern(/^https?:\/\/(?:www\.)?[^\s$.?#]+\.[^\s$.?#]+[^\s]*$/)
    .max(100),
  registeredAddress: Joi.object({
    street: Joi.string().required().max(100),
    cityOrTown: Joi.string().required().max(100),
    countyOrState: Joi.string().allow(null).optional().max(100),
    country: Joi.string().valid("GB", null).optional().required(),
    postcode: Joi.string()
      .required()
      .pattern(
        /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Za-z][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
      )
      .max(12),
  }).allow(null),
  tradingAddress: Joi.object({
    street: Joi.string().required().max(100),
    cityOrTown: Joi.string().required().max(100),
    countyOrState: Joi.string().allow(null).optional().max(100),
    country: Joi.string().valid("GB", null).optional().required(),
    postcode: Joi.string()
      .required()
      .pattern(
        /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Za-z][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
      )
      .max(12),
  }).allow(null),
  vatInformation: Joi.object({
    isRegisteredForVat: Joi.boolean().allow(null).optional(),
    vatNumber: Joi.string()
      .pattern(/^(?:GB)?\d{9}$|^(?:GB)?\d{12}$/)
      .allow(null)
      .optional(),
  }),
  hasConsentedToCreditCheck: Joi.boolean().valid(true).required(),
  hasConsentedToPrivacyPolicy: Joi.boolean().valid(true).required(),
});

export const companySchema = Joi.object({
  registeredCompanyName: Joi.string().allow(null).optional().max(100),
  tradingName: Joi.string().max(100).required(),
  companyType: Joi.string().allow(null).optional(),
  companyRegistrationNumber: Joi.string().allow(null),
  tradingFromDate: Joi.string().allow(null).isoDate(),
  industry: Joi.string()
    .valid(
      "agriculture_forestry_and_fishing",
      "architecture_and_engineering",
      "business_support_services",
      "car_dealers_garages_and_mechanics",
      "cleaning_landscaping_and_gardening",
      "construction",
      "education_and_training",
      "finance_and_insurance",
      "health_and_care",
      "hotels_restaurants_and_pubs",
      "it_and_communications",
      "leisure_and_other_services",
      "manufacturing_food_processing_and_printing",
      "professional_services",
      "property_and_real_estate",
      "publishing_film_and_media_production",
      "retail",
      "recruitment_agencies",
      "transport_and_logistics",
      "wholesale",
      "other",
    )
    .required(),
  sicCode: Joi.string().allow(null).optional(),
  website: Joi.string()
    .allow(null)
    .pattern(/^https?:\/\/(?:www\.)?[^\s$.?#]+\.[^\s$.?#]+[^\s]*$/)
    .max(100),
  registeredAddress: Joi.object({
    street: Joi.string().required().max(100),
    cityOrTown: Joi.string().required().max(100),
    countyOrState: Joi.string().allow(null).optional().max(100),
    country: Joi.string().valid("GB", null).optional().required(),
    postcode: Joi.string()
      .required()
      .pattern(
        /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Za-z][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
      )
      .max(12),
  }).allow(null),
  tradingAddress: Joi.object({
    street: Joi.string().required().max(100),
    cityOrTown: Joi.string().required().max(100),
    countyOrState: Joi.string().allow(null).optional().max(100),
    country: Joi.string().valid("GB", null).optional().required(),
    postcode: Joi.string()
      .required()
      .pattern(
        /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Za-z][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
      )
      .max(12),
  }).allow(null),
  vatInformation: Joi.object({
    isRegisteredForVat: Joi.boolean().allow(null).optional(),
    vatNumber: Joi.string()
      .allow(null)
      .pattern(/^(?:GB)?\d{9}$|^(?:GB)?\d{12}$/)
      .optional(),
  }),
  hasConsentedToCreditCheck: Joi.boolean().valid(true).required(),
  hasConsentedToPrivacyPolicy: Joi.boolean().valid(true).required(),
});
