import { ErpId } from "@APP/constants";
import { AppState } from "@APP/redux";
import { isLinkedLedger } from "@APP/utils";

export const getAuth = (state: AppState) => state.auth;
export const getAuthentication = (state: AppState) => state.auth.authenticated;
export const getSettingsHiddenValue = (state: AppState) => state.auth.settingsHidden;
export const getOcmVisible = (state: AppState) => state.auth.isOcmVisible;
export const getDashboardVisible = (state: AppState) => state.auth.isDashboardVisible;
export const getUser = (state: AppState) => state.auth.user;
export const getUsername = (state: AppState) => state.auth.user?.username;
export const getBankAccounts = (state: AppState) => state.auth.user?.bankAccounts;
export const getErpId = (state: AppState) => state.auth.user?.erp;
export const getErpPlan = (state: AppState) => state.auth.user?.erpPlan;
export const getUserOrganisation = (state: AppState) => state.auth.user?.org;
export const getReconsentBanks = (state: AppState) => state.auth.reconsentBanks;
export const getSubscription = (state: AppState) => state.auth.user?.subscription;
export const getCardPaymentApp = (state: AppState) => state.auth.user?.cardPaymentApp;
export const getBankLedgers = (state: AppState) => state.auth.bankLedgers;
export const getClearingLedger = (state: AppState) => state.auth.clearingLedger;
export const getCoPStatus = (state: AppState) => state.auth.CoPStatus;
export const getPaymentBankAccounts = (state: AppState) => {
  if (state.auth.user?.erp === ErpId.INTERNAL) {
    return state.auth.user.bankAccounts;
  }
  return state.auth.user?.bankAccounts?.filter((account) =>
    isLinkedLedger(state.auth.bankLedgers, account.account.identification),
  );
};
export const getCardPaymentsCustodians = (state: AppState) => state.auth.cardPaymentsCustodians;
export const getCardPaymentsSettings = (state: AppState) => state.auth.cardPaymentsSettings;
