import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import { Page } from "@APP/components";
import { ERPOrgType } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { useOrganisationDetails } from "@APP/hooks";
import { getUserOrganisation, hideLoader, showLoader } from "@APP/redux";
import {
  setActiveStep,
  setNextPath,
  setNextStepActive,
} from "@APP/redux/actions/workingCapitalFinance";
import makeStyles from "@mui/styles/makeStyles";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import WcfFooter from "@APP/components/layout/WCFLayout";
import { API } from "@APP/services";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "center",
    borderRadius: "5px",
    minHeight: "80vh",
  },
  companyInfo: {
    paddingBottom: "16px",
  },
  companyDetails: {
    borderRadius: "5px",
  },
  bold: {
    color: "#000",
  },
  update: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "16px",
  },
  updateBtn: {
    borderRadius: "5px",
  },
}));

export const orgDisplayText = (orgType: string) => {
  switch (orgType) {
    case ERPOrgType.LIMITED_COMPANY:
      return "Limited Company";
    case ERPOrgType.SOLE_TRADER:
      return "Sole Trader";
    case ERPOrgType.LIMITED_LIABILITY_PARTNERSHIP:
      return "Partnership";
    case ERPOrgType.OTHER_ORG:
      return "Other Organisation";
    default:
      return "-";
  }
};

function CompanyProfile() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const org = useSelector(getUserOrganisation);
  const organisationDetailsHook = useOrganisationDetails();
  const history = useHistory();
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false);

  const isSoleTrader: boolean = org?.orgType === ERPOrgType.SOLE_TRADER;

  useEffect(() => {
    (async () => {
      dispatch(setActiveStep(0));
      await wcfCustomer();
      dispatch(setNextStepActive(true));
      dispatch(setNextPath(SCREEN_PATHS.WCF_COMPANY_PROFILE_FORM));
    })();
  }, []);

  const wcfCustomer = async () => {
    dispatch(showLoader());
    try {
      const customer = await API.getWcfCustomer();
      if (customer && customer.status === "Submitted") {
        history.push(SCREEN_PATHS.WCF_FINANCE_LISTING);
      }
      setIsCustomerLoaded(true);
    } catch (error) {
      setIsCustomerLoaded(true);
      // Do nothing for now
    }
    dispatch(hideLoader());
  };

  const handleUpdateOrg = async (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(showLoader());
    await organisationDetailsHook.updateOrganisationDetails();
    dispatch(hideLoader());
  };

  if (!isCustomerLoaded) return null;

  return (
    <Page className={classes.container} p={0}>
      <Box display="flex" flexDirection="column" flexGrow={1} p={3}>
        <Card elevation={1} className={classes.cardContainer}>
          <form style={{ width: "100%" }}>
            <WcfStepper />
            <Grid container mt={1} xs={12} md={6}>
              <Grid item>
                <Typography
                  variant="h4"
                  component="p"
                  fontWeight={600}
                  id="wcfCompanyProfileSummeryTitle"
                  color="primary"
                  paddingBottom={2}>
                  Check your company profile
                </Typography>
                <Typography
                  component="p"
                  color="textSecondary"
                  className={classes.companyInfo}
                  id="wcfCompanyProfileSummerySubTitle">
                  Please verify the company profile we retrieved from your accounting package. If
                  the information is incorrect, please update your company information in your
                  accounting software and click <b className={classes.bold}>"Update"</b>. If the
                  information is accurate, click <b className={classes.bold}>"Next"</b> to proceed.
                </Typography>

                <Grid>
                  <Grid container spacing={1} mb={1}>
                    <Grid item display="flex" xs={12} md={6}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" id="wcfCompanyProfileName">
                          Company Name:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          id="wcfCompanyProfileNameValue">
                          {org?.companyInfo?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} mb={1}>
                    <Grid item display="flex" xs={12} md={6}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" id="wcfCompanyProfileAddress">
                          Company Address:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          id="wcfCompanyProfileAddressValue">
                          {org?.companyInfo?.address?.lines &&
                            org?.companyInfo?.address?.lines.join(", ")}
                          <br />
                          {org?.companyInfo?.address?.city}
                          <br />
                          {org?.companyInfo?.address?.state}
                          <br />
                          {org?.companyInfo?.address?.country}
                          <br />
                          {org?.companyInfo?.address?.postcode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <br />
                  <Grid container spacing={1} mb={1}>
                    <Grid item display="flex" xs={12} md={6}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" id="wcfCompanyProfileWebsite">
                          Website:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          id="wcfCompanyProfileWebsiteValue">
                          {org?.website ?? "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} mb={1}>
                    <Grid item display="flex" xs={12} md={6}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" id="wcfCompanyProfileType">
                          Company type:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          id="wcfCompanyProfileTypeValue">
                          {orgDisplayText(org?.orgType.toString() ?? "")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!isSoleTrader && (
                    <Grid container spacing={1} mb={1}>
                      <Grid item display="flex" xs={12} md={6}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" id="wcfCompanyProfileNumber">
                            Company number:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            id="wcfCompanyProfileNumberValue">
                            {org?.taxInfo?.utrNumber ?? "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Divider />
                  <br />
                  <Grid container spacing={1} mb={1}>
                    <Grid item display="flex" xs={12} md={6}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" id="wcfCompanyProfileVat">
                          VAT number:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          id="wcfCompanyProfileVatValue">
                          {org?.taxInfo?.vatRegistrationNumber ?? "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} mb={1}>
                    <Grid item display="flex" xs={12} md={6}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" id="wcfCompanyProfileSic">
                          SIC Code:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          id="wcfCompanyProfileSicValue">
                          {org?.SIC[0] ?? "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.update}>
                  <Button
                    variant="contained"
                    className={classes.updateBtn}
                    id="wcfApUpdateButton"
                    onClick={(e: SyntheticEvent) => handleUpdateOrg(e)}>
                    Update
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={0} md={6} />
            </Grid>
          </form>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter
        displaySaveAndExitButton={true}
        displayPrevButton={true}
        displayNextButton={true}
      />
    </Page>
  );
}

export default CompanyProfile;
