import { useCallback } from "react";
import { useSelector } from "react-redux";

import { getReconsentBanks, setReconsentBanks, useAppDispatch } from "@APP/redux";
import { API } from "@APP/services";

const useReconsentBanks = () => {
  const dispatch = useAppDispatch();
  const reconsentBanks = useSelector(getReconsentBanks);

  const fetchReconsentBanks = useCallback(async () => {
    const { data } = await API.getBankReconsents();

    if (!!data.length) {
      dispatch(setReconsentBanks(data));
    }

    return data;
  }, []);

  const checkReconsentRequired = useCallback(async () => {
    const data = await fetchReconsentBanks();

    return !!data.length;
  }, [fetchReconsentBanks]);

  const updateBankConsents = useCallback(async () => {
    await API.updateBankConsents(reconsentBanks.map((item) => item.connectionId));
  }, [reconsentBanks]);

  const revokeBankConsents = useCallback(async () => {
    await API.revokeBankConsents(reconsentBanks.map((item) => item.consentId));
  }, [reconsentBanks]);

  return { checkReconsentRequired, revokeBankConsents, updateBankConsents, fetchReconsentBanks };
};

export default useReconsentBanks;
