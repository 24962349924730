import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Box, lighten, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";

import { cardPaymentCheck, checkUserRoles, setIdTab } from "@APP/utils";
import { Page, TabPanel } from "@APP/components";
import { ErpId, ORG_ADMIN_ROLE, Provider, TabsInterface, TabsName } from "@APP/constants";
import { SetupBankAccountsView } from "@APP/views";
import CONFIG from "@APP/config";
import { getPermissions, getUser } from "@APP/redux";
import { useAccessPermission, useApplicationListing } from "@APP/hooks";

import AccountingPackage from "./AccountingPackage";
import Account from "./Account";
import TermsAndConditions from "./TermsAndConditions";
import { Users } from "./Users";
import { CardPaymentsUS, CardPaymentsUK } from "./CardPayments";
import OrganisationPage from "./Organisation/OrganisationPage";

const useStyles = makeStyles((theme) => ({
  tabsIndicator: {
    zIndex: -1,
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
  },
  tabItem: {
    borderRadius: theme.shape.borderRadius,
    minHeight: 40,
  },
  tabsRoot: {
    minHeight: 40,
  },
  tabsScroller: {
    overflowY: "hidden",
  },
}));

enum TabNames {
  USER = "USER",
  ORGANISATION = "ORGANISATION",
  ACCOUNT = "ACCOUNT",
  CARD_PAYMENTS = "CARD_PAYMENTS",
  BANK_ACCOUNTS = "BANK_ACCOUNTS",
  ACCOUNTING_PACKAGE = "ACCOUNTING_PACKAGE",
  TERMS_OF_USE_AND_PRIVACY_POLICY = "TERMS_OF_USE_AND_PRIVACY_POLICY",
}

const SettingsView = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const isLgSizeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const queryParams = queryString.parse(history.location.search ?? "") as {
    tab: string;
    code?: string;
    error?: string;
  };
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const permissions = useSelector(getPermissions);
  const username = user?.username || "";

  //bankifi.atlassian.net/browse/BN-3463
  // const cardCustodians = useSelector(getCardPaymentsCustodians);
  // Remove restriction on linking square
  // const isSquareLinked = useMemo(
  //   () => cardCustodians.some((item) => item.id === "square"),
  //   [cardCustodians],
  // );

  const { fetchAllPermissions } = useAccessPermission();
  const { getApplicationListing } = useApplicationListing();

  useEffect(() => {
    fetchAllPermissions();
    getApplicationListing();
  }, []);

  const TABS: TabsInterface = {
    USER: {
      tabLabel: "User",
      tabsNames: checkUserRoles([ORG_ADMIN_ROLE], user?.roles)
        ? [TabsName.USER, TabsName.CREATE_USER, TabsName.USERS_LIST, TabsName.CREATE_USER_SUCCESS]
        : [TabsName.USER],
      getComponent: () => <Users />,
      tab: TabNames.USER,
    },
    ORGANISATION: {
      tabLabel: t("Settings.TabLabels.Organisation"),
      tabsNames: [TabsName.ORGANISATION],
      getComponent: () => <OrganisationPage />,
      tab: TabNames.ORGANISATION,
    },
    ACCOUNT: {
      tabLabel: "Account",
      tabsNames: [
        TabsName.ACCOUNT,
        TabsName.ACTIVATE_ACCOUNT,
        TabsName.DEACTIVATE_ACCOUNT,
        TabsName.ACCOUNT_SUCCESS,
      ],
      getComponent: () => <Account />,
      tab: TabNames.ACCOUNT,
    },
    CARD_PAYMENTS: {
      tabLabel: t("Settings.TabLabels.PaymentsTab"),
      tabsNames: [TabsName.CARD_PAYMENTS],
      getComponent: () => (Provider.isMoneyhub ? <CardPaymentsUK /> : <CardPaymentsUS />),
      tab: TabNames.CARD_PAYMENTS,
    },

    BANK_ACCOUNTS: {
      tabLabel: t("Settings.TabLabels.BankAccounts"),
      tabsNames: [TabsName.BANK_ACCOUNTS, TabsName.CONSENT, TabsName.CONSENT_EXPIRED],
      getComponent: () => <SetupBankAccountsView />,
      tab: TabNames.BANK_ACCOUNTS,
    },
    ACCOUNTING_PACKAGE: {
      tabLabel: "Accounting Package",
      tabsNames: [TabsName.ACCOUNTING_PACKAGE, TabsName.SETUP_ACCOUNTING_PACKAGE],
      getComponent: () => <AccountingPackage />,
      tab: TabNames.ACCOUNTING_PACKAGE,
    },
    TERMS_OF_USE_AND_PRIVACY_POLICY: {
      tabLabel: "Terms of Use & Privacy Policy",
      tabsNames: [TabsName.TERMS_OF_USE_AND_PRIVACY_POLICY],
      getComponent: () => <TermsAndConditions />,
      tab: TabNames.TERMS_OF_USE_AND_PRIVACY_POLICY,
    },
  };

  const [selectedTabIndex, setSelectedTabIndex] = useState<null | number>(null);

  const handleChangeTab = (event: React.SyntheticEvent<{}>, newSelectedTabIndex: number) => {
    setSelectedTabIndex(newSelectedTabIndex);
  };

  const tabs = useMemo(
    () =>
      CONFIG.FEATURES.SETTINGS_TABS.map((tab) => TABS[tab]).filter((tab) => {
        if (tab.tab === TabNames.ORGANISATION && !permissions.organisation.view) {
          return false;
        } else if (
          tab.tab === TabNames.ACCOUNTING_PACKAGE &&
          user?.erp === ErpId.INTERNAL &&
          !permissions.accounting_package.view
        ) {
          return false;
        } else if (tab.tab === TabNames.CARD_PAYMENTS && !permissions.card_payment.view) {
          return false;
        } else if (tab.tab === TabNames.CARD_PAYMENTS && !cardPaymentCheck(username)) {
          return false;
        } else if (tab.tab === TabNames.BANK_ACCOUNTS && !permissions.bank_account.view) {
          return false;
        } else if (tab.tab === TabNames.CARD_PAYMENTS && !CONFIG?.FEATURES?.RTP?.CARD_PAYMENTS) {
          return false;
        }

        return true;
      }),
    [permissions],
  );

  useEffect(() => {
    // In case if the app opened after linking an accounting package with the code or error parameters, navigate the user to the AP screen
    if (!queryParams.tab && selectedTabIndex === null && (queryParams.code || queryParams.error)) {
      return history.push(
        `${history.location.pathname}${history.location.search}&tab=${TabsName.SETUP_ACCOUNTING_PACKAGE}`,
      );
    }

    if (!queryParams.tab && selectedTabIndex === null && (queryParams.code || queryParams.error)) {
      return history.push(
        `${history.location.pathname}${history.location.search}&tab=${TabsName.SETUP_ACCOUNTING_PACKAGE}`,
      );
    }

    // Set default tab parameter in URL if missing
    if (!queryParams.tab) {
      return history.replace({
        pathname: history.location.pathname,
        search: `tab=${TabsName.USER}`,
      });
    }

    const indexSelectedTabName = tabs.findIndex(({ tabsNames }) =>
      tabsNames.some((name) => name === queryParams.tab),
    );

    setSelectedTabIndex(indexSelectedTabName === -1 ? 0 : indexSelectedTabName);
  }, [tabs, queryParams.tab]);

  useEffect(() => {
    /**
     * To avoid misalignment tab after refreshing page
     */

    window.dispatchEvent(new Event("resize"));

    const indexSelectedTabName = tabs.findIndex(({ tabsNames }) =>
      tabsNames.some((name) => name === queryParams?.tab),
    );

    if (selectedTabIndex === null || selectedTabIndex === indexSelectedTabName) return;

    const query = queryString.stringify({
      ...queryParams,
      tab: tabs[selectedTabIndex]?.tabsNames[0],
    });

    history.push({
      pathname: history.location.pathname,
      search: query,
    });
  }, [selectedTabIndex]);

  return (
    <Page title="Settings" display="flex" flexDirection="column" height="100%">
      {selectedTabIndex !== null && (
        <>
          <Tabs
            centered={isLgSizeScreen ? undefined : true}
            value={selectedTabIndex}
            textColor="primary"
            onChange={handleChangeTab}
            classes={{ root: classes.tabsRoot, scroller: classes.tabsScroller }}
            TabIndicatorProps={{ className: classes.tabsIndicator }}
            variant={isLgSizeScreen ? "scrollable" : undefined}
            scrollButtons={false}>
            {tabs.map(({ tabLabel, tab }, index) => (
              <Tab
                key={tabLabel}
                label={tabLabel}
                {...setIdTab(index, tab)}
                className={classes.tabItem}
              />
            ))}
          </Tabs>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            {tabs.map(({ getComponent, tabLabel }, index) => (
              <TabPanel value={selectedTabIndex} index={index} key={tabLabel}>
                {getComponent()}
              </TabPanel>
            ))}
          </Box>
        </>
      )}
    </Page>
  );
};

export default SettingsView;
