import { Box } from "@mui/material";

import OrganisationDetails from "./OrganisationDetails";

const OrganisationPage = () => (
  <>
    <OrganisationDetails />
    <Box mb={3} />
  </>
);

export default OrganisationPage;
