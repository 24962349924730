import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import {
  FooterActionsButtons,
  MoneyhubFooter,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { formatCurrency } from "@APP/utils";
import {
  getPayable,
  getUser,
  hideLoader,
  setPaymentInitiation,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import {
  BANK_CONSENT_EXPIRED_ERROR_CODE,
  INVALID_MAVERICK_SEC_ERROR_CODE,
} from "@APP/services/api";
import {
  ImmediatePaymentDetails,
  PaymentTypes,
  SchemesNames,
  SEC_CODES_LABELS,
} from "@APP/types/paybles";
import { useHandleErrorCodes, useOrganisationDetails } from "@APP/hooks";
import { Provider } from "@APP/constants";
import { API } from "@APP/services";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  tableCell: {
    border: "none",
    padding: theme.spacing(1, 0, 0),
  },
  smallCell: {
    width: 220,
  },
}));

const PayableConfirmView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { updateOrganisationDetails } = useOrganisationDetails();
  const handleErrorCodes = useHandleErrorCodes();

  const { selectedPayable, bankAccountData, payerBankAccount } = useSelector(getPayable);
  const user = useSelector(getUser);

  const grossTotal = selectedPayable
    ? formatCurrency(selectedPayable.totalAmountTaxInclusive.amount, {
        currency: selectedPayable.totalAmountTaxInclusive.currency,
      })
    : "";

  const remainingAmount = selectedPayable
    ? formatCurrency(selectedPayable.remainingAmountTaxInclusive.amount, {
        currency: selectedPayable.remainingAmountTaxInclusive.currency,
      })
    : "";

  const redirectToBack = () => {
    if (Provider.isMaverick && selectedPayable)
      return history.push(
        `${SCREEN_PATHS.PAYABLES_LIST}/${selectedPayable.entityDetails.externalId}`,
      );

    history.push(SCREEN_PATHS.PAYABLE_SELECT_ACCOUNT);
  };

  const getFormatSingleImmediatePaymentBody = (bankId: string) => ({
    amount: selectedPayable?.remainingAmountTaxInclusive!,
    creditAccount: {
      schemeName: SchemesNames.SORT_CODE_ACCOUNT_NUMBER,
      identification:
        bankAccountData?.sortCode!.replaceAll("-", "") + bankAccountData?.accountNumber!,
      name: selectedPayable?.supplierContact.name,
    },
    paymentType: PaymentTypes.IMMEDIATE,
    additionalData: {
      BankId: bankId,
      erpId: selectedPayable?.entityDetails.source ?? "",
      invoiceId: selectedPayable?.entityDetails.externalId ?? "",
      initialInvoiceType: selectedPayable?.invoiceType ?? "",
      customerId: user?.username ?? "",
      userId: payerBankAccount?.additionalData.userId,
      orgId: user?.org?.id,
      payerAccountId: payerBankAccount?.accountId,
      invoiceReference: selectedPayable?.reference,
    },
    debtorAccount: {
      schemeName: payerBankAccount?.account.schemeName || SchemesNames.SORT_CODE_ACCOUNT_NUMBER,
      identification: payerBankAccount?.account.identification || "",
    },
    remittanceInformation: {
      reference: selectedPayable?.reference || null,
    },
  });

  const processMoneyhubSupplierPayment = async () => {
    try {
      const bankId = payerBankAccount?.bankId.toLowerCase() || "";
      const immediatePaymentDetails: ImmediatePaymentDetails =
        getFormatSingleImmediatePaymentBody(bankId);

      const paymentInitiation = await API.setupASingleImmediatePayment(
        bankId,
        immediatePaymentDetails,
        SCREEN_PATHS.PAYABLE_SUCCESS,
      );
      dispatch(setPaymentInitiation(paymentInitiation));

      if (paymentInitiation.redirectUrl) {
        window.location.href = paymentInitiation.redirectUrl;
      }
    } catch (e) {
      const errorCode = e.response?.date?.errorCode;

      const isHandled = handleErrorCodes(errorCode, {
        errorCodes: [BANK_CONSENT_EXPIRED_ERROR_CODE],
      });

      if (!isHandled) {
        history.push(SCREEN_PATHS.PAYABLE_FAILURE);
      }
    }
  };

  const processMaverickSupplierPayment = async () => {
    try {
      await API.createMaverickSupplierPayment({
        amount: selectedPayable!.remainingAmountTaxInclusive,
        erpId: user!.erp!,
        invoiceId: selectedPayable!.entityDetails.externalId,
        accountName: selectedPayable!.supplierContact.name,
        routingNumber: bankAccountData!.sortCode,
        accountNumber: bankAccountData!.accountNumber,
        accountType: bankAccountData!.accountType!,
        secCode: bankAccountData!.secCode!,
        customerEmail: bankAccountData!.email!,
      });

      history.push(SCREEN_PATHS.PAYABLE_SUCCESS);
    } catch (e) {
      const errorCode = e.response?.date?.errorCode;

      const isHandled = handleErrorCodes(errorCode, {
        errorCodes: [BANK_CONSENT_EXPIRED_ERROR_CODE],
      });

      if (!isHandled) {
        history.push(
          SCREEN_PATHS.PAYABLE_FAILURE +
            (errorCode !== INVALID_MAVERICK_SEC_ERROR_CODE ? "/invalid_sec" : ""),
        );
      }
    }
  };

  const payInvoice = async () => {
    dispatch(showLoader());

    const isOrgUpdated = await updateOrganisationDetails();

    if (isOrgUpdated) {
      return dispatch(hideLoader());
    }

    if (Provider.isMaverick) {
      await processMaverickSupplierPayment();
    } else {
      await processMoneyhubSupplierPayment();
    }

    dispatch(hideLoader());
  };

  return (
    <Page title="Supplier Payment">
      <ScreenHeader title="Supplier Payment" id="paymentConfirmTitle" />
      <ScreenHeaderSubtitle
        subtitle="Please check the details are correct before confirming payment."
        step="Confirm Supplier Payment"
      />

      <Box mb={3}>
        <Grid container>
          <Grid item lg={12} md={12} xs={12}>
            <Card elevation={12}>
              <CardHeader
                title="Supplier payment details"
                data-testid="supplier-payment-details-card-title"
              />
              <Divider />
              <CardContent>
                <Table>
                  <caption className="visuallyHidden">Supplier payment details table</caption>
                  <TableBody>
                    <TableRow>
                      <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                        <Typography variant="h6" data-testid="supplier-name-field-label">
                          {t("Payables.PayableInvoiceDetails.SupplierName.Label")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {selectedPayable?.supplierContact.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                        <Typography variant="h6" data-testid="sort-code-field-label">
                          {t("Payables.PayableInvoiceDetails.AccountSortCode.Label")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {bankAccountData?.sortCode}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                        <Typography variant="h6" data-testid="bank-account-number-field-label">
                          {t("Payables.PayableInvoiceDetails.AccountNumber.Label")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {bankAccountData?.accountNumber}
                      </TableCell>
                    </TableRow>
                    {bankAccountData?.accountType && (
                      <TableRow>
                        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                          <Typography variant="h6" data-testid="bank-account-number-field-label">
                            Account Type
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {bankAccountData.accountType}
                        </TableCell>
                      </TableRow>
                    )}
                    {bankAccountData?.secCode && (
                      <TableRow>
                        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                          <Typography variant="h6" data-testid="bank-account-number-field-label">
                            SEC Code
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {SEC_CODES_LABELS[bankAccountData.secCode]}
                        </TableCell>
                      </TableRow>
                    )}
                    {bankAccountData?.email && (
                      <TableRow>
                        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                          <Typography variant="h6" data-testid="bank-account-number-field-label">
                            Email
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>{bankAccountData.email}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                        <Typography variant="h6" data-testid="unique-reference-field-label">
                          Unique Reference
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {selectedPayable?.reference}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                        <Typography variant="h6" data-testid="amount-field-label">
                          Amount
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>{grossTotal}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                        <Typography variant="h6" data-testid="remaining-amount-field-label">
                          Remaining Amount
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>{remainingAmount}</TableCell>
                    </TableRow>
                    {!Provider.isMaverick && (
                      <TableRow>
                        <TableCell className={clsx(classes.tableCell, classes.smallCell)}>
                          <Typography variant="h6" data-testid="payer-account-field-label">
                            Make payment from this account
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {payerBankAccount?.account.identification}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <FooterActionsButtons
        backButtonText={Provider.isMaverick ? "Back to invoice details" : "Back to bank accounts"}
        handleBackButton={redirectToBack}
        continueButtonText="Pay Invoice"
        handleContinue={payInvoice}
        continueButtonDataTestId="pay-invoice-continue-btn"
        backButtonDataTestId="back-to-bank-accounts-btn"
      />

      {!Provider.isMaverick && (
        <Box mt={4} mb={4}>
          <MoneyhubFooter />
        </Box>
      )}
    </Page>
  );
};

export default PayableConfirmView;
