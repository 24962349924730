import CONFIG from "@APP/config";
import request from "@APP/services/api/request";
import { CustomForecastRequest, ForecastChartData, ForecastResponse } from "@APP/types";

export const generateCashflowForecast = async ({
  forecastEndDate,
}: {
  forecastEndDate: string;
}) => {
  const response = await request<{ id: string }>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/forecasting/cash-flow`,
    params: {
      to_date: forecastEndDate,
    },
  });
  return response.data;
};

export const fetchCashflowForecast = async ({ forecastId }: { forecastId: string }) => {
  const response = await request<ForecastResponse>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/forecasting/cash-flow/${forecastId}`,
  });

  return response;
};

export const fetchCustomCashflowForecast = async ({
  forecastId,
  payload,
}: {
  forecastId: string;
  payload: CustomForecastRequest;
}) => {
  const response = await request<{ forecast: ForecastChartData[] }>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/forecasting/cash-flow/${forecastId}`,
    data: {
      ...payload,
    },
  });

  return response.data;
};
