import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { fetchUserData, getErpId, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { API } from "@APP/services";
import { ErpId } from "@APP/constants";
import { getErrorMessageByErrorCode } from "@APP/utils";
import { ErrorCode } from "@APP/types";
import { CONSENT_REVOKED_ERROR_CODES } from "@APP/hooks/useHandleErrorCodes";

const ORG_COMPANY_VALIDATION_ERROR_CODES: ErrorCode[] = [6017, 6006, 6103, 6106, 1007, 1021];
const BANK_ACCOUNT_USING_ERROR_CODES: ErrorCode[] = [1232, 1233];

interface OrganisationDetailsOptions {
  errorCodesExcludedFromHandling?: number[];
}

const useOrganisationDetails = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const erpId = useSelector(getErpId);
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();

  const linkOrganisation = async () => {
    try {
      const currentErp = erpId || (await API.getCurrentERP()).currentErp;

      if (currentErp === ErpId.INTERNAL) return;

      await API.linkERPOrganisation(currentErp);
    } catch (err) {
      throw err;
    }
  };

  const getOrganisationDetails = async (
    { errorCodesExcludedFromHandling }: OrganisationDetailsOptions = {
      errorCodesExcludedFromHandling: [],
    },
  ) => {
    try {
      dispatch(showLoader());
      await linkOrganisation();

      const userData = await dispatch(fetchUserData());
      return userData;
    } catch (error) {
      const errorData = error.response?.data;

      if (ORG_COMPANY_VALIDATION_ERROR_CODES.includes(errorData?.errorCode)) {
        await dispatch(fetchUserData());

        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          getErrorMessageByErrorCode(errorData?.errorCode),
          [{ text: "Okay" }],
        );
      }

      if (CONSENT_REVOKED_ERROR_CODES.includes(errorData?.errorCode)) {
        await linkOrganisation();
        await dispatch(fetchUserData());

        return;
      }

      const isHandled = handleErrorCodes(errorData?.errorCode, {
        excludedErrorCodes: errorCodesExcludedFromHandling,
      });

      if (isHandled) return;

      if (errorData?.errorCode === 1101) throw errorData;

      throw errorData;
    } finally {
      dispatch(hideLoader());
    }
  };

  /**
   * Returns `true` or `false` dependetly needs to update
   * organisation details
   */
  const updateOrganisationDetails = async () => {
    try {
      const currentErp = erpId || (await API.getCurrentERP()).currentErp;

      if (currentErp !== ErpId.INTERNAL) {
        await API.linkERPOrganisation(currentErp);

        await dispatch(fetchUserData());
      }
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;

      if (BANK_ACCOUNT_USING_ERROR_CODES.includes(errorCode)) {
        const errorMessage = getErrorMessageByErrorCode(errorCode, "using");
        alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), errorMessage, [
          {
            text: "Okay",
          },
        ]);

        return true;
      }
    }

    return false;
  };

  return { getOrganisationDetails, updateOrganisationDetails };
};

export default useOrganisationDetails;
