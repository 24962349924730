import { SortType } from "@APP/types";
import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";

import SortIcon from "../SortIcon";

type Props = {
  children: string;
  active: boolean;
  type: SortType;
  boxProps?: BoxProps;
  typographyProps?: TypographyProps;
  typographyDataTestId?: string;
};

const SortableTitle = ({
  children,
  active,
  type,
  boxProps,
  typographyProps,
  typographyDataTestId,
}: Props) => (
  <Box className="semanticButton" role="button" alignItems="center" display="flex" {...boxProps}>
    <Typography
      component="span"
      variant="subtitle2"
      color="textPrimary"
      sx={{ fontWeight: 500 }}
      {...typographyProps}
      data-testid={typographyDataTestId}>
      {children}
    </Typography>
    <SortIcon active={active} type={type} />
  </Box>
);

export default SortableTitle;
