import { createActionCreator } from "deox";

export const setRegistrationEmail = createActionCreator(
  "@REGISTRATION/SET_EMAIL",
  (resolve) => (email: string) => resolve(email),
);

export const setRegistrationPhone = createActionCreator(
  "@REGISTRATION/SET_PHONE",
  (resolve) => (phone: string) => resolve(phone),
);

export const setRegistrationInitiated = createActionCreator(
  "@REGISTRATION/SET_INITIATED_STATE",
  (resolve) => (isInitiated: boolean) => resolve(isInitiated),
);

export const resetToDefaultState = createActionCreator(
  "@REGISTRATION/RESET_TO_DEFAULT_STATE",
  (resolve) => () => resolve(),
);
