import { TFunction } from "react-i18next";
import { CountryCode } from "libphonenumber-js";
import * as Yup from "yup";

import { getPhoneNumberValidationSchema, businessNameValidationSchema } from "@APP/utils";

export const ContactDetailsValidationSchema = (t: TFunction, countryCode: CountryCode) =>
  Yup.object().shape({
    businessName: businessNameValidationSchema(),
    contactEmail: Yup.string()
      .email(t("Errors.Common.Validation.InvalidEmail"))
      .required(t("Errors.Common.Validation.EmailRequired")),
    contactPhone: getPhoneNumberValidationSchema(countryCode, t).fields.phone,
  });
