import React from "react";
import { Box, Card, CardContent, CardHeader, Divider, Typography, capitalize } from "@mui/material";
import { useSelector } from "react-redux";

import { getErpId } from "@APP/redux";
import { NoteBox } from "@APP/components";

const ClearingLedgerContainer = ({ children }: { children: React.ReactNode }) => {
  const erpId = useSelector(getErpId);

  return (
    <Card elevation={4}>
      <CardHeader
        title="Clearing ledger in your Accounting Package"
        data-testid="clearing-ledger-header"
      />
      <Divider />
      <CardContent>
        <Box mb={2}>
          <NoteBox>
            <Typography variant="body2">
              You need a clearing ledger in your {capitalize(erpId!)} accounting package in order to
              create a payment request. When a card payment is authorised we will update your
              invoice to paid and add a payment to your clearing ledger. When the settlement occurs
              several days later the payment will be added to the account from your accounting
              package you have selected.
            </Typography>
          </NoteBox>
        </Box>
      </CardContent>
      {children}
    </Card>
  );
};

export default ClearingLedgerContainer;
