import { FocusEventHandler } from "react";
import { getDefaultOptions, format } from "date-fns";
import i18next from "i18next";
import { AsYouType } from "libphonenumber-js";

import CONFIG, { AvailableFeatures } from "@APP/config";
import { HandleErrorOptions } from "@APP/hooks/useHandleErrorCodes";
import { ERROR_CODE_OBJECTS } from "@APP/services/api";
import {
  Ledger,
  UserSubscription,
  BankAccountExtended,
  DateDefaultOptions,
  SubscriptionStatuses,
  ErrorCode,
  UserPermissionApiResponse,
  PermissionState,
} from "@APP/types";
import { ErpId, ORG_ADMIN_ROLE } from "@APP/constants";
import { AppState } from "@APP/redux";

/**
 * A wrapper around regular "setTimeout" that can be awaited inside async/await code block.
 * @param ms - milliseconds
 */
export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Returns greeting message based on current day time.
 */
export const getGreeting = (): string => {
  const hours = new Date().getHours();

  if (hours >= 4 && hours < 12) return "Good Morning";
  if (hours >= 12 && hours <= 18) return "Good Afternoon";

  return "Good Evening";
};

/**
 * Extracts error message text from the error object.
 * @param error
 * @param fallbackMessage - optional fallback message
 * @param config - { printServerErrorCode: boolean } - whether to print the code for server error
 */
export const formatErrorMessage = (
  error: any,
  fallbackMessage = i18next.t("Errors.Common.Alerts.Generic.Message"),
  config: { printServerErrorCode: boolean } = { printServerErrorCode: true },
): string => {
  let errorMessage = fallbackMessage;
  if (!error) {
    errorMessage = "";
  } else if (typeof error === "string") {
    errorMessage = error;
  } else if (Array.isArray(error)) {
    errorMessage = error.reduce((prev, curr) => `${prev}${prev ? ";" : ""} ${String(curr)}`, "");
  } else if (typeof error === "object") {
    errorMessage = error?.response?.data?.errorMessage ?? error?.message ?? fallbackMessage;
    if (config.printServerErrorCode && error?.response?.data?.errorCode) {
      errorMessage = errorMessage + errorCodeString(error?.response?.data?.errorCode);
    }
  }
  return errorMessage;
};

/**
 * Returns only digits from a string.
 * @param text - the string to strip off non-digit characters from
 */
export const getOnlyDigits = (text: string) => {
  return text
    .split("")
    .filter((digit) => /[0-9+]/.test(digit))
    .join("");
};

/**
 * Performs a currency format based on the given options
 * @param value - a value to format
 * @param options - format options
 * @returns formatted value
 */
export const formatCurrency = (
  value: string | number = "0",
  options?: Intl.NumberFormatOptions,
) => {
  return new Intl.NumberFormat("en-US", {
    ...options,
    style: "currency",
    currency: options?.currency || CONFIG.INPUTS.SUPPORTED_CURRENCIES[0],
  }).format(Number(value));
};

export const setIdTab = (index: number, name?: string): Record<string, string> => ({
  id: `tab-${index}`,
  "data-testid": "settings-tab-" + name,
  "aria-controls": `tabpanel-${index}`,
});

export enum STATUS_SETTLED {
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
}

export const capitalize = (str?: string | null) => {
  if (!str) return "";

  return str[0].toUpperCase() + str.slice(1);
};

export const getCurrencySymbol = (currency: string = CONFIG.INPUTS.SUPPORTED_CURRENCIES[0]) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  })
    .format(0)
    .replace(/[\d., ]/g, "");
};

//Replaces all commas, currency symbols to allow coercion to number and using parseFloat retains +/- on string as a number. Return ternary stops -£0 being given as a value, converting to £0.
export const getPriceInNumberFormat = (
  price: string,
  currency: string = CONFIG.INPUTS.SUPPORTED_CURRENCIES[0],
): number => {
  const priceAsFloat = parseFloat(
    price.replaceAll(",", "").replace(getCurrencySymbol(currency), ""),
  );
  return priceAsFloat === -0 ? 0 : priceAsFloat;
};

export const isLinkedLedger = (ERPLedgers: Ledger[], accountIdentification: String) =>
  ERPLedgers.some((ledger) => {
    return ledger.bankDetails?.accountNumber === accountIdentification;
  });

export const errorCodeString = (errorCode: ErrorCode) => {
  return errorCode ? `<br><small>Code - ${errorCode}</small>` : "";
};

export const errorCodeStringToast = (errorCode: ErrorCode) => {
  return errorCode
    ? `\n
    Code - ${errorCode}`
    : "";
};

export const getErrorMessageByErrorCode = (
  errorCode: ErrorCode,
  errorType: HandleErrorOptions["errorType"] = "linking",
  interpolatedValues?: Record<string, string>,
  isToast?: boolean,
) => {
  let error: string;
  if (ERROR_CODE_OBJECTS.includes(errorCode)) {
    error = i18next.t(`Errors.ErrorCodes.${errorCode}.Message`);
  } else if (errorCode === 1233) {
    error = i18next.t(`Errors.ErrorCodes.1233.${errorType}`);
  } else {
    error = i18next.t(`Errors.ErrorCodes.${errorCode}`, interpolatedValues);
  }
  error = error + (isToast ? errorCodeStringToast(errorCode) : errorCodeString(errorCode));
  return error;
};

export const calculateTotalAccountsBalance = (bankAccounts: BankAccountExtended[]) =>
  bankAccounts
    ?.map((bankAccount: BankAccountExtended) => Number(bankAccount.balance?.amount))
    .reduce((a, b) => a + b, 0);
/**
 * Checks whether activation of subscription is available or not
 */
export const checkSubscriptionActivationAvailable = (subscription?: UserSubscription | null) => {
  const STATUSES_FOR_SUBSCRIPTION_ACTIVATION: SubscriptionStatuses[] = [
    SubscriptionStatuses.Suspended,
    SubscriptionStatuses.Cancelled,
    SubscriptionStatuses.PendingCancellation,
  ];

  const STATUSES_FOR_SUBSCRIPTION_CANCELLATION: SubscriptionStatuses[] = [
    SubscriptionStatuses.Active,
    SubscriptionStatuses.Trial,
  ];

  if (!subscription) return true;

  if (STATUSES_FOR_SUBSCRIPTION_CANCELLATION.some((status) => status === subscription.status)) {
    return false;
  }

  return STATUSES_FOR_SUBSCRIPTION_ACTIVATION.some(
    (subscriptionStatus) => subscriptionStatus === subscription.status,
  );
};

export const handleKeyboardClick = (
  event: React.KeyboardEvent<HTMLElement>,
  callbackFn: () => void,
) => {
  if (event.key === "Enter" || event.key === " ") callbackFn();
};

/**
 * A handler for onFocus event helping to set 'aria-activedescendant' without additional implementation local state management
 * working directly with list element
 * @param event - event object is triggered by onFocus event
 */
export const handleAriaActiveDescendantChange: FocusEventHandler<Element> = (event) => {
  const listNode = event.target.parentElement;
  const optionNode = event.target;

  listNode?.setAttribute("aria-activedescendant", optionNode.getAttribute("id") || "");
};

/**
 * A wrapper around format that returns the date formatted to the locale set via CONFIG.DATE_LOCALE.
 * If date is not provided, returns undefined.
 * @param date? - date to format
 * @param formatString - format string, e.g. "dd/MM/yyyy"
 */
export const formatDisplayedDate = (date?: Date | string | null, formatString: string = "P") => {
  const dateLocaleOptions: DateDefaultOptions = getDefaultOptions();

  if (!date || (typeof date === "string" && date === "")) return undefined;
  return format(typeof date === "string" ? new Date(date) : date, formatString, dateLocaleOptions);
};

/**
 * Format phone numbers or return "-" if number is not provided, or returns the unformatted number if it's not a valid phone number.
 * @param phoneNumber - phone number to format
 * @param fallBackValue - value to return if phone number is null | undefined
 */
export const formatPhoneNumber = (
  unformattedPhoneNumber?: string | null,
  fallBackValue: string = "-",
  countryCode = CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE,
) => {
  try {
    if (!unformattedPhoneNumber || unformattedPhoneNumber === "") return fallBackValue;
    const asYouType = new AsYouType({ defaultCountry: countryCode });

    return asYouType.input(unformattedPhoneNumber);
  } catch (e) {
    return unformattedPhoneNumber;
  }
};

const nativeInvoicing: AvailableFeatures[] = CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION
  ? [
      "DASHBOARD",
      "NATIVE_INVOICING",
      "CUSTOMER_INVOICES",
      "PAYMENT_REQUEST",
      "WORKING_CAPITAL_FINANCE",
      "SETTINGS",
    ]
  : [
      "DASHBOARD",
      "NATIVE_INVOICING",
      "CUSTOMER_INVOICES",
      "PAYMENT_REQUEST",
      "MAKE_PAYMENT",
      "WORKING_CAPITAL_FINANCE",
      "SETTINGS",
    ];

export const getAvailableFeaturesBasedOnERP = (erp: ErpId) => {
  const INTERNAL_ERP_FEATURES: AvailableFeatures[] = CONFIG.FEATURES.GENERAL_FEATURES.includes(
    "NATIVE_INVOICING",
  )
    ? nativeInvoicing
    : ["DASHBOARD", "SETTINGS", "WORKING_CAPITAL_FINANCE"];

  const EXTERNAL_ERP_FEATURES: AvailableFeatures[] = [
    "DASHBOARD",
    "CASHFLOW_FORECAST",
    "CUSTOMER_INVOICES",
    "PAYMENT_REQUEST",
    "SUPPLIER_INVOICES",
    "MAKE_PAYMENT",
    "WORKING_CAPITAL_FINANCE",
    "SETTINGS",
  ];

  return CONFIG.FEATURES.GENERAL_FEATURES.filter((feature) => {
    return (erp === ErpId.INTERNAL ? INTERNAL_ERP_FEATURES : EXTERNAL_ERP_FEATURES).includes(
      feature,
    );
  });
};

export const checkUserRoles = (roles: string[], userRoles?: string[]) => {
  if (!userRoles) return false;

  // If Working Capital Finance is Enabled, then hide all the feature for Users Module
  if (CONFIG.FEATURES?.WORKING_CAPITAL_FINANCE_APPLICATION) return false;

  return roles.every((role) => userRoles.find((userRole) => userRole === role));
};

export const convertPermissionsApiResponseToState = ({
  permissionsState,
  response,
  user,
}: {
  permissionsState: PermissionState;
  response: UserPermissionApiResponse["payload"];
  user: AppState["auth"]["user"];
}): PermissionState => {
  const permissionStateCopy = { ...permissionsState };

  response.forEach(({ resource, action, status }) => {
    let newState = {
      ...permissionStateCopy[resource as keyof PermissionState],
      [action]: status === "Authorized",
    };

    if (
      (resource === "bank_account" ||
        resource === "accounting_package" ||
        resource === "organisation") &&
      checkUserRoles([ORG_ADMIN_ROLE], user?.roles || [])
    ) {
      newState = {
        ...permissionStateCopy[resource as keyof PermissionState],
        [action]: true,
      };
    }

    permissionStateCopy[resource as keyof PermissionState] = newState;
  });

  return permissionStateCopy;
};

export const openNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const cardPaymentCheck = (email: string | null | undefined): boolean => {
  const { RTP } = CONFIG?.FEATURES;

  if (RTP?.CARD_PAYMENT_SINGLE_USER && RTP.CARD_PAYMENT_EMAIL && email) {
    return email === RTP.CARD_PAYMENT_EMAIL;
  }

  if (RTP?.CARD_PAYMENTS) {
    return true;
  }

  return false;
};

export const isNativeInvoicingEnabled = (): boolean => {
  return CONFIG?.FEATURES?.GENERAL_FEATURES?.includes("NATIVE_INVOICING") ? true : false;
};
