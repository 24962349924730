import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { formatCurrency } from "@APP/utils";

import {
  ForecastInvoiceWithSelected,
  ForecastManualTransactionWithSelected,
} from "../CashflowForecastView";

type Props = {
  data: null | ForecastInvoiceWithSelected[] | ForecastManualTransactionWithSelected[];
  title: string;
  amountColor: "secondary" | "error";
};

const useStyles = makeStyles(() => ({
  unpaidInvoices: {
    fontSize: "2.2rem",
  },
}));

const UnpaidInvoiceContent = ({ data, title, amountColor }: Props) => {
  const classes = useStyles();

  if (!data?.length) return null;

  const balanceUnpaid = (
    data as Array<ForecastInvoiceWithSelected | ForecastManualTransactionWithSelected>
  ).reduce((acc, item) => (item.selected ? acc + Number(item.amount.amount) : acc), 0);

  return (
    <Box
      pt={1}
      pb={2}
      data-testid={`due-content-${title.replace(/[\s\n]/g, "-").toLowerCase()}`}
      id={`dueContent${title.replace(/[\s\n]/g, "-").toLowerCase()}`}
      display="flex"
      justifyContent="center">
      <Typography
        className={classes.unpaidInvoices}
        variant="h3"
        component="h2"
        color={clsx(amountColor, "styleOverdue")}>
        {formatCurrency(balanceUnpaid.toString(), {
          currency: data[0].amount.currency,
        })}
      </Typography>
      <Box display="flex" ml={4} alignItems="center">
        <Typography
          variant="body2"
          component="h4"
          align="center"
          style={{ whiteSpace: "pre-line" }}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default UnpaidInvoiceContent;
