import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { FooterActionsButtons, ResultNotification } from "@APP/components";
import { getPermissions, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { useAccessPermission } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { TabsName } from "@APP/constants";

const SuccessCreationUser = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { fetchPermission } = useAccessPermission();
  const permissions = useSelector(getPermissions);

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      await fetchPermission([{ user: "create" }]);
      dispatch(hideLoader());
    })();
  }, []);

  return (
    <>
      <ResultNotification type="success" title="User successfully created">
        They will receive a notification to their email inbox with the link to setup their
        credentials for the account.
      </ResultNotification>
      <FooterActionsButtons
        backButtonText="View Users"
        handleBackButton={() => history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.USERS_LIST}`)}
        continueButtonText="Add User"
        hiddenContinueButton={!permissions?.user?.create}
        handleContinue={() => history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.CREATE_USER}`)}
      />
    </>
  );
};

export default SuccessCreationUser;
