import React, { FocusEvent } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { OnValueChange } from "react-number-format/types/types";

import {
  getForecastBalances,
  getForecastLoading,
  getForecastManualBalanceMode,
  setManualBalanceMode,
  useAppDispatch,
} from "@APP/redux";
import { BankAccountsTiled, NumberFormatCustom, DisplayedBankData } from "@APP/components";
import { formatCurrency, getCurrencySymbol } from "@APP/utils";
import CONFIG from "@APP/config";

export interface ForecastAccountProps {
  onTryAgain: () => void;
  handleOnSubmit: () => void;
  handleBalanceChange: OnValueChange;
  handleBlur: (e: FocusEvent) => void;
  totalAccountBalance: string;
  errors: FormikErrors<{ totalAccountBalance: string }>;
  touched: FormikTouched<{ totalAccountBalance: string }>;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<{ totalAccountBalance: string }>> | Promise<void>;
  initialAccountBalance: string;
}

const useStyles = makeStyles((theme) => ({
  linkButton: {
    ...theme.typography.body1,
  },
}));

const ForecastAccounts = ({
  onTryAgain,
  handleBalanceChange,
  totalAccountBalance,
  handleOnSubmit,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  initialAccountBalance,
}: ForecastAccountProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLessThenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const forecastBalances = useSelector(getForecastBalances);
  const forecastLoading = useSelector(getForecastLoading);
  const manualBalanceMode = useSelector(getForecastManualBalanceMode);

  const backFromManualMode = async () => {
    dispatch(setManualBalanceMode(false));
    await setFieldValue("totalAccountBalance", initialAccountBalance);
    handleOnSubmit();
  };

  const renderCardContent = () => {
    if (!manualBalanceMode && !forecastBalances?.items?.length) {
      return (
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            role="region"
            aria-live="polite">
            <Box maxWidth="300px" textAlign="center">
              <Typography id="forecastAccountTitle">
                {t("CashFlowForecast.ForecastAccounts.NoForecastAccountsTitle")}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onTryAgain()}
                id="forecastAccountTryAgainButton">
                Try again
              </Button>
            </Box>
            <Box mt={2}>
              <Link
                component="button"
                type="button"
                aria-label="update your forecast by providing a manual balance"
                className={classes.linkButton}
                onClick={() => dispatch(setManualBalanceMode(true))}
                id="forecastAccountManuallyButton">
                Enter {t("CashFlowForecast.TotalAccountBalance")} manually →
              </Link>
            </Box>
          </Box>
        </CardContent>
      );
    }

    if (manualBalanceMode) {
      return (
        <CardContent>
          <Box height={200} role="region" aria-live="polite">
            <Link
              component="button"
              type="button"
              aria-label="abandon updating balance manually"
              className={classes.linkButton}
              onClick={backFromManualMode}
              id="forecastAccountBackButton">
              ← Back
            </Link>
            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" flexDirection={isLessThenSmall ? "column" : "row"}>
                  <Box width="100%" display="flex" alignItems="center" mr={isLessThenSmall ? 0 : 2}>
                    <Typography variant="h6" noWrap>
                      Input your {t("CashFlowForecast.TotalAccountBalance")}:{" "}
                    </Typography>
                  </Box>
                  <NumberFormatCustom
                    label={t("CashFlowForecast.TotalAccountBalance")}
                    type="text"
                    fullWidth
                    // value={totalAccountBalance}
                    defaultValue={totalAccountBalance}
                    name="totalAccountBalance"
                    onBlur={handleBlur}
                    onValueChange={handleBalanceChange}
                    decimalScale={2}
                    allowNegative
                    thousandSeparator
                    error={Boolean(touched.totalAccountBalance && errors.totalAccountBalance)}
                    helperText={touched.totalAccountBalance && errors.totalAccountBalance}
                    inputProps={{
                      "data-testid": "total-account-balance-input",
                    }}
                    prefix={getCurrencySymbol(CONFIG.INPUTS.SUPPORTED_CURRENCIES[0])}
                    variant="outlined"
                    id="forecastAccountBalance"
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    color="primary"
                    id="forecastAccountGetForecastButton">
                    Get Forecast
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      );
    }

    if (forecastBalances?.items.length) {
      const bankAccountsFromForecastBalance = forecastBalances?.items?.map(
        ({ accountIdentification: accountNumber, accountName, balance, bankId: fullName, logo }) =>
          ({
            accountNumber,
            accountName,
            balance,
            bankInfo: { fullName, logo },
          } as DisplayedBankData),
      );

      return (
        <Box>
          <Box p={2} display="flex" justifyContent="center">
            <Typography variant="h1" component="h2" color="secondary">
              {formatCurrency(forecastBalances?.totalBalance?.amount || totalAccountBalance, {
                currency: forecastBalances?.totalBalance?.currency,
              })}
            </Typography>
            <Box display="flex" ml={4} alignItems="center" maxWidth="200px">
              <Typography
                variant="body2"
                component="h3"
                align="center"
                style={{ whiteSpace: "pre-line" }}>
                Your {t("CashFlowForecast.TotalAccountBalance")} according to our calculations
              </Typography>
            </Box>
          </Box>
          <BankAccountsTiled bankAccounts={bankAccountsFromForecastBalance!} />
        </Box>
      );
    }
  };

  const renderFooterContent = () => {
    if (
      (forecastLoading && !forecastBalances) ||
      manualBalanceMode ||
      !forecastBalances?.items?.length
    ) {
      return null;
    }

    return (
      <>
        <Divider />
        <CardActions>
          <Box p={2} width="100%" role="region" aria-live="polite">
            <Box width="100%" display="flex" justifyContent="center" height="100%">
              <Box textAlign="center">
                <Typography variant="body1" color="textPrimary">
                  If the calculation is wrong, you can
                </Typography>
                <Link
                  component="button"
                  type="button"
                  aria-label="update your forecast by providing a manual balance"
                  className={classes.linkButton}
                  onClick={() => dispatch(setManualBalanceMode(true))}
                  id="forecastEnterManually">
                  Enter {t("CashFlowForecast.TotalAccountBalance")} manually →
                </Link>
              </Box>
            </Box>
          </Box>
        </CardActions>
      </>
    );
  };

  return (
    <Card elevation={4}>
      <CardHeader
        subheader={t("CashFlowForecast.ForecastAccounts.CardSubheader")}
        subheaderTypographyProps={{
          "data-testid": "card-header-forecast-linked-bank-subtitle",
        }}
        title="Account Balances"
        titleTypographyProps={{ "data-testid": "card-header-forecast-linked-bank-title" }}
        data-testid="bank-accounts-forecast-card-header"
      />
      <Divider />
      <form onSubmit={handleOnSubmit}>
        {renderCardContent()}
        {renderFooterContent()}
      </form>
    </Card>
  );
};

export default ForecastAccounts;
