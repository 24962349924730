import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  fetchUserData,
  getCardPaymentsCustodians,
  getPermissions,
  getUser,
  hideLoader,
  setClearingLedger,
  setDefaultAutomatedCollectionsState,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { API } from "@APP/services";
import { ErpId, TabsName, ErpConsentStatusParams, ErpConsent, Provider } from "@APP/constants";
import { CardPaymentApplicationStatus, Custodian } from "@APP/types";
import { useAccessPermission, useAlert } from "@APP/hooks";
import { cardPaymentCheck, formatErrorMessage } from "@APP/utils";
import {
  getCurrentERPConsent,
  NO_CLEARING_LEDGER_CREATED_ERROR_CODE,
  revokeERPConsent,
} from "@APP/services/api";
import CONFIG from "@APP/config";
import { Center } from "@APP/views/common";

import Ledger from "./Ledger";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  avatar: {
    height: 100,
    width: 100,
    borderRadius: 0,
  },
  loaderContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    fontSize: 100,
  },
}));

const CurrentAccountingPackage = () => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchAllPermissions } = useAccessPermission();
  const permissions = useSelector(getPermissions);

  const user = useSelector(getUser);
  const cardPaymentsCustodians = useSelector(getCardPaymentsCustodians);
  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(true);
  const [accountingPackage, setAccountingPackage] = useState<Custodian | null>(null);

  const getAccountingPackage = async (erpId: ErpId) => {
    try {
      const erpData = await API.getCustodian(erpId);
      setAccountingPackage(erpData);
    } catch (error) {
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    }
  };

  const getClearingLedgers = async (erpId: ErpId) => {
    try {
      const data = await API.fetchClearingLedger(erpId);
      dispatch(setClearingLedger(data));
    } catch (error) {
      const errorData = error?.response?.data;

      if (errorData.errorCode !== NO_CLEARING_LEDGER_CREATED_ERROR_CODE) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (user?.erp && user?.erp !== ErpId.INTERNAL) {
        await fetchAllPermissions();
        await getAccountingPackage(user.erp);

        const username = user?.username || "";
        if (
          (cardPaymentCheck(username) &&
            user.cardPaymentApp?.status === CardPaymentApplicationStatus.Approved) ||
          (Provider.isMoneyhub && !!cardPaymentsCustodians.length)
        ) {
          await getClearingLedgers(user.erp);
        }

        setLoading(false);
      } else {
        setLoading(false);
        history.push({
          pathname: SCREEN_PATHS.SETTINGS,
          search: `tab=${TabsName.SETUP_ACCOUNTING_PACKAGE}`,
        });
      }
    })();
  }, [user?.erp]);

  const onDisconnectWarning = () => {
    alert.open(
      "Are you sure?",
      "This action will revoke the connection to the accounting package. Any ongoing payment requests will not be reconciled in your accounting package.",
      [{ text: "Cancel" }, { text: "Yes", onClick: handleConsentRevoke }],
    );
  };

  const handleConsentRevoke = async () => {
    try {
      dispatch(showLoader());
      if (user?.erp && user?.erp !== ErpId.INTERNAL) {
        const { data: authorizedConsents } = await getCurrentERPConsent(
          accountingPackage!.id,
          ErpConsentStatusParams.AUTHORIZED,
        );
        await authorizedConsents.forEach((consent: ErpConsent) =>
          revokeERPConsent(user!.erp!.toString(), consent.consentId),
        );

        dispatch(setDefaultAutomatedCollectionsState());
        dispatch(hideLoader());
        alert.open("Success", "Your accounting package was successfully disconnected.", [
          { text: "Okay", onClick: handleAfterConsentRevoked },
        ]);
      } else {
        throw new Error();
      }
    } catch {
      dispatch(hideLoader());
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        t("Errors.AccountingPackageLinking.Alerts.APDisconnect.Message"),
        [{ text: "Okay" }],
      );
    }
  };

  if (!accountingPackage) return null;

  const handleAfterConsentRevoked = async () => {
    await dispatch(fetchUserData());
    setAccountingPackage(null);

    if (
      !CONFIG.FEATURES.REGISTRATION.MOBILE_FLOW_ENABLED &&
      !CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING")
    ) {
      history.push(SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE);
    }
  };

  return (
    <>
      <Card className={classes.root} elevation={4}>
        {loading && (
          <Box className={classes.loaderContainer}>
            <CircularProgress color="primary" size={60} />
          </Box>
        )}
        <CardHeader
          title="Accounting Package"
          subheader="Current accounting package."
          data-testid="accounting-package-card-header"
          id="currentAccountPackageTitle"
        />
        <Divider />
        <CardContent>
          <Box alignItems="center" textAlign="center" display="flex" flexDirection="column">
            <Box mb={2}>
              <Avatar
                className={classes.avatar}
                src={accountingPackage.logo}
                alt={accountingPackage.fullName}
                imgProps={{ style: { objectFit: "contain" } }}
              />
            </Box>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
              component="p"
              data-testid={`accounting-package-${accountingPackage.shortName.toLowerCase()}`}>
              {accountingPackage.shortName}
            </Typography>
          </Box>
        </CardContent>
        {permissions?.accounting_package?.view && permissions?.accounting_package?.delete ? (
          <>
            <Divider />
            <CardActions>
              <Center
                gap={1}
                sx={{
                  ...(isSmSizeScreen && {
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }),
                }}>
                <Button
                  color="primary"
                  variant="contained"
                  href={accountingPackage.website}
                  id="ap-go-to-website-button"
                  data-testid="go-to-website-button"
                  className="minWidth">
                  Go To {accountingPackage.shortName} Website
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onDisconnectWarning}
                  id="ap-go-to-website-button"
                  data-testid="go-to-website-button"
                  className="minWidth">
                  Disconnect from {accountingPackage.shortName.toUpperCase()}
                </Button>
              </Center>
            </CardActions>
          </>
        ) : (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "32px",
              textAlign: "center",
              wordBreak: "break-word",
              paddingTop: "16px",
            }}
            variant="h6"
            component="p"
            color="secondary">
            Please ask your administrator(s) to unlink your account.
          </Typography>
        )}
      </Card>
      <Ledger />
    </>
  );
};

export default CurrentAccountingPackage;
