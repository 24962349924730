import { createReducer } from "deox";

import { BankAccountExtended, Payable } from "@APP/types";
import {
  MaverickPaymentAccountSecCode,
  MaverickPaymentAccountType,
  PaymentInitiation,
} from "@APP/types/paybles";

import {
  setBankAccountData,
  setInitialData,
  setPayerBankAccount,
  setPaymentInitiation,
  setSelectedPayableData,
  setSupplierName,
} from "../actions";

export interface PayableState {
  selectedPayable: null | Payable;
  bankAccountData: null | {
    sortCode: string;
    accountNumber: string;
    accountType?: MaverickPaymentAccountType;
    secCode?: MaverickPaymentAccountSecCode;
    email?: string;
  };
  payerBankAccount: null | BankAccountExtended;
  paymentInitiation: null | PaymentInitiation;
}

const defaultState: PayableState = {
  selectedPayable: null,
  bankAccountData: null,
  payerBankAccount: null,
  paymentInitiation: null,
};

const payableReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setSelectedPayableData, (state, { payload }) => ({
    ...state,
    selectedPayable: payload,
  })),
  handleAction(setBankAccountData, (state, { payload }) => ({
    ...state,
    bankAccountData: payload,
  })),
  handleAction(setPayerBankAccount, (state, { payload }) => ({
    ...state,
    payerBankAccount: payload,
  })),
  handleAction(setPaymentInitiation, (state, { payload }) => ({
    ...state,
    paymentInitiation: payload,
  })),
  handleAction(setSupplierName, (state, { payload }) => ({
    ...state,
    selectedPayable: state.selectedPayable
      ? {
          ...state.selectedPayable,
          supplierContact: {
            ...state.selectedPayable.supplierContact,
            name: payload,
          },
        }
      : null,
  })),
  handleAction(setInitialData, () => defaultState),
]);

export default payableReducer;
