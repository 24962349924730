import { useEffect, useRef } from "react";
import {
  Box,
  BoxProps,
  Table as MUITable,
  TableBody,
  TableHead,
  TableContainer,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";

import { PaginationPanel } from "@APP/components";
import { InputProps } from "@mui/material/Input";

interface Props<T> {
  /**
   * Array of data objects.
   */
  data: T[];
  /**
   * A function that returns the JSX elements for each row of the table.
   */
  renderHeader: () => JSX.Element;
  /**
   * A function that returns the JSX elements for each row of the table.
   */
  renderRows: (item: T, index: number) => JSX.Element;
  /**
   * Props for styling table container.
   */
  containerProps?: BoxProps;
  /**
   * Whether to render pagination footer - true by default.
   */
  showPagination?: boolean;
  /**
   * Number of rows per page.
   */
  entries?: number;
  /**
   * Zero-based index.
   */
  page?: number;
  /**
   * Handler for changing the entries value.
   */
  onEntriesChange?: (newEntries: number) => void;
  /**
   * Handler for changing pages.
   */
  onPageChange?: (newPage: number) => void;
  /**
   * A number that indicates the last page.
   */
  lastPage?: number;
  /**
   * Props for styling table container. When filtering is enable and data is empty
   */
  renderEmptyDataRow?: () => JSX.Element | undefined;

  /**
   * Props for styling overrides for table
   */
  styleOverrides?: ClassNameMap;
  /**
   * Limits the maximum height of the table body
   */
  maxHeightTableBody?: number;
  /**
   * Props for select`s input element
   */
  paginationSelectInputProps?: InputProps["inputProps"];
  /**
   * Optional hidden caption string to be used in MUI Table, for accessibility purposes.
   */
  hiddenCaption?: string;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    height: "100%",
  },
  tableBody: {
    overflowY: "auto",
    "& tr": {
      "&:focus": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  paginationPanelItem: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.action.active,
        opacity: 0.6,
      },
    },
  },
}));

const Table = <T,>({
  data,
  renderHeader,
  renderRows,
  showPagination = true,
  page = 0,
  entries = 15,
  lastPage = 1,
  onEntriesChange,
  onPageChange,
  containerProps = {},
  renderEmptyDataRow,
  styleOverrides,
  maxHeightTableBody,
  paginationSelectInputProps,
  hiddenCaption,
}: Props<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDisplayMoreMd = useMediaQuery(theme.breakpoints.up("md"));
  const tableRef = useRef<HTMLDivElement>(null);

  // Scrolls the contents of the table up when the page changes.
  useEffect(() => {
    tableRef?.current?.scrollTo(0, 0);
  }, [data]);

  return (
    <Box {...containerProps}>
      <TableContainer
        ref={tableRef}
        className={classes.container}
        style={{ maxHeight: maxHeightTableBody }}>
        <MUITable stickyHeader={isDisplayMoreMd} className={(classes.table, styleOverrides?.table)}>
          <TableHead>{renderHeader()}</TableHead>
          <TableBody className={classes.tableBody}>
            {!data.length && !!renderEmptyDataRow ? renderEmptyDataRow() : data.map(renderRows)}
          </TableBody>
          {hiddenCaption && <caption className="visuallyHidden">{hiddenCaption}</caption>}
        </MUITable>
      </TableContainer>

      {showPagination && (
        <PaginationPanel
          entries={entries}
          onEntriesChange={onEntriesChange}
          rowsPerPageOptions={[5, 10, 15, 25]}
          lastPage={lastPage}
          page={page}
          paginationSelectInputProps={paginationSelectInputProps}
          onPageChange={onPageChange}
        />
      )}
    </Box>
  );
};

export default Table;
