import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, CardContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LinkOffIcon from "@mui/icons-material/LinkOff";

import { getPermissions } from "@APP/redux";

const useStyles = makeStyles(() => ({
  linkOffIcon: {
    fontSize: 100,
  },
}));

const NoLinkedBankAccountsContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const permissions = useSelector(getPermissions);

  return (
    <CardContent>
      <Box alignItems="center" display="flex" flexDirection="column">
        <LinkOffIcon
          className={classes.linkOffIcon}
          color="disabled"
          id="noLinkedBankAccountsIcon"
        />
        <Typography
          variant="body2"
          align="center"
          gutterBottom
          data-testid="please-link-bank-accounts-you-want-text"
          id="noLinkedBankAccountsText">
          {t(
            permissions.bank_account.create
              ? "CommonViews.NoLinkedBankAccountsContent"
              : "CommonViews.NoLinkedBankAccountsContentNoAuth",
          )}
        </Typography>
      </Box>
    </CardContent>
  );
};

export default NoLinkedBankAccountsContent;
