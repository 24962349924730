import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

import { Page, TermsAndConditions } from "@APP/components";
import { useAlert } from "@APP/hooks";
import { fetchUserData, getUser, hideLoader, showLoader } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { acceptTermsAndConditions } from "@APP/services/api";

export const UserTermsAndConditions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const alert = useAlert();
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const handleTermsAndConditionsAccept = async () => {
    try {
      dispatch(showLoader);
      await acceptTermsAndConditions();
      dispatch(fetchUserData());
      dispatch(hideLoader);
    } catch {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        "Sorry, we were unable to save your agreement to the Terms & Conditions. Please try again later.",
        [{ text: "Okay" }],
      );
    }
  };

  // Checks the updated T&C status before attempting to navigate to dashboard.
  useEffect(() => {
    user?.termsAndConditionsAccepted && history.push(SCREEN_PATHS.DASHBOARD);
  }, [user?.termsAndConditionsAccepted]);

  return (
    <Page title="Accept Terms & Conditions">
      <Card elevation={4}>
        <CardHeader
          title="Terms & Conditions"
          data-testid="user-Terms-conditions-header"
          id="userTermsConditionsTitle"
        />
        <Divider />
        <CardContent>
          <TermsAndConditions onSubmit={handleTermsAndConditionsAccept} />
        </CardContent>
      </Card>
    </Page>
  );
};

export default UserTermsAndConditions;
