import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { CountryCode } from "libphonenumber-js/types";
import { getCountryCallingCode } from "libphonenumber-js";

import {
  CustomerDetailsForm,
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";
import { CustomerValidationSchema } from "@APP/utils";
import {
  getInvoice,
  setAddressInformation,
  setDeliveryEmails,
  setPayerEmail,
  setPayerName,
  setPayerPhone,
  useAppDispatch,
  setVatNumber,
} from "@APP/redux";

const InvoiceCreateCustomer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [countryCode, setCountryCode] = useState<CountryCode>(
    CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE,
  );
  const { customerContact } = useSelector(getInvoice);

  const { errors, handleBlur, handleChange, setFieldValue, handleSubmit, touched, values } =
    useFormik({
      initialValues: {
        name: customerContact?.name || "",
        email: customerContact?.email || "",
        mobile: customerContact?.mobile || "",
        address: customerContact?.shippingAddress?.addressLines?.[0] || "",
        addressLine2: customerContact?.shippingAddress?.addressLines?.[1] || "",
        state: customerContact?.shippingAddress?.state,
        city: customerContact?.shippingAddress?.city || "",
        postcode: customerContact?.shippingAddress?.postcode || "",
        vatNumber: customerContact?.vatNumber || "",
      },
      validationSchema: CustomerValidationSchema(countryCode, t),
      onSubmit: ({
        name,
        email,
        mobile,
        address,
        addressLine2,
        state,
        city,
        postcode,
        vatNumber,
      }) => {
        dispatch(setPayerName(name));
        dispatch(setPayerEmail(email || undefined));
        dispatch(setDeliveryEmails({ email }));
        dispatch(
          setPayerPhone(
            mobile.replace(`+${getCountryCallingCode(countryCode)}`, "") !== ""
              ? mobile
              : undefined,
          ),
        );

        dispatch(
          setAddressInformation({
            addressLines: address || addressLine2 ? [address, addressLine2] : [],
            state: state || undefined,
            city: city || undefined,
            postcode: postcode || undefined,
          }),
        );

        dispatch(setVatNumber(vatNumber));

        history.push(SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS);
      },
    });

  return (
    <Page title="Create Customer">
      <form onSubmit={handleSubmit}>
        <ScreenHeader title="Create Customer" id="invoiceCreateCustomerTitle" />
        <ScreenHeaderSubtitle subtitle={t("Invoice.CreateCustomer.ScreenHeaderSubtitle")} />
        <CustomerDetailsForm
          values={{ ...values, state: customerContact?.shippingAddress?.state }}
          touched={touched}
          errors={errors}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <FooterActionsButtons
          backButtonText="Back to Customers"
          handleBackButton={() => history.goBack()}
          disabledContinueButton={!values.name}
          typeButtonContinue="submit"
          continueButtonText="Continue"
        />
      </form>
    </Page>
  );
};

export default InvoiceCreateCustomer;
