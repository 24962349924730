import { createActionCreator } from "deox";

import { PayableState } from "@APP/redux/reducers/payable";
import { Payable, BankAccountExtended } from "@APP/types";
import { PaymentInitiation } from "@APP/types/paybles";

export const setSelectedPayableData = createActionCreator(
  "@PAYABLE/SET_SELECTED_PAYABLE_DATA",
  (resolve) => (payableData: Payable) => resolve(payableData),
);

export const setBankAccountData = createActionCreator(
  "@PAYABLE/SET_BANK_ACCOUNT_DATA",
  (resolve) => (bankAccountData: PayableState["bankAccountData"]) => resolve(bankAccountData),
);

export const setPayerBankAccount = createActionCreator(
  "@PAYABLE/SET_PAYER_BANK_ACCOUNT",
  (resolve) => (payerBankAccount: BankAccountExtended) => resolve(payerBankAccount),
);

export const setPaymentInitiation = createActionCreator(
  "@PAYABLE/SET_PAYMENT_INITIATION",
  (resolve) => (paymentInitiation: PaymentInitiation) => resolve(paymentInitiation),
);

export const setSupplierName = createActionCreator(
  "@PAYABLE/SET_SUPPLIER_NAME",
  (resolve) => (supplierName: string) => resolve(supplierName),
);

export const setInitialData = createActionCreator(
  "@PAYABLE/SET_INITIAL_DATA",
  (resolve) => () => resolve(),
);
