import React from "react";
import { useTranslation } from "react-i18next";
import Receipt from "@mui/icons-material/Receipt";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import { Box, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";

import CONFIG from "@APP/config";
import { getErpId } from "@APP/redux";
import { ErpId } from "@APP/constants";
import { PermissionState } from "@APP/types";

const AvailableFeaturesOnlyForExternalErp: { title: string; icon: React.ElementType }[] = [
  {
    icon: Receipt,
    title: "Customer Invoices",
  },
  {
    icon: Receipt,
    title: "Supplier Invoices",
  },
  {
    icon: ShowChartIcon,
    title: "Cash Flow Forecast",
  },
  {
    icon: AccountBalanceWallet,
    title: "Payment Requests Management",
  },
];

const AccountingPackageSetupHeader = ({
  headerText,
  subHeaderText,
  error,
  permissions,
}: {
  headerText: string;
  subHeaderText: string;
  error?: string | (string | null)[] | null;
  permissions?: PermissionState;
}) => {
  const erpId = useSelector(getErpId);
  const { t } = useTranslation();

  const internalOrNoErpSelected = erpId === ErpId.INTERNAL || erpId === ErpId.NO_ERP;

  return (
    <Box textAlign="center" p={3}>
      <Box mb={2}>
        <LinkIcon sx={{ fontSize: 120 }} color="primary" id="accountingPacketSetupLinkIcon" />
      </Box>
      <Typography
        align="center"
        color="textPrimary"
        variant="h4"
        gutterBottom
        data-testid="setup-your-accounting-package-title"
        id="accountingPacketSetupTitle">
        {headerText}
      </Typography>
      <Box mt={3}>
        {permissions?.accounting_package?.create ? (
          <Typography
            align="center"
            color="textSecondary"
            variant="body2"
            data-testid="please-select-an-accounting-package-text"
            id="accountingPackageSetupText">
            {error === "access_denied"
              ? t("Setup.SetupAccountingPackage.SelectAccountingPackageErrorText")
              : subHeaderText}
          </Typography>
        ) : (
          <Typography sx={{ paddingLeft: "2px" }} variant="h6" component="p" color="secondary">
            Please ask your administrator(s) to link your account.
          </Typography>
        )}

        {(!error || internalOrNoErpSelected) &&
          CONFIG.FEATURES.GENERAL_FEATURES.includes("MULTI_CHANNEL") &&
          !CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING") && (
            <Typography
              align="center"
              color="textSecondary"
              variant="body2"
              data-testid="please-select-an-accounting-package-text"
              id="accountingPackageSetupPreview">
              After you link your accounting package, you will get access to the following features
              in {CONFIG.PRODUCT_NAME} web app:
            </Typography>
          )}
      </Box>
      {(!error || internalOrNoErpSelected) &&
        CONFIG.FEATURES.GENERAL_FEATURES.includes("MULTI_CHANNEL") &&
        !CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING") && (
          <>
            <Box mt={3} display="flex" justifyContent="space-between">
              {AvailableFeaturesOnlyForExternalErp.map(({ icon: Icon, title }) => (
                <Box
                  key={title}
                  px={1.5}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  width="25%"
                  id="availableFeaturesExternalErpWrapper">
                  <Icon
                    aria-label={`${title} icon`}
                    color="primary"
                    fontSize="large"
                    id="availableFeaturesExternalErpIcon"
                  />
                  <Typography variant="caption" id="availableFeaturesExternalErpText">
                    {title}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        )}
    </Box>
  );
};

export default AccountingPackageSetupHeader;
