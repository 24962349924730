import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { Step, StepLabel, Stepper } from "@mui/material";
import { getWorkingCapitalFinanceDetails } from "@APP/redux";

function WcfStepper({ steps = 5, style }: Readonly<{ steps?: number; style?: CSSProperties }>) {
  const { activeStep } = useSelector(getWorkingCapitalFinanceDetails);

  return (
    <Stepper activeStep={activeStep} sx={{ ...style }}>
      {[...Array(steps).keys()].map((index) => (
        <Step key={index}>
          <StepLabel />
        </Step>
      ))}
    </Stepper>
  );
}

export default WcfStepper;
