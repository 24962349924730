import { updateInvoiceStatus } from "@APP/services/api";
import { InvoiceType, Receivable } from "@APP/types";
import { getUser, hideLoader, useAppDispatch } from "@APP/redux";
import { useSelector } from "react-redux";

const useCreateInvoice = () => {
  const dispatch = useAppDispatch();

  const user = useSelector(getUser);

  const approveReceivable = async (receivable: Receivable) => {
    try {
      await updateInvoiceStatus(
        user?.erp!,
        receivable.entityDetails.externalId,
        InvoiceType.Receivables,
        {
          status: "Approved",
        },
      );
    } catch (err) {
      dispatch(hideLoader());
      throw err;
    }
  };

  return { approveReceivable };
};

export default useCreateInvoice;
