export const countries = [{ label: "United Kingdom", value: "GB" }];

export const industries = [
  { label: "Agriculture, Forestry and Fishing", value: "agriculture_forestry_and_fishing" },
  { label: "Architecture and Engineering", value: "architecture_and_engineering" },
  { label: "Business Support Services", value: "business_support_services" },
  { label: "Car Dealers, Garages and Mechanics", value: "car_dealers_garages_and_mechanics" },
  { label: "Cleaning, Landscaping and Gardening", value: "cleaning_landscaping_and_gardening" },
  { label: "Construction", value: "construction" },
  { label: "Education and Training", value: "education_and_training" },
  { label: "Finance and Insurance", value: "finance_and_insurance" },
  { label: "Health and Care", value: "health_and_care" },
  { label: "Hotels, Restaurants and Pubs", value: "hotels_restaurants_and_pubs" },
  { label: "IT and Communications", value: "it_and_communications" },
  { label: "Leisure and Other Services", value: "leisure_and_other_services" },
  {
    label: "Manufacturing, Food Processing and Printing",
    value: "manufacturing_food_processing_and_printing",
  },
  { label: "Professional Services", value: "professional_services" },
  { label: "Property and Real Estate", value: "property_and_real_estate" },
  { label: "Publishing, Film and Media Production", value: "publishing_film_and_media_production" },
  { label: "Retail", value: "retail" },
  { label: "Recruitment Agencies", value: "recruitment_agencies" },
  { label: "Transport and Logistics", value: "transport_and_logistics" },
  { label: "Wholesale", value: "wholesale" },
  { label: "Other", value: "other" },
];

export const urgencyLevels = [
  { label: "As soon as possible", value: "asap" },
  { label: "In the following weeks", value: "in_the_following_weeks" },
  { label: "In the following months", value: "in_the_following_months" },
  { label: "Other", value: "other" },
];

export const assetTypes = [
  {
    label: "Access machinery and equipment",
    value: "access_machinery_and_equipment",
  },
  {
    label: "Agricultural forestry landscaping machinery and equipment",
    value: "agricultural_forestry_landscaping_machinery_and_equipment",
  },
  {
    label: "Audio visual and broadcasting equipment",
    value: "audio_visual_and_broadcasting_equipment",
  },
  {
    label: "Building systems and components",
    value: "building_systems_and_components",
  },
  {
    label: "Buses and coaches",
    value: "buses_and_coaches",
  },
  {
    label: "Cars and motorcycles",
    value: "cars_and_motorcycles",
  },
  {
    label: "Commercial equipment and tools",
    value: "commercial_equipment_and_tools",
  },
  {
    label: "Commercial furniture and fittings",
    value: "commercial_furniture_and_fittings",
  },
  {
    label: "Computer software",
    value: "computer_software",
  },
  {
    label: "Computers peripherals security and telephony equipment",
    value: "computers_peripherals_security_and_telephony_equipment",
  },
  {
    label: "Construction and civil engineering machinery and equipment",
    value: "construction_and_civil_engineering_machinery_and_equipment",
  },
  {
    label: "Electric motors generators transformers and accumulators",
    value: "electric_motors_generators_transformers_and_accumulators",
  },
  {
    label: "Engineering machinery and equipment",
    value: "engineering_machinery_and_equipment",
  },
  {
    label: "Heavy commercial vehicles",
    value: "heavy_commercial_vehicles",
  },
  {
    label: "Light commercial vehicles",
    value: "light_commercial_vehicles",
  },
  {
    label: "Manufacturing machinery and equipment",
    value: "manufacturing_machinery_and_equipment",
  },
  {
    label: "Marine vessels and aircrafts",
    value: "marine_vessels_and_aircrafts",
  },
  {
    label: "Materials lifting handling and storage machinery and equipment",
    value: "materials_lifting_handling_and_storage_machinery_and_equipment",
  },
  {
    label: "Medical and dental equipment and tools",
    value: "medical_and_dental_equipment_and_tools",
  },
  {
    label: "Mining quarrying and aggregate machinery and equipment",
    value: "mining_quarrying_and_aggregate_machinery_and_equipment",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "Printing and graphics machinery and equipment",
    value: "printing_and_graphics_machinery_and_equipment",
  },
  {
    label: "Recycling and waste management machinery and equipment",
    value: "recycling_and_waste_management_machinery_and_equipment",
  },
  {
    label: "Trailers",
    value: "trailers",
  },
];

export const roles = [
  {
    label: "Director",
    value: "director",
  },
  {
    label: "Shareholder",
    value: "shareholder",
  },
  {
    label: "Business Owner",
    value: "business_owner",
  },
  {
    label: "Company Secretary",
    value: "company_secretary",
  },
  {
    label: "Partner",
    value: "partner",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const answers = [
  { label: "Yes", selected: true },
  { label: "No", selected: false },
];
