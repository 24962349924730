import { AssetFinanceReq } from "@APP/types/wcf";

export const assetFinancePayload: AssetFinanceReq = {
  assetFinanceRequest: {
    currency: null,
    depositOrInitialRentalAmount: null,
    balloonAmount: null,
    vatPaidUpFrontAmount: null,
    productType: null,
    isRefinance: null,
    assets: [
      {
        category: null,
        condition: null,
        description: null,
        manufacturer: null,
        manufactureDate: null,
        manufactureYear: null,
        model: null,
        numberOfUnits: null,
        costPerUnit: null,
        vatPerUnit: null,
        registrationNumber: null,
        serialNumber: null,
        isInsured: null,
      },
    ],
    duration: {
      amount: 1,
      unit: "1",
    },
  },
  invoiceFinanceRequest: null,
  commercialLoanRequest: null,
  urgency: null,
  detailedFundingPurpose: null,
  partyToContact: null,
  introducer: {
    id: null,
    contactDetails: null,
  },
  applicants: [
    {
      firstName: null,
      lastName: null,
      middleName: null,
      dateOfBirth: null,
      hasConsentedToPrivacyPolicy: null,
      hasConsentedToCreditCheck: null,
      isMainApplicant: null,
      address: {
        street: null,
        cityOrTown: null,
        countryOrState: null,
        country: null,
        postcode: null,
      },
      companyRole: null,
      contactDetails: {
        firstName: null,
        lastName: null,
        middleName: null,
        mobilePhoneNumber: null,
        otherPhoneNumber: null,
        email: null,
      },
      guarantees: {
        can_provide_personal_guarantee: false,
      },
    },
  ],
  formStage: {
    page: 3,
    status: "Draft",
  },
};
