import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import CONFIG from "@APP/config";
import { useAlert } from "@APP/hooks";
import { API } from "@APP/services";

interface Props {
  onCaptchaComplete: (token: string | null) => void;
  captchaSkip: (value: boolean) => void;
  captchaToken: string | null;
}

const ReCaptcha = ({ onCaptchaComplete, captchaToken, captchaSkip }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [bypassCaptcha, setBypassCaptcha] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const recaptcha = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (!captchaToken) {
      resetCaptcha();
    }
  }, [captchaToken]);

  const resetCaptcha = () => {
    recaptcha.current?.reset();
  };

  const fetchIp = async () => {
    try {
      const response: any = await API.getUserIp();
      if (response?.ip === CONFIG.ENDTEST.ENDTEST_IP) {
        captchaSkip(true);
        setIsLoading(false);
        setBypassCaptcha(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching the IP address:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchIp();
    })();
  }, []);

  const onChange = (token: string | null) => {
    onCaptchaComplete(token);
  };

  const onError = () => {
    onCaptchaComplete(null);
    alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), t("Errors.ErrorCodes.114"));
    resetCaptcha();
  };

  if (isLoading || bypassCaptcha) return null;

  return (
    <ReCAPTCHA
      ref={recaptcha}
      id="react-google-recaptcha"
      sitekey={CONFIG.SERVICES.GOOGLE?.RECAPTCHA_SITE_KEY!}
      onChange={onChange}
      onExpired={onError}
      size="normal"
    />
  );
};

export default ReCaptcha;
