import { useSelector } from "react-redux";
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";

import { getForecastBalances, getForecastData } from "@APP/redux";
import { useMemo } from "react";

interface Props {
  onTryAgain: () => void;
}

const ForecastErrorSection = ({ onTryAgain }: Props) => {
  const data = useSelector(getForecastData);
  const forecastBalances = useSelector(getForecastBalances);

  const errorMessage = useMemo(() => {
    if (!data)
      return "Cash Flow Forecast can’t be built as we were unable to get your data. Please try again.";

    if (!forecastBalances?.items.length)
      return "Cash Flow Forecast can’t be built as we were unable to get your linked bank account balances.";

    return null;
  }, [forecastBalances, data]);

  return (
    <Card elevation={4}>
      <CardHeader title="Your Forecast" />
      <Divider />
      <CardContent>
        <Box
          minHeight={150}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center">
          <Typography variant="body1" id="forecastErrorMessage">
            {errorMessage}
          </Typography>
          {!data ? (
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onTryAgain()}
                id="forecastErrorTryAgainButton">
                Try Again
              </Button>
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ForecastErrorSection;
