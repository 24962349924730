import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  lighten,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useAlert } from "@APP/hooks";
import {
  AppState,
  fetchUserData,
  getOtherOrgTypes,
  getUser,
  getUserOrganisation,
  hideLoader,
  setActiveStep,
  setNextStepActive,
  setOtherOrgTypesBusinessName,
  setOtherOrgTypesFirstName,
  setOtherOrgTypesLastName,
  showLoader,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { WCF_CUSTOMER_ERROR_CODE, createOrganisation, refreshToken } from "@APP/services/api";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";
import { businessNameValidationSchema, formatErrorMessage } from "@APP/utils";
import { CommonTextField, Page } from "@APP/components";
import { CompanyType } from "@APP/constants";
import { CreateOrganisationSoleTrader } from "@APP/types";
import WcfFooter from "@APP/components/layout/WCFLayout";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";

const otherOrgTypesValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your First Name.")
    .min(2, "Please enter from 2 to 30 symbols.")
    .max(30, "Please enter from 2 to 30 symbols.")
    .matches(
      /^[A-Za-z-'’ ]+$/,
      "This field may contain only letters, hyphen, apostrophe and spaces.",
    ),
  lastName: Yup.string()
    .required("Please enter your Last Name.")
    .min(2, "Please enter from 2 to 30 symbols.")
    .max(30, "Please enter from 2 to 30 symbols.")
    .matches(
      /^[A-Za-z-'’ ]+$/,
      "This field may contain only letters, hyphen, apostrophe and spaces.",
    ),
  businessName: businessNameValidationSchema(),
});

const useStyles = makeStyles((theme) => ({
  fieldset: {
    border: 0,
    marginBottom: theme.spacing(2),
  },
  legend: {
    marginBottom: theme.spacing(2),
  },
  grid: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.9),
    padding: theme.spacing(2, 2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "start",
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  gridText: {
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  cardContaier: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "flex-start",
    borderRadius: "5px",
    minHeight: "80vh",
    flexGrow: 1,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  cardWrapper: {
    display: "flex",
    flexGrow: 1,
  },
}));

type Props = {
  orgType: CompanyType;
  title: string;
};

const OrgDetailsOtherCompany = ({ orgType, title }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const org = useSelector(getUserOrganisation);
  const user = useSelector(getUser);
  const otherOrgTypes = useSelector(getOtherOrgTypes);

  const fetchWcfCustomer = async () => {
    try {
      dispatch(showLoader());
      const customer = await API.getWcfCustomer();
      if (customer && customer.status === "Submitted") {
        history.push(SCREEN_PATHS.WCF_FINANCE_LISTING);
      }
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;
      // If Customer is not created, don't show any error codes.
      // will be used for decoupling logic from WcfFooter

      // setCustNotFound(errorCode === 24110);

      if (errorCode === 5004) return;
      if (!WCF_CUSTOMER_ERROR_CODE.includes(errorCode)) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleNextScreen = () => history.push(SCREEN_PATHS.WCF_MANUAL_COMPANY);

  const handleOnSubmit = async (values: AppState["registeredCompany"]["otherOrgTypes"]) => {
    try {
      if (!user || !user.username || !user.phone) throw new Error("");

      dispatch(showLoader());
      const body: CreateOrganisationSoleTrader = {
        name: {
          first: values.firstName,
          last: values.lastName,
        },
        companyName: values.businessName,
        orgType: orgType,
      };
      if (!org?.companyInfo.id) {
        await createOrganisation(user.username, user.phone, body);
        const { token } = await refreshToken();
        AppLocalStorage.setItem(LocalStorageKey.authToken, token);
        await dispatch(fetchUserData());
      }
      dispatch(hideLoader());
      handleNextScreen();
    } catch (e) {
      dispatch(hideLoader());
      alert.open("Error", formatErrorMessage({}, "Something went wrong "));
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    validateForm,
  } = useFormik({
    initialValues: otherOrgTypes,
    validationSchema: otherOrgTypesValidationSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    dispatch(setActiveStep(0));
    fetchWcfCustomer();
    dispatch(setNextStepActive(true));
    (async () => {
      await validateForm();
    })();
  }, []);

  const handleSetFirstName = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setOtherOrgTypesFirstName(values.firstName));
  };

  const handleSetLastName = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setOtherOrgTypesLastName(values.lastName));
  };

  const handleSetBusinessName = (e: React.FocusEvent) => {
    handleBlur(e);
    dispatch(setOtherOrgTypesBusinessName(values.businessName));
  };

  const renderPersonalInformation = () => (
    <fieldset className={clsx(classes.fieldset)}>
      <legend className={clsx(classes.legend)}>
        <Typography variant="h4" component="h2">
          Personal Information
        </Typography>
      </legend>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <CommonTextField
            label="First Name"
            id="wcfOtherOrgDetailsFirstName"
            disabled={isSubmitting}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            fullWidth
            margin="none"
            name="firstName"
            onBlur={handleSetFirstName}
            onChange={handleChange}
            value={values.firstName}
            autoComplete="given-name"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CommonTextField
            label="Last Name"
            id="wcfOtherOrgDetailsLastName"
            disabled={isSubmitting}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            fullWidth
            margin="none"
            name="lastName"
            onBlur={handleSetLastName}
            onChange={handleChange}
            type="text"
            value={values.lastName}
            autoComplete="family-name"
          />
        </Grid>
      </Grid>
    </fieldset>
  );

  const renderBusinessInformation = () => (
    <fieldset className={clsx(classes.fieldset)}>
      <legend className={clsx(classes.legend)}>
        <Typography variant="h4" component="h2">
          Business Information
        </Typography>
      </legend>

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <CommonTextField
            label="Business Name"
            disabled={isSubmitting}
            error={Boolean(touched.businessName && errors.businessName)}
            helperText={touched.businessName && errors.businessName}
            fullWidth
            margin="none"
            id="wcfOtherOrgDetailsBusinessName"
            name="businessName"
            onBlur={handleSetBusinessName}
            onChange={handleChange}
            type="text"
            value={values.businessName}
            autoComplete="organization"
          />
        </Grid>
      </Grid>
    </fieldset>
  );

  return (
    <Page className={classes.container} p={0}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Box p={3} className={classes.cardWrapper}>
          <Card elevation={4} className={classes.cardContaier}>
            <WcfStepper style={{ width: "100%" }} />
            <CardHeader
              title={title}
              subheader={t("Components.Common.OtherOrgAndSoleTraderForm.CardSubheader")}
              id="orgDetailsOtherCompanyTitle"
            />
            <Divider />
            <CardContent style={{ width: "100%" }}>
              <Box mt={3}>{renderPersonalInformation()}</Box>
              <Box mt={3}>{renderBusinessInformation()}</Box>
            </CardContent>
            <Divider />
          </Card>
        </Box>
        <WcfFooter
          displaySaveAndExitButton={false}
          handleSubmit={handleSubmit}
          data={{
            firstName: otherOrgTypes.firstName,
            lastName: otherOrgTypes.lastName,
            businessName: otherOrgTypes.businessName,
          }}
          allowRedirect={false}
        />
      </form>
    </Page>
  );
};

export default OrgDetailsOtherCompany;
