import { createActionCreator } from "deox";

export const showLoader = createActionCreator(
  "@DASHBOARD_APP/SHOW_LOADER",
  (resolve) => () => resolve(),
);

export const hideLoader = createActionCreator(
  "@DASHBOARD_APP/HIDE_LOADER",
  (resolve) => () => resolve(),
);
