import React from "react";
import { Helmet } from "react-helmet";
import { Box, BoxProps, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
  title?: string;
  children: React.ReactNode;
}

/**
 * Reusable page container component with ability to manage the web page title.
 */
const Page = ({ title = "", children, ...props }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const padding = useMediaQuery(theme.breakpoints.down("md")) ? 1 : 3;

  return (
    <Box p={padding} height="100%" overflow="auto" {...props}>
      <Helmet>
        <title>
          {t("ProductName")} {title ? `| ${title}` : ""}
        </title>
      </Helmet>
      {children}
    </Box>
  );
};

export default Page;
