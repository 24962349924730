import * as Yup from "yup";
import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";

type ParsableDate<TDate = null> = Date | number | TDate;

export const CommonDatePickerValidationScheme = ({
  minDate,
  maxDate,
  minDateMessage = "Please enter a date after the minimum date",
  maxDateMessage = "Please enter a date before the maximum date",
  invalidDateMessage = "Please enter a valid date",
}: {
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  minDateMessage?: string;
  maxDateMessage?: string;
  invalidDateMessage?: string;
}) =>
  Yup.object().shape({
    date: Yup.date()
      .required("Please enter a date")
      .nullable()
      .typeError(invalidDateMessage)
      .isDateCorrectlyFormed(invalidDateMessage)
      .test("Date validation", (date, { createError }) => {
        if (!date) return createError({ message: invalidDateMessage });
        if (maxDate && isAfter(new Date(date), endOfDay(maxDate)))
          return createError({ message: maxDateMessage });
        if (minDate && isBefore(new Date(date), startOfDay(minDate)))
          return createError({ message: minDateMessage });

        return true;
      }),
  });
