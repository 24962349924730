import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, FormControlLabel, Link, Radio, RadioGroup, Typography } from "@mui/material";

import { AuthForm, AuthLayout, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import CONFIG from "@APP/config";

enum OrgOptions {
  NewOrganisation = "NewOrganisation",
  ExistingOrganisation = "ExistingOrganisation",
}

const RegistrationOrgSelectionView = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedOrgOption, setSelectedOrgOption] = useState(OrgOptions.ExistingOrganisation);

  const handleOnContinue = () => {
    history.push(SCREEN_PATHS.REGISTRATION_INFORMATION);
  };

  const renderMainContent = () => (
    <AuthForm backwardPath={SCREEN_PATHS.LOGIN}>
      <Box mb={3}>
        <Typography variant="h4" fontWeight="bold" component="p" id="createNewOrgText">
          {t("Registration.OrgSelection.CreateNewOrganisation")}
        </Typography>
      </Box>
      <RadioGroup
        value={selectedOrgOption}
        name="options-for-user-creation-radio-group"
        onChange={(e) => setSelectedOrgOption(e.target.value as OrgOptions)}
        id="registrationORGRadioGroup">
        <Box display="flex">
          <Box display="flex" flex={1}>
            <FormControlLabel
              value={OrgOptions.ExistingOrganisation}
              label={t("Registration.OrgSelection.NoOptionLabel")}
              componentsProps={{ typography: { variant: "body2" } }}
              control={<Radio id="registrationOrgNoBox" />}
              id="registrationOrgNo"
            />
          </Box>
          <Box display="flex" flex={1}>
            <FormControlLabel
              value={OrgOptions.NewOrganisation}
              label={t("Registration.OrgSelection.YesOptionLabel")}
              componentsProps={{ typography: { variant: "body2" } }}
              control={<Radio id="registrationOrgYesBox" />}
              id="registrationOrgYes"
            />
          </Box>
        </Box>
      </RadioGroup>
      <Box mt={3} mb={3}>
        {selectedOrgOption === OrgOptions.ExistingOrganisation ? (
          <>
            <Typography variant="body2">
              {t("Registration.OrgSelection.ReachOutOrganisationAdministrator")}
            </Typography>
            <Typography variant="body2">
              <br />
              If you need help, please send your request to{" "}
              <Link
                underline="always"
                href={`mailto:${CONFIG.SUPPORT_EMAIL}`}
                id="registrationOrgSupportEmail">
                {CONFIG.SUPPORT_EMAIL}
              </Link>
              .
            </Typography>
          </>
        ) : (
          <Typography variant="body2">
            {t("Registration.OrgSelection.AfterRegistrationInfo")}
          </Typography>
        )}
      </Box>
      <Button
        color="primary"
        fullWidth
        size="large"
        variant="contained"
        type="button"
        onClick={handleOnContinue}
        disabled={selectedOrgOption === OrgOptions.ExistingOrganisation}
        data-testid="continue-button"
        id="registrationORGContinueButton">
        Continue
      </Button>
    </AuthForm>
  );

  return (
    <Page title="Get Started" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

export default RegistrationOrgSelectionView;
