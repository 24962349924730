import { combineReducers } from "redux";

import authReducer from "./auth";
import payableReducer from "./payable";
import registrationReducer from "./registration";
import dashboardAppReducer from "./dashboardApp";
import automatedCollectionsReducer from "./automatedCollections";
import invoiceReducer from "./invoice";
import { forecastReducer } from "./forecast";
import rtpDetailsReducer from "./rtpDetails";
import registeredCompanyReducer from "./registeredCompany";
import { makePaymentDefaultState, makePaymentReducer } from "./makePayment";
import accessReducer from "./access";
import workingcapitalFinanceReducer from "./workingCapitalFinance";

const appReducer = combineReducers({
  access: accessReducer,
  auth: authReducer,
  registration: registrationReducer,
  payable: payableReducer,
  automatedCollections: automatedCollectionsReducer,
  dashboardApp: dashboardAppReducer,
  invoice: invoiceReducer,
  forecast: forecastReducer,
  rtpDetails: rtpDetailsReducer,
  makePayment: makePaymentReducer,
  registeredCompany: registeredCompanyReducer,
  workinCapitalfinance: workingcapitalFinanceReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "@AUTH/LOGOUT") return appReducer(undefined, action);

  return appReducer(state, action);
};

export {
  rootReducer,
  accessReducer,
  authReducer,
  registrationReducer,
  automatedCollectionsReducer,
  payableReducer,
  invoiceReducer,
  forecastReducer,
  rtpDetailsReducer,
  makePaymentReducer,
  registeredCompanyReducer,
  makePaymentDefaultState,
  workingcapitalFinanceReducer,
};

export default rootReducer;
