import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  Link,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { CommonTextField, PhoneField } from "@APP/components";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { fetchUserData, getPermissions, getUser } from "@APP/redux";
import { API } from "@APP/services";
import { NO_PENDING_EMAIL_VERIFICATION_ERROR_CODE } from "@APP/services/api";
import { BusinessContactStatus } from "@APP/types";
import { Center } from "@APP/views/common";

import { ContactDetailsValidationSchema } from "./ContactDetailsValidationSchema";
import { handleKeyPress } from "@APP/views/wcf/utils/utils";

const useStyles = makeStyles((theme) => ({
  subField: {
    marginTop: theme.spacing(1.8),
  },
  bottomButton: {
    flexDirection: "column",
    width: "100%",
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: 4,
};

const ContactDetails = () => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const handleErrorCodes = useHandleErrorCodes();
  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isDisplaySizeLessMd = useMediaQuery(theme.breakpoints.up("xl"));
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector(getUser);
  const permissions = useSelector(getPermissions);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  const [emailStatus, setEmailStatus] = useState<BusinessContactStatus | null>(null);
  const isAdmin = user?.roles.includes("org-admin");

  const getBusinessContactDetails = async () => {
    try {
      const businessContactEmail = await API.getBusinessContactEmail(user?.org?.id!);
      setEmailStatus(businessContactEmail);

      // if the email has not yet been verified, revert the displayed value to the previous value.
      !businessContactEmail?.verified &&
        setFieldValue(
          "contactEmail",
          user?.org?.businessContact?.email ?? user?.org?.companyInfo.email,
        );
    } catch (error) {
      const { errorCode } = error?.response?.data;
      if (errorCode === NO_PENDING_EMAIL_VERIFICATION_ERROR_CODE) return;
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Errors.Common.Alerts.Generic.Message"),
        [{ text: "Okay" }],
      );
    }
  };

  useEffect(() => {
    getBusinessContactDetails();
  }, []);

  const handleEditDetails = async () => {
    const { businessName, contactEmail, contactPhone } = values;
    if (businessName && contactEmail && contactPhone) {
      try {
        await API.updateContactDetails(
          user!.org!.id,
          businessName.trim(),
          contactEmail,
          contactPhone,
        );
        alert.open("Success", "Your contact details have been saved.", [{ text: "Okay" }]);

        setIsEditable(false);
        getBusinessContactDetails();
      } catch (error) {
        const errorCode = error?.response?.data?.errorCode;
        const isHandled = handleErrorCodes(errorCode);

        if (isHandled) return;

        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message"),
          [{ text: "Okay" }],
        );
      } finally {
        dispatch(fetchUserData());
      }
    }
  };

  const handleResendVerificationEmail = async () => {
    if (emailStatus) {
      try {
        await API.resendContactVerificationEmail(user!.org!.id);
        alert.open(
          "Success",
          `We’ve successfully sent you a verification email to ${emailStatus.email} inbox, please confirm your address to complete its update.`,
          [{ text: "Okay" }],
        );
      } catch {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          `Sorry, we were unable to send you a verification email to ${emailStatus.email} inbox. Please try again later.`,
          [{ text: "Okay" }],
        );
      }
    }
  };

  const {
    initialValues,
    values,
    errors,
    dirty,
    isValid,
    isSubmitting,
    submitCount,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      businessName: user?.org?.businessContact?.name ?? user?.org?.name,
      contactEmail: user?.org?.businessContact?.email ?? user?.org?.companyInfo.email,
      contactPhone: user?.org?.businessContact?.telephone ?? user?.org?.companyInfo.telephone,
    },
    validationSchema: ContactDetailsValidationSchema(t, CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE),
    onSubmit: handleEditDetails,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        open={isEditable}
        onClose={() => {
          setIsEditable(false);
          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box sx={{ ...style, ...(!isPhone && { width: "34%" }) }}>
            <Typography variant="h6" data-testid="organisation-invoice-notes">
              Contact Details
            </Typography>
            <Grid
              display="block"
              gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr"}
              columnGap={3}>
              <CommonTextField
                className={classes.subField}
                onKeyDown={handleKeyPress}
                inputProps={{
                  "data-testid": "business-name-input",
                  disabled: !isEditable,
                  readOnly: !isEditable,
                }}
                fullWidth
                label="Business Name"
                placeholder="Business Name"
                name="businessName"
                onChange={handleChange}
                value={values.businessName}
                error={!!errors.businessName}
                helperText={errors.businessName}
                id="contactDetailsBusinessName"
              />
              <PhoneField
                className={classes.subField}
                inputProps={{ "data-testid": "phone-number-input", disabled: !isEditable }}
                readOnly={!isEditable}
                fullWidth
                label="Phone"
                placeholder="Phone"
                name="contactPhone"
                onValueChange={(value) => setFieldValue("contactPhone", value)}
                value={values.contactPhone}
                helperText={errors.contactPhone}
                error={!!errors.contactPhone}
                countryCode={CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE}
                id="contactDetailsPhone"
              />
              <Box position="relative">
                <CommonTextField
                  className={classes.subField}
                  inputProps={{
                    "data-testid": "contact-email-input",
                    disabled: !isEditable,
                    readOnly: !isEditable,
                  }}
                  onKeyDown={handleKeyPress}
                  fullWidth
                  label="Email"
                  placeholder="Email"
                  name="contactEmail"
                  onChange={handleChange}
                  value={values.contactEmail}
                  error={!!errors.contactEmail}
                  helperText={errors.contactEmail}
                  id="contactDetailsEmail"
                />
                <Box
                  position={isSmSizeScreen ? "static" : "absolute"}
                  style={{ padding: "8px" }}
                  aria-live="polite">
                  {values.contactEmail !== initialValues.contactEmail && submitCount === 0 ? (
                    <FormHelperText>
                      We will send you a verification email to your inbox. You will need to confirm
                      your address to complete its update.
                    </FormHelperText>
                  ) : emailStatus && !emailStatus?.verified ? (
                    <FormHelperText>
                      We’ve sent you a verification email to <b>{emailStatus.email}</b> inbox,
                      please confirm your address to complete its update.{" "}
                      <Link
                        sx={{ cursor: "pointer" }}
                        underline="always"
                        role="button"
                        tabIndex={0}
                        onClick={handleResendVerificationEmail}
                        id="contactDetailsResendEmail">
                        Resend email
                      </Link>
                    </FormHelperText>
                  ) : null}
                </Box>
              </Box>
            </Grid>
            <Box
              className={classes.bottomButton}
              style={{
                marginTop:
                  (values.contactEmail !== initialValues.contactEmail && submitCount === 0) ||
                  (emailStatus && !emailStatus?.verified)
                    ? "48px"
                    : "",
              }}>
              <Center
                gap={1}
                flexDirection={isDisplaySizeLessMd ? "row" : "column-reverse"}
                justifyContent="space-between"
                padding="8px 0px 8px 0px">
                <Button
                  color="primary"
                  variant="outlined"
                  data-testid="cancel-contact-details-button"
                  onClick={() => {
                    setIsEditable(false);
                    resetForm();
                  }}
                  id="contactDetailsCancel"
                  className="minWidth">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  data-testid="save-contact-details-button"
                  type="submit"
                  onClick={handleEditDetails}
                  disabled={!isValid || isSubmitting || !dirty}
                  id="contactDetailsSave"
                  className="minWidth">
                  Save
                </Button>
              </Center>
            </Box>
          </Box>
        </>
      </Modal>
      <CardHeader
        title="Contact Details"
        subheader="View your contact details that are presented to your customers in payment requests."
        data-testid="contact-details-card-header"
        id="contactDetailsCardTitle"
      />
      <Divider />
      <CardContent>
        <Box>
          <Typography
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              fontWeight: "500",
            }}>
            Business name:{" "}
            <Typography
              sx={{
                width: "60%",
                wordBreak: "break-word",
                textAlign: "left",
              }}>
              {values.businessName}
            </Typography>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              fontWeight: "500",
            }}>
            Phone:{" "}
            <Typography
              sx={{
                width: "60%",
                wordBreak: "break-word",
                textAlign: "left",
              }}>
              {values.contactPhone}
            </Typography>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              fontWeight: "500",
            }}>
            Email:{" "}
            <Typography
              sx={{
                width: "60%",
                wordBreak: "break-word",
                textAlign: "left",
              }}>
              {values.contactEmail}
            </Typography>
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      {permissions?.organisation?.update ? (
        isAdmin && (
          // user?.erp === ErpId.INTERNAL && (
          <CardActions>
            <Center mt={2}>
              <Button
                color="primary"
                variant="contained"
                data-testid="edit-contact-details-button"
                onClick={() => setIsEditable(true)}
                id="contactDetailsEdit"
                className="minWidth">
                Edit
              </Button>
            </Center>
          </CardActions>
        )
      ) : (
        // )
        <></>
      )}
    </form>
  );
};

export default ContactDetails;
