import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";

import { Page, ResultNotification } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { ErpId, TabsName } from "@APP/constants";
import { useAlert } from "@APP/hooks";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";
import {
  fetchCardPaymentsCustodians,
  fetchCardPaymentsSettings,
  getClearingLedger,
  getDashboardAppLoading,
  getErpId,
  hideLoader,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { capitalize } from "@APP/utils";

import { CardPaymentLocalConsentData } from "../CardPaymentUK/CardPaymentsUK";

const CardPaymentLinkingResult = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const isLoading = useSelector(getDashboardAppLoading);
  const clearingLedger = useSelector(getClearingLedger);
  const erpId = useSelector(getErpId);

  const [isLinkedSuccessfully, setIsLinkedSuccessfully] = useState<boolean | null>(null);

  const { code } = queryString.parse(history.location.search ?? "") as {
    code?: string;
  };

  const processCardPaymentConsentAuthorisation = async () => {
    const localStorageData = AppLocalStorage.getItem(LocalStorageKey.setupCardPaymentsConsentData);

    const consentLocalData: CardPaymentLocalConsentData = JSON.parse(localStorageData || "");
    const { consentId, redirectUrl } = consentLocalData;

    await API.authoriseSquareConsent({ consentId, code: code as string, redirectUrl });
  };

  const onFinishSquareLinking = async () => {
    let clearingLedgerCreated = false;
    try {
      if (clearingLedger || erpId === ErpId.INTERNAL || !isLinkedSuccessfully) {
        redirectToCardPaymentsScreen();
        clearingLedgerCreated = true;
        return;
      }

      dispatch(showLoader());

      await API.createClearingLedger(erpId!);

      await dispatch(fetchCardPaymentsSettings());

      clearingLedgerCreated = true;

      alert.open(
        "Success",
        `We have created a clearing ledger in ${capitalize(
          erpId,
        )}. We will book Card Payments here until the transaction is settled.`,
        [{ text: "Okay", onClick: redirectToCardPaymentsScreen }],
      );
    } catch (error) {
      clearingLedgerCreated = false;
    } finally {
      dispatch(hideLoader());
      if (!clearingLedgerCreated) redirectToCardPaymentsScreen();
    }
  };

  const redirectToCardPaymentsScreen = () => {
    history.replace(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.CARD_PAYMENTS}`);
  };

  useEffect(() => {
    /**
     * If a user has landed on the page with consent authorisation code, handles the authorisation.
     * Otherwise, cleans up all any locally stored data that can be left after previous consent initiation
     */
    (async () => {
      try {
        if (code) {
          dispatch(showLoader());

          await processCardPaymentConsentAuthorisation();
          await dispatch(fetchCardPaymentsCustodians());

          setIsLinkedSuccessfully(true);
        } else {
          setIsLinkedSuccessfully(false);
        }

        AppLocalStorage.removeItem(LocalStorageKey.setupCardPaymentsConsentData);
      } catch (error) {
        AppLocalStorage.removeItem(LocalStorageKey.setupCardPaymentsConsentData);

        setIsLinkedSuccessfully(false);
      } finally {
        dispatch(hideLoader());
      }
    })();
  }, [code]);

  return (
    <Page title={isLinkedSuccessfully ? "Success" : "Failure"}>
      {!isLoading && isLinkedSuccessfully != null ? (
        <Box maxWidth={800} ml="auto" mr="auto">
          <ResultNotification
            type={isLinkedSuccessfully ? "success" : "error"}
            footer={
              <Button color="secondary" variant="contained" onClick={onFinishSquareLinking}>
                Okay
              </Button>
            }>
            {isLinkedSuccessfully
              ? "Your square account was successfully linked."
              : "We were unable to link your Square account, you can retry at any time from the payments tab in settings."}
          </ResultNotification>
        </Box>
      ) : null}
    </Page>
  );
};

export default CardPaymentLinkingResult;
