import { useCallback } from "react";
import { useSelector } from "react-redux";
import { endOfDay, format } from "date-fns";

import {
  getBankAccounts,
  getForecastEndDate,
  setForecastData,
  setForecastEndDate,
  setForecastId,
  setForecastLoading,
  useAppDispatch,
} from "@APP/redux";
import { API } from "@APP/services";
import { ForecastResponse } from "@APP/types";
import { API_DATE_FORMAT } from "@APP/constants";

import useAlert from "../useAlert";
import useHandleErrorCodes from "../useHandleErrorCodes";

const CASHFLOW_FORECAST_MAX_REQUEST_ATTEMPTS = 5;
const CASHFLOW_FORECAST_LONG_PULLING_TIMER = 5000;

const FORECAST_PROCESSING_STATUS = 202;
const FORECAST_COMPLETE_STATUS = 200;

const useCashflowForecasting = () => {
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();
  const bankAccounts = useSelector(getBankAccounts);

  const forecastEndDate = useSelector(getForecastEndDate);

  const generateCashflowForecast = useCallback(
    async (forecastEndDateArgument?: Date) => {
      dispatch(setForecastLoading(true));

      try {
        const { id: forecastId } = await API.generateCashflowForecast({
          forecastEndDate: format(forecastEndDateArgument ?? forecastEndDate, API_DATE_FORMAT),
        });

        const data = await getCashflowForecast(forecastId);

        dispatch(setForecastEndDate(endOfDay(forecastEndDateArgument ?? forecastEndDate)));
        dispatch(setForecastId(forecastId));

        return { data, forecastId };
      } catch (error) {
        dispatch(setForecastLoading(false));

        throw error;
      }
    },
    [dispatch, handleErrorCodes, forecastEndDate, alert],
  );

  const getCashflowForecast = useCallback(
    (forecastId: string): Promise<ForecastResponse | undefined | void> => {
      return new Promise((resolve, reject) => {
        async function fetchCashflowForecast(attempts = 0) {
          try {
            if (attempts < CASHFLOW_FORECAST_MAX_REQUEST_ATTEMPTS) {
              const response = await API.fetchCashflowForecast({ forecastId });

              if (response.status === FORECAST_PROCESSING_STATUS) {
                setTimeout(
                  async () => await fetchCashflowForecast(attempts + 1),
                  CASHFLOW_FORECAST_LONG_PULLING_TIMER,
                );
              }

              if (response.status === FORECAST_COMPLETE_STATUS) {
                const data = {
                  ...response.data,
                  balances: {
                    ...response.data?.balances,
                    items: response.data?.balances?.items.map((item) => ({
                      ...item,
                      logo: bankAccounts?.find(
                        (account) => account.account.identification === item.accountIdentification,
                      )?.bankInfo.logo,
                    })),
                  },
                } as ForecastResponse;

                dispatch(setForecastData(data));
                dispatch(setForecastLoading(false));
                return resolve(response.data);
              }
            }

            if (attempts === CASHFLOW_FORECAST_MAX_REQUEST_ATTEMPTS) {
              dispatch(setForecastData(null));
              dispatch(setForecastLoading(false));
            }
          } catch (error) {
            dispatch(setForecastLoading(false));

            return reject(error);
          }
        }

        fetchCashflowForecast();
      });
    },
    [dispatch, alert],
  );

  return { generateCashflowForecast, getCashflowForecast };
};

export default useCashflowForecasting;
