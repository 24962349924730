import React from "react";

import { AlertContext, AlertProvider } from "./AlertProvider";

/**
 * Launches an alert dialog with the specified title, message and buttons (optional).
 * Inspired by React Native's Alert API: https://reactnative.dev/docs/alert
 */
const useAlert = () => {
  const { openAlert, renderAlert, closeAlert, showAlert } = React.useContext(AlertContext);
  return { open: openAlert, render: renderAlert, close: closeAlert, showAlert };
};

export { AlertProvider };
export default useAlert;
