import { useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getMakePayment,
  makePaymentDefaultState,
  setDefaultMakePaymentState,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";

const useClearMakePaymentState = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const makePayment = useSelector(getMakePayment);

  useEffect(() => {
    if (
      !_.isEqual(makePaymentDefaultState, makePayment) &&
      !pathname.includes(SCREEN_PATHS.MAKE_PAYMENT)
    ) {
      dispatch(setDefaultMakePaymentState());
    }
  }, [pathname]);
};

export default useClearMakePaymentState;
