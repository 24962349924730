import { createActionCreator } from "deox";
import { AuthState } from "../reducers/auth";

export const setPermissionData = createActionCreator(
  "@AUTH/SET_USER_PERMISSIONS_DATA",
  (resolve) =>
    (payload: {
      permissions: { resource: string; action: string; status: string }[];
      user: AuthState["user"];
    }) =>
      resolve(payload),
);

export const setOrgAdminPermissionData = createActionCreator(
  "@AUTH/SET_ORG_ADMIN_PERMISSIONS_DATA",
  (resolve) => (payload: AuthState["user"]) => resolve(payload),
);
