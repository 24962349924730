import { Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  setActiveStep,
  setApplicantFormData,
  setAssetFinanceFormData,
  setFormSubType,
  setFormType,
  setInvoiceFinanceFormData,
  setNextPath,
  setNextStepActive,
  setProductType,
} from "@APP/redux/actions/workingCapitalFinance";

import makeStyles from "@mui/styles/makeStyles";
import WcfFooter from "@APP/components/layout/WCFLayout";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import {
  getUserOrganisation,
  getWorkingCapitalFinanceDetails,
  hideLoader,
  setReviewPage,
  showLoader,
} from "@APP/redux";
import { formatOption } from "@APP/views/wcf/index";
import { API } from "@APP/services";
import { PraeturaOrgType, WcfCustomer } from "@APP/types";

import { dateFormatterUK, removeUnderscoreAndCapitalize } from "../utils/utils";
import { assetTypes, urgencyLevels } from "../utils/dropdown";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "32px",
    alignItems: "center",
    borderRadius: "5px",
  },
}));

function ReviewForm() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    formType,
    subType,
    wcfFormData: { assetFinanceForm, applicantDetailsForm, invoiceFinanceForm },
  } = useSelector(getWorkingCapitalFinanceDetails);
  const isInvoice = formType.includes("Invoice");
  const org = useSelector(getUserOrganisation);
  const history = useHistory();

  const { applicationId }: { applicationId: string } = useParams();
  const [wcfCustomer, setWcfCustomer] = useState<WcfCustomer | null>(null);

  const isOtherOrg =
    wcfCustomer?.companyType === PraeturaOrgType.OTHER ||
    wcfCustomer?.companyType === PraeturaOrgType.LIMITED_LIABILITY_COMPANY ||
    wcfCustomer?.companyType === PraeturaOrgType.LIMITED_LIABILITY_PARTNERSHIP;

  useEffect(() => {
    (async () => {
      await retrieveWcfData();
    })();
  }, []);

  const retrieveWcfData = async () => {
    try {
      dispatch(showLoader());
      const resCust = await API.getWcfCustomer();
      setWcfCustomer(resCust);
      const res = await API.getApplicationDetailsById(applicationId);
      if (res && res?.data && res?.data?.praeturaStatus)
        history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
      const isInvoice = !res.data.assetFinanceRequest;
      dispatch(setProductType(res?.data?.assetFinanceRequest?.productType as string));
      dispatch(setFormType(isInvoice ? "Invoice Finance" : "Asset Finance"));
      if (!isInvoice)
        dispatch(
          setFormSubType(
            removeUnderscoreAndCapitalize(res.data.assetFinanceRequest?.productType ?? ""),
          ),
        );

      if (isInvoice) {
        dispatch(
          setInvoiceFinanceFormData({
            annualTurnover: res.data.invoiceFinanceRequest?.annualTurnover ?? 1,
            purpose: res.data.detailedFundingPurpose ?? "",
            urgency: res.data.urgency ?? "",
          }),
        );
      } else {
        dispatch(
          setAssetFinanceFormData({
            units: res.data.assetFinanceRequest?.assets[0].numberOfUnits ?? 1,
            pricePerUnit: res.data.assetFinanceRequest?.assets[0].costPerUnit ?? 1,
            duration: res.data.assetFinanceRequest?.duration.amount?.toString() ?? "60",
            isRefinance: res.data.assetFinanceRequest?.isRefinance ?? false,
            deposit: res.data.assetFinanceRequest?.depositOrInitialRentalAmount ?? 1,
            purpose: res.data.assetFinanceRequest?.assets[0].description ?? "",
            urgency: res.data.urgency ?? urgencyLevels[0].value,
            assetType: res.data.assetFinanceRequest?.assets[0].category ?? assetTypes[0].value,
            model: res.data.assetFinanceRequest?.assets[0].model ?? "",
            manufacturer: res.data.assetFinanceRequest?.assets[0].manufacturer ?? "",
            yearOfManufactured: res.data.assetFinanceRequest?.assets[0].manufactureYear ?? 1250,
            isNew: res.data.assetFinanceRequest?.assets[0].condition ?? "new",
            isInsured: res.data.assetFinanceRequest?.assets[0].isInsured ?? false,
            assetPic: [],
            invoicePic: [],
          }),
        );
      }
      dispatch(
        setApplicantFormData(
          res.data.applicants?.map((applicant, idx) => {
            return {
              id: idx,
              firstName: applicant.firstName ?? "",
              middleName: applicant.middleName ?? "",
              lastName: applicant.lastName ?? "",
              dateOfBirth: applicant.dateOfBirth ?? "",
              address: applicant.address.street ?? "",
              city: applicant.address.cityOrTown ?? "",
              postcode: applicant.address.postcode ?? "",
              country: applicant.address.country ?? "",
              role: applicant.companyRole ?? "",
              mainApplicant: applicant.isMainApplicant ? "Yes" : "No",
              guarantee: "No",
              privacyPolicy: applicant.hasConsentedToPrivacyPolicy ?? false,
              creditCheck: applicant.hasConsentedToCreditCheck ?? false,
              email: applicant.contactDetails?.email ?? "",
              phoneNumber: applicant.contactDetails?.mobilePhoneNumber ?? "",
            };
          }),
        ),
      );
    } catch (error) {
      history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    dispatch(setNextStepActive(true));
    dispatch(setNextPath(`${SCREEN_PATHS.WCF_SUCCESS}/${applicationId}`));
    dispatch(setActiveStep(4));
    dispatch(setReviewPage(true));
  }, []);

  const companyAddress = isOtherOrg
    ? `${wcfCustomer?.registeredAddress?.street ?? ""} ${
        wcfCustomer?.registeredAddress?.cityOrTown ?? ""
      } ${wcfCustomer?.registeredAddress?.countyOrState ?? ""} ${
        wcfCustomer?.registeredAddress?.country ?? ""
      } ${wcfCustomer?.registeredAddress?.postcode ?? ""}`
    : `${wcfCustomer?.tradingAddress?.street ?? ""} ${
        wcfCustomer?.tradingAddress?.cityOrTown ?? ""
      } ${wcfCustomer?.tradingAddress?.countyOrState ?? ""} ${
        wcfCustomer?.tradingAddress?.country ?? ""
      } ${wcfCustomer?.tradingAddress?.postcode ?? ""}`;

  const getCompanyType = (type: string) => {
    switch (type) {
      case PraeturaOrgType.LIMITED_LIABILITY_COMPANY:
      case PraeturaOrgType.LIMITED_LIABILITY_PARTNERSHIP:
        return "Limited Company";
      case PraeturaOrgType.SOLE_TRADER:
        return "Sole Trader";
      case PraeturaOrgType.PARTNERSHIP:
        return "Partnership";
      case PraeturaOrgType.OTHER:
        return "Other Organisation";
      default:
        return "-";
    }
  };

  const mainApplicant = applicantDetailsForm?.filter(
    (applicant) => applicant.mainApplicant === "Yes",
  );
  const mainApplicantName = `${mainApplicant?.[0]?.firstName} ${mainApplicant?.[0]?.lastName}`;

  return (
    <Page className={classes.container} p={0}>
      <Box display="flex" flexDirection="column" flexGrow={1} p={3} id={"wcfReviewBox"}>
        <Card elevation={1} className={classes.cardContainer} id={"wcfReviewCard"}>
          <form style={{ width: "100%" }} id={"wcfReviewForm"}>
            <WcfStepper />
            <Grid container mt={1} id={"wcfReviewGrid"}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component="p"
                  fontWeight={600}
                  color="primary"
                  id="wcfFinanceReviewTitle"
                  paddingBottom={2}>
                  Review and submit you finance application
                </Typography>
                <Typography
                  variant="h5"
                  component="p"
                  fontWeight={600}
                  color="primary"
                  id="wcfFinanceSummeryTitle"
                  paddingBottom={1}>
                  Finance Summary
                </Typography>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewTypeOfFinance"}>
                        Type of Finance requested:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        id={"wcfReviewTypeOfFinanceValue"}
                        color="textSecondary">
                        {isInvoice ? formType : `${formType} - ${subType}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewAnnualTurnOrAmtBorrow"}>
                        {isInvoice ? "Annual Turnover:" : "Amount to borrow:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        id={"wcfReviewAnnualTurnOrAmtBorrowValue"}>
                        {isInvoice
                          ? `£ ${invoiceFinanceForm.annualTurnover}`
                          : `£ ${
                              (assetFinanceForm?.pricePerUnit || 0) * (assetFinanceForm?.units || 0)
                            }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {!isInvoice ? (
                  <Grid container spacing={1} mb={1}>
                    <Grid item display="flex" xs={12} md={6}>
                      <Grid item xs={12} md={6} id={"wcfReviewNoOfMonths"}>
                        <Typography variant="h6">Number of months:</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          id={"wcfReviewNoOfMonthsValue"}>
                          {assetFinanceForm.duration}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewPurpose"}>
                        Purpose:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        id={"wcfReviewPurposeValue"}>
                        {isInvoice ? invoiceFinanceForm.purpose : assetFinanceForm.purpose}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewUrgency"}>
                        Urgency:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        id={"wcfReviewUrgencyValue"}>
                        {isInvoice
                          ? formatOption(invoiceFinanceForm.urgency)
                          : formatOption(assetFinanceForm.urgency)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ width: "200%" }} />
                <Typography
                  variant="h5"
                  component="p"
                  fontWeight={600}
                  color="primary"
                  pt={1}
                  pb={1}
                  id={"wcfReviewContactSummery"}>
                  Company Summary
                </Typography>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewCompanyName"}>
                        Company name:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        id={"wcfReviewCompanyNameValue"}>
                        {org?.companyInfo.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewAddress"}>
                        {isOtherOrg ? "Registered Address:" : "Trading Address:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        id={"wcfReviewAddressValue"}>
                        {companyAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewCompanyType"}>
                        Company type:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        id={"wcfReviewCompanyTypeValue"}>
                        {getCompanyType(wcfCustomer?.companyType ?? "")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewCompanyNumber"}>
                        Company number:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        id={"wcfReviewCompanyNumberValue"}>
                        {wcfCustomer?.companyRegistrationNumber ?? "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewVat"}>
                        VAT number:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" color="textSecondary" id={"wcfReviewVatValue"}>
                        {wcfCustomer?.vatInformation?.vatNumber ?? "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mb={1}>
                  <Grid item display="flex" xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" id={"wcfReviewVat"}>
                        SIC code:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" color="textSecondary" id={"wcfReviewVatValue"}>
                        {wcfCustomer?.sicCode ?? "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider sx={{ width: "200%" }} />
                {applicantDetailsForm?.length ? (
                  <>
                    <Typography
                      variant="h5"
                      component="p"
                      fontWeight={600}
                      color="primary"
                      pt={1}
                      pb={1}>
                      Applicant Summary
                    </Typography>
                    <Grid container spacing={1} mb={1}>
                      <Grid item display="flex" xs={12} md={6}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" id={"wcfReviewApplicantName"}>
                            Applicant Name:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            id={"wcfReviewApplicantNameValue"}>
                            {mainApplicant?.length ? mainApplicantName : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} mb={1}>
                      <Grid item display="flex" xs={12} md={6}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" id={"wcfReviewDob"}>
                            Date of birth:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            id={"wcfReviewDobValue"}>
                            {mainApplicant?.length
                              ? dateFormatterUK(mainApplicant?.[0]?.dateOfBirth as string)
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} mb={1}>
                      <Grid item display="flex" xs={12} md={6}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" id={"wcfReviewApplicantRole"}>
                            Applicant Role:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            id={"wcfReviewApplicantRoleValue"}>
                            {mainApplicant?.length
                              ? formatOption(mainApplicant?.[0]?.role as string)
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={0} md={6} />
            </Grid>
          </form>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter
        displaySaveAndExitButton={true}
        displayPrevButton={true}
        displayNextButton={true}
        showApplicationSubmitText={true}
      />
    </Page>
  );
}

export default ReviewForm;
