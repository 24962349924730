import { Box, Card, Link, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReceiptIcon from "@mui/icons-material/Receipt";

import { ICustomerReceivablesDetails } from "@APP/types";
import { Table } from "@APP/components";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";

type Props = {
  data: Array<ICustomerReceivablesDetails & { failureReason?: string }>;
  handleClickOnCustomerName: (receivableId: string) => () => void;
  emailTitle?: string;
};

const useStyles = makeStyles((theme) => ({
  linkTitle: {
    ...theme.typography.body1,
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  cellWithTextWrap: {
    whiteSpace: "pre-line",
  },
}));

const CustomerReceivablesDetailsTable = ({
  data,
  handleClickOnCustomerName,
  emailTitle,
}: Props) => {
  const classes = useStyles();
  const isFailureReasonExist = data.some((item) => item.failureReason);
  const areUniqueReferencesEmpty = data.every((item) => !item.reference);

  const renderHeader = () => (
    <TableRow>
      <TableCell id="customerReceivableNameLabel">Customer Name</TableCell>
      <TableCell id="customerReceivableEmailLabel">{emailTitle || "Email"}</TableCell>
      <TableCell id="customerReceivableAmountLabel">Amount</TableCell>
      {!areUniqueReferencesEmpty && (
        <TableCell id="customerReceivableUniqueLabel">Unique Reference</TableCell>
      )}
      <TableCell id="customerReceivableInvoiceDueDateLabel">Invoice Due Date</TableCell>
      {isFailureReasonExist && (
        <TableCell id="customerReceivableFailureLabel">Failure Reason</TableCell>
      )}
    </TableRow>
  );

  const renderRows = (item: Props["data"][0]) => (
    <TableRow key={item.reference} hover>
      <TableCell>
        <Box display="flex" alignItems="center">
          <ReceiptIcon
            className={classes.tableIcon}
            color="action"
            id="customerReceivableNameIcon"
          />
          <Link
            component="button"
            className={classes.linkTitle}
            onClick={handleClickOnCustomerName(item.id)}
            id="customerReceivableName">
            {item.name}
          </Link>
        </Box>
      </TableCell>
      <TableCell id="customerReceivableEmail">{item.email}</TableCell>
      <TableCell id="customerReceivableAmount">
        {formatCurrency(item.amount, { currency: item.currency })}
      </TableCell>
      {!areUniqueReferencesEmpty && (
        <TableCell id="customerReceivableUnique">{item.reference}</TableCell>
      )}
      <TableCell id="customerReceivableInvoiceDueDate">
        {formatDisplayedDate(item.dueDateTime)}
      </TableCell>
      {isFailureReasonExist && (
        <TableCell className={classes.cellWithTextWrap} id="customerReceivableFailure">
          {item.failureReason}
        </TableCell>
      )}
    </TableRow>
  );

  return (
    <Card elevation={12}>
      <Table
        data={data}
        renderHeader={renderHeader}
        renderRows={renderRows}
        showPagination={false}
      />
    </Card>
  );
};

export default CustomerReceivablesDetailsTable;
