import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
  CustomerDetailsForm,
} from "@APP/components";
import { getErpId, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { CustomerType } from "@APP/types";
import { fetchCustomerById } from "@APP/services/api";
import { formatErrorMessage } from "@APP/utils";
import { useAlert } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";

const CustomerDetails = () => {
  const { customerId }: { customerId: string } = useParams();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();

  const erpId = useSelector(getErpId);

  const [customer, setCustomer] = useState<CustomerType | null | undefined>();

  useEffect(() => {
    (async () => {
      try {
        if (!erpId) return;

        dispatch(showLoader());
        setCustomer((await fetchCustomerById(erpId, customerId)).data[0]);
      } catch (error) {
        setCustomer(null);
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      } finally {
        dispatch(hideLoader());
      }
    })();
  }, []);

  if (customer === undefined) return null;

  return (
    <Page title="View Customer">
      <ScreenHeader title="View Customer" id="customerDetailsTitle" />
      <ScreenHeaderSubtitle subtitle="Please check your customer details:" />
      <CustomerDetailsForm
        values={{
          name: customer?.entityContact?.name,
          email: customer?.entityContact?.email,
          mobile: customer?.entityContact?.mobile,
          address: customer?.entityContact?.billingAddress?.addressLines?.[0],
          addressLine2: customer?.entityContact?.billingAddress?.addressLines?.[1],
          state: customer?.entityContact?.billingAddress?.state,
          city: customer?.entityContact?.billingAddress?.city,
          vatNumber: customer?.vatNumber,
        }}
        formEditable={false}
      />
      <FooterActionsButtons
        backButtonText="Back"
        handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_SELECT_CUSTOMER)}
      />
    </Page>
  );
};

export default CustomerDetails;
