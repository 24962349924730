import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import { FooterActionsButtons, Page, ResultNotification, ScreenHeader } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { getErrorMessageByErrorCode } from "@APP/utils";
import { ErrorCode } from "@APP/types";
import { useEffect } from "react";

const InvoiceFailure = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state } = useLocation<{
    errorMessage: string;
    errorCode: ErrorCode;
    hideContinueButton: boolean;
  }>();

  useEffect(() => {
    return () => {
      if (history.action === "POP" && state?.errorCode === 6101) {
        history.replace(SCREEN_PATHS.RECEIVABLES_LIST);
      }
    };
  }, []);

  const getErrorContent = () => {
    if (state?.errorCode) {
      return getErrorMessageByErrorCode(state.errorCode);
    } else if (state?.errorMessage) {
      return state?.errorMessage;
    }

    return t("Errors.InvoiceCreation.Alerts.ProcessInvoice.Message");
  };

  return (
    <Page title="View your Invoice">
      <ScreenHeader title="View your Invoice" id="invoiceFailureTitle" />
      <ResultNotification type="error">{parse(getErrorContent())}</ResultNotification>
      <FooterActionsButtons
        backButtonText="Back to Customer Invoices"
        handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_LIST)}
        continueButtonText={state?.hideContinueButton ? "" : "View Invoice details"}
        handleContinue={() => history.push(SCREEN_PATHS.RECEIVABLES_CONFIRM)}
      />
    </Page>
  );
};

export default InvoiceFailure;
