import React from "react";
import { Box, BoxProps, Card, CardContent, lighten, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/InfoRounded";

interface Props extends BoxProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.92),
    "&.MuiCardContent-root": {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  infoIcon: {
    position: "absolute",
    top: -7,
    left: -7,
    width: 22,
    height: 22,
  },
  text: {
    whiteSpace: "pre-line",
  },
}));

const NoteBox = ({ children, ...boxProps }: Props) => {
  const classes = useStyles();

  return (
    <Box position="relative" {...boxProps}>
      <InfoIcon color="secondary" className={classes.infoIcon} />
      <Card elevation={4}>
        <CardContent className={classes.container}>
          {typeof children === "string" ? (
            <Typography variant="body2" className={classes.text}>
              {children}
            </Typography>
          ) : (
            children
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default NoteBox;
