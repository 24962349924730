import request from "@APP/services/api/request";
import CONFIG from "@APP/config";
import { SubscriptionPlan, UserSubscriptionResponse } from "@APP/types";

export const getUserSubscription = async () => {
  const response = await request<UserSubscriptionResponse>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/subscriptions`,
  });

  return response.data;
};

export const getSubscriptionsBillingPlans = async () => {
  const response = await request<{ data: SubscriptionPlan[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/subscriptions/plans`,
  });
  return response.data.data;
};

export const subscribeToPlan = async (planId: string) => {
  return request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/subscriptions/${planId}`,
  });
};

export const unsubscribeFromPlan = async (subscriptionId: string) => {
  return request({
    method: "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/subscriptions/${subscriptionId}`,
  });
};
