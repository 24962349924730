import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";

import CONFIG from "@APP/config";

const TermsAndConditionsLinks = () => {
  const { t } = useTranslation();

  return (
    <ul>
      <li>
        <Link
          variant="body2"
          href={CONFIG.URLS.TERMS_AND_CONDITIONS_URL}
          target="_blank"
          data-testid="customer-terms-link"
          id="termsAndConditionsCustomerTermLink"
          rel="noopener noreferrer">
          {t("Registration.FullConditionLinkText")}
        </Link>
      </li>
      {CONFIG.URLS.PAYMENT_PROVIDER.TERMS_AND_CONDITIONS_URL && (
        <li>
          <Link
            variant="body2"
            href={CONFIG.URLS.PAYMENT_PROVIDER.TERMS_AND_CONDITIONS_URL}
            target="_blank"
            id="termsAndConditionsCustomerTermLinkOne"
            data-testid="third-party-terms-link"
            rel="noopener noreferrer">
            {CONFIG.AIS_PROVIDER}
          </Link>
        </li>
      )}
      {CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION && (
        /* DETAILS FOR THIS PENDING FROM PRODUCT */
        <li>
          <Link
            variant="body2"
            href="https://www.praeturaventures.com/"
            target="_blank"
            data-testid="praetura-terms"
            id="termsAndConditionsPraeturaTermLink"
            rel="noopener noreferrer">
            Praetura
          </Link>
        </li>
      )}
    </ul>
  );
};

export default TermsAndConditionsLinks;
