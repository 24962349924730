import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  CustomerTable,
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { getPermissions, getUser, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { useAccessPermission, useAlert, useHandleErrorCodes } from "@APP/hooks";
import { CustomerType } from "@APP/types";
import { ErpId } from "@APP/constants";
import { capitalize } from "@APP/utils";
import { API } from "@APP/services";

const CustomerList = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const { fetchAllPermissions } = useAccessPermission();

  const permissions = useSelector(getPermissions);
  const user = useSelector(getUser);

  const [customers, setCustomers] = useState<CustomerType[] | undefined>();

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  const getAllCustomersRecursively = async (
    erpId: string,
    page = 0,
    prevCustomers: CustomerType[] = [],
  ): Promise<CustomerType[]> => {
    const response = await API.fetchCustomers(erpId, { page });
    const customers = [...prevCustomers, ...response.data] as CustomerType[];
    if (response.links.next) {
      return getAllCustomersRecursively(erpId, page + 1, customers);
    }
    return customers;
  };

  const loadCustomers = async () => {
    try {
      dispatch(showLoader());
      const customers = await getAllCustomersRecursively(user?.erp as ErpId);

      if (!customers.length) {
        setCustomers([]);

        return alert.open(
          "",
          t("Errors.OutboundPayments.Alerts.NoCustomers.Message", {
            ACCOUNTING_PACKAGE: capitalize(user?.erp),
          }),
          [
            {
              text: "Create Customer",
              onClick: () => history.push(SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER),
            },
          ],
        );
      }

      setCustomers(customers);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open(
        "",
        user?.erp === ErpId.INTERNAL
          ? t("Errors.OutboundPayments.Alerts.GetCustomers.InternalERPMessage")
          : t("Errors.OutboundPayments.Alerts.GetCustomers.ExternalERPMessage"),
        [{ text: "Cancel" }, { text: "Try again", onClick: async () => await loadCustomers() }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    (async () => {
      await loadCustomers();
    })();
  }, []);

  const handleClickRow = (customerId?: string) => {
    if (!customerId) return;

    history.push(`${SCREEN_PATHS.EDIT_CUSTOMER}/${customerId}`);
  };

  if (!customers) return null;

  return (
    <Page title="Customers">
      <Grid container>
        <Grid item xs={12}>
          <ScreenHeader title="Customers" />
          <ScreenHeaderSubtitle
            subtitle={
              permissions.contact.create
                ? "Choose a customer or create a new one."
                : "Choose a customer."
            }
          />
        </Grid>
        {permissions.contact.create && (
          <Grid item xs={12}>
            <Box display="flex" textAlign="center" justifyContent="flex-end">
              <Button
                className="createCTAButton"
                id="createCTAButton"
                variant="contained"
                color="secondary"
                component={RouterLink}
                to={SCREEN_PATHS.CREATE_CUSTOMER}>
                Create new Customer
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <CustomerTable customers={customers} onClickRow={handleClickRow} />
      {!!customers?.length && (
        <FooterActionsButtons
          backButtonText="Back"
          handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_SELECT_CUSTOMER)}
        />
      )}
    </Page>
  );
};

export default CustomerList;
