import React, { useState } from "react";
import { Box, Card, CardHeader, darken, Divider, Tab, Tabs, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Trans, useTranslation } from "react-i18next";

import { setIdTab } from "@APP/utils";
import { TabPanel } from "@APP/components";
import CONFIG from "@APP/config";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  list: {
    paddingLeft: theme.spacing(2),
  },
  tabsRoot: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: darken(theme.palette.background.default, 0.04),
    minHeight: 46,

    "& .MuiTabs-flexContainer": {
      position: "relative",
      alignItems: "center",
      height: "100%",
      zIndex: 100,
    },

    "& .MuiTabs-scroller": {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
  tabsIndicator: {
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    height: 40,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
  tabItem: {
    borderRadius: theme.shape.borderRadius,
    minHeight: 40,
  },
}));

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  return (
    <>
      <Box mb={4}>
        <Tabs
          classes={{ root: classes.tabsRoot }}
          TabIndicatorProps={{ className: classes.tabsIndicator }}
          value={tabIndex}
          textColor="primary"
          onChange={handleChangeTab}
          variant="fullWidth"
          scrollButtons={false}>
          <Tab
            label="Terms of Use"
            {...setIdTab(0, "terms_of_use_tab")}
            className={classes.tabItem}
            disableRipple
          />
          <Tab
            label="Privacy Policy"
            {...setIdTab(1, "privacy_policy")}
            className={classes.tabItem}
            disableRipple
          />
        </Tabs>
      </Box>
      <Card elevation={4}>
        <CardHeader
          title="Terms of Use & Privacy Policy"
          data-testid="user-card-header"
          id="termConditionPrivacyTitle"
        />
        <Divider />
        <TabPanel value={tabIndex} index={0}>
          <Box mt={1} mb={4} display="flex" flexDirection="column" alignItems="center">
            <Typography align="center" color="primary" variant="h3">
              Terms of Use
            </Typography>
            <Box maxWidth="90%" mt={3}>
              <Typography variant="body2">
                <Trans
                  i18nKey="Settings.TermsAndConditions"
                  values={{ productName: t("ProductName") }}
                  components={[
                    <b />,
                    <Link
                      href={CONFIG.URLS.TERMS_AND_CONDITIONS_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="termConditionPrivacyLink"
                    />,
                  ]}
                />
              </Typography>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Box mt={1} mb={4} display="flex" flexDirection="column" alignItems="center">
            <Typography align="center" color="primary" variant="h3">
              Privacy Policy
            </Typography>
            <Box maxWidth="90%" mt={3}>
              <Typography variant="body2">
                {t("Settings.PrivacyNoticeMainText1", { productName: t("ProductName") })}
              </Typography>
              <Box mt={2}>
                <Typography variant="body2">
                  <Trans
                    i18nKey="Settings.PrivacyNoticeMainText2"
                    values={{ productName: t("ProductName") }}
                    components={[
                      <b />,
                      <Link
                        href={CONFIG.URLS.PRIVACY_POLICY_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="termConditionsPrivacyLink"
                      />,
                    ]}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        </TabPanel>
      </Card>
    </>
  );
};

export default TermsAndConditions;
