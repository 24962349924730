import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CompanyType, ManualOrgType } from "@APP/constants";
import { getBusinessType, getUserOrganisation, setBusinessType } from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { API } from "@APP/services";

import OrgDetailsLtdCompany from "./OrgDetailsLtdCompany";
import OrgDetailsOtherCompany from "./OrgDetailsOtherCompany";
import { convertToCapitalizeCase } from "../utils/utils";

const OrganizationDetails = () => {
  const history = useHistory();

  const businessType = useSelector(getBusinessType);
  const org = useSelector(getUserOrganisation);
  const dispatch = useDispatch();

  const fetchWcfCustomer = async () => {
    try {
      const customer = await API.getWcfCustomer();
      if (customer?.status === "Submitted") {
        history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
      } else {
        if (!businessType) history.push(`${SCREEN_PATHS.WCF_ACCOUNTING}`);
        if (org) history.push(`${SCREEN_PATHS.WCF_MANUAL_COMPANY}`);
      }
      if (customer?.status === "Draft" || org) history.push(`${SCREEN_PATHS.WCF_MANUAL_COMPANY}`);
      dispatch(setBusinessType(convertToCapitalizeCase(customer.companyType as string)));
    } catch (error) {
    } finally {
      if (org) history.push(`${SCREEN_PATHS.WCF_MANUAL_COMPANY}`);
    }
  };

  useEffect(() => {
    fetchWcfCustomer();
  }, []);

  const orgType =
    businessType === ManualOrgType.OTHER || businessType === ManualOrgType.PARTNERSHIP
      ? CompanyType.OtherOrgTypes
      : CompanyType.SoleTrader;

  return businessType === "Limited Liability Company" ||
    businessType === "Limited Liability Partnership" ? (
    <OrgDetailsLtdCompany />
  ) : (
    <OrgDetailsOtherCompany orgType={orgType} title={businessType} />
  );
};

export default OrganizationDetails;
