import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  SimplePaletteColorOptions,
  SvgIcon,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import InfoIcon from "@mui/icons-material/Info";
import makeStyles from "@mui/styles/makeStyles";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useParams } from "react-router-dom";

import {
  getWorkingCapitalFinanceDetails,
  hideLoader,
  setActiveStep,
  setFormSubType,
  setIsRefinance,
  setNextPath,
  setNextStepActive,
  setProductType,
  showLoader,
} from "@APP/redux";
import { Page } from "@APP/components";
import palette from "@APP/styles/theme/default/palette";
import { SCREEN_PATHS } from "@APP/navigation";
import WcfFooter from "@APP/components/layout/WCFLayout";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import { API } from "@APP/services";
import { useAlert } from "@APP/hooks";
import CONFIG from "@APP/config";

import { assetFinancePayload } from "../utils/assetFinancePayload";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContaier: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "center",
    height: "80vh",
    borderRadius: "5px",
  },
  assestFinance: {
    width: "35%",
    borderRadius: 5,
    backgroundColor: "#FCFCFC",
  },
  cardIcons: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontSize: "3em",
    color: (palette.primary as SimplePaletteColorOptions)?.main,
  },
  cardHeader: {
    display: "grid",
    padding: "0px",
    color: (palette.primary as SimplePaletteColorOptions)?.main,
  },
  info: {
    display: "flex",
    gap: "5px",
    marginBottom: "10px",
    alignItems: "center",
  },
  infoGrid: {
    display: "flex",
    width: "100%",
    height: "100%",
    margin: "16px",
  },
  assetCards: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "2%",
  },
  checkIcon: {
    display: "flex",
    justifyContent: "flex-end",
    height: 0,
    padding: "8px 8px 0 0",
  },
}));

interface FinanceOptions {
  id: number;
  label: string;
  value: string;
  unique: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  content: string;
  selected: boolean;
}

interface FinanceProps {
  options: FinanceOptions[];
  handleOptions: (id: number, value: string, label?: string) => void;
}

const assetFinanceOptions = [
  {
    id: 0,
    label: "Hire Purchase",
    value: "hire_purchase",
    unique: "hire_purchase",
    icon: PendingActionsIcon,
    content: "Own the asset but spread the cost",
    selected: false,
    refinance: false,
  },
  {
    id: 1,
    label: "Finance Lease",
    value: "finance_lease",
    unique: "finance_lease",
    icon: PendingActionsIcon,
    content: "Use the asset without the ties of ownership",
    selected: false,
    refinance: false,
  },
  {
    id: 2,
    label: "Refinance a Purchase",
    value: "hire_purchase",
    unique: "rehire_purchase",
    icon: PendingActionsIcon,
    content: "Unlock capital from existing assets",
    financeLabel: "Hire Purchase",
    selected: false,
    refinance: true,
  },
  {
    id: 3,
    label: "Refinance a Lease",
    value: "finance_lease",
    unique: "refinance_lease",
    icon: PendingActionsIcon,
    content: "Unlock capital from existing assets",
    financeLabel: "Finance Lease",
    selected: false,
    refinance: true,
  },
];

const FinanceCards: React.FC<FinanceProps> = ({ options, handleOptions }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.assetCards} gap={2}>
      {options.map((financeOptions) => (
        <Card
          id={financeOptions.unique}
          elevation={1}
          style={{
            backgroundColor: financeOptions.selected ? "#FFF" : "",
            boxShadow: financeOptions.selected ? "2px 2px" : "",
            border: financeOptions.selected
              ? `1px solid ${(palette.primary as SimplePaletteColorOptions)?.main}`
              : "",
            cursor: "pointer",
          }}
          className={classes.assestFinance}
          key={financeOptions.label}
          onClick={() =>
            handleOptions(financeOptions?.id, financeOptions?.value, financeOptions?.label)
          }>
          <Grid className={classes.checkIcon}>
            {financeOptions.selected && (
              <CheckCircleIcon
                sx={{
                  color: (palette.secondary as SimplePaletteColorOptions)?.main,
                }}
              />
            )}
          </Grid>
          <Grid className={classes.infoGrid}>
            <CardContent>
              <SvgIcon className={classes.cardIcons} component={financeOptions.icon} />
            </CardContent>
            <Grid display="flex" flexDirection="column" paddingTop={2}>
              <CardHeader className={classes.cardHeader} title={financeOptions.label} />
              <Typography variant="subtitle2" color="textSecondary" marginBottom={3}>
                {financeOptions.content}
              </Typography>
              <Grid className={classes.info}>
                <InfoIcon />
                <Link
                  href={CONFIG.URLS.LEARN_MORE_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                  id="assetFinanceLearnMoreLink">
                  Learn more
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      ))}
    </Grid>
  );
};

function AssetFinance() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { t } = useTranslation();
  const { nextStepActive, nextPath } = useSelector(getWorkingCapitalFinanceDetails);
  const { applicationId }: { applicationId: string } = useParams();

  const classes = useStyles();
  const [customFinanceOptions, setCustomFinanceOptions] = useState(assetFinanceOptions);
  const [title, setTitle] = useState<string>("");
  const [optionsClicked, setOptionsClicked] = useState(false);

  const updateFinanceApplication = async (value: string, isRefinance: boolean) => {
    let apiData = { ...assetFinancePayload, productType: value, isRefinance };
    apiData = {
      ...apiData,
      assetFinanceRequest: {
        ...apiData.assetFinanceRequest,
        productType: value,
        isRefinance,
      },
    };
    try {
      dispatch(showLoader());
      await API.updateApplication(apiData, applicationId);
    } catch (error) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        "Unable to update finance options. Please try again.",
        [{ text: "Okay" }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleAssetOptions = (id: number, value: string, label?: string) => {
    const refinance = customFinanceOptions[id].refinance;
    dispatch(setProductType(value));
    dispatch(setIsRefinance(refinance));
    updateFinanceApplication(value, refinance);
    setTitle(label as string);
    setOptionsClicked(true);
    const selectedAssetFinanceOptions = assetFinanceOptions.map((assetOption) =>
      assetOption.id === id
        ? { ...assetOption, selected: true }
        : { ...assetOption, selected: false },
    );
    setCustomFinanceOptions(selectedAssetFinanceOptions);
  };

  useEffect(() => {
    if (nextStepActive && nextPath) {
      dispatch(setNextStepActive(false));
      dispatch(setNextPath(""));
    }
  }, []);

  useEffect(() => {
    dispatch(setNextStepActive(optionsClicked));
    dispatch(setNextPath(`${SCREEN_PATHS.WCF_ASSET_FINANCE_FORM_DATA}/${applicationId}`));
    dispatch(setFormSubType(title));
    dispatch(setActiveStep(2));
  }, [title]);

  return (
    <Page title="Asset Finance" className={classes.container} p={0}>
      <Box p={3}>
        <Card elevation={4} className={classes.cardContaier}>
          <form style={{ width: "100%" }}>
            <WcfStepper />
            <Typography
              variant="h4"
              component="p"
              fontWeight={600}
              color="primary"
              mt={1}
              id="assetFinanceTitle">
              Your asset finance application
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" marginBottom={2}>
              To start your application, please select what type of asset finance you need.
            </Typography>
            <FinanceCards options={customFinanceOptions} handleOptions={handleAssetOptions} />
          </form>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter
        displaySaveAndExitButton={false}
        displayPrevButton={false}
        displayNextButton={true}
      />
    </Page>
  );
}

export default AssetFinance;
