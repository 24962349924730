import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  createLinkExternalDefaultLedger,
  fetchLinkedERPLedgers,
  getERPBankAccounts,
} from "@APP/services/api";
import { getErpId, hideLoader, setBankLedgers, showLoader, useAppDispatch } from "@APP/redux";
import { BankAccountExtended, ErpBanksAccounts } from "@APP/types";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";

const useBankLedgers = () => {
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const alert = useAlert();
  const { t } = useTranslation();

  const erp = useSelector(getErpId);

  const fetchERPBankAccounts = async (onTryAgain: Function, onCancel?: Function) => {
    try {
      dispatch(showLoader());

      const { data } = await getERPBankAccounts(erp!);
      dispatch(hideLoader());

      return data;
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      dispatch(hideLoader());

      if (isHandled) throw new Error();

      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        t("Errors.AccountingPackageLinking.Alerts.RetrieveLedgerAccounts.Message"),
        [
          { text: "Cancel", onClick: () => onCancel },
          { text: "Try again", onClick: async () => await onTryAgain() },
        ],
      );

      throw new Error();
    }
  };

  const linkSingleDefaultLedger = async (
    erpBanksAccounts: ErpBanksAccounts[],
    bankAccounts: BankAccountExtended[],
  ) => {
    await createLinkExternalDefaultLedger(
      erp!,
      erpBanksAccounts[0].accountId,
      bankAccounts![0].account.identification,
      bankAccounts![0].account.schemeName,
      bankAccounts![0].bankId,
    );

    const { data } = await fetchLinkedERPLedgers(erp!);
    dispatch(setBankLedgers(data));
  };

  return { fetchERPBankAccounts, linkSingleDefaultLedger };
};

export default useBankLedgers;
