import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isBefore } from "date-fns";

import { Box, TableCell, TableContainer, TableRow, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getForecastBalances, getForecastEndDate } from "@APP/redux";
import { ActiveCheckbox, EmptyList, Table } from "@APP/components";
import { capitalize, formatCurrency, formatDisplayedDate } from "@APP/utils";
import { TxType } from "@APP/types";

import { ForecastManualTransactionWithSelected } from "../CashflowForecastView";
import { TableProps } from "./InvoiceTable";

interface Props extends TableProps {
  data: ForecastManualTransactionWithSelected[] | null;
  handleSelectRow: (item: ForecastManualTransactionWithSelected) => void;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 475,
  },
  tableHead: {
    "& th": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const OtherPaymentsTable = ({
  tableTitles,
  data,
  handleSelectRow,
  handleSelectAllRows,
  emptyListMessage,
  tableKey,
}: Props) => {
  const classes = useStyles();

  const balances = useSelector(getForecastBalances);
  const forecastEndDate = useSelector(getForecastEndDate);

  const [page, setPage] = useState(0);
  const [entries, setEntries] = useState(5);

  const lengthOfSelected = useMemo(() => data?.filter((item) => item.selected).length ?? 0, [data]);

  const amountOfAvailableItemsForSelection = useMemo(() => {
    return data?.filter(({ date }) => !isBefore(new Date(forecastEndDate), new Date(date))).length;
  }, [data]);

  const countOfSelected = useMemo(
    () => `${lengthOfSelected} / ${data?.length ?? 0}`,
    [data, lengthOfSelected],
  );

  const handleOnEntriesChange = (entries: number) => {
    setEntries(entries);
    setPage(0);
  };

  const renderHeader = () => {
    return (
      <TableRow className={classes.tableHead}>
        <TableCell padding="checkbox" style={{ minWidth: 100 }}>
          <ActiveCheckbox
            label={countOfSelected}
            onClick={() => {
              handleSelectAllRows(amountOfAvailableItemsForSelection === lengthOfSelected);
            }}
            checked={
              amountOfAvailableItemsForSelection === lengthOfSelected &&
              !(lengthOfSelected === 0 && amountOfAvailableItemsForSelection === 0)
            }
            inputProps={{ "aria-label": "select all other payments" }}
            id="otherPaymentCheckbox"
          />
        </TableCell>
        {tableTitles.map(({ title, testId }) => (
          <TableCell
            key={title}
            data-testid={`table-header-cell-${tableKey}-${testId}`}
            id={`tableHeaderCell${tableKey}${testId}`}>
            {title}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderRows = (item: ForecastManualTransactionWithSelected, index: number) => {
    const { date, amount, selected } = item;
    return (
      <TableRow key={`forecast-${tableKey}-${index}`}>
        <TableCell padding="checkbox">
          <ActiveCheckbox
            disabled={isBefore(new Date(forecastEndDate), new Date(item.date))}
            checked={selected}
            onClick={() => {
              handleSelectRow(item);
            }}
            inputProps={{ "aria-label": "select payment" }}
            id="otherPaymentTableCheckboxSelectPayment"
          />
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">{formatDisplayedDate(date)}</Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            color={item.type === TxType.CREDIT ? "secondary" : "inherit"}>
            {item.type === TxType.CREDIT ? "+ " : "- "}
            {formatCurrency(amount.amount, { currency: balances?.totalBalance?.currency })}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">{capitalize(item.type)}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  if (!data?.length)
    return (
      <Box display="flex" flexGrow={1} m={2}>
        <EmptyList
          message={emptyListMessage}
          emptyListDataTestId={`empty-table-text-other-payments`}
          id="emptyTableTextOtherPayments"
        />
      </Box>
    );

  return (
    <Box px={2} pt={2}>
      <Typography variant="subtitle2">Added Payments</Typography>
      <Box minHeight={200}>
        <TableContainer data-testid={`table-body-${tableKey}`}>
          <Table
            data={data.slice(page * entries, (page + 1) * entries)}
            renderHeader={renderHeader}
            renderRows={renderRows}
            onPageChange={setPage}
            onEntriesChange={handleOnEntriesChange}
            page={page}
            entries={entries}
            styleOverrides={classes}
            maxHeightTableBody={400}
            lastPage={data.length && data.length < entries ? 1 : Math.ceil(data.length / entries)}
            paginationSelectInputProps={{
              id: `table-${tableKey}-rows-per-page-pagination-input`,
            }}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default OtherPaymentsTable;
