import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Grid } from "@mui/material";

import {
  CustomerTable,
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import { CustomerType } from "@APP/types";
import { API } from "@APP/services";
import {
  getInvoice,
  getPermissions,
  getUser,
  hideLoader,
  setAddressInformation,
  setDefaultInvoiceState,
  setDefaultRtpDetailsToReceivables,
  setDeliveryEmails,
  setPayerEmail,
  setPayerId,
  setPayerName,
  setPayerPhone,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { ErpId, Provider } from "@APP/constants";
import { useAccessPermission, useAlert, useHandleErrorCodes } from "@APP/hooks";
import { capitalize } from "@APP/utils";
import { SCREEN_PATHS } from "@APP/navigation";

const SelectCustomer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const { fetchAllPermissions } = useAccessPermission();

  const { customerContact } = useSelector(getInvoice);
  const user = useSelector(getUser);
  const permissions = useSelector(getPermissions);

  const [selectedCustomerId, setSelectedCustomerId] = useState<undefined | string>();
  const [customers, setCustomers] = useState<CustomerType[] | undefined>();

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  const getAllCustomersRecursively = async (
    erpId: string,
    page = 0,
    prevCustomers: CustomerType[] = [],
  ): Promise<CustomerType[]> => {
    const response = await API.fetchCustomers(erpId, { page });
    const customers = [...prevCustomers, ...response.data] as CustomerType[];
    if (response.links.next) {
      return getAllCustomersRecursively(erpId, page + 1, customers);
    }
    return customers;
  };

  const loadCustomers = async () => {
    try {
      dispatch(showLoader());
      const customers = await getAllCustomersRecursively(user?.erp as ErpId);

      if (!customers.length && permissions.contact.create) {
        setCustomers([]);

        return alert.open(
          "",
          t("Errors.OutboundPayments.Alerts.NoCustomers.Message", {
            ACCOUNTING_PACKAGE: capitalize(user?.erp),
          }),
          [
            {
              text: "Create Customer",
              onClick: () => history.push(SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER),
            },
          ],
        );
      }

      setCustomers(customers);
    } catch (error) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open(
        "",
        user?.erp === ErpId.INTERNAL
          ? t("Errors.OutboundPayments.Alerts.GetCustomers.InternalERPMessage")
          : t("Errors.OutboundPayments.Alerts.GetCustomers.ExternalERPMessage"),
        [{ text: "Cancel" }, { text: "Try again", onClick: async () => await loadCustomers() }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    dispatch(setDefaultRtpDetailsToReceivables());
    setSelectedCustomerId(customerContact.id?.externalId);
    (async () => {
      await loadCustomers();
    })();
  }, []);

  const handleCreateCustomer = () => {
    dispatch(setDefaultInvoiceState());
    history.push(SCREEN_PATHS.RECEIVABLES_CREATE_CUSTOMER);
  };

  const handleClickRow = (customerId?: string) => {
    setSelectedCustomerId(customerId);
  };

  const handleContinue = () => {
    const selectedCustomer = customers?.find(
      (customer) => customer.entityContact.id?.externalId === selectedCustomerId,
    );

    if (!selectedCustomer) return;

    dispatch(setPayerName(selectedCustomer?.entityContact.name!));
    dispatch(setPayerId(selectedCustomer?.entityContact?.id));

    if (selectedCustomer?.entityContact.email) {
      dispatch(setPayerEmail(selectedCustomer?.entityContact.email));
      dispatch(setDeliveryEmails({ email: selectedCustomer?.entityContact.email }));
    }

    if (selectedCustomer?.entityContact.mobile)
      dispatch(setPayerPhone(selectedCustomer?.entityContact.mobile));

    if (Provider.isMaverick) {
      dispatch(
        setAddressInformation({
          addressLines: selectedCustomer?.entityContact.shippingAddress?.addressLines,
          state: selectedCustomer?.entityContact.shippingAddress?.state,
          city: selectedCustomer?.entityContact.shippingAddress?.city,
          postcode: selectedCustomer?.entityContact.shippingAddress?.postcode,
        }),
      );
    }

    history.push(SCREEN_PATHS.RECEIVABLES_DELIVERY_DETAILS);
  };

  if (!customers) return null;

  const getSubtitle = () => {
    if (!permissions.contact.create) return "Choose a customer.";

    if (!permissions.contact.view) return "You can create a new customer.";

    return user?.erp === ErpId.INTERNAL
      ? "Choose a customer or create a new one."
      : "Choose a customer from your accounting package or create a new one.";
  };

  return (
    <Page title="Create Invoice">
      <Grid container>
        <Grid item xs={12}>
          <ScreenHeader title="Select Customer" id="selectCustomerTitle" />
          <ScreenHeaderSubtitle subtitle={getSubtitle()} />
        </Grid>
        {permissions.contact.create && (
          <Grid item xs={12}>
            <Box display="flex" textAlign="center" justifyContent="flex-end">
              <Button
                className="createCTAButton"
                variant="contained"
                color="secondary"
                onClick={handleCreateCustomer}
                id="selectCustomerCreateButton">
                Create new Customer
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      {permissions.contact.view && (
        <CustomerTable customers={customers} showViewInfoSection onClickRow={handleClickRow} />
      )}
      {!!customers?.length && (
        <FooterActionsButtons
          backButtonText="Back"
          handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_LIST)}
          disabledContinueButton={!selectedCustomerId}
          handleContinue={handleContinue}
          continueButtonText="Continue"
          hiddenContinueButton={!permissions.contact.view}
        />
      )}
    </Page>
  );
};

export default SelectCustomer;
