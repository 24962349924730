import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { IMAGES } from "@APP/assets";
import CONFIG from "@APP/config";
import { TOP_BAR_HEIGHT } from "@APP/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: TOP_BAR_HEIGHT,
  },
  logoLink: {
    color: "inherit",
    fontSize: 24,
  },
  logo: {
    height: 42,
    paddingRight: 12,
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar elevation={1} color="primary">
      <Toolbar className={classes.toolbar}>
        <Box m="auto">
          <RouterLink className={classes.logoLink} to="/">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
              aria-label="navigate to dashboard"
              height={TOP_BAR_HEIGHT}>
              <img
                className={classes.logo}
                src={IMAGES.APP_LOGO}
                alt={CONFIG.PRODUCT_NAME}
                id="topBarAppLogo"
              />
              <Typography variant="h4" component="h1" id="topBarProductName">
                {t("ProductName")}
              </Typography>
            </Box>
          </RouterLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
