import CONFIG from "@APP/config";
import {
  RTP,
  RTPDeliveryChannel,
  RTPStatus,
  RTPQueryFilter,
  ResponsePagination,
  RequestPagination,
  RequestSorting,
  InstalmentDetailsResponse,
  StandingOrderResponse,
  SortBy,
  SortType,
  CreateOrganisationSoleTrader,
  CreateOrganisationCompanyType,
  Contact,
} from "@APP/types";
import request from "../request";
import { Channel, RTPMessageType } from "@APP/services/RTP";
import { CompanyDetails } from "@APP/types/wcf";

type RTPRequest = {
  amount?: {
    amount: string;
    currency: string;
  };
  channel: keyof typeof RTPDeliveryChannel | (string & {});
  customer: Contact;
  details: {
    externalId: string;
    source: string;
  };
  scheduledDates?: (string | string[])[];
  status: keyof typeof RTPStatus | (string & {});
  supplier: {
    SIC?: string[] | undefined;
    companyInfo?: {
      email?: string | undefined;
      id: string;
      name: string;
      telephone: string;
    };
    name: string;
    otherContacts?: any[];
    primaryContact?: {
      id: string;
      name: string;
      email: string;
      telephone: string;
    };
    taxInfo?: {
      defaultCurrency: string;
      defaultVatRate: string;
      utrNumber: string;
      vatRegistrationNumber?: string;
    };
  };
  supplierBankDetails?: {
    accountNumber: string;
    scheme: string;
  };
  reminder?: ReminderRTP[];
  rtpCreationUserZone: string;
};

type ReminderRTP = {
  enabled: Boolean;
  channel: Channel;
  tpe: RTPMessageType;
};

export const getPaymentRequests = async (
  pagination?: RequestPagination,
  filter?: RTPQueryFilter | null,
  sorting?: RequestSorting,
) => {
  const { page, entries } = { page: 0, entries: 50, ...pagination };
  const { sort_by = SortBy.dueDate, sort_type = SortType.desc } = sorting!;
  const response = await request<{
    data: RTP[];
    links: ResponsePagination;
    meta: { totalItems: number };
  }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/payment-requests`,
    params: {
      page: `${page * entries};;;${entries}`,
      ...filter,
      sort_by,
      sort_type,
    },
  });
  return response.data;
};

export const getPaymentRequest = async (requestId: string) => {
  const response = await request<{ data: RTP[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/payment-requests`,
    params: { requestId },
  });
  return response.data.data[0];
};

export const updateRTPStatusToPaid = async (requestId: string) =>
  await request({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/${requestId}/paid`,
  });

export const updateRTPStatusToCancelled = async (requestId: string) =>
  await request({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/${requestId}/cancel`,
  });

export const createPaymentRequest = async (
  data: RTPRequest,
  action: "Create" | "CreateAndSend" = "Create",
) => {
  await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/request-payment`,
    data,
    params: { action },
  });
};

export const resendRTP = async (
  rtpId: string,
  channel: RTPDeliveryChannel,
  messageType?: RTPMessageType,
) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/send`,
    data: {
      rtpId,
      messageType,
      channel,
    },
  });
  return response.data;
};

export const createOrganisation = (
  email: string,
  phone: string,
  body: CreateOrganisationSoleTrader | CreateOrganisationCompanyType,
) => {
  return request(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/organisation`,
      params: { email, phone },
      data: body,
    },
    { auth: false },
  );
};

export const getCompanyByKeyword = (keyword: string, offset: number = 0) => {
  return request(
    {
      method: "GET",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/public/companies/search/${keyword}?page=${offset};;;20`,
    },
    { auth: false },
  );
};

export const getDirectorsByRegistrationNumber = (
  registrationNumber: string,
  offset: number = 0,
) => {
  return request(
    {
      method: "GET",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/public/companies/${registrationNumber}/directors?page=${offset}`,
    },
    { auth: false },
  );
};

export const getInstalmentDetailsForRtp = async (rtpId: string) => {
  const response = await request<InstalmentDetailsResponse>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/installment-payment`,
    params: { rtpId },
  });

  return response.data;
};

export const getStandingOrderDetailsForRtp = async (paymentId: string, bankId: string) => {
  const response = await request<StandingOrderResponse>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bankId}/payments/standing-orders/${paymentId}/details`,
  });

  return response.data;
};

export const getServiceChargeRtps = async () => {
  const response = await request<{ data: RTP[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/request-to-pay/payment-requests/by-customer`,
  });

  return response.data;
};

export const getCompanyByNumber = async (companyNumber: string) => {
  const response = await request<CompanyDetails>(
    {
      method: "GET",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/public/companies/company_profile/${companyNumber}`,
    },
    { auth: false },
  );

  return response.data;
};
