import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { Grid } from "@mui/material";

import { TabsName } from "@APP/constants";
import { API } from "@APP/services";
import { SubscriptionPlan } from "@APP/types";
import { useAlert } from "@APP/hooks";
import { hideLoader, showLoader, useAppDispatch } from "@APP/redux";

import AccountDetails from "./AccountDetails";
import ActivateAccount from "./ActivateAccount";
import DeactivateAccount from "./DeactivateAccount";
import Success from "./Success";

const Account = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const queryParams = queryString.parse(history.location?.search ?? "") as { tab: string };
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan | null>(null);

  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoader());

        const billingPlans = await API.getSubscriptionsBillingPlans();
        if (!billingPlans?.length) {
          throw new Error("");
        }

        const availablePlan = billingPlans.find((plan) => plan.available);
        setSubscriptionPlan(availablePlan || null);
      } catch (error) {
        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Common.Alerts.Generic.Message"),
          [
            {
              text: "Okay",
            },
          ],
        );
      } finally {
        dispatch(hideLoader());
      }
    })();
  }, []);

  return (
    <Grid container justifyContent="center" spacing={5}>
      <Grid item xs={12} md={10} lg={8}>
        {queryParams.tab === TabsName.ACCOUNT && (
          <AccountDetails subscriptionPlan={subscriptionPlan} />
        )}
        {queryParams.tab === TabsName.ACTIVATE_ACCOUNT && (
          <ActivateAccount subscriptionPlan={subscriptionPlan} />
        )}
        {queryParams.tab === TabsName.DEACTIVATE_ACCOUNT && <DeactivateAccount />}
        {queryParams.tab === TabsName.ACCOUNT_SUCCESS && <Success />}
      </Grid>
    </Grid>
  );
};

export default Account;
