import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  SimplePaletteColorOptions,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";

import { Page } from "@APP/components";
import palette from "@APP/styles/theme/default/palette";
import WcfFooter from "@APP/components/layout/WCFLayout";
import {
  showLoader,
  hideLoader,
  useAppDispatch,
  setInvoiceFinanceFormData,
  setApplicantFormData,
  setFormType,
  setAssetFinanceFormData,
  setFormSubType,
  setProductType,
} from "@APP/redux";
import { API } from "@APP/services";
import { SCREEN_PATHS } from "@APP/navigation";
import { Application } from "@APP/types/wcf";
import { formatDisplayedDate } from "@APP/utils";
import { useAlert, useApplicationListing } from "@APP/hooks";
import { getApplicationDetailsById } from "@APP/services/api";
import {
  fetchWcfCustomer,
  handleRedirect,
  removeUnderscoreAndCapitalize,
} from "@APP/views/wcf/utils/utils";
import { assetDefaultData, invoiceDefaultData } from "@APP/redux/reducers/workingCapitalFinance";

import { assetTypes, urgencyLevels } from "../utils/dropdown";

const PRODUCT_TYPE_NAMES: { [key: string]: string } = {
  hire_purchase: "Hire Purchase",
  finance_lease: "Finance Lease",
};

const PRODUCT_TYPE_NAMES_REFINANCE: { [key: string]: string } = {
  hire_purchase: "Refinance a Purchase",
  finance_lease: "Refinance a Lease",
};

const useStyles = makeStyles(() => ({
  statusCard: {
    display: "flex",
    flexDirection: "column",
    padding: "32px",
    minWidth: "330px",
    borderRadius: "8px",
    backgroundColor: "#FCFCFC",
    maxWidth: "330px",
  },
  cardButton: {
    width: "100%",
    borderRadius: "8px",
  },
  financeCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "32px",
    borderRadius: "8px",
    border: `2px solid ${(palette.primary as SimplePaletteColorOptions)?.main}`,
  },
  page: {
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    borderRadius: "5px",
    height: "80vh",
    padding: "32px",
    flex: 1,
  },
  tabBoxContainer: {
    display: "flex",
    gap: 1,
    flexWrap: "wrap",
    maxHeight: "100vh",
    paddingTop: "2vh",
    paddingBottom: "32vh",
    overflowY: "scroll",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContaier: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "32px",
    borderRadius: "5px",
    alignItems: "center",
    height: "80vh",
  },
  financeContainer: {
    backgroundColor: "#FCFCFC",
    textAlign: "center",
    padding: "32px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
  },
}));

function StatusCard({
  data,
  index,
  completed,
}: Readonly<{ index: number; data: Application; completed: boolean }>) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();

  const financeType = data?.invoiceFinanceRequest
    ? Object.keys(data?.invoiceFinanceRequest).length > 0
      ? 2
      : 1
    : 1;

  const isRefinance = data?.assetFinanceRequest?.isRefinance;

  const productType = data?.assetFinanceRequest?.productType
    ? " - " +
      (isRefinance
        ? PRODUCT_TYPE_NAMES_REFINANCE[data?.assetFinanceRequest?.productType]
        : PRODUCT_TYPE_NAMES[data?.assetFinanceRequest?.productType])
    : "";

  const handleResume = async () => {
    try {
      dispatch(showLoader());
      const res = await getApplicationDetailsById(data.applicationId as string);
      const isInvoice = !res.data.assetFinanceRequest;
      dispatch(setProductType(res?.data?.assetFinanceRequest?.productType as string));

      if (res.data.formStage.page === 5) {
        if (isInvoice) {
          dispatch(
            setInvoiceFinanceFormData({
              annualTurnover: res.data.invoiceFinanceRequest?.annualTurnover ?? 1,
              purpose: res.data.detailedFundingPurpose ?? "",
              urgency: res.data.urgency ?? "",
            }),
          );
          dispatch(
            setApplicantFormData(
              res.data.applicants?.map((applicant, idx) => {
                return {
                  id: idx,
                  firstName: applicant.firstName ?? "",
                  middleName: applicant.middleName ?? "",
                  lastName: applicant.lastName ?? "",
                  dateOfBirth: applicant.dateOfBirth ?? "",
                  address: applicant.address.street ?? "",
                  city: applicant.address.cityOrTown ?? "",
                  postcode: applicant.address.postcode ?? "",
                  country: applicant.address.country ?? "",
                  role: applicant.companyRole ?? "",
                  mainApplicant: applicant.isMainApplicant ? "Yes" : "No",
                  guarantee: "No",
                  privacyPolicy: applicant.hasConsentedToPrivacyPolicy ?? false,
                  creditCheck: applicant.hasConsentedToCreditCheck ?? false,
                  email: applicant.contactDetails?.email ?? "",
                  phoneNumber: applicant.contactDetails?.mobilePhoneNumber ?? "",
                };
              }),
            ),
          );
        } else {
          dispatch(
            setAssetFinanceFormData({
              units: res.data.assetFinanceRequest?.assets[0].numberOfUnits ?? 1,
              pricePerUnit: res.data.assetFinanceRequest?.assets[0].costPerUnit ?? 1,
              duration: res.data.assetFinanceRequest?.duration.amount?.toString() ?? "60",
              isRefinance: res.data.assetFinanceRequest?.isRefinance ?? false,
              deposit: res.data.assetFinanceRequest?.depositOrInitialRentalAmount ?? 1,
              purpose: res.data.assetFinanceRequest?.assets[0].description ?? "",
              urgency: res.data.urgency ?? urgencyLevels[0].value,
              assetType: res.data.assetFinanceRequest?.assets[0].category ?? assetTypes[0].value,
              model: res.data.assetFinanceRequest?.assets[0].model ?? "",
              manufacturer: res.data.assetFinanceRequest?.assets[0].manufacturer ?? "",
              yearOfManufactured: res.data.assetFinanceRequest?.assets[0].manufactureYear ?? 1250,
              isNew: res.data.assetFinanceRequest?.assets[0].condition ?? "new",
              isInsured: res.data.assetFinanceRequest?.assets[0].isInsured ?? false,
              assetPic: [],
              invoicePic: [],
            }),
          );
        }
        dispatch(
          setApplicantFormData(
            res.data.applicants?.map((applicant, idx) => {
              return {
                id: idx,
                firstName: applicant.firstName ?? "",
                middleName: applicant.middleName ?? "",
                lastName: applicant.lastName ?? "",
                dateOfBirth: applicant.dateOfBirth ?? "",
                address: applicant.address.street ?? "",
                city: applicant.address.cityOrTown ?? "",
                postcode: applicant.address.postcode ?? "",
                country: applicant.address.country ?? "",
                role: applicant.companyRole ?? "",
                mainApplicant: applicant.isMainApplicant ? "Yes" : "No",
                guarantee: "No",
                privacyPolicy: applicant.hasConsentedToPrivacyPolicy ?? false,
                creditCheck: applicant.hasConsentedToCreditCheck ?? false,
                email: applicant.contactDetails?.email ?? "",
                phoneNumber: applicant.contactDetails?.mobilePhoneNumber ?? "",
              };
            }),
          ),
        );
        history.push(`${SCREEN_PATHS.WCF_FORM_SUMMARY}/${data.applicationId}`);
      } else if (res.data.formStage.page === 4) {
        if (isInvoice) {
          dispatch(
            setInvoiceFinanceFormData({
              annualTurnover: res.data.invoiceFinanceRequest?.annualTurnover ?? 1,
              purpose: res.data.detailedFundingPurpose ?? "",
              urgency: res.data.urgency ?? "",
            }),
          );
          dispatch(
            setApplicantFormData(
              res.data.applicants?.map((applicant, idx) => {
                return {
                  id: idx,
                  firstName: applicant.firstName ?? "",
                  middleName: applicant.middleName ?? "",
                  lastName: applicant.lastName ?? "",
                  dateOfBirth: applicant.dateOfBirth ?? "",
                  address: applicant.address.street ?? "",
                  city: applicant.address.cityOrTown ?? "",
                  postcode: applicant.address.postcode ?? "",
                  country: applicant.address.country ?? "",
                  role: applicant.companyRole ?? "",
                  mainApplicant: applicant.isMainApplicant ? "Yes" : "No",
                  guarantee: "No",
                  privacyPolicy: applicant.hasConsentedToPrivacyPolicy ?? false,
                  creditCheck: applicant.hasConsentedToCreditCheck ?? false,
                  email: applicant.contactDetails?.email ?? "",
                  phoneNumber: applicant.contactDetails?.mobilePhoneNumber ?? "",
                };
              }),
            ),
          );
        } else {
          dispatch(
            setAssetFinanceFormData({
              units: res.data.assetFinanceRequest?.assets[0].numberOfUnits ?? 1,
              pricePerUnit: res.data.assetFinanceRequest?.assets[0].costPerUnit ?? 1,
              duration: res.data.assetFinanceRequest?.duration.amount?.toString() ?? "60",
              isRefinance: res.data.assetFinanceRequest?.isRefinance ?? false,
              deposit: res.data.assetFinanceRequest?.depositOrInitialRentalAmount ?? 1,
              purpose: res.data.assetFinanceRequest?.assets[0].description ?? "",
              urgency: res.data.urgency ?? urgencyLevels[0].value,
              assetType: res.data.assetFinanceRequest?.assets[0].category ?? assetTypes[0].value,
              model: res.data.assetFinanceRequest?.assets[0].model ?? "",
              manufacturer: res.data.assetFinanceRequest?.assets[0].manufacturer ?? "",
              yearOfManufactured: res.data.assetFinanceRequest?.assets[0].manufactureYear ?? 1250,
              isNew: res.data.assetFinanceRequest?.assets[0].condition ?? "new",
              isInsured: res.data.assetFinanceRequest?.assets[0].isInsured ?? false,
              assetPic: [],
              invoicePic: [],
            }),
          );
          dispatch(
            setApplicantFormData(
              res.data.applicants?.map((applicant, idx) => {
                return {
                  id: idx,
                  firstName: applicant.firstName ?? "",
                  middleName: applicant.middleName ?? "",
                  lastName: applicant.lastName ?? "",
                  dateOfBirth: applicant.dateOfBirth ?? "",
                  address: applicant.address.street ?? "",
                  city: applicant.address.cityOrTown ?? "",
                  postcode: applicant.address.postcode ?? "",
                  country: applicant.address.country ?? "",
                  role: applicant.companyRole ?? "",
                  mainApplicant: applicant.isMainApplicant ? "Yes" : "No",
                  guarantee: "No",
                  privacyPolicy: applicant.hasConsentedToPrivacyPolicy ?? false,
                  creditCheck: applicant.hasConsentedToCreditCheck ?? false,
                  email: applicant.contactDetails?.email ?? "",
                  phoneNumber: applicant.contactDetails?.mobilePhoneNumber ?? "",
                };
              }),
            ),
          );
        }
        history.push(`${SCREEN_PATHS.WCF_APPLICANT_DETAILS}/${data.applicationId}`);
      } else {
        if (isInvoice) {
          history.push(`${SCREEN_PATHS.WCF_INVOICE_APPLICATION}/${data.applicationId}`);
        } else {
          if (!res?.data?.assetFinanceRequest?.productType) {
            history.push(`${SCREEN_PATHS.WCF_ASSET_FINANCE}/${data.applicationId}`);
          } else {
            history.push(`${SCREEN_PATHS.WCF_ASSET_FINANCE_FORM_DATA}/${data.applicationId}`);
          }
        }
      }
      dispatch(setFormType(isInvoice ? "Invoice Finance" : "Asset Finance"));
      if (!isInvoice)
        dispatch(
          setFormSubType(
            removeUnderscoreAndCapitalize(res.data.assetFinanceRequest?.productType ?? ""),
          ),
        );
    } catch (error) {
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Failure"),
        "Unable to create application. Please try again.",
        [{ text: "Okay" }],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <Card
      className={classes.statusCard}
      style={{ margin: "4px", position: "relative" }}
      id={"statusCard" + index.toString()}>
      <Typography variant="caption" color="textSecondary">
        {data.createdAt ? formatDisplayedDate(data.createdAt) : ""}
      </Typography>
      <Typography variant="h4" my={1}>
        {financeType === 1 ? "Asset Finance" + productType : "Invoice Finance"}
      </Typography>
      {completed && (
        <Typography variant="body2" color="textSecondary" mb={2}>
          Id: {data?.applicationId}
        </Typography>
      )}
      <Typography variant="body2" color="textSecondary" mb={2} style={{ marginTop: "auto" }}>
        {!completed
          ? "You haven’t completed your application yet"
          : data?.praeturaStatus || "Application Received"}
      </Typography>
      {!completed ? (
        <Button
          variant="outlined"
          className={classes.cardButton}
          onClick={handleResume}
          id={"resume" + index.toString()}>
          Resume
        </Button>
      ) : (
        <CheckCircleIcon
          sx={{
            color: (palette.secondary as SimplePaletteColorOptions)?.main,
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
        />
      )}
    </Card>
  );
}

function ApplyFinanceCard({
  redirectToApplication,
  custNotFound,
  isAPLinked,
  resetWCFState,
}: Readonly<{
  redirectToApplication: boolean;
  custNotFound: boolean;
  isAPLinked: boolean;
  resetWCFState: () => void;
}>) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card className={classes.financeCard}>
      <Typography variant="h4" my={1}>
        Require additional financing?
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={4}>
        Submit your application here
      </Typography>
      <Button
        variant="contained"
        style={{ marginTop: "auto" }}
        className={classes.cardButton}
        id="applyForFinancing"
        onClick={() => {
          resetWCFState();
          handleRedirect(custNotFound, isAPLinked, redirectToApplication, history);
        }}>
        Apply for financing
      </Button>
    </Card>
  );
}

function TabPanel({
  index,
  value,
  children,
}: Readonly<{
  index: number;
  value: number;
  children: ReactNode;
}>) {
  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      pt={2}>
      {value === index && <div>{children}</div>}
    </Grid>
  );
}

function ApplicationListingPage() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { getApplicationListing } = useApplicationListing();

  const [value, setValue] = useState(0);

  const [inProgress, setInProgress] = useState<any[]>([]);
  const [submitted, setSubmitted] = useState<any[]>([]);
  const [isNoListing, setIsNoListing] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isCustomerSubmitted, setIsCustomerSubmitted] = useState<boolean>(false);
  const [custNotFound, setCustNotFound] = useState(false);
  const [isAPLinked, setIsAPLinked] = useState(false);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      await retrieveListing();
      await fetchWcfCustomer(setCustNotFound, setIsAPLinked, setIsCustomerSubmitted);
    })();
  }, []);

  const retrieveListing = async () => {
    try {
      dispatch(showLoader());
      setIsloading(true);
      const { data: wcfList } = await API.wcfListing();
      getApplicationListing();
      // If no entry found, show Apply for financing section
      if (wcfList?.data?.length === 0) {
        setIsNoListing(true);
      }
      const { submittedData, progressData } = wcfList?.data?.reduce(
        (
          acc: { submittedData: Application[]; progressData: Application[] },
          element: Application,
        ) => {
          if (element.bfStatus === "Submitted") {
            acc.submittedData.push(element);
          } else {
            acc.progressData.push(element);
          }
          return acc;
        },
        { submittedData: [], progressData: [] },
      );

      // Show Success Tab if there is no entry in In-Progress Tab
      if (progressData.length === 0 && submittedData.length > 0) setValue(1);

      setSubmitted(submittedData);
      setInProgress(progressData);
      dispatch(hideLoader());
      setIsloading(false);
    } catch (error) {
      setIsNoListing(true);
      dispatch(hideLoader());
      setIsloading(false);
    }
  };

  const resetWCFState = () => {
    dispatch(setAssetFinanceFormData(assetDefaultData));
    dispatch(setInvoiceFinanceFormData(invoiceDefaultData));
    dispatch(setApplicantFormData(null));
    dispatch(setFormType(""));
    dispatch(setFormSubType(""));
  };

  if (isLoading) return null;

  if (isNoListing)
    return (
      <Page className={classes.container} title="Working capital finance" p={0}>
        <Box p={3}>
          <Card className={classes.cardContaier}>
            <Grid className={classes.financeContainer}>
              <CardHeader
                title="You haven't applied for any financing yet."
                subheader="Start your application here:"
                id="applicationListingTitle"
              />
              <Button
                sx={{ borderRadius: "5px" }}
                variant="outlined"
                onClick={() => {
                  resetWCFState();
                  handleRedirect(custNotFound, isAPLinked, isCustomerSubmitted, history);
                }}
                id="applicationListingApplyButton">
                Apply for financing
              </Button>
            </Grid>
          </Card>
        </Box>
        <Box flexGrow={1} />
        <WcfFooter
          displaySaveAndExitButton={false}
          displayPrevButton={false}
          displayNextButton={false}
        />
      </Page>
    );

  return (
    <Page className={classes.page} p={0}>
      <Box p={3}>
        <Card elevation={1} className={classes.cardContainer}>
          <Tabs value={value} onChange={handleChange} aria-label="status-tabs">
            <Tab
              label={
                <Typography variant="h5" id="wcfListingInProgressTab">
                  In Progress
                </Typography>
              }
            />
            <Tab
              label={
                <Typography variant="h5" id="wcfListingSubmittedTab">
                  Submitted
                </Typography>
              }
            />
          </Tabs>
          <TabPanel value={value} index={0} key={"wcfListingInProgressTabSelection"}>
            <Box className={classes.tabBoxContainer}>
              {inProgress?.map((card, index) => (
                <StatusCard
                  key={card.id ? card.id : card._id}
                  index={index}
                  data={card}
                  completed={false}
                />
              ))}
              <ApplyFinanceCard
                redirectToApplication={isCustomerSubmitted}
                custNotFound={custNotFound}
                isAPLinked={isAPLinked}
                resetWCFState={resetWCFState}
              />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box className={classes.tabBoxContainer}>
              {submitted?.map((card, index) => (
                <StatusCard
                  key={card.id ? card.id : card._id}
                  index={index}
                  data={card}
                  completed={true}
                />
              ))}
              <ApplyFinanceCard
                redirectToApplication={isCustomerSubmitted}
                custNotFound={custNotFound}
                isAPLinked={isAPLinked}
                resetWCFState={resetWCFState}
              />
            </Box>
          </TabPanel>
        </Card>
      </Box>
    </Page>
  );
}

export default ApplicationListingPage;
