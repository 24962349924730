import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { addMinutes } from "date-fns";

import { SCREEN_PATHS } from "@APP/navigation";
import { getAuthentication, logout, useAppDispatch } from "@APP/redux";
import { AppLocalStorage, LocalStorageKey } from "@APP/services";

import useAlert from "../useAlert";

interface IdleTimeoutProps {
  idleTime: number;
}

const IdleTimeout: React.FC<IdleTimeoutProps> = ({ idleTime }) => {
  const [isIdle, setIsIdle] = useState<boolean>(false);

  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const authenticated = useSelector(getAuthentication);

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  const { reset, getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
    onAction: () => {
      AppLocalStorage.setItem(LocalStorageKey.sessionIdleTime, new Date().toString());
      setIsIdle(false);
      reset();
    },
  });

  const handleSessionLogout = () => {
    dispatch(logout());
    history.replace(SCREEN_PATHS.LOGIN);
  };

  useEffect(() => {
    const sessionAutoLogout = localStorage.getItem("auth.sessionAutoLogout");
    const sessionIdleTime = localStorage.getItem("auth.sessionIdleTime");
    if (sessionAutoLogout) handleSessionLogout();
    if (sessionIdleTime) {
      const parsedSessionIdleTime = new Date(sessionIdleTime);
      const sessionTimeWithIdleMins = addMinutes(parsedSessionIdleTime, idleTime);
      if (Date.now() > sessionTimeWithIdleMins.getTime()) handleSessionLogout();
    }
  }, [authenticated]);

  useEffect(() => {
    if (authenticated) {
      const checkIdleTime = setInterval(() => {
        const remainingTime = getRemainingTime();
        if (remainingTime === 0 && isIdle) {
          AppLocalStorage.setItem(LocalStorageKey.sessionAuthLogout, "true");
          alert.open(
            "You have been logged out",
            "You have not used the application for 30 minutes so we have logged you out, select close to login.",
            [
              {
                text: "Close",
                onClick: () => handleSessionLogout(),
              },
            ],
          );
        }
      }, 1000);

      return () => {
        clearInterval(checkIdleTime);
      };
    }
  }, [getRemainingTime, isIdle, authenticated]);

  return <></>;
};

export default IdleTimeout;
