import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Button, ListItem, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { getPermissions } from "@APP/redux";
import { PermissionState } from "@APP/types";

interface Props {
  id?: string;
  className?: string;
  href: string;
  icon?: React.ElementType;
  title: string;
  isFullMenu: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  isOcmFeature?: boolean;
  isOcmVisible?: boolean;
  featureType?: keyof PermissionState;
  onClick?: (e: React.SyntheticEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(1),
    marginRight: "auto",
  },
  active: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.action.active,
    },
  },
}));

const NavItem = ({
  id,
  className,
  href,
  icon: Icon,
  title,
  isFullMenu,
  onClick,
  disabled = false,
  isHidden = false,
  isOcmFeature,
  isOcmVisible = true,
  featureType,
  ...rest
}: Props) => {
  const classes = useStyles();
  const permissions = useSelector(getPermissions);

  if ((featureType && !permissions[featureType]?.view) || isHidden) return null;

  if (CONFIG.FEATURES?.WORKING_CAPITAL_FINANCE_APPLICATION && !isOcmVisible && isOcmFeature) {
    return null;
  }

  return (
    <ListItem className={clsx(classes.item, className)} id={id} disableGutters {...rest}>
      <Tooltip title={title} placement="right" disableHoverListener={isFullMenu}>
        <Button
          color="primary"
          variant="text"
          aria-label={`navigate to ${title}`}
          fullWidth
          id={"sidebar_navigation_" + id}
          activeClassName={classes.active}
          component={RouterLink}
          onClick={onClick}
          disabled={disabled}
          to={href}>
          {Icon && <Icon size="20" aria-label={`${title} icon`} />}
          {isFullMenu && <span className={classes.title}>{title}</span>}
        </Button>
      </Tooltip>
    </ListItem>
  );
};

export default NavItem;
