import { useLocation } from "react-router-dom";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Page } from "@APP/components";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 200,
    [theme.breakpoints.down("md")]: {
      fontSize: 120,
    },
  },
  cardTitle: {
    fontWeight: "bold",
  },
  cardContent: {
    paddingLeft: 0,
    paddingRight: 0,
    "&.MuiCardContent-root:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    margin: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
    },
  },
  contactButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2, 2, 0, 2),
  },
}));

const CoPInvalidCloseMatchNotificationView = () => {
  const classes = useStyles();
  const location = useLocation();

  const getNotificationContent = () => {
    switch (location.pathname) {
      case SCREEN_PATHS.COP_APPLICATION_PENDING:
        return "We are performing a few final checks using the details you provided. We will contact you when your account setup is complete so you can start to get paid.";
      case SCREEN_PATHS.COP_APPLICATION_UNDER_REVIEW:
        return `Your application is under review. Should you wish to discuss this further please contact us at ${CONFIG.PRODUCT_NAME} Support.`;
      default:
        return `We were unable to validate your bank account details. Should you wish to discuss this further please contact us at ${CONFIG.PRODUCT_NAME} Support`;
    }
  };

  return (
    <Page title="Application Pending">
      <Box justifyContent="center" display="flex" flexDirection="column" alignItems="center" mt={3}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" className={classes.cardTitle}>
                Application Pending
              </Typography>
            }
            subheader="Application under review"
            sx={{ pb: 0 }}
            id="applicationPendingHeader"
          />
          <CardContent className={classes.cardContent}>
            <Divider />
            <Box className={classes.cardContentWrapper} data-testid="result-notification-children">
              <LinkOffIcon className={classes.icon} color="disabled" />
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {getNotificationContent()}
              </Typography>
            </Box>
            <Divider />
            <Box className={classes.contactButtonWrapper}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => window.open(`mailto:${CONFIG.SUPPORT_EMAIL}`)}
                id="applicationPendingSupportButton">
                Contact {CONFIG.PRODUCT_NAME} Support
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default CoPInvalidCloseMatchNotificationView;
