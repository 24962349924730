import parse from "html-react-parser";
import { Box, Button, Typography } from "@mui/material";

interface Props {
  errorMessage: string;
  onTryAgain?: () => void;
  errorCodeMessage?: string;
}

const ErrorContent = ({ onTryAgain, errorMessage, errorCodeMessage }: Props) => (
  <Box
    minHeight={150}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    textAlign="center">
    <Typography variant="body1">
      {errorMessage}
      {parse(errorCodeMessage || "")}
    </Typography>
    {onTryAgain && (
      <Box mt={3}>
        <Button color="primary" variant="contained" onClick={() => onTryAgain()}>
          Try Again
        </Button>
      </Box>
    )}
  </Box>
);

export default ErrorContent;
