import { useSelector } from "react-redux";
import { Box, Typography, Card } from "@mui/material";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

import { getForecastBalances } from "@APP/redux";
import { formatCurrency } from "@APP/utils";

const ForecastTooltip = ({ payload, active }: TooltipProps<ValueType, NameType>) => {
  const balances = useSelector(getForecastBalances);

  if (active && payload && payload.length) {
    return (
      <Card>
        <Box padding={1}>
          <Typography>
            {formatCurrency(payload[0].value as string, {
              currency: balances?.totalBalance?.currency,
            })}
          </Typography>
        </Box>
      </Card>
    );
  }
  return null;
};
export default ForecastTooltip;
