import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import StopIcon from "@mui/icons-material/Stop";

const useStyles = makeStyles((theme) => ({
  availableCashIcon: {
    color: theme.palette.primary.main,
  },
}));

const CustomLegend = () => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Box>
        <Box display="flex" alignItems="center">
          <StopIcon className={classes.availableCashIcon} fontSize="medium" />
          <Typography variant="subtitle2" component="p">
            Available Cash
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomLegend;
