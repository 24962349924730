import { ComponentType } from "react";
import { InputAttributes, NumericFormat, NumericFormatProps } from "react-number-format";
import { TextFieldProps } from "@mui/material";

import { CommonTextField } from "../CommonTextField";

export type NumberFormatCustomInputType = ComponentType<InputAttributes>;

export const NumberFormatCustom = (props: Omit<TextFieldProps, "ref"> & NumericFormatProps) => (
  <NumericFormat customInput={CommonTextField as NumberFormatCustomInputType} {...props} />
);

export default NumberFormatCustom;
