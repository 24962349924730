import { useTranslation } from "react-i18next";

import { Page, PersonalAndBusinessDetailsForm } from "@APP/components";
import { CompanyType } from "@APP/constants";

const OtherOrgTypesView = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("Common.CompanyTypes.OtherOrgTypes")}>
      <PersonalAndBusinessDetailsForm
        orgType={CompanyType.OtherOrgTypes}
        title={t("Common.CompanyType.OtherOrgTypes")}
        dataTestIdForCardHeader="other-org-header"
      />
    </Page>
  );
};

export default OtherOrgTypesView;
