import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AuthForm, AuthLayout, Page, TermsAndConditions } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { getRegistrationInitiated, setRegistrationInitiated } from "@APP/redux";

export const TermsAndConditionsView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const registrationInitiated = useSelector(getRegistrationInitiated);

  const handleTermsAndConditionsAccept = () => {
    !registrationInitiated && dispatch(setRegistrationInitiated(true));
    history.push(SCREEN_PATHS.REGISTRATION_EMAIL);
  };

  const renderMainContent = () => (
    <AuthForm title="Terms and Conditions">
      <TermsAndConditions onSubmit={handleTermsAndConditionsAccept} />
    </AuthForm>
  );

  return (
    <Page title="Get Started" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

export default TermsAndConditionsView;
