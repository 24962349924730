import { Box, Typography } from "@mui/material";

type Props = {
  subtitle?: string | JSX.Element;
  step?: string;
};

const ScreenHeaderSubtitle = ({ subtitle, step }: Props) => (
  <>
    {step && (
      <Box mb={2} data-testid="screen-header-step">
        <Typography gutterBottom variant="h5" id="screenHeaderStep">
          {step}
        </Typography>
      </Box>
    )}

    {subtitle && (
      <Box mb={2} display="flex" alignItems="center" data-testid="screen-header-subtitle">
        <Typography variant="subtitle1" component="span">
          {subtitle}
        </Typography>
      </Box>
    )}
  </>
);

export default ScreenHeaderSubtitle;
