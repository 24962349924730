import { useTranslation } from "react-i18next";

import { Page, PersonalAndBusinessDetailsForm } from "@APP/components";
import { CompanyType } from "@APP/constants";

const SoleTraderTypesView = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("Common.CompanyTypes.SoleTrader")}>
      <PersonalAndBusinessDetailsForm
        orgType={CompanyType.SoleTrader}
        title={t("Common.CompanyType.SoleTrader")}
        dataTestIdForCardHeader="sole-trader-header"
      />
    </Page>
  );
};

export default SoleTraderTypesView;
