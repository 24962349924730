import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBefore, startOfDay } from "date-fns";
import { TableCell, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { InvoiceType, Payable, Receivable } from "@APP/types";
import { formatCurrency, formatDisplayedDate, handleKeyboardClick } from "@APP/utils";
import { SCREEN_PATHS } from "@APP/navigation";
import { ErpId } from "@APP/constants";
import { AppState, getPermissions, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { API } from "@APP/services";
import { useAlert, useFeatureGateway } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:focus": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

type Props = {
  data: Array<Receivable | Payable> | null;
  dataType: InvoiceType;
  user: AppState["auth"]["user"];
};

const BodyTableContent = ({ dataType, data, user }: Props) => {
  const classes = useStyles();
  const gateway = useFeatureGateway();
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const permissions = useSelector(getPermissions);

  const styleIfOverDue = (dueDateTime: Date) =>
    isBefore(startOfDay(new Date(dueDateTime)), new Date()) ? "error" : "inherit";

  const showExistingRTPAlert = (existingRTP: Receivable[]) => {
    return alert.open(
      t("Errors.ReceivableList.Alerts.AlreadyRequested.Title"),
      t("Errors.ReceivableList.Alerts.AlreadyRequested.Message"),
      [
        {
          text: "View payment request",
          onClick: () =>
            window.open(`${SCREEN_PATHS.PAYMENT_REQUESTS_LIST}/${existingRTP[0].id}`, "_blank"),
        },
        {
          text: "Select another Invoice",
        },
      ],
    );
  };

  const handleClick = async ({ path, selectedRtpId }: { path: string; selectedRtpId: string }) => {
    if (!permissions?.rtp?.view) return null;
    dispatch(showLoader());
    const { data: existingRTP } = await API.getReceivable(user?.erp as ErpId, selectedRtpId);

    if (existingRTP.length > 0) {
      dispatch(hideLoader());
      return showExistingRTPAlert(existingRTP);
    }

    const paymentСreationAllowed = await gateway.createPayment({
      checkClearingLedger: dataType === InvoiceType.Receivables,
    });

    dispatch(hideLoader());

    if (!paymentСreationAllowed) {
      return;
    }

    history.push(path);
  };

  const renderContent = () => {
    switch (dataType) {
      case InvoiceType.Receivables:
        const receivables = data as Receivable[];

        return receivables.map(
          ({
            reference,
            entityDetails: { externalId },
            dueDateTime,
            customerContact,
            totalAmountTaxInclusive: { amount, currency },
            remainingAmountTaxInclusive: {
              amount: remainingAmount,
              currency: remainingAmountCurrency,
            },
          }) => (
            <TableRow
              key={reference}
              className={classes.tableRow}
              data-testid={`table-row-${dataType}-${reference}`}
              id={`tableRow${dataType}${reference}`}
              onClick={() =>
                handleClick({
                  path: `${SCREEN_PATHS.RECEIVABLES_LIST}/${externalId}`,
                  selectedRtpId: externalId,
                })
              }
              onKeyDown={(event) =>
                handleKeyboardClick(event, () =>
                  handleClick({
                    path: `${SCREEN_PATHS.RECEIVABLES_LIST}/${externalId}`,
                    selectedRtpId: externalId,
                  }),
                )
              }
              hover={permissions?.rtp?.view}
              style={{ cursor: permissions?.rtp?.view ? "pointer" : "auto" }}
              tabIndex={0}
              role="button"
              aria-label={`see details for invoice: ${reference}`}>
              <TableCell
                align="center"
                data-testid={`table-cell-${dataType}-due-date`}
                id={`tableCell${dataType}DueDate`}>
                <Typography
                  className="styleOverdue"
                  variant="body2"
                  component="span"
                  color={styleIfOverDue(dueDateTime)}>
                  {formatDisplayedDate(dueDateTime)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" component="span">
                  {customerContact.name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" component="span">
                  {reference}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" component="span">
                  {formatCurrency(user?.erp === ErpId.INTERNAL ? amount : remainingAmount, {
                    currency: user?.erp === ErpId.INTERNAL ? currency : remainingAmountCurrency,
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          ),
        );
      case InvoiceType.Payables:
        const payables = data as Payable[];

        return payables.map(
          ({
            reference,
            entityDetails: { externalId },
            dueDateTime,
            supplierContact,
            totalAmountTaxInclusive: { amount, currency },
            remainingAmountTaxInclusive: {
              amount: remainingAmount,
              currency: remainingAmountCurrency,
            },
          }) => (
            <TableRow
              key={reference + externalId}
              data-testid={`table-row-${dataType}-${reference + externalId}`}
              id={`tableRow${dataType}${reference + externalId}`}
              className={classes.tableRow}
              hover
              onClick={() =>
                handleClick({
                  path: `${SCREEN_PATHS.PAYABLES_LIST}/${externalId}`,
                  selectedRtpId: externalId,
                })
              }
              onKeyDown={(event) =>
                handleKeyboardClick(event, () =>
                  handleClick({
                    path: `${SCREEN_PATHS.PAYABLES_LIST}/${externalId}`,
                    selectedRtpId: externalId,
                  }),
                )
              }
              tabIndex={0}
              role="button"
              aria-label={`see details for supplier invoice: ${reference}`}>
              <TableCell
                align="center"
                data-testid={`table-cell-${dataType}-due-date`}
                id={`tableCell${dataType}DueDate`}>
                <Typography
                  className="styleOverdue"
                  variant="body2"
                  component="span"
                  color={styleIfOverDue(dueDateTime)}>
                  {formatDisplayedDate(dueDateTime)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" component="span">
                  {supplierContact.name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" component="span">
                  {formatCurrency(user?.erp === ErpId.INTERNAL ? amount : remainingAmount, {
                    currency: user?.erp === ErpId.INTERNAL ? currency : remainingAmountCurrency,
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          ),
        );
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

export default BodyTableContent;
