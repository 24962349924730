import request from "@APP/services/api/request";
import CONFIG from "@APP/config";
import {
  ImmediatePaymentDetails,
  CreateMaverickSupplierPaymentPayload,
  PaymentInitiation,
  GetMaverickPayableIdsByStatusPayload,
} from "@APP/types/paybles";

export const setupASingleImmediatePayment = async (
  bankId: string,
  immediatePaymentDetails: ImmediatePaymentDetails,
  redirectPathAfterSuccess: string,
): Promise<PaymentInitiation> => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bankId}/payments`,
    data: immediatePaymentDetails,
    params: {
      redirectUrl: window.location.origin + redirectPathAfterSuccess,
    },
  });

  return response.data;
};

export const confirmPayment = async (
  bankId: string,
  consentId: string,
  authorizationCode: string,
  redirectUrl: string,
) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bankId}/payments/${consentId}/confirm`,
    params: {
      authorizationCode,
      redirectUrl,
    },
  });

  return response.data;
};

export const createMaverickSupplierPayment = async (
  payload: CreateMaverickSupplierPaymentPayload,
) => {
  await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/maverick/payments/supplier-payments`,
    data: payload,
  });
};

export const getMaverickPendingPayablesIds = async (
  payload: GetMaverickPayableIdsByStatusPayload,
) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/maverick/payments/supplier-payments/find`,
    data: payload,
  });

  return response.data;
};
