import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TFunction, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Grid, MenuItem } from "@mui/material";
import { addMonths, endOfDay, isBefore, isSameDay, isValid, startOfDay } from "date-fns";
import * as Yup from "yup";

import {
  CommonDatePicker,
  CommonTextField,
  FooterActionsButtons,
  IconWithTooltip,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getErpId,
  getErpPlan,
  getInvoice,
  setInvoiceCurrency,
  setInvoiceDueDateTime,
  setInvoiceIssueDateTime,
  setInvoiceReference,
  useAppDispatch,
} from "@APP/redux";
import { ErpId } from "@APP/constants";
import { handleAriaActiveDescendantChange, isDateValid } from "@APP/utils";
import CONFIG from "@APP/config";
import { ERPPlanValue } from "@APP/types";

const generalInvoiceDetailsValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    currency: Yup.string().required(t("Errors.InvoiceCreation.Validation.InvoiceCurrencyRequired")),
    issueDateTime: Yup.date()
      .required(t("Errors.InvoiceCreation.Validation.IssueDateRequired"))
      .nullable()
      .typeError(t("Errors.InvoiceCreation.Validation.IssueDateValid"))
      .isDateCorrectlyFormed(t("Errors.InvoiceCreation.Validation.IssueDateValid"))
      .test("issueDateTime", t("Errors.InvoiceCreation.Validation.IssueDateValid"), (value) => {
        if (!value) return false;
        return isValid(value);
      })
      .min(startOfDay(new Date()), t("Errors.InvoiceCreation.Validation.IssueDatePast")),
    dueDateTime: Yup.date()
      .required(t("Errors.InvoiceCreation.Validation.DueDateRequired"))
      .nullable()
      .typeError(t("Errors.InvoiceCreation.Validation.DueDateValid"))
      .isDateCorrectlyFormed(t("Errors.InvoiceCreation.Validation.DueDateValid"))
      .test("dueDateTime", t("Errors.InvoiceCreation.Validation.DueDateValid"), (value) => {
        if (!value) return false;
        return isValid(new Date(value));
      })
      .min(startOfDay(new Date()), t("Errors.InvoiceCreation.Validation.DueDatePast"))
      .test(
        "dueDateTime",
        t("Errors.InvoiceCreation.Validation.DueDateAfter"),
        (value, context) => {
          return isDateValid(context.parent.issueDateTime);
        },
      )
      .test(
        "dueDateTime",
        t("Errors.InvoiceCreation.Validation.DueDateAfter"),
        (value, context) => {
          if (!value) return false;
          const issueDateTime = new Date(context.parent.issueDateTime);
          return isBefore(issueDateTime, value) || isSameDay(issueDateTime, value);
        },
      ),
    reference: Yup.string().test(
      "test-unique-reference",
      "Unique reference invalid, it must not contain . or , characters.",
      (reference = "") => !reference.match(/[,.]/g),
    ),
  });

const GeneralInvoiceDetails = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    invoiceDueDateTime,
    invoiceIssueDateTime,
    invoiceCurrency,
    customerContact,
    paymentUniqueReference,
  } = useSelector(getInvoice);
  const erpId = useSelector(getErpId);
  const erpPlan = useSelector(getErpPlan);

  const setInvoiceDueDateAutomatically = erpId === ErpId.SAGE && erpPlan === ERPPlanValue.START;

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isValid,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      issueDateTime: invoiceIssueDateTime,
      dueDateTime: invoiceDueDateTime,
      currency: invoiceCurrency,
      reference: paymentUniqueReference,
    },
    validationSchema: generalInvoiceDetailsValidationSchema(t),
    onSubmit: ({ dueDateTime, issueDateTime, currency, reference }) => {
      dispatch(setInvoiceIssueDateTime(issueDateTime));
      dispatch(setInvoiceDueDateTime(endOfDay(dueDateTime)));
      dispatch(setInvoiceCurrency(currency));

      if (reference) dispatch(setInvoiceReference(reference));

      history.push(SCREEN_PATHS.RECEIVABLES_LINE_ITEMS);
    },
  });

  useEffect(() => {
    if (setInvoiceDueDateAutomatically) {
      setFieldValue("dueDateTime", addMonths(new Date(), 1), true);
    }
  }, [setInvoiceDueDateAutomatically]);

  return (
    <Page title="Create Invoice details">
      <form onSubmit={handleSubmit}>
        <ScreenHeader title="Create Invoice details" id="generalInvoiceDetailsTitle" />
        <ScreenHeaderSubtitle subtitle="Please enter your Invoice details:" />
        <Card elevation={12}>
          <CardContent>
            <Grid container justifyContent="center">
              <Grid
                item
                md={5}
                xs={12}
                style={setInvoiceDueDateAutomatically ? { maxWidth: "85%" } : undefined}>
                <CommonDatePicker
                  className="keyboardDatePicker"
                  slotProps={{
                    textField: {
                      error: Boolean(touched.issueDateTime && errors.issueDateTime),
                      helperText:
                        touched.issueDateTime && (errors.issueDateTime as string | undefined),
                      name: "issueDateTime",
                      id: "issue-date-picker",
                      onClick: () =>
                        !touched.issueDateTime && setFieldTouched("issueDateTime", true, false),
                    },
                  }}
                  onChange={(value) => {
                    !touched.issueDateTime && setFieldTouched("issueDateTime", true, false);
                    setFieldValue("issueDateTime", value, true);
                  }}
                  value={values.issueDateTime}
                  label="Issue Date"
                  aria-label="invoice issue date picker"
                  customValidation
                />
                <Box position="relative">
                  <CommonDatePicker
                    slotProps={{
                      textField: {
                        error: Boolean(errors.dueDateTime),
                        helperText: errors.dueDateTime as string | undefined,
                        name: "dueDateTime",
                        id: "due-date-date-picker",
                        onClick: () =>
                          !touched.dueDateTime && setFieldTouched("dueDateTime", true, false),
                      },
                    }}
                    onChange={(value) => {
                      !touched.dueDateTime && setFieldTouched("dueDateTime", true, false);
                      setFieldValue("dueDateTime", value, true);
                    }}
                    value={values.dueDateTime}
                    label="Due Date"
                    aria-label="invoice due date picker"
                    customValidation
                    disabled={setInvoiceDueDateAutomatically}
                  />
                  {setInvoiceDueDateAutomatically && (
                    <IconWithTooltip
                      title="As you use Sage START account type, the due date will automatically be set for 1 month from the Invoice creation date."
                      style={{ position: "absolute", right: -30, top: 24 }}
                      infoIconSize="medium"
                      placement="bottom"
                      color="primary"
                    />
                  )}
                </Box>
                <CommonTextField
                  fullWidth
                  select
                  type="currency"
                  value={values.currency}
                  label="Currency"
                  id="currency-select"
                  error={Boolean(touched.currency && errors.currency)}
                  helperText={touched.currency && errors.currency}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  data-testid="currency-select"
                  name="currency"
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    id: "currency-field",
                  }}
                  InputLabelProps={{
                    htmlFor: "currency-field",
                  }}
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        "aria-activedescendant": `currency-option-${values.currency}`,
                        onFocus: handleAriaActiveDescendantChange,
                      },
                    },
                  }}>
                  {CONFIG.INPUTS.SUPPORTED_CURRENCIES.map((currency) => (
                    <MenuItem key={currency} id={`currency-option-${currency}`} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </CommonTextField>
                {erpId === ErpId.INTERNAL && (
                  <CommonTextField
                    placeholder="Unique Reference (Optional)"
                    label="Unique Reference (Optional)"
                    margin="normal"
                    name="reference"
                    fullWidth
                    value={values.reference}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onValueChange={setFieldValue}
                    error={Boolean(touched?.reference && errors?.reference)}
                    helperText={touched?.reference && errors?.reference}
                    data-testid="reference-input-container"
                    id="generalInvoiceDetailsReference"
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <FooterActionsButtons
          backButtonText={customerContact.id ? "Back to Customers" : "Back to Customer Details"}
          handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_SELECT_CUSTOMER)}
          disabledContinueButton={!isValid}
          typeButtonContinue="submit"
          continueButtonText="Continue"
        />
      </form>
    </Page>
  );
};

export default GeneralInvoiceDetails;
