import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  SimplePaletteColorOptions,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import queryString from "query-string";

import { Page } from "@APP/components";
import {
  setActiveStep,
  setNextPath,
  setNextStepActive,
} from "@APP/redux/actions/workingCapitalFinance";
import palette from "@APP/styles/theme/default/palette";
import { SCREEN_PATHS } from "@APP/navigation";
import WcfFooter from "@APP/components/layout/WCFLayout/WcfFooter";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import { IMAGES } from "@APP/assets";
import {
  CONSENT_REVOCATION_ERROR_CODES,
  ORG_COMPANY_VALIDATION_ERROR_CODES,
  ORG_VALIDATION_ERROR_CODES,
  REQUEST_LIMIT_REACHED_ERROR_CODE,
} from "@APP/services/api";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";
import {
  fetchUserData,
  getErpId,
  getUserOrganisation,
  hideLoader,
  setDefaultAutomatedCollectionsState,
  showLoader,
} from "@APP/redux";
import { ErpId } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { errorCodeString, formatErrorMessage, getErrorMessageByErrorCode } from "@APP/utils";
import { Custodian } from "@APP/types";

const useStyles = makeStyles(() => ({
  cardContaier: {
    maxWidth: 375,
    minWidth: 375,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "16px",
    border: "1px solid #546e7a",
    borderRadius: "5px",
  },
  manualCardContainer: {
    maxWidth: 375,
    minWidth: 375,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "26px",
    border: "1px solid #546e7a",
    borderRadius: "5px",
    marginTop: "1%",
    cursor: "pointer",
    position: "relative",
  },
  subHeader: {
    marginTop: "2%",
  },
  content: {
    marginTop: "1%",
    color: "#546e7a",
  },
  cardcontentList: { display: "flex", flexWrap: "wrap", gap: "2%", maxWidth: "60%" },
  listContents: { listStyleType: "none", marginBottom: "16px", marginTop: "1%" },
  successfulText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1%",
    position: "absolute",
    margin: "0",
    top: "60%",
    left: "30%",
    transform: "translateY(-50%)",
  },
  cardContainer: { minHeight: "80vh", padding: "32px", position: "relative" },
}));

interface ExtendedCustodian extends Custodian {
  gap: number;
  selected: boolean;
}

function LinkAccountingPage() {
  const [accountingPackages, setAccountingPackages] = useState<ExtendedCustodian[] | null>(null);
  const [selectedPackage, setSelectedPackage] = useState<ExtendedCustodian | null>(null);
  const [organisationError, setOrganisationError] = useState("");
  const [erpLinked, setERPlinked] = useState<boolean>(false);
  const [orgDataLoaded, setOrgDataLoaded] = useState<boolean>(false);
  const [manualClicked, setManualClicked] = useState<boolean>(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const alert = useAlert();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const handleErrorCodes = useHandleErrorCodes();
  const erpId = useSelector(getErpId);
  const org = useSelector(getUserOrganisation);

  const { code, realmId } = queryString.parse(location.search);

  useEffect(() => {
    dispatch(setActiveStep(0));
    if (orgDataLoaded && !organisationError) {
      dispatch(setNextStepActive(erpLinked));
      dispatch(setNextPath(SCREEN_PATHS.COMPANY_PROFILE));
    } else {
      dispatch(setNextStepActive(false));
    }
  }, [erpLinked, organisationError, orgDataLoaded]);

  useEffect(() => {
    if (org) history.push(`${SCREEN_PATHS.WCF_MANUAL_COMPANY}`);
    if (erpId !== ErpId.INTERNAL) {
      setERPlinked(true);
      setOrgDataLoaded(true);
    }
    fetchAccountingPackages();
  }, []);

  useEffect(() => {
    if (!code) {
      AppLocalStorage.removeItem(LocalStorageKey.setupErpConsentData);
    }
    code && processConsentAuthorisation();
  }, [code]);

  const handleConsentInitiation = async () => {
    try {
      dispatch(showLoader());
      const erpData = await API.initiateERPConsent(
        selectedPackage!.id,
        SCREEN_PATHS.WCF_ACCOUNTING,
      );
      const erpConsentLocalData = {
        consentId: erpData.consentId,
        erpId: selectedPackage!.id,
      };
      AppLocalStorage.setItem(
        LocalStorageKey.setupErpConsentData,
        JSON.stringify(erpConsentLocalData),
      );
      dispatch(hideLoader());
      window.location.href = erpData.redirectUrl!;
    } catch (error) {
      dispatch(hideLoader());
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    }
  };
  interface ERPConsentLocalData {
    erpId: ErpId;
    consentId: string;
  }
  /**
   * Authorises ERP consent using the local data saved earlier at the stage of consent initiation.
   * Attempts to link organisation info from an accounting package to a user account.
   */
  const processConsentAuthorisation = async () => {
    // * Step 1: Authorise external ERP consent based on the local data saved after consent initiation.
    // Once finished, remove all local data related to consent initiation.
    const erpConsentLocalData: ERPConsentLocalData = JSON.parse(
      AppLocalStorage.getItem(LocalStorageKey.setupErpConsentData) || "",
    );

    if (!erpConsentLocalData) {
      return console.log("Consent initiation data is missing, please try again");
    }
    try {
      await API.authorizeERPConsent(
        erpConsentLocalData.erpId,
        erpConsentLocalData.consentId,
        code as string,
        SCREEN_PATHS.WCF_ACCOUNTING,
        realmId ? (realmId as string) : undefined,
      );
      const { currentErp } = await API.getCurrentERP();
      let externalERPLinked =
        currentErp === ErpId.SAGE || currentErp === ErpId.XERO || currentErp === ErpId.QUICKBOOKS;
      externalERPLinked && setERPlinked(true);
      dispatch(setDefaultAutomatedCollectionsState());
    } catch (error) {
      // If, for some reason, сonsent authorization resulted in an error,
      // check whether the consent was actually authorized (handling a possible timeout on the backend side).
      // In the absence of a consent, show the error message. Otherwise continue with linking the organisation.
      try {
        const { currentErp } = await API.getCurrentERP();
        if (currentErp !== erpConsentLocalData.erpId) throw error;
        console.log("External ERP consent authorization was successful after an error.", error);
      } catch (error) {
        const errorData = error?.response?.data;
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          "We were unable to link your accounting package, please try again later." +
            errorCodeString(errorData?.errorCode),
          [
            {
              text: "Okay",
              onClick: () => history.replace(`${SCREEN_PATHS.WCF_ACCOUNTING}`),
            },
          ],
        );

        return;
      }
    }

    await linkOrganisationData(erpConsentLocalData);
  };

  const fetchAccountingPackages = async () => {
    dispatch(showLoader());
    try {
      const availablePackages = await API.getCustodiansByType({
        custodianType: "AccountingPackage",
      });
      const filteredCustodians = availablePackages
        .filter((custodian) => (custodian.id === ErpId.INTERNAL ? false : true))
        .map((custodian) => {
          return {
            ...custodian,
            selected: false,
            gap: custodian.id === ErpId.QUICKBOOKS ? 1 : custodian.id === ErpId.SAGE ? 12 : 17,
          };
        });

      setAccountingPackages(filteredCustodians);
    } catch (error) {
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error), [
        { text: "Okay", onClick: () => history.replace(SCREEN_PATHS.SETTINGS) },
      ]);
    }
    dispatch(hideLoader());
  };

  const linkOrganisationData = async (erpConsentLocalData: ERPConsentLocalData) => {
    try {
      await API.linkERPOrganisation(erpConsentLocalData.erpId);
      setOrgDataLoaded(true);
    } catch (error) {
      const errorData = error.response?.data;
      setERPlinked(false);
      if (
        ORG_VALIDATION_ERROR_CODES.includes(errorData?.errorCode) ||
        ORG_COMPANY_VALIDATION_ERROR_CODES.includes(errorData?.errorCode) ||
        CONSENT_REVOCATION_ERROR_CODES.includes(errorData?.errorCode) ||
        errorData?.errorCode === REQUEST_LIMIT_REACHED_ERROR_CODE
      ) {
        const errorMessage = getErrorMessageByErrorCode(errorData.errorCode);
        setOrganisationError(errorMessage);
        alert.open(t("Errors.Common.Alerts.AlertTitles.Failure"), errorMessage);
      } else if (CONSENT_REVOCATION_ERROR_CODES.includes(errorData?.errorCode)) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          getErrorMessageByErrorCode(errorData.errorCode),
          [{ text: "Okay", onClick: () => history.push(SCREEN_PATHS.WCF_ACCOUNTING) }],
        );
      } else {
        setOrganisationError(formatErrorMessage(error, JSON.stringify(error)));
      }
    }

    try {
      const { token } = await API.refreshToken();
      AppLocalStorage.setItem(LocalStorageKey.authToken, token);

      await dispatch(fetchUserData());
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;
      const isHandled = handleErrorCodes(errorCode);

      if (isHandled) {
        return;
      }

      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    }

    setERPlinked(true);
  };

  const handleManualOrg = () => {
    dispatch(setNextStepActive(true));
    dispatch(setNextPath(SCREEN_PATHS.WCF_BUSINESS_TYPE));
    setManualClicked(true);
  };

  return (
    <Page display="flex" flexDirection="column" p={0}>
      <Box p={3}>
        <Card elevation={4} sx={{ borderRadius: "5px" }}>
          <CardContent className={classes.cardContainer}>
            <Typography variant="h2" id="linkAccountingPageTitle">
              Your finance application
            </Typography>
            <Typography className={classes.subHeader} variant="h5">
              Tell us more about your business
            </Typography>
            <Typography className={classes.content}>
              To get started, we would like to ask you for some basic information about your
              business.
            </Typography>
            <WcfStepper />
            {erpLinked && orgDataLoaded && !organisationError ? (
              <Typography className={classes.successfulText} variant="h3">
                You have successfully linked your accounting package! <br />
                Click 'Next Step' to proceed with your application.
              </Typography>
            ) : (
              <>
                <Card
                  className={classes.manualCardContainer}
                  id="wcfAccountingPackage_manual"
                  onClick={handleManualOrg}
                  sx={{
                    backgroundColor: manualClicked ? "" : "#546e7a10",
                    boxShadow: manualClicked ? "2px 2px" : "",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Grid container spacing={1}>
                    <Grid display="flex" justifyContent="center" alignItems="center" item xs={12}>
                      <Typography variant="h6">Manually enter your business details</Typography>
                    </Grid>
                  </Grid>
                  {manualClicked && (
                    <CheckCircleIcon
                      sx={{
                        color: (palette.secondary as SimplePaletteColorOptions)?.main,
                        position: "absolute",
                        right: "16px",
                      }}
                    />
                  )}
                </Card>
                <Typography sx={{ marginTop: "1%" }} variant="h5">
                  Or Link Your Accounting Package
                </Typography>
                <Typography className={classes.content}>
                  You can alternatively link your accounting package to set up your company profile
                  without having to key in your company details, or proceed with no accounting
                  package.
                </Typography>
                <ul className={classes.cardcontentList} id="wcfAccountingPackageList">
                  {accountingPackages &&
                    accountingPackages.map((accPackage) => {
                      const image =
                        accPackage.id === ErpId.QUICKBOOKS
                          ? IMAGES.QUICK_BOOKS_LOGO
                          : accPackage.id === ErpId.SAGE
                          ? IMAGES.SAGE_LOGO
                          : IMAGES.XERO_LOGO;
                      return (
                        <li
                          id={"wcfAccountingPackage_" + accPackage.id}
                          key={accPackage.id}
                          className={classes.listContents}
                          onClick={() => {
                            setSelectedPackage(accPackage);
                            setManualClicked(false);
                          }}>
                          <Card
                            className={classes.cardContaier}
                            sx={{
                              gap: `${accPackage.gap}%`,
                              backgroundColor: accPackage.selected ? "" : "#546e7a10",
                              boxShadow: accPackage.selected ? "2px 2px" : "",
                              cursor: "pointer",
                            }}>
                            <img
                              alt={accPackage.shortName}
                              src={image}
                              style={{ marginLeft: accPackage.id === ErpId.XERO ? "10%" : "0%" }}
                            />

                            <Grid container spacing={1} mb={1}>
                              <Grid item xs={12}>
                                <Typography variant="h6">{accPackage.shortName}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {accPackage.fullName}
                                </Typography>
                              </Grid>
                            </Grid>
                            {!manualClicked && selectedPackage?.id === accPackage.id && (
                              <CheckCircleIcon
                                sx={{
                                  color: (palette.secondary as SimplePaletteColorOptions)?.main,
                                }}
                              />
                            )}
                          </Card>
                        </li>
                      );
                    })}
                </ul>
                <Button
                  variant="contained"
                  id={"linkAccountingPackageButton"}
                  onClick={() => handleConsentInitiation()}
                  disabled={!selectedPackage || manualClicked}>
                  Link Selected Accounting Package
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter displaySaveAndExitButton={false} />
    </Page>
  );
}

export default LinkAccountingPage;
