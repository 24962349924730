import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { ErpId } from "@APP/constants";
import { getCardPaymentsCustodians, getClearingLedger, getPermissions, getUser } from "@APP/redux";
import { NoLinkedBankAccountsView } from "@APP/views/common";

import SetLedgerForPaymentsBooking from "./SetLedgerForPaymentsBooking";
import { ClearingLedgerView, CreateClearingLedgerView } from "./Clearingledger";
import { cardPaymentCheck } from "@APP/utils";

const Ledger = () => {
  const user = useSelector(getUser);
  const clearingLedger = useSelector(getClearingLedger);
  const permissions = useSelector(getPermissions);
  const username = user?.username || "";

  const cardCustodians = useSelector(getCardPaymentsCustodians);

  const isSquareLinked = useMemo(
    () => cardCustodians.some((item) => item.id === "square"),
    [cardCustodians],
  );

  const renderClearingLedgersContent = () => {
    // Removed logic if maverick is not present
    // https://bankifi.atlassian.net/browse/BN-3327
    // if (!Provider.isMaverick) return null;

    if (
      cardPaymentCheck(username) &&
      !clearingLedger &&
      isSquareLinked &&
      user?.erp !== ErpId.INTERNAL
    ) {
      return (
        <Box mt={4}>
          <CreateClearingLedgerView />
        </Box>
      );
    }

    return (
      <Box mt={4}>
        <ClearingLedgerView />
      </Box>
    );
  };

  return (
    <>
      {!user?.bankAccounts?.length && <NoLinkedBankAccountsView />}
      {!!user?.bankAccounts?.length &&
        (permissions.accounting_package.create || permissions.accounting_package.update) && (
          <SetLedgerForPaymentsBooking />
        )}
      {renderClearingLedgersContent()}
    </>
  );
};

export default Ledger;
