import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";

const useStyles = makeStyles((theme) => ({
  cardActionsContainer: {
    paddingTop: "16px",
    paddingBottom: "0px",
    justifyContent: "center",
  },
  cardActionButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  featureIcons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  iconText: {
    wordBreak: "break-word",
    fontWeight: "500",
    textAlign: "center",
  },
}));

interface Props {
  clickState?: (state: boolean) => void;
  redirect?: () => void;
}

function LinkBankAccounts({ clickState, redirect }: Props) {
  const classes = useStyles();

  return (
    <Grid display="flex" justifyContent="center" alignItems="center">
      <Card elevation={4} sx={{ width: "60%" }}>
        <CardContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            <LinkIcon sx={{ fontSize: 100 }} color="primary" id="LinkBankAccounts" />
            <Typography variant="h4">{`Welcome to the ${CONFIG.PRODUCT_NAME} app`}</Typography>
            <Typography variant="body2" color="textSecondary" align="center" padding="16px">
              By linking your bank account you can control and administer your accounts receivables
              and payables from one place. Create and send invoices, pay and get paid and view all
              your account balances within one view. <br /> After you link your bank account, you
              will get access to the following features in Open Cash Management web app:
            </Typography>
            <Grid display="flex" width="100%" justifyContent="space-evenly" padding="16px">
              <Grid display="grid">
                <ReceiptIcon color="primary" className={classes.featureIcons} />
                <Typography className={classes.iconText} color="primary">
                  Customer Invoices
                </Typography>
              </Grid>
              <Grid display="grid">
                <ReceiptIcon color="primary" className={classes.featureIcons} />
                <Typography className={classes.iconText} color="primary">
                  Supplier Invoices
                </Typography>
              </Grid>
              <Grid display="grid">
                <TrendingUpIcon color="primary" className={classes.featureIcons} />
                <Typography className={classes.iconText} color="primary">
                  Cash Flow Forecast
                </Typography>
              </Grid>
              <Grid display="grid">
                <AccountBalanceWalletIcon color="primary" className={classes.featureIcons} />
                <Typography className={classes.iconText} color="primary">
                  Payment Request Management
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ width: "106%" }} />
          </Box>
          <CardActions sx={{ width: "100%" }} className={classes.cardActionsContainer}>
            <Button
              className={classes.cardActionButton}
              color="primary"
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => (clickState ? clickState(true) : redirect && redirect())}
              id="addBankAccountsButton">
              Link Bank Accounts
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default LinkBankAccounts;
