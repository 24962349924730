import { WcfCustomer } from "@APP/types";
import { WcfForm } from "@APP/types/wcf";
import { createActionCreator } from "deox";

export const setNextStepActive = createActionCreator(
  "@WCF_ACTIVE/SET_NEXT_STEP_ACTIVE",
  (resolve) => (value: boolean) => resolve(value),
);

export const setNextPath = createActionCreator(
  "@WCF_NEXT_PATH/SET_NEXT_ACTIVE",
  (resolve) => (value: string) => resolve(value),
);

export const setActiveStep = createActionCreator(
  "@WCF_NEXT_PATH/SET_ACTIVE_STEP",
  (resolve) => (value: number) => resolve(value),
);

export const setApplicantFormData = createActionCreator(
  "@WCF_APPLICANT_FORM/SET_FORM_DATA",
  (resolve) => (value: WcfForm["applicantDetailsForm"]) => resolve(value),
);

export const setCompanyProfileFormData = createActionCreator(
  "@WCF_COMPANY_PROFILE_FORM/SET_FORM_DATA",
  (resolve) => (value: WcfForm["companyProfileForm"]) => resolve(value),
);

export const setAssetFinanceFormData = createActionCreator(
  "@WCF_ASSET_FINANCE_FORM/SET_FORM_DATA",
  (resolve) => (value: WcfForm["assetFinanceForm"]) => resolve(value),
);

export const setInvoiceFinanceFormData = createActionCreator(
  "@WCF_INVOICE_FINANCE_FORM/SET_FORM_DATA",
  (resolve) => (value: WcfForm["invoiceFinanceForm"]) => resolve(value),
);

export const setFormType = createActionCreator(
  "@WCF_FORM_TYPE/SET_FORM_TYPE",
  (resolve) => (value: string) => resolve(value),
);

export const setFormSubType = createActionCreator(
  "@WCF_FORM_SUBTYPE/SET_FORM_SUBTYPE",
  (resolve) => (value: string) => resolve(value),
);

export const setApplicationID = createActionCreator(
  "@WCF_APPLICATION_ID/SET_APPLICATION_ID",
  (resolve) => (value: string) => resolve(value),
);

export const setNextClicked = createActionCreator(
  "@WCF_NEXT_CLICKED/SET_NEXT_CLICKED",
  (resolve) => (payload: { clicked: boolean; title: string }) => resolve(payload),
);

export const setProductType = createActionCreator(
  "@WCF_PRODUCT_TYPE/SET_PRODUCT_TYPE",
  (resolve) => (value: string) => resolve(value),
);

export const setIsRefinance = createActionCreator(
  "@WCF_PRODUCT_TYPE/SET_IS_REFINANCE",
  (resolve) => (value: boolean) => resolve(value),
);

export const setCustomer = createActionCreator(
  "@WCF_CUSTOMER/SET_CUSTOMER",
  (resolve) => (value: WcfCustomer) => resolve(value),
);

export const setOrgType = createActionCreator(
  "@WCF_ORG_TYPE/SET_ORG_TYPE",
  (resolve) => (value: string) => resolve(value),
);
