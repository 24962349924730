import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { BankConsentLocalData } from "@APP/types";
import { AppLocalStorage, API, LocalStorageKey } from "@APP/services";
import { formatErrorMessage } from "@APP/utils";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";

const useSetupConsentInitiation = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const { state } = useLocation<{
    bankId: string;
    provider: string;
    bankName: string;
    redirectUrl: string;
  }>();

  /**
   * Initiates consent for the selected bank,
   * serializes and stores the consent data locally for later use during consent authorisation.
   */
  const setupConsentInitiation = async () => {
    try {
      dispatch(showLoader());

      const { data } = await API.initiateBankConsent(state.bankId, state.redirectUrl);
      const { sub } = queryString.parse(data.redirectUrl);
      const bankConsentLocalData: BankConsentLocalData = {
        consentId: data.consentId,
        bankId: state.bankId,
        bankName: state.bankName,
        sub: sub ? (sub as string) : "",
        provider: state.provider,
      };
      AppLocalStorage.setItem(
        LocalStorageKey.setupBankConsentData,
        JSON.stringify(bankConsentLocalData),
      );
      window.location.href = data.redirectUrl;
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;
      const isHandled = handleErrorCodes(errorCode);

      if (!isHandled)
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));

      dispatch(hideLoader());
    }
  };

  return setupConsentInitiation;
};

export default useSetupConsentInitiation;
