import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  tableRow: {
    cursor: "pointer",
  },
  sortHeader: {
    cursor: "pointer",
  },
  tableCell: {
    border: "none",
  },
  tableIcon: {
    marginRight: theme.spacing(1),
  },
  placeholderIcon: {
    marginBottom: theme.spacing(1),
  },
}));
