import React from "react";
import clsx from "clsx";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CLOSE_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH, TOP_BAR_HEIGHT } from "@APP/styles";
import { formatDisplayedDate, getGreeting } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("lg")]: {
      left: 0,
      width: "100%",
    },
    height: TOP_BAR_HEIGHT,
    zIndex: 9,
  },
  toolbarContent: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      paddingLeft: "50px",
    },
  },
  toolbarWithFullSideBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: OPEN_SIDEBAR_WIDTH + 20,
      transition: theme.transitions.create("paddingLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  toolbarWithSmallSideBar: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: CLOSE_SIDEBAR_WIDTH + 20,
      transition: theme.transitions.create("paddingLeft", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  toolbarTitle: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

interface Props {
  username?: string;
  className?: string;
  isFullSideBar: boolean;
}

const TopBar = ({ username, className, isFullSideBar }: Props) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={1} color="inherit">
      <Toolbar>
        <Box
          pt={1}
          pb={1}
          className={clsx(classes.toolbarContent, {
            [classes.toolbarWithFullSideBar]: isFullSideBar,
            [classes.toolbarWithSmallSideBar]: !isFullSideBar,
          })}>
          <Typography variant="h3" component="p" color="primary" className={classes.toolbarTitle}>
            {getGreeting()}, <span>{username ?? "Admin"}</span>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {formatDisplayedDate(new Date(), "PPPP")}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
