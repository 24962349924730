import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { API } from "@APP/services";
import { ResultNotification } from "@APP/components";

const useStyles = makeStyles(() => ({
  iframe: {
    width: "100%",
    border: "none",
  },
  iframeHidden: {
    display: "none",
  },
}));

const MaverickWidgetIframe = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [maverickWidgetUrl, setMaverickWidgetUrl] = useState<string | undefined>();
  const [isError, setIsError] = useState(false);

  const fetchMaverickWidgetUrl = async () => {
    try {
      setIsLoading(true);
      setIsError(false);

      const { url } = await API.getMaverickWidgetUrl();
      setMaverickWidgetUrl(url);
    } catch (error) {
      setMaverickWidgetUrl(undefined);
      setIsError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchMaverickWidgetUrl();
  }, []);

  return (
    <>
      {isLoading ? (
        <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      {!isLoading && isError ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}>
          <Box>
            <ResultNotification type="error">
              <Typography align="center" mb={2}>
                {t("Errors.Admin.Messages.DisplayPaymentApp")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={fetchMaverickWidgetUrl}
                id="maverickWidgetIFrameTryAgainButton">
                Try again
              </Button>
            </ResultNotification>
          </Box>
        </Box>
      ) : null}
      {maverickWidgetUrl ? (
        <Box minHeight={400} display="flex" flexGrow={1}>
          <iframe
            src={maverickWidgetUrl}
            onLoad={() => {
              setIsLoading(false);
            }}
            title="Maverick Widget"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin"
            className={clsx(classes.iframe, { [classes.iframeHidden]: isLoading })}
          />
        </Box>
      ) : null}
    </>
  );
};

export default MaverickWidgetIframe;
