import { MouseEventHandler } from "react";
import { Box, Button, ButtonProps, Grid, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type Props = {
  backButtonText?: string;
  backButtonDataTestId?: string;
  continueButtonText?: string;
  continueButtonDataTestId?: string;
  handleBackButton?: () => void;
  handleContinue?: MouseEventHandler;
  disabledContinueButton?: boolean;
  hiddenContinueButton?: boolean;
  typeButtonContinue?: "button" | "reset" | "submit";
  continueButtonProps?: ButtonProps;
};

const useStyles = makeStyles(() => ({
  startIcon: {
    position: "absolute",
    left: -18,
  },
  endIcon: {
    position: "absolute",
    right: -18,
  },
  label: {
    position: "relative",
    width: "auto",
  },
}));

const FooterActionsButtons = ({
  backButtonText,
  backButtonDataTestId = "backButton",
  continueButtonText,
  continueButtonDataTestId = "continueButton",
  handleBackButton,
  handleContinue,
  typeButtonContinue,
  disabledContinueButton = false,
  hiddenContinueButton = false,
  continueButtonProps,
}: Props) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Grid container spacing={3} direction={isPhone ? "column-reverse" : "row"}>
        <Grid item lg={6} md={6} sm={7} xs={12}>
          {backButtonText && (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth={isPhone}
              data-testid={backButtonDataTestId}
              id={backButtonDataTestId}
              classes={isPhone ? { startIcon: classes.startIcon } : undefined}
              startIcon={<ChevronLeftIcon />}
              onClick={handleBackButton}>
              {backButtonText}
            </Button>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={5} xs={12}>
          <Grid container justifyContent="flex-end" spacing={0}>
            {continueButtonText && !hiddenContinueButton && (
              <Button
                type={typeButtonContinue}
                fullWidth={isPhone}
                variant={continueButtonProps?.variant || "contained"}
                size="small"
                color="primary"
                disabled={disabledContinueButton}
                data-testid={continueButtonDataTestId}
                id={continueButtonDataTestId}
                classes={isPhone ? { endIcon: classes.endIcon } : undefined}
                endIcon={<ChevronRightIcon />}
                onClick={handleContinue}
                {...continueButtonProps}>
                {continueButtonText}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterActionsButtons;
