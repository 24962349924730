import { createReducer } from "deox";
import CONFIG from "@APP/config";
import {
  setBeneficiaryName,
  setBankAccountSortCode,
  setSortCodeAccountNumberOrIBAN,
  setIBAN,
  setAmount,
  setReference,
  setBICCode,
  setBankAccountNumber,
  setCurrency,
  setSelectedPayerAccount,
  setDefaultMakePaymentState,
  setMakePaymentInitiation,
} from "@APP/redux/actions";
import { BankAccountExtended } from "@APP/types";
import { PaymentInitiation, SchemesNames } from "@APP/types/paybles";

export interface MakePaymentState {
  beneficiaryName: string;
  bankAccountSortCode: string;
  bankAccountNumber: string;
  BICCode: string;
  IBAN: string;
  currency: string;
  amount: string;
  reference: string;
  sortCodeAccountNumberOrIBAN: SchemesNames;
  selectedPayerAccount: BankAccountExtended | null;
  paymentInitiation: null | PaymentInitiation;
}

const makePaymentDefaultState: MakePaymentState = {
  beneficiaryName: "",
  bankAccountSortCode: "",
  bankAccountNumber: "",
  BICCode: "",
  IBAN: "",
  currency: CONFIG.INPUTS.SUPPORTED_CURRENCIES[0],
  amount: "",
  reference: "",
  sortCodeAccountNumberOrIBAN: SchemesNames.SORT_CODE_ACCOUNT_NUMBER,
  selectedPayerAccount: null,
  paymentInitiation: null,
};

const makePaymentReducer = createReducer(makePaymentDefaultState, (handleAction) => [
  handleAction(setBeneficiaryName, (state, { payload }) => ({
    ...state,
    beneficiaryName: payload,
  })),
  handleAction(setSortCodeAccountNumberOrIBAN, (state, { payload }) => ({
    ...state,
    sortCodeAccountNumberOrIBAN: payload,
  })),
  handleAction(setCurrency, (state, { payload }) => ({
    ...state,
    currency: payload,
  })),
  handleAction(setBankAccountSortCode, (state, { payload }) => ({
    ...state,
    bankAccountSortCode: payload,
  })),
  handleAction(setBankAccountNumber, (state, { payload }) => ({
    ...state,
    bankAccountNumber: payload,
  })),
  handleAction(setBICCode, (state, { payload }) => ({
    ...state,
    BICCode: payload,
  })),
  handleAction(setIBAN, (state, { payload }) => ({
    ...state,
    IBAN: payload,
  })),
  handleAction(setAmount, (state, { payload }) => ({
    ...state,
    amount: payload,
  })),
  handleAction(setReference, (state, { payload }) => ({
    ...state,
    reference: payload,
  })),
  handleAction(setSelectedPayerAccount, (state, { payload }) => ({
    ...state,
    selectedPayerAccount: payload,
  })),
  handleAction(setDefaultMakePaymentState, () => ({
    ...makePaymentDefaultState,
  })),
  handleAction(setMakePaymentInitiation, (state, { payload }) => ({
    ...state,
    paymentInitiation: payload,
  })),
]);

export { makePaymentReducer, makePaymentDefaultState };
