import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TFunction, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  lighten,
  Link,
  List,
  ListItemAvatar,
  ListItemButton,
  Typography,
  FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as Yup from "yup";
import clsx from "clsx";
import PersonOutline from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";

import { SCREEN_PATHS } from "@APP/navigation";
import { CommonTextField, Page } from "@APP/components";
import {
  fetchUserData,
  getSelectedRegisteredCompany,
  getUser,
  getUserOrganisation,
  hideLoader,
  setActiveStep,
  setNextStepActive,
  setRegisteredCompanySearchingValue,
  setSelectedRegisteredCompany,
  setSelectedRegisteredDirector,
  showLoader,
} from "@APP/redux";
import { useAlert } from "@APP/hooks";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";
import { CompanyType } from "@APP/constants";
import {
  CreateOrganisationCompanyType,
  GetCompanyByKeywordType,
  GetDirectorsByRegistrationNumberType,
  WcfCustomer,
} from "@APP/types";
import WcfFooter from "@APP/components/layout/WCFLayout";
import debounce from "lodash.debounce";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";

enum CompanyStatus {
  ACTIVE = "active",
}

const registeredCompanyValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    keyword: Yup.string().required(t("Errors.Registration.Validation.CompanyRequired")),
    company: Yup.string().nullable().required("Please select your Company from the list."),
    director: Yup.string().nullable().required("Please select your Director from the list."),
  });

const useStyles = makeStyles((theme) => ({
  fieldset: {
    border: 0,
    marginBottom: theme.spacing(2),
  },
  legend: {
    marginBottom: theme.spacing(2),
  },
  grid: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.9),
    padding: theme.spacing(2, 0),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  helperText: {
    ...theme.typography.body2,
  },
  gridText: {
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
  },
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: "auto",
    maxHeight: 300,
    borderRadius: theme.shape.borderRadius < 20 ? theme.shape.borderRadius : 20,
  },
  listItem: {
    borderRadius: theme.shape.borderRadius < 20 ? theme.shape.borderRadius : 20,
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      color: theme.palette.secondary.contrastText,
    },
    "&.Mui-selected:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      color: theme.palette.secondary.contrastText,
    },
  },
  avatarIcon: {
    minWidth: 30,
    [theme.breakpoints.up("md")]: {
      minWidth: 40,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  cardContaier: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "flex-start",
    borderRadius: "5px",
    minHeight: "80vh",
    flexGrow: 1,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  cardWrapper: {
    display: "flex",
    flexGrow: 1,
  },
}));

const OrgDetailsLtdCompany = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const org = useSelector(getUserOrganisation);

  const user = useSelector(getUser);
  const { selectedCompany, selectedDirector, searchingValue } = useSelector(
    getSelectedRegisteredCompany,
  );

  const [companyList, setCompanyList] = useState<GetCompanyByKeywordType[] | null>(null);
  const [directorsList, setDirectorsList] = useState<GetDirectorsByRegistrationNumberType[] | null>(
    null,
  );
  const [wcfCustomer, setWcfCustomer] = useState<WcfCustomer | null>(null);
  const [executeDebounce, setExecuteDebounce] = useState(false);

  const fetchWcfCustomer = async () => {
    try {
      const customer = await API.getWcfCustomer();
      setWcfCustomer(customer);
    } catch (error) {}
  };

  const handleOnSubmit = async () => {
    try {
      if (!selectedCompany || !user || !user.username || !user.phone) throw new Error("");

      dispatch(showLoader());

      const body: CreateOrganisationCompanyType = {
        companyName: selectedCompany?.title,
        companyNumber: selectedCompany?.company_number,
        orgType: CompanyType.RegisteredCompany,
      };

      if (!org?.companyInfo.id) {
        await API.createOrganisation(user.username, user.phone, body);
        const { token } = await API.refreshToken();
        AppLocalStorage.setItem(LocalStorageKey.authToken, token);
        await dispatch(fetchUserData());
      }
      dispatch(hideLoader());

      history.push(SCREEN_PATHS.WCF_MANUAL_COMPANY, { customer: wcfCustomer });
    } catch (error) {
      dispatch(hideLoader());
      alert.open(
        t("Errors.Common.Alerts.AlertTitles.Error"),
        t("Registration.RegisteredCompany.ErrorTextFailedToCreateOrganisation"),
        [{ text: "Okay" }],
      );
    }
  };

  // Forces validation against all 3 fields when: 1. No search term provided, 2. Search term not submitted, 3. No selected company, 4.  No selected business.
  const handleOnSubmitHelper = () => {
    if (!companyList) {
      if (Boolean(values.keyword && !companyList) || !values.keyword) {
        setFieldTouched("keyword", true, false);
        setFieldError("keyword", t("Errors.Registration.Validation.CompanyRequired"));
        return;
      }
    }
    if (!values.company) {
      setFieldTouched("company", true, true);
      return;
    }
    if (!values.director) {
      setFieldTouched("director", true, true);
      return;
    } else {
      handleOnSubmit();
    }
  };

  const {
    values,
    errors,
    handleSubmit,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    validateField,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      keyword: searchingValue,
      company: selectedCompany,
      director: selectedDirector,
    },
    validationSchema: registeredCompanyValidationSchema(t),
    onSubmit: handleOnSubmitHelper,
  });

  const fetchCompanies = async (value: string) => {
    await searchCompany(value);
    setDirectorsList(null);
    dispatch(setSelectedRegisteredDirector(null));
    dispatch(setSelectedRegisteredCompany(null));
  };

  useEffect(() => {
    setFieldValue("company", selectedCompany?.title || null, true);
  }, [selectedCompany]);

  useEffect(() => {
    setFieldValue("director", selectedDirector?.name || null, true);
  }, [selectedDirector]);

  useEffect(() => {
    dispatch(setActiveStep(0));
    fetchWcfCustomer();
    dispatch(setNextStepActive(true));
    (async () => {
      if (values.keyword) {
        await searchCompany(values.keyword);
      }

      if (selectedCompany) {
        await selectCompany(selectedCompany);
      }
    })();
  }, []);

  // Removes error state and helper text when a company list has loaded. Otherwise the error doesn't dismiss.
  useEffect(() => {
    validateField("keyword");
  }, [companyList]);

  useEffect(() => {
    if (executeDebounce) {
      setExecuteDebounce(false);
      fetchCompanies(values.keyword);
    }
  }, [executeDebounce]);

  const searchCompany = async (value: string) => {
    dispatch(setRegisteredCompanySearchingValue(values.keyword));
    if (value.length < 1) {
      setCompanyList(null);
      return;
    }

    try {
      dispatch(showLoader());

      const { data } = await API.getCompanyByKeyword(values.keyword);

      const activeCompanies = data.items.filter(
        (company: GetCompanyByKeywordType) => company.company_status === CompanyStatus.ACTIVE,
      );

      setCompanyList(activeCompanies);
    } catch (error) {
      setCompanyList([]);
    }
    dispatch(hideLoader());
  };

  const debouncedFetchCompanies = useCallback(
    debounce(() => setExecuteDebounce(true), 300),
    [],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange(e);
    debouncedFetchCompanies();
  };

  const selectCompany = async (company: GetCompanyByKeywordType) => {
    try {
      const { data } = await API.getDirectorsByRegistrationNumber(company.company_number);

      const activeDirectors = data.items.filter(
        (director: GetDirectorsByRegistrationNumberType) => director.resigned_on === null,
      );
      setDirectorsList(activeDirectors);
    } catch (error) {
      setDirectorsList([]);
    }
  };

  const handleSelectCompany = async (company: GetCompanyByKeywordType) => {
    dispatch(setSelectedRegisteredCompany(company));
    dispatch(setSelectedRegisteredDirector(null));

    await selectCompany(company);
  };

  const renderCompanySearch = () => (
    <fieldset className={clsx(classes.fieldset)}>
      <legend className={clsx(classes.legend)}>
        <Typography variant="h4" component="h3" id="wcfLLPOrLLCCompanyHouseTitle">
          Companies House
        </Typography>
        <Typography variant="caption" component="p" id="wcfLLPOrLLCCompanyHouseSubTitle">
          Search by Company Name or Registration Number.
        </Typography>
      </legend>

      <Box mt={1}>
        <CommonTextField
          label="Company Name or Registration Number"
          error={Boolean(touched.keyword && errors.keyword)}
          id="wcfLLPOrLLCCompanyOrRegistrationNumber"
          helperText={touched.keyword && errors.keyword}
          fullWidth
          margin="none"
          name="keyword"
          onBlur={handleBlur}
          onChange={handleSearchChange}
          type="text"
          value={values.keyword}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <IconButton size="large" style={{ cursor: "default" }}>
                <SearchIcon />
              </IconButton>
            ),
          }}
          autoComplete="organization"
        />
      </Box>
    </fieldset>
  );

  const renderCompanyList = () => {
    if (companyList === null) {
      return (
        <Card elevation={4} id="wcfLLPOrLLCCompanyHouseEmptyCard">
          <Box p={2} maxHeight={300}>
            <Typography id="wcfLLPOrLLCCompanyHouseEmptyTitle">
              Please search for your company by Company Name or Registration Number.
            </Typography>
          </Box>
        </Card>
      );
    }

    if (companyList.length === 0)
      return (
        <Card elevation={4} id="wcfLLPOrLLCCompanyHouseListCard">
          <Box p={2} maxHeight={300}>
            <Typography id="wcfLLPOrLLCCompanyHouseListTitle">
              {t("Errors.Registration.Messages.CompanyHouse")}
            </Typography>
          </Box>
        </Card>
      );

    return (
      <>
        <List
          className={classes.list}
          component={Card}
          elevation={4}
          id="wcfLLPOrLLCCompanyHouseList">
          {companyList.map((company) => (
            <ListItemButton
              className={classes.listItem}
              selected={company.company_number === selectedCompany?.company_number}
              key={company.company_number}
              onClick={() => handleSelectCompany(company)}
              aria-pressed={company.company_number === selectedCompany?.company_number}
              id="wcfLLPOrLLCCompanyHouseListButton">
              <ListItemAvatar className={classes.avatarIcon} id="wcfLLPOrLLCCompanyHouseListAvatar">
                <PersonOutline
                  fontSize="medium"
                  color="primary"
                  id="wcfLLPOrLLCCompanyHouseListIcon"
                />
              </ListItemAvatar>
              <Typography variant="body2" id="wcfLLPOrLLCCompanyHouseListTitle">
                {company.title}
              </Typography>
            </ListItemButton>
          ))}
        </List>
        {touched.company && errors.company && (
          <FormHelperText
            className={classes.helperText}
            error
            id="wcfLLPOrLLCCompanyHouseListError">
            {errors.company}
          </FormHelperText>
        )}
      </>
    );
  };

  const renderDirectorList = () => {
    if (directorsList === null) {
      return (
        <Card elevation={4} id="wcfLLPOrLLCDirectorEmptyCard">
          <Box p={2} maxHeight={300}>
            <Typography id="wcfLLPOrLLCDirectorEmptyTitle">
              Please search for your company by Company Name or Registration Number.
            </Typography>
          </Box>
        </Card>
      );
    }

    if (directorsList.length === 0)
      return (
        <Card elevation={4} id="wcfLLPOrLLCDirectorListCard">
          <Box p={2} maxHeight={300}>
            <Typography id="wcfLLPOrLLCDirectListTitle">
              {t("Errors.Registration.Messages.Directors")}
            </Typography>
          </Box>
        </Card>
      );

    return (
      <>
        <List className={classes.list} component={Card} elevation={4} id="wcfLLPOrLLCDirectorList">
          {directorsList.map((director) => (
            <ListItemButton
              className={classes.listItem}
              selected={director.name === selectedDirector?.name}
              key={director.name}
              onClick={() => dispatch(setSelectedRegisteredDirector(director))}
              aria-pressed={director.name === selectedDirector?.name}
              id="wcfLLPOrLLCDirectorListButton">
              <ListItemAvatar className={classes.avatarIcon} id="wcfLLPOrLLCDirectorListAvatar">
                <PersonOutline fontSize="medium" color="primary" id="wcfLLPOrLLCDirectorListIcon" />
              </ListItemAvatar>
              <Typography variant="body2" id="wcfLLPOrLLCDirectorListTitle">
                {director.name}
              </Typography>
            </ListItemButton>
          ))}
        </List>
        {touched.director && errors.director && (
          <FormHelperText className={classes.helperText} error id="wcfLLPOrLLCDirectorListError">
            {errors.director}
          </FormHelperText>
        )}
      </>
    );
  };

  return (
    <Page className={classes.container} p={0}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Box p={3} className={classes.cardWrapper}>
          <Card elevation={4} className={classes.cardContaier}>
            <WcfStepper style={{ width: "100%" }} />
            <CardHeader
              title={t("Common.CompanyType.RegisteredCompany")}
              subheader={t("Registration.RegisteredCompany.CardSubheader")}
              data-testid="registered-company-header"
              id="orgDetailsLTDCompanyTitle"
            />
            <Divider />
            <CardContent style={{ width: "100%" }}>
              <Box>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={5}>
                    {renderCompanySearch()}
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <Box mb={5}>
                      <Typography variant="h4" component="h3">
                        Select your Company below:
                      </Typography>
                    </Box>
                    {renderCompanyList()}
                    <Box mb={3} mt={3}>
                      <Typography variant="h4" component="h3">
                        Select your Director:
                      </Typography>
                    </Box>
                    {renderDirectorList()}
                  </Grid>
                </Grid>
              </Box>
              <Box textAlign="center" pt={4}>
                <Typography variant="body1">
                  The company information displayed here is retrieved from Companies House{" "}
                  <Link
                    variant="body1"
                    href="https://www.gov.uk/file-changes-to-a-company-with-companies-house"
                    target="_blank"
                    data-testid="companies-house-link"
                    rel="noopener noreferrer"
                    id="wcfLLPOrLLCCompanyHouseInfoLink">
                    click here
                  </Link>{" "}
                  if you would like to request a change to this information.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <WcfFooter
          displaySaveAndExitButton={false}
          handleSubmit={handleSubmit}
          data={{
            registeredCompanyName: selectedCompany?.title as string,
            companyRegistrationNumber: selectedCompany?.company_number as string,
            orgType: CompanyType.RegisteredCompany,
            director: selectedDirector?.name as string,
          }}
          allowRedirect={false}
        />
      </form>
    </Page>
  );
};

export default OrgDetailsLtdCompany;
