import { FC, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useFormik } from "formik";
import { Box, Button, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlined from "@mui/icons-material/LockOutlined";

import { API } from "@APP/services";
import { SCREEN_PATHS } from "@APP/navigation";
import { Page, AppLoader, CommonTextField, PasswordTooltip } from "@APP/components";
import { passwordValidationSchema } from "@APP/utils";

type FormInputData = { password: string; confirmPassword?: string };

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-4),
  },
  icon: {
    fontSize: 120,
  },
}));

const ResetPasswordView: FC<RouteProps> = ({ location }) => {
  const queryParams = queryString.parse(location?.search ?? "");
  const code = queryParams.code as string;

  const { t } = useTranslation();

  const [isCodeValid, setIsCodeValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdateError, setIsUpdateError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      if (code) {
        setIsLoading(true);
        try {
          await API.verifyResetPasswordCode(code);
        } catch (error) {
          setIsCodeValid(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsCodeValid(false);
      }
    })();
  }, []);

  const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values } =
    useFormik({
      initialValues: { password: "", confirmPassword: "" },
      validationSchema: passwordValidationSchema(t),
      onSubmit: async ({ password }: FormInputData) => {
        setIsLoading(true);
        try {
          await API.resetPassword(code, password);
          setIsSubmitted(true);
        } catch (error) {
          setIsUpdateError(true);
        } finally {
          setIsLoading(false);
        }
      },
    });

  const messageContainer = !isCodeValid || isSubmitted || isUpdateError;

  if (isLoading) return <AppLoader />;

  return (
    <Page
      title="Reset password"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center">
      {messageContainer ? (
        <Container className={classes.container} maxWidth="sm">
          {isSubmitted ? (
            <LockOpenOutlinedIcon className={classes.icon} color="secondary" />
          ) : (
            <LockOutlined className={classes.icon} color="primary" />
          )}
          <Box mt={2} mb={3}>
            <Typography align="center" variant="h1" gutterBottom id={"reset-password-title"}>
              {!isCodeValid && "Reset Account Password"}
              {isSubmitted && "Password reset successful"}
              {isUpdateError && "Password reset failure"}
            </Typography>
            <Typography align="center" gutterBottom id={"reset-password-subtitle"}>
              {!isCodeValid && "Your reset code is invalid or expired, please try again."}
              {isSubmitted && (
                <>
                  You have successfully reset the password for your account. <br /> You can use your
                  new password to log in to the app.
                </>
              )}
              {isUpdateError && <>{t("Errors.Registration.Alert.ResetPwGeneric")}</>}
            </Typography>
          </Box>
          <Button
            href={isSubmitted ? SCREEN_PATHS.APP_ROOT : SCREEN_PATHS.LOGIN}
            variant="contained"
            id={"reset-password-button-ok"}
            color={isSubmitted ? "secondary" : "primary"}>
            Okay
          </Button>
        </Container>
      ) : (
        <Container className={classes.container} maxWidth="sm">
          <Box mb={3} mt={1}>
            <LockOutlined className={classes.icon} color="primary" />
          </Box>
          <Typography align="center" variant="h1" gutterBottom id={"reset-password-title-one"}>
            Reset Account Password
          </Typography>
          <form onSubmit={handleSubmit} id={"reset-password-form"}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="body1" color="textSecondary">
                Enter a strong password&nbsp;
              </Typography>
              <PasswordTooltip />
            </Box>
            <CommonTextField
              disabled={isSubmitting}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              fullWidth
              placeholder="Enter Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              data-testid="password-input"
              id="password-input"
              autoComplete="new-password"
            />
            <CommonTextField
              disabled={isSubmitting}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              fullWidth
              placeholder="Confirm Password"
              margin="normal"
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.confirmPassword}
              data-testid="confirm-password-input"
              id="confirm-password-input"
              autoComplete="new-password"
            />
            <Box mt={2}>
              <Button
                color="primary"
                data-testid="reset-password-button-res"
                disabled={
                  values.password !== values.confirmPassword ||
                  values.password === "" ||
                  Boolean(touched.password && errors.password)
                }
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                id="resetPasswordButtonReset">
                Reset
              </Button>
            </Box>
          </form>
        </Container>
      )}
    </Page>
  );
};

export default ResetPasswordView;
