import { useEffect, useState } from "react";
import { Box, Card, CardHeader, SimplePaletteColorOptions, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";

import {
  getWorkingCapitalFinanceDetails,
  setActiveStep,
  setFormType,
  setNextPath,
  setNextStepActive,
} from "@APP/redux";
import { Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import WcfFooter from "@APP/components/layout/WCFLayout";
import palette from "@APP/styles/theme/default/palette";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";

import FinanceOptions from "./FinanceOptions";
import WcfIcon from "../../../assets/wcfIcons/WcfIcon";
import AssetFinancingIcon from "../../../assets/wcfIcons/AssetFinancingIcon";
import InvoiceFinanceIcon from "../../../assets/wcfIcons/InvoiceFinanceIcon";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "flex-start",
    height: "80vh",
    borderRadius: "5px",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
  assetFinanceIcon: {
    padding: "16px",
    color: (palette.primary as SimplePaletteColorOptions)?.main,
    fontSize: "50px",
  },
}));

const options = [
  {
    id: 1,
    uniqueName: "asset_finance",
    icon: <AssetFinancingIcon />,
    title: "Asset finance",
    subTitle:
      "Whether you are looking to fund new or used vehicles, equipment or machinery, enable expansion plans, consolidate debts or provide an injection of working capital",
    selected: false,
  },
  {
    id: 2,
    uniqueName: "invoice_finance",
    icon: <InvoiceFinanceIcon />,
    title: "Invoice financing",
    subTitle:
      "We know that waiting to get paid can be frustrating and that the cash tied up in unpaid invoices could be put to better use helping you grow your business.We can help by providing funding against these invoices, and where needed we will also provide a professional credit control service that works in tandem with your business to collect debts when they are due from your clients.",
    selected: false,
  },
];

const FinanceType = () => {
  const dispatch = useDispatch();
  const { nextStepActive, nextPath } = useSelector(getWorkingCapitalFinanceDetails);
  const classes = useStyles();
  const [optionsClicked, setOptionsClicked] = useState(false);
  const [customOptions, setCustomOptions] = useState(options);
  const [title, setTitle] = useState("");

  const handleOptionsClicked = (id: number, title: string) => {
    setOptionsClicked(true);
    setTitle(title);
    const selectedOption = options.map((option) =>
      option.id === id ? { ...option, selected: true } : { ...option, selected: false },
    );
    setCustomOptions(selectedOption);
  };

  useEffect(() => {
    if (nextStepActive && nextPath) {
      dispatch(setNextStepActive(false));
      dispatch(setNextPath(""));
    }
  }, []);

  useEffect(() => {
    dispatch(setNextStepActive(optionsClicked));
    dispatch(setActiveStep(1));
    if (title === "Invoice financing") {
      dispatch(setNextPath(SCREEN_PATHS.WCF_LOAN_APPLICATION));
      dispatch(setFormType("Invoice Finance"));
    } else if (title === "Asset finance") {
      dispatch(setNextPath(SCREEN_PATHS.WCF_FINANCE_INVOICE_ASSET_FINANCE));
      dispatch(setFormType("Asset Finance"));
    }
  }, [title]);

  return (
    <Page className={classes.container} p={0}>
      <Box display="flex" flexDirection="column" flexGrow={1} p={3}>
        <Card elevation={4} className={classes.cardContainer}>
          <WcfStepper style={{ width: "100%" }} />
          <span className={classes.assetFinanceIcon}>
            <WcfIcon height={71} width={116} />
          </span>
          <Typography sx={{ paddingLeft: "16px" }} variant="h3" id="financeTypeTitle">
            What type of finance do you need?
          </Typography>
          <CardHeader
            subheader="No matter what finance your business needs, we are here to help,
             Please select the type of financing you require."
          />
          <ul style={{ maxWidth: "60%" }}>
            {customOptions.map((option) => (
              <li
                key={option.id}
                id={"wcfTypeOfFinanceLink_" + option.uniqueName}
                style={{ listStyleType: "none", marginBottom: "16px", cursor: "pointer" }}
                onClick={() => handleOptionsClicked(option.id, option.title)}>
                <FinanceOptions
                  id={"wcfTypeOfFinanceOptionCard_" + option.uniqueName}
                  Icon={option.icon}
                  title={option.title}
                  subTitle={option.subTitle}
                  selected={option.selected}
                />
              </li>
            ))}
          </ul>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter displaySaveAndExitButton={false} displayPrevButton={false} title={title} />
    </Page>
  );
};

export default FinanceType;
