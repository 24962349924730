import { createActionCreator } from "deox";

import { Receivable, ReceivableWithFailureReason } from "@APP/types";

export const setDefaultAutomatedCollectionsState = createActionCreator(
  "@AUTOMATED_COLLECTIONS/SET_DEFAULT_STATE",
  (resolve) => () => resolve(),
);

export const selectReceivables = createActionCreator(
  "@AUTOMATED_COLLECTIONS/SELECT_RECEIVABLES",
  (resolve) => (receivables: Receivable[]) => resolve(receivables),
);

export const removeSelectedReceivable = createActionCreator(
  "@AUTOMATED_COLLECTIONS/REMOVE_RECEIVABLE",
  (resolve) => (externalId: string) => resolve(externalId),
);

export const addSelectedReceivable = createActionCreator(
  "@AUTOMATED_COLLECTIONS/ADD_RECEIVABLE",
  (resolve) => (receivable: Receivable) => resolve(receivable),
);

export const updateTypedContractDetails = createActionCreator(
  "@AUTOMATED_COLLECTIONS/UPDATE_TYPED_CONTRACT_DETAILS",
  (resolve) => (receivables: Receivable[]) => resolve(receivables),
);

export const clearReceivablesList = createActionCreator(
  "@AUTOMATED_COLLECTIONS/CLEAR_RECEIVABLES_LIST",
  (resolve) => () => resolve(),
);

export const markFailureReceivable = createActionCreator(
  "@AUTOMATED_COLLECTIONS/MARK_FAILURE_RECEIVABLE",
  (resolve) => (receivable: ReceivableWithFailureReason) => resolve(receivable),
);

export const markSuccessReceivable = createActionCreator(
  "@AUTOMATED_COLLECTIONS/MARK_SUCCESS_RECEIVABLE",
  (resolve) => (receivable: Receivable) => resolve(receivable),
);

export const clearSuccessListReceivables = createActionCreator(
  "@AUTOMATED_COLLECTIONS/CLEAR_SUCCESS_LIST_RECEIVABLES",
  (resolve) => () => resolve(),
);

export const clearFailureListReceivables = createActionCreator(
  "@AUTOMATED_COLLECTIONS/CLEAR_FAILURE_LIST_RECEIVABLES",
  (resolve) => () => resolve(),
);

export const removeFailureReceivable = createActionCreator(
  "@AUTOMATED_COLLECTIONS/REMOVE_FAILURE_RECEIVABLE",
  (resolve) => (externalId: string) => resolve(externalId),
);
