import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { eachDayOfInterval } from "date-fns";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  getForecastBalances,
  getForecastChartData,
  getForecastEndDate,
  getForecastLoading,
} from "@APP/redux";
import { formatDisplayedDate, getCurrencySymbol } from "@APP/utils";
import { ForecastChartData } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";

import CustomLegend from "./CustomLegend";
import CustomTooltip from "./CustomTooltip";
import ErrorContent from "./ErrorContent";
import ChartFooter from "./ChartFooter";

const useStyles = makeStyles((theme) => ({
  chart: {
    ...theme.typography.body2,
  },
  cardContent: {
    padding: 0,
  },
  label: {
    textAnchor: "start",
  },
}));

const getEmptyData = (forecastEndDate: Date) =>
  eachDayOfInterval({ start: new Date(), end: forecastEndDate }).map((date) => ({
    date: formatDisplayedDate(date),
  })) as ForecastChartData[];

const DEFAULT_CHART_TICKS = [0, 10000, 20000, 30000, 40000, 50000, 60000, 70000];

export const ForecastLineChart = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isLessThenSmall = useMediaQuery(theme.breakpoints.down("md"));

  const balances = useSelector(getForecastBalances);
  const forecastEndDate = useSelector(getForecastEndDate);
  const data = useSelector(getForecastChartData);

  const formattedDateData = useMemo(() => {
    const dataForChart = data?.map(({ date, balance }) => ({
      date: formatDisplayedDate(new Date(date)),
      balance,
    })) as ForecastChartData[];

    /*
      To show straight line instead of one dot, it's required to add another dot with empty date field
     */
    if (dataForChart?.length === 1) {
      return [{ balance: dataForChart[0].balance, date: "" }, dataForChart[0]];
    }

    return dataForChart;
  }, [data]);

  const currencySymbol = getCurrencySymbol(balances?.totalBalance?.currency);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        className={classes.chart}
        data={formattedDateData || getEmptyData(forecastEndDate)}
        margin={{
          right: parseInt(theme.spacing(5)),
          top: isLessThenSmall ? parseInt(theme.spacing(2)) : 0,
        }}>
        {formattedDateData && <Tooltip content={<CustomTooltip />} />}
        <XAxis
          label={{
            value: "Date",
            offset: 0,
            position: "right",
            dy: -parseInt(theme.spacing(2)),
            dx: parseInt(theme.spacing(1)),
          }}
          dataKey="date"
          dx={parseInt(theme.spacing(5))}
          dy={parseInt(theme.spacing(1))}
          padding={{ right: parseInt(theme.spacing(5)) }}
        />
        <ReferenceLine y={0} stroke="grey" />
        <YAxis
          tickFormatter={(value) =>
            new Intl.NumberFormat("en", {
              notation: isPhone || value.toString().length > 6 ? "compact" : "standard",
              compactDisplay: "short",
            }).format(value)
          }
          allowDecimals={false}
          ticks={formattedDateData ? undefined : DEFAULT_CHART_TICKS}
          type="number"
          tickLine={false}
          padding={{ top: 10 }}>
          <Label
            position="insideTopRight"
            className={classes.label}
            dx={parseInt(theme.spacing(-6.25))}
            dy={parseInt(theme.spacing(-3.5))}>
            {t("CashFlowForecast.ForecastChart.YAxisLabel", { currencySymbol })}
          </Label>
        </YAxis>
        <Legend
          align="right"
          verticalAlign="top"
          content={<CustomLegend />}
          wrapperStyle={{
            left: parseInt(theme.spacing(4)),
          }}
        />
        <Line
          type="monotone"
          dataKey="balance"
          stroke={theme.palette.primary.main}
          dot={false}
          activeDot={false}
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export type ForecastChartProps = {
  onTryAgain?: () => void;
  showNavigateToForecast?: boolean;
  errorMessage?: string;
  errorCodeMessage?: string;
};

export const ForecastChart = ({
  showNavigateToForecast,
  onTryAgain,
  errorMessage,
  errorCodeMessage,
}: ForecastChartProps) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const forecastLoading = useSelector(getForecastLoading);

  const renderMainContent = () => {
    if (forecastLoading) {
      return (
        <Box height={400} width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }

    if (errorMessage)
      return (
        <ErrorContent
          errorCodeMessage={errorCodeMessage}
          errorMessage={errorMessage}
          onTryAgain={onTryAgain}
        />
      );

    return (
      <>
        <ForecastLineChart />
        <ChartFooter />
        {showNavigateToForecast && (
          <Box mt={2} mx={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              fullWidth={isPhone}
              onClick={() => history.push(SCREEN_PATHS.CASHFLOW_FORECAST)}
              id="forecastChartPersonalizeButton">
              Personalise your Forecast
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <Grid item xs={12}>
      <Card elevation={4}>
        <CardHeader title="Your Forecast" />
        <Divider />
        <CardContent className={isPhone ? classes.cardContent : undefined}>
          {renderMainContent()}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ForecastChart;
