import { createReducer } from "deox";

import { Receivable, ReceivableWithFailureReason } from "@APP/types";
import {
  addSelectedReceivable,
  clearFailureListReceivables,
  clearReceivablesList,
  clearSuccessListReceivables,
  markFailureReceivable,
  markSuccessReceivable,
  removeFailureReceivable,
  removeSelectedReceivable,
  selectReceivables,
  setDefaultAutomatedCollectionsState,
  updateTypedContractDetails,
} from "@APP/redux/actions/automatedCollections";

export interface AutomatedCollectionsState {
  receivables: Receivable[];
  receivablesSuccess: Receivable[];
  receivableFailure: ReceivableWithFailureReason[];
}

const defaultState: AutomatedCollectionsState = {
  receivables: [],
  receivablesSuccess: [],
  receivableFailure: [],
};

const automatedCollectionsReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setDefaultAutomatedCollectionsState, () => defaultState),
  handleAction(selectReceivables, (state, { payload }) => ({
    ...state,
    receivables: payload,
  })),
  handleAction(addSelectedReceivable, (state, { payload }) => ({
    ...state,
    receivables: [...state.receivables, payload],
  })),
  handleAction(removeSelectedReceivable, (state, { payload }) => ({
    ...state,
    receivables: state.receivables.filter(
      (receivable) => receivable.entityDetails.externalId !== payload,
    ),
  })),
  handleAction(clearReceivablesList, (state) => ({
    ...state,
    receivables: [],
    receivablesSuccess: [],
  })),
  handleAction(updateTypedContractDetails, (state, { payload }) => ({
    ...state,
    receivables: payload.map((selectedReceivable) => ({
      ...selectedReceivable,
      customerContact: {
        ...selectedReceivable.customerContact,
        email:
          payload.find(
            (receivable) =>
              receivable.entityDetails.externalId === selectedReceivable.entityDetails.externalId,
          )?.customerContact.email || "",
      },
    })),
  })),
  handleAction(markFailureReceivable, (state, { payload }) => {
    let receivableFailure = state.receivableFailure;
    const isAlreadyAddedReceivable = !!receivableFailure.find(
      (item) => item.entityDetails.externalId === payload.entityDetails.externalId,
    );

    if (isAlreadyAddedReceivable) {
      receivableFailure = state.receivableFailure.map((item) =>
        item.entityDetails.externalId === payload.entityDetails.externalId ? payload : item,
      );
    } else {
      receivableFailure = [...state.receivableFailure, payload];
    }

    return {
      ...state,
      receivableFailure: receivableFailure,
    };
  }),
  handleAction(markSuccessReceivable, (state, { payload }) => ({
    ...state,
    receivablesSuccess: [...state.receivablesSuccess, payload],
  })),
  handleAction(clearSuccessListReceivables, (state) => ({
    ...state,
    receivablesSuccess: [],
    receivables: state.receivables.filter(
      (receivable) =>
        !state.receivablesSuccess.some(
          (successReceivable) =>
            successReceivable.entityDetails.externalId === receivable.entityDetails.externalId,
        ),
    ),
  })),
  handleAction(clearFailureListReceivables, (state) => ({
    ...state,
    receivableFailure: [],
    receivables: state.receivables.filter(
      (receivable) =>
        !state.receivableFailure.some(
          (failureReceivable) =>
            failureReceivable.entityDetails.externalId === receivable.entityDetails.externalId,
        ),
    ),
  })),
  handleAction(removeFailureReceivable, (state, { payload }) => ({
    ...state,
    receivableFailure: state.receivableFailure.filter(
      (receivable) => receivable.entityDetails.externalId !== payload,
    ),
  })),
]);

export default automatedCollectionsReducer;
