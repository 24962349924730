import { Box, Card, Typography, useTheme, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/InfoRounded";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 48,
  },
  iconWarning: {
    color: theme.palette.warning.light,
  },
  iconError: {
    color: theme.palette.error.light,
  },
  iconSuccess: {
    color: theme.palette.success.light,
  },
  iconInfo: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  type?: "success" | "warning" | "info" | "error";
  children: React.ReactNode | string;
}

const MessageCard = ({ type = "info", children }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isPhoneOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card elevation={6}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={isPhoneOrTablet ? "column" : "row"}
        textAlign={isPhoneOrTablet ? "center" : "left"}
        p={3}>
        <Box>
          {type === "success" && <CheckIcon className={clsx(classes.icon, classes.iconSuccess)} />}
          {type === "warning" && (
            <WarningIcon className={clsx(classes.icon, classes.iconWarning)} />
          )}
          {type === "info" && <InfoIcon className={clsx(classes.icon, classes.iconInfo)} />}
          {type === "error" && (
            <ErrorOutlineIcon className={clsx(classes.icon, classes.iconError)} />
          )}
        </Box>
        <Box ml={2} mt={isPhoneOrTablet ? 2 : 0}>
          {typeof children === "string" ? (
            <Typography color="inherit" variant="body2">
              {children}
            </Typography>
          ) : (
            children
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default MessageCard;
