import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FooterActionsButtons, InvoiceSummary, Page, ResultNotification } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getInvoice,
  getPermissions,
  setDefaultInvoiceState,
  setDefaultRtpDetailsToReceivables,
  setDeliveryEmails,
  updateTypedContractDetails,
  useAppDispatch,
} from "@APP/redux";
import CONFIG from "@APP/config";
import { formatCurrency } from "@APP/utils";
import { useAccessPermission, useAlert } from "@APP/hooks";
import { useEffect } from "react";

const InvoiceSuccess = () => {
  const { fetchPermission } = useAccessPermission();
  const permissions = useSelector(getPermissions);
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    fetchPermission([{ rtp: "create" }]);
  }, []);

  const invoice = useSelector(getInvoice);

  const handleContinue = () => {
    if (!invoice.externalReceivable) return;

    if (
      CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT <
      Number(invoice.externalReceivable.totalAmountTaxInclusive.amount)
    ) {
      return alert.open(
        t("Errors.RTPCreation.Alerts.ExceedsMax.Title"),
        t("Errors.RTPCreation.Alerts.ExceedsMax.Message", {
          MAX: formatCurrency(CONFIG.INPUTS.MAXIMUM_TRANSACTION_AMOUNT, {
            minimumFractionDigits: 0,
          }),
        }),
        [
          { text: "Close" },
          { text: "View Invoices", onClick: () => history.push(SCREEN_PATHS.RECEIVABLES_LIST) },
        ],
      );
    }

    dispatch(setDefaultInvoiceState());
    dispatch(updateTypedContractDetails([invoice.externalReceivable]));
    dispatch(setDefaultRtpDetailsToReceivables());
    dispatch(
      setDeliveryEmails({
        invoiceId: invoice.externalReceivable.entityDetails.externalId,
        email: invoice.externalReceivable.customerContact?.email ?? "",
      }),
    );

    history.push(SCREEN_PATHS.PAYMENT_REQUESTS_DELIVERY_DETAILS);
  };

  return (
    <Page title="View your Invoice">
      <ResultNotification title="You are almost there!" type="success">
        Your Invoice was successfully created, but it has not been sent to your customer. Please
        select send payment request to proceed.
      </ResultNotification>
      <Box mt={2}>
        <FooterActionsButtons
          backButtonText="Go to Customer Invoices"
          handleBackButton={() => history.push(SCREEN_PATHS.RECEIVABLES_LIST)}
          continueButtonText="Send Payment Request"
          hiddenContinueButton={!permissions?.rtp?.create}
          handleContinue={handleContinue}
        />
      </Box>
      <Box mt={2}>
        <InvoiceSummary
          customerContact={invoice.externalReceivable?.customerContact}
          reference={invoice.externalReceivable?.reference}
          invoiceIssueDateTime={invoice.externalReceivable?.dateTimeIssued}
          invoiceDueDateTime={invoice.externalReceivable?.dueDateTime}
          lineItems={invoice.externalReceivable?.lineItems}
          totalAmountTaxExclusive={invoice.externalReceivable?.totalAmountTaxExclusive?.amount}
          totalAmountTaxInclusive={invoice.externalReceivable?.totalAmountTaxInclusive?.amount}
          currency={invoice.externalReceivable?.totalAmountTaxExclusive?.currency}
          taxCategories={invoice.externalReceivable?.taxCategories}
        />
      </Box>
    </Page>
  );
};

export default InvoiceSuccess;
